import { useNavigation } from '@react-navigation/native'
import { Pressable, View } from 'react-native'
import { useAuth } from '../contexts/authContext'
import Button from './Button'
import PasswordField from './inputs/PasswordField'
import TextField from './inputs/TextField'
import Typography from './Typography'
import { useController, useForm } from 'react-hook-form'
import Surface, { SurfaceProps } from './Surface'
import { useTranslations } from '../contexts/localeContext'
import Constants from 'expo-constants'
import { useStyles } from '../hooks/useStyles'
import { useSpacingFn } from '../contexts/SpacingContext'

type LoginFormData = {
  username: string
  password: string
}

interface LoginFormProps extends SurfaceProps {}

const LoginForm = ({ style, ...props }: LoginFormProps) => {
  const { logIn, isLoginError, isLoginLoading, loginError } = useAuth()
  const navigation = useNavigation()
  const t = useTranslations()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<LoginFormData>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const { field: usernameField } = useController({
    name: 'username',
    control,
    rules: { required: true },
  })

  const { field: passwordField } = useController({
    name: 'password',
    control,
    rules: { required: true },
  })

  const autofillLogin = (username: string, password: string) => {
    setValue('username', username)
    setValue('password', password)
    trigger(['password', 'username']) // Trigger the validation for each field
  }

  const onSubmit = ({ username, password }: LoginFormData) => {
    logIn(username, password)
  }

  const styles = useStyles(({ spacing }) => ({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    headingContainer: {
      marginBottom: spacing(4),
    },
    inputWrapper: {
      width: '100%',
      marginBottom: spacing(4),
    },
    forgotPasswordContainer: {
      marginVertical: spacing(6),
    },
    inputErrorContainer: {
      marginTop: spacing(1),
      marginLeft: spacing(4),
    },
    loginErrorContainer: {
      marginBottom: spacing(4),
    },
    loginButtonContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))

  const spacing = useSpacingFn()
  return (
    <Surface {...props} style={[styles.container, style]}>
      <View style={styles.headingContainer}>
        <Typography variant="h1" color="on-tertiary">
          {t('log_in_prompt')}
        </Typography>
      </View>
      <View style={styles.inputWrapper}>
        <TextField
          value={usernameField.value}
          onChangeText={usernameField.onChange}
          label={t('email')}
          ref={usernameField.ref}
          onBlur={usernameField.onBlur}
          error={Boolean(errors.username)}
        />
        {errors.username && (
          <Typography variant="caption" color="error" style={styles.inputErrorContainer}>
            {t('errors.field_required')}
          </Typography>
        )}
      </View>

      <View style={styles.inputWrapper}>
        <PasswordField
          value={passwordField.value}
          onChangeText={passwordField.onChange}
          onBlur={passwordField.onBlur}
          error={Boolean(errors.password)}
        />
        {errors.password && (
          <Typography variant="caption" color="error" style={styles.inputErrorContainer}>
            {t('errors.field_required')}
          </Typography>
        )}
      </View>

      <View style={styles.forgotPasswordContainer}>
        <Pressable onPress={() => navigation.navigate('ForgotPassword')} hitSlop={24}>
          <Typography variant="p1" color="on-tertiary">
            {t('did_you_forget_password')}
          </Typography>
        </Pressable>
      </View>

      {isLoginError && (
        <Typography variant="p1" color="error" style={styles.loginErrorContainer}>
          {loginError.message}
        </Typography>
      )}

      <View style={styles.loginButtonContainer}>
        <Button
          title={t('log_in')}
          onPress={handleSubmit(onSubmit)}
          loading={isLoginLoading}
          loadingIndicatorPosition="trailing"
          color="primary"
          size="large"
        />
        {Constants.expoConfig.extra.env === 'development' && (
          <>
            <Button
              size="small"
              color="warning"
              title="Autofill SYSTEM"
              onPress={() => autofillLogin('ulrik@avaofnorway.com', 'graphql')}
              style={{ marginTop: spacing(4) }}
            />
            <Button
              size="small"
              color="warning"
              title="Autofill BRAND"
              onPress={() => autofillLogin('tech@guilty.no', 'graphql')}
              style={{ marginTop: spacing(4) }}
            />
            <Button
              size="small"
              color="warning"
              title="Autofill DEALER"
              onPress={() => autofillLogin('dealer@guilty.no', 'graphql')}
              style={{ marginTop: spacing(4) }}
            />
            <Button
              size="small"
              color="warning"
              title="Autofill STORE"
              onPress={() => autofillLogin('store@guilty.no', 'graphql')}
              style={{ marginTop: spacing(4) }}
            />
          </>
        )}
      </View>
    </Surface>
  )
}

export default LoginForm
