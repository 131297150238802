import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Lottery, UserTickets } from '../../types/api'
import Badge from '../Badge'
import ArrowButton from '../buttons/ArrowButton'
import ImageHeader from '../ImageHeader'
import { useTranslations } from '../../contexts/localeContext'

export interface LotteryImageHeaderProps {
  lottery: Lottery
  userTickets: UserTickets
}

export const LotteryImageHeader: React.FC<LotteryImageHeaderProps> = ({ lottery, userTickets }) => {
  const navigation = useNavigation()
  const isExpired = lottery.isExpired
  const t = useTranslations()

  return (
    <ImageHeader
      title={isExpired ? t('ended_lottery') : t('active_lottery')}
      image={{ uri: lottery.imageUrl ?? undefined }}
      topLeftNode={
        <ArrowButton
          size={40}
          variant="left"
          onPress={() => navigation.navigate('Home', { screen: 'Lotteries' })}
        />
      }
      bottomLeftNode={
        <Badge
          label={`${t('your_tickets')}: ${
            isExpired ? lottery.userTicketsConsumed : userTickets.balance
          }`}
          size="extra-large"
          color="surface"
        />
      }
      bottomRightNode={
        !isExpired && (
          <Badge
            label={`${lottery.prizes.length} ${t('prizes')}`}
            size="extra-large"
            color="secondary"
          />
        )
      }
    />
  )
}
