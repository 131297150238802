import React, { HTMLAttributes } from 'react'
import { ImageStyle, StyleProp } from 'react-native'

export interface ResponsiveImageProps {
  source: string
  width?: number
  style?: StyleProp<ImageStyle>
  webStyle?: React.CSSProperties
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ source, webStyle }) => {
  // This is just a regular image.
  return (
    <img
      src={source}
      style={{
        maxWidth: '100%',
        height: 'auto',
        ...webStyle,
      }}
    />
  )
}

export default ResponsiveImage
