import { useMemo } from 'react'
import { useWindowDimensions, View } from 'react-native'
import * as YoutubeIframeLoader from 'youtube-iframe'
import { useSpacingFn } from '../../../contexts/SpacingContext'
import { ContentVideo } from '../../../types/course'
import VideoPlayer from '../../cloudinary/VideoPlayer.web'
import Typography from '../../Typography'
import { getYoutubeVideoId, youtubeEventToEnum } from '../../../utils/youtube'

type VideoBlockProps = {
  content: ContentVideo
  onComplete(): void
}

const VideoBlock = ({ content, onComplete }: VideoBlockProps) => {
  // Extract video id from url
  if (!content.url) return null
  const spacing = useSpacingFn()
  const { width } = useWindowDimensions()

  if (content.type === 'cloudinary' && content.resourceId) {
    console.log('cloudinary video', content, content.resourceId)
    return (
      <View>
        <VideoPlayer source={content.url} width={width - spacing(8)} aspectRatio={4 / 3} />
        {content.caption && (
          <Typography variant="p2" style={{ marginLeft: spacing(3), marginTop: spacing(1) }}>
            {content.caption}
          </Typography>
        )}
      </View>
    )
  }

  const id = useMemo(() => {
    const id = getYoutubeVideoId(content.url)
    return id
  }, [content])

  const handleChangeState = (event: string | { data: number }) => {
    let state = event

    if (typeof event === 'object' && event.data) {
      state = youtubeEventToEnum(event.data)
    }

    switch (state) {
      case 'unstarted':
        return
      case 'playing':
        onComplete()
        return
      case 'ended':
        return

      default:
        return
    }
  }

  const PLAYER_MAX_WIDTH = 700
  const now = Date.now().toString()
  const videoWidth = Math.min(width, PLAYER_MAX_WIDTH) - spacing(8)
  const videoHeight = (videoWidth * 9) / 16

  if (id === undefined) {
    return null
  }

  YoutubeIframeLoader.load((YT) => {
    const player = new YT.Player(`player-${id}-${now}`, {
      height: videoHeight,
      width: videoWidth,
      videoId: id,
      showInfo: 0,
      events: {
        onStateChange: handleChangeState,
      },
      playerVars: {
        modestbranding: 1,
      },
    })
  })

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <div style={{ width: videoWidth }}>
        <div>
          <div id={`player-${id}-${now}`}></div>
        </div>
        {content.caption && (
          <Typography variant="p2" style={{ marginLeft: spacing(3), marginTop: spacing(1) }}>
            {content.caption}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default VideoBlock
