import ScrollView from '../components/ScrollView'
import { RootStackScreenProps } from '../navigation/types'
import { View } from 'react-native'
import Container from '../components/Container'
import { useSpacingFn } from '../contexts/SpacingContext'
import Typography from '../components/Typography'
import { useStyles } from '../hooks/useStyles'
import { usePurchasedUrlProductQuery } from '../generated/graphql'
import Button from '../components/Button'
import { format } from 'date-fns'
import Badge from '../components/Badge'
import * as WebBrowser from 'expo-web-browser'
import ProductImageHeader from '../components/product/ProductImageHeader'
import { useTranslations } from '../contexts/localeContext'

const PurchasedUrlProduct = (props: RootStackScreenProps<'PurchasedUrlProduct'>) => {
  const t = useTranslations()
  const productId = props.route.params.productId

  const { data } = usePurchasedUrlProductQuery({ id: productId })

  const spacing = useSpacingFn()

  const styles = useStyles(({ spacing, palette }) => ({
    badge: {
      alignSelf: 'flex-start',
      marginBottom: spacing(4),
    },
    container: { flex: 1 },
    scrollContainer: { backgroundColor: palette.surface.main },
  }))

  if (data?.purchasedUrlProduct?.__typename === 'ProductNotFoundError') {
    return <Typography>{t('product_not_found')}</Typography>
  }

  const product = data?.purchasedUrlProduct?.data.product
  const urlProduct = data?.purchasedUrlProduct?.data
  const isExpired =
    data?.purchasedUrlProduct &&
    new Date(data.purchasedUrlProduct.data.product.activeTo) < new Date()
  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollContainer}>
        {product && urlProduct && (
          <ProductImageHeader
            product={product}
            badgeProps={{
              color: 'background',
              size: 'extra-large',
              label: `${t('expires_at')} ${format(
                new Date(urlProduct.product.activeTo),
                'dd/MM/yyyy'
              )}`,
            }}
          ></ProductImageHeader>
        )}
        <View>
          <Container style={{ marginVertical: spacing(4) }}>
            {product && (
              <Typography variant="h2" weight="semibold">
                {product.name}
              </Typography>
            )}
            {product && (
              <Typography style={{ marginVertical: spacing(4) }} variant="p1">
                {product.description}
              </Typography>
            )}
            {urlProduct && (
              <View style={{ alignItems: 'center' }}>
                <Button
                  title={t('view_coupon')}
                  tintColor="on-success"
                  disableElevation
                  color="success"
                  size="large"
                  style={{ marginVertical: spacing(4) }}
                  onPress={() => WebBrowser.openBrowserAsync(urlProduct.url)}
                />
              </View>
            )}
            {isExpired && (
              <Typography
                style={{ marginVertical: spacing(4), textAlign: 'center' }}
                variant="subtitle"
                color="error"
              >
                {t('coupon_expired')}
              </Typography>
            )}
          </Container>
        </View>
      </ScrollView>
    </View>
  )
}

export default PurchasedUrlProduct
