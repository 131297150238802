import Constants from 'expo-constants'

export interface ReactNativeFile {
  uri: string
  name: string
  filename: string
  type: string
}

export interface ImageUploadClient {
  /**
   * Upload an image to cloudinary, unsignedly
   * @param file A file object.
   */
  upload(file: string | ReactNativeFile): Promise<string>
}

interface UploadApiResponse {
  public_id: string
  version: string
  width: number
  height: number
  format: string
  created_at: string
  resource_type: string
  tags: string[]
  bytes: number
  type: string
  etag: string
  url: string
  secure_url: string
  signature: string
  original_filename: string
}

interface ErrorResponse {
  error: {
    message: string
  }
}

const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'error' in obj &&
    'message' in (obj.error as ErrorResponse)
  )
}

const isUploadApiResponse = (obj: unknown): obj is UploadApiResponse => {
  return typeof obj === 'object' && obj !== null && 'secure_url' in obj
}

export default class CloudinaryImageUploader implements ImageUploadClient {
  upload(file: ReactNativeFile | string): Promise<string> {
    const preset = String(Constants.expoConfig?.extra?.cloudinaryAvatarUploadPreset!)

    const formData = new FormData()
    // @ts-ignore Because typescript doesnt know how react-native handles form data
    formData.append('file', file)
    formData.append('upload_preset', preset)
    formData.append('cloud_name', 'ava-of-norway')

    return fetch('https://api.cloudinary.com/v1_1/ava-of-norway/image/upload', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data: unknown) => {
        if (isErrorResponse(data)) {
          throw new Error(data.error.message)
        }

        if (isUploadApiResponse(data)) {
          return data.secure_url
        }

        throw new Error('Urecognized api response from cloudinary.')
      })
  }
}
