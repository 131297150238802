import { DocumentText1, ArrowRight2, LampCharge } from 'iconsax-react-native'
import { useCallback, useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { useTranslations } from '../contexts/localeContext'
import { CourseChapterType } from '../generated/graphql'
import { useAnimatedElevation } from '../hooks/useAnimatedElevation'
import IconButton from './IconButton'
import ProgressBar from './ProgressBar'
import Surface, { SurfaceProps } from './Surface'
import Typography from './Typography'
import Icon from '@expo/vector-icons/Feather'
import { useTheme } from '../contexts/ThemeContext'
import { Color } from '../hooks/usePaletteColor'
import { useStyles } from '../hooks/useStyles'

export interface ChapterListItemProps extends SurfaceProps {
  /**
   * Chapter heading.
   */
  heading: string

  /**
   * Is the chapter is completed
   */
  completed?: boolean

  /**
   * Is the chapter started
   */
  started?: boolean

  /**
   * What is the current step of this chapter.
   * Used for showing progress bar
   */
  currentStep?: number

  /**
   * What is the total number of steps in this chapter.
   * Used for showing progress bar
   */
  totalSteps?: number

  type?: CourseChapterType

  disabled?: boolean

  onPress?: () => void
}

const ChapterListItem = ({
  completed = false,
  started = false,
  disabled = false,
  heading,
  currentStep = 0,
  totalSteps = 100,
  type = CourseChapterType.Info,
  onPress,
  style,
  ...props
}: ChapterListItemProps) => {
  const theme = useTheme()
  const t = useTranslations()

  const [pressed, setPressed] = useState(false)
  const elevation = useAnimatedElevation(pressed ? 4 : 0)

  const handlePressIn = useCallback(() => {
    setPressed(true)
  }, [])

  const handlePressOut = useCallback(() => {
    setPressed(false)
  }, [])

  const transformedHeading = useMemo(() => {
    const textMaxLength = 30
    if (heading.length < textMaxLength) {
      return heading
    }

    return `${heading.slice(0, textMaxLength)}...`
  }, [heading])

  const progressColor = useMemo((): Color => {
    if (completed) {
      return 'success'
    }

    const percentage = Math.round((currentStep / totalSteps) * 100)
    if (percentage >= 50) {
      return 'success'
    }

    return 'warning'
  }, [currentStep, totalSteps])

  const styles = useStyles(({ spacing, shapes }) => ({
    surface: {
      ...shapes.extraLarge,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      padding: spacing(3),
    },
    leadingContainer: {
      marginRight: spacing(3),
    },
    trailingContainer: {
      marginLeft: spacing(3),
    },
    bodyContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    progressBarContainer: {
      marginTop: spacing(2),
    },
    contentContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }))

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onHoverIn={() => setPressed(true)}
      onHoverOut={() => setPressed(false)}
    >
      <Surface {...props} style={[style, styles.surface, elevation]}>
        <View style={styles.leadingContainer}>
          {type === CourseChapterType.Info && (
            <IconButton
              pointerEvents="none"
              color="background"
              icon={({ color, size }) => <DocumentText1 color={color} size={size} />}
            />
          )}
          {type === CourseChapterType.Quiz && (
            <IconButton
              pointerEvents="none"
              color="background"
              icon={({ color, size }) => <LampCharge color={color} size={size} />}
            />
          )}
        </View>
        <View style={styles.bodyContainer}>
          <View style={styles.contentContainer}>
            <View style={{ flex: 1 }}>
              <Typography variant="subtitle">{transformedHeading}</Typography>
              <Typography variant="p2">
                {completed ? t('complete') : started ? t('started') : t('get_started')}
              </Typography>
            </View>
            {started && (
              <View>
                {completed ? (
                  <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Icon name="check" color={theme.palette.success.main} size={28} />
                  </View>
                ) : (
                  <IconButton
                    pointerEvents="none"
                    size={32}
                    color="background"
                    icon={({ color, size }) => <ArrowRight2 color={color} size={size} />}
                  />
                )}
              </View>
            )}
          </View>
          <View style={styles.progressBarContainer}>
            {started && (
              <ProgressBar
                size="small"
                color={progressColor}
                currentStep={completed ? (totalSteps === 0 ? 1 : totalSteps) : currentStep}
                totalSteps={Math.max(1, totalSteps)}
              />
            )}
          </View>
        </View>
        {!started && (
          <View style={styles.trailingContainer}>
            <IconButton
              pointerEvents="none"
              color="success"
              icon={
                <Typography variant="subtitle" color="on-success" weight="semibold">
                  {t('start', { capitalized: false })}
                </Typography>
              }
            />
          </View>
        )}
      </Surface>
    </Pressable>
  )
}

export default ChapterListItem
