import { setStatusBarStyle } from 'expo-status-bar'
import { Coin } from 'iconsax-react-native'
import { useEffect, useState } from 'react'
import { FlatList, ListRenderItemInfo, Platform, Pressable, View, ViewStyle } from 'react-native'

import Avatar from '../../components/Avatar'
import Button, { ButtonProps } from '../../components/Button'
import Container from '../../components/Container'
import CourseListCard from '../../components/course/CourseListCard'
import IconButton from '../../components/IconButton'
import SafeAreaView from '../../components/SafeAreaView'
import ScrollView from '../../components/ScrollView'
import Skeleton from '../../components/Skeleton'
import Surface from '../../components/Surface'
import Typography from '../../components/Typography'
import { useTranslations } from '../../contexts/localeContext'
import { useSpacingFn } from '../../contexts/SpacingContext'
import {
  CourseStatus,
  CourseType,
  useAppCompanyCourseFiltersQuery,
  useAppCourseParentsQuery,
} from '../../generated/graphql'
import { usePaletteColor } from '../../hooks/usePaletteColor'
import { useStyles } from '../../hooks/useStyles'
import { HomeTabScreenProps } from '../../navigation/types'
import { CourseParentListItem } from '../../types/api'

interface CourseListFilter {
  type: CourseType[]
  companyIds: string[]
}

interface CourseListPagination {
  take: number
  skip: number
}

const CompanyCard: React.FC<{
  active: boolean
  imageUrl?: string
  id: string
  name: string
  value: number
  style?: ViewStyle

  onPress(id: string): void
}> = ({ name, value, id, active, onPress, imageUrl, style }) => {
  const palette = usePaletteColor(active ? 'accent' : 'surface')
  const styles = useStyles(({ spacing, shapes }) => ({
    container: {
      paddingHorizontal: spacing(2),
      paddingTop: spacing(4),
      width: spacing(15),
      paddingBottom: spacing(2),
      ...shapes.extraLarge,
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: spacing(20),
      backgroundColor: palette.main,
    },
    imageContainer: {
      marginBottom: spacing(1),
    },
  }))
  return (
    <Pressable onPress={() => onPress(id)}>
      <Surface style={[styles.container, style]}>
        <View style={styles.imageContainer}>
          <Avatar image={{ uri: imageUrl }} name={name} />
        </View>
        <Typography color={palette.on} variant="p2" numberOfLines={1}>
          {name}
        </Typography>
        <Typography color={palette.on} style={{ fontFamily: 'Inter-SemiBold' }}>
          {value.toString()}
        </Typography>
      </Surface>
    </Pressable>
  )
}

const Courses = ({ navigation }: HomeTabScreenProps<'Courses'>) => {
  const OFFSET = 20

  setStatusBarStyle('dark')
  const t = useTranslations()

  const spacing = useSpacingFn()

  const [courses, setCourses] = useState<CourseParentListItem[]>([])
  const [pagination, setPagination] = useState<CourseListPagination>({ take: OFFSET, skip: 0 })
  const [filter, setFilter] = useState<CourseListFilter>({ type: [], companyIds: [] })
  const [endIsReached, setEndIsReached] = useState<boolean>(false)

  const { data, isLoading, isInitialLoading, refetch } = useAppCourseParentsQuery(
    { ...pagination, type: filter.type[0] ?? undefined, companies: filter.companyIds ?? undefined },
    {
      onSuccess(data) {
        if (data.appCourseParents.count === courses.length + data.appCourseParents.list.length) {
          setEndIsReached(true)
        }
        const newCourseIds = data.appCourseParents.list.map(({ id }) => id)
        const existingCourseIds = courses.map(({ id }) => id)

        const intersectionSize = new Set(newCourseIds.concat(existingCourseIds)).size

        // If any of the incoming items have items that overlap with the current list of courses
        // we do nothing.
        if (intersectionSize === newCourseIds.length + existingCourseIds.length) {
          setCourses((courses) => [...courses, ...data.appCourseParents.list])
        }
      },
    }
  )

  const { data: companyFilterData, refetch: refetchCompanyFilters } =
    useAppCompanyCourseFiltersQuery()

  const onCompanyCardPress = (id: string) => {
    if (filter.companyIds.includes(id)) {
      setFilter((filter) => ({
        ...filter,
        companyIds: filter.companyIds.filter((_id) => _id !== id),
      }))
    } else {
      setFilter({
        ...filter,
        companyIds: [id],
      })
    }
  }

  const onFilterButtonPress = (key: CourseType | null) => {
    if (key === null) {
      setFilter((filter) => ({ ...filter, type: [] }))
      return
    }

    if (filter.type.includes(key)) {
      return
    }

    setFilter((filter) => ({ ...filter, type: [key] }))
  }

  const onEndReached = () => {
    if (data?.appCourseParents.count === courses.length) {
      setEndIsReached(true)
    } else {
      setPagination((previous) => ({ ...previous, skip: previous.skip + previous.take }))
    }
  }

  useEffect(() => {
    // Reset pagination when filter changes.
    setCourses([])
    setPagination({ take: OFFSET, skip: 0 })
    setEndIsReached(false)
  }, [filter])

  useEffect(() => {
    // Refetch courses when screen is focused.
    const unsubscribe = navigation.addListener('focus', () => {
      refetch()
      setCourses([])
      setPagination({ take: OFFSET, skip: 0 })
      setEndIsReached(false)
      refetchCompanyFilters()
    })

    return unsubscribe
  }, [navigation])

  const styles = useStyles(({ palette, spacing, shapes }) => ({
    container: {
      flex: 1,
      backgroundColor: palette.background.main,
    },
    safeAreaContainer: {
      flex: 1,
      position: 'relative',
    },
    scrollContainer: {
      flexGrow: 1,
    },
    scrollContentContainer: {
      flexGrow: 1,
      paddingTop: spacing(4),
      paddingHorizontal: spacing(4),
      paddingBottom: spacing(4),
    },
    titleContainer: {
      backgroundColor: palette.background.main,
      justifyContent: 'space-between',
    },
    skeletonItems: {
      ...shapes.extraLarge,
      height: spacing(16),
    },
  }))

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <Container style={{ flex: 1, paddingHorizontal: 0 }}>
          <View
            style={{
              paddingHorizontal: spacing(4),
              paddingTop: spacing(4),
              marginBottom: spacing(4),
            }}
          >
            <Typography variant="h2">{t('titles.my_courses')}</Typography>
          </View>
          <View
            style={{
              justifyContent: 'center',
            }}
          >
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: 'row', flexGrow: 0, marginBottom: spacing(4) }}
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: spacing(4),
              }}
            >
              {(companyFilterData === undefined
                ? []
                : companyFilterData.appCompanyCourseFilters
              ).map(({ company, courseCount }, index) => (
                <CompanyCard
                  key={company.id}
                  id={company.id}
                  active={filter.companyIds.includes(company.id)}
                  name={company.name}
                  value={courseCount}
                  style={{ marginLeft: index === 0 ? 0 : spacing(2) }}
                  imageUrl={company.avatarUrl ?? undefined}
                  onPress={(id) => onCompanyCardPress(id)}
                />
              ))}
            </ScrollView>
          </View>
          <View
            style={{
              justifyContent: 'center',
              paddingBottom: spacing(2),
            }}
          >
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: 'row', flexGrow: 0 }}
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: spacing(4),
              }}
            >
              {[
                { type: null, title: t('all') },
                { type: CourseType.Recommended, title: t('recommended') },
                { type: CourseType.Optional, title: t('optional') },
                { type: CourseType.Required, title: t('required') },
              ].map((button: ButtonProps & { type: CourseType | null }, index: number) => {
                const isAllButton = button.type === null
                const noFiltersActive = filter.type.length === 0
                const isActive = button.type !== null && filter.type.includes(button.type)

                return (
                  <Button
                    {...button}
                    rounded={true}
                    variant={isActive || (isAllButton && noFiltersActive) ? 'outline' : 'standard'}
                    color={'surface'}
                    tintColor={
                      isActive || (isAllButton && noFiltersActive) ? 'accent' : 'on-surface'
                    }
                    size="medium"
                    disableElevation={true}
                    style={{
                      marginLeft: index === 0 ? 0 : spacing(2),
                    }}
                    key={button.title}
                    onPress={() => onFilterButtonPress(button.type)}
                  />
                )
              })}
            </ScrollView>
          </View>
          <FlatList
            style={styles.scrollContainer}
            contentContainerStyle={styles.scrollContentContainer}
            data={courses}
            keyExtractor={(item: CourseParentListItem) => String(item.id)}
            ListFooterComponent={
              isInitialLoading ? (
                <View>
                  {Array.from(new Array(6).keys()).map((_, i) => {
                    return (
                      <Skeleton
                        key={i}
                        variant="rectangular"
                        style={[styles.skeletonItems, { marginTop: spacing(i === 0 ? 0 : 2) }]}
                      ></Skeleton>
                    )
                  })}
                </View>
              ) : (
                <View style={{ marginTop: spacing(5), alignItems: 'center' }}>
                  {!endIsReached && (
                    <Button
                      title={t('load_more')}
                      color="surface"
                      disableElevation={Platform.OS !== 'web'}
                      onPress={onEndReached}
                      loading={isLoading}
                      rounded
                    ></Button>
                  )}
                </View>
              )
            }
            renderItem={({ item, index }: ListRenderItemInfo<CourseParentListItem>) => {
              return (
                <View
                  style={{
                    marginTop: index === 0 ? 0 : spacing(2),
                  }}
                >
                  <CourseListCard course={item} />
                </View>
              )
            }}
          />
        </Container>
      </SafeAreaView>
    </View>
  )
}

export default Courses
