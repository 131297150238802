import { View, ViewStyle } from 'react-native'
import { TypographyVariant, useTheme } from '../contexts/ThemeContext'
import { Color } from '../hooks/usePaletteColor'
import { useStyles } from '../hooks/useStyles'
import Typography from './Typography'

type BadgeSize = 'small' | 'medium' | 'large' | 'extra-large'
export interface BadgeProps {
  label?: string | number
  color?: Color
  max?: number
  size?: BadgeSize
  style?: ViewStyle
}

const Badge: React.FC<BadgeProps> = ({
  label = '',
  color = 'secondary',
  max = 99,
  size = 'medium',
  style,
}) => {
  const theme = useTheme()
  const styles = useStyles<{ container: any }>(
    ({ spacing, palette }) => {
      const defaultStyles = {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 9999,
        height: spacing(2),
        minWidth: spacing(2),
        backgroundColor: palette[color].main,
      }

      const containerSizeStyles = {
        small: {
          width: spacing(2),
          minWidth: spacing(2),
        },
        medium: {
          height: spacing(4),
          paddingHorizontal: spacing(2),
        },
        large: {
          height: spacing(5),
          paddingHorizontal: spacing(2),
        },
        'extra-large': {
          height: spacing(8),
          paddingHorizontal: spacing(4),
        },
      }

      return {
        container: {
          ...defaultStyles,
          ...containerSizeStyles[size],
        },
      }
    },
    [color, size]
  )
  const typographyVariants: { [K in BadgeSize]: TypographyVariant } = {
    small: 'caption',
    medium: 'p2',
    large: 'p2',
    'extra-large': 'subtitle',
  }

  const getLabel = () => {
    switch (typeof label) {
      case 'number':
      case 'string':
        return (
          <Typography
            variant={typographyVariants[size]}
            color={theme.palette[color].on}
            weight="medium"
          >
            {typeof label === 'number' && label > max ? `${max}+` : `${label}`}
          </Typography>
        )
    }
  }
  return <View style={[styles.container, style]}>{getLabel()}</View>
}

export default Badge
