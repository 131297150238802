import { useMemo } from 'react'
import { User } from '../../types/api'
import Avatar, { AvatarProps } from '../Avatar'
import Skeleton from '../Skeleton'

export interface UserAvatarProps extends AvatarProps {
  user?: User
}

const UserAvatar = ({ user, image: imageProp, ...props }: UserAvatarProps) => {
  const isLoading = useMemo(() => user === undefined, [user])
  const image =
    imageProp !== undefined ? imageProp : user?.avatarUrl ? { uri: user.avatarUrl } : undefined
  return isLoading ? (
    <Skeleton variant="circular">
      <Avatar {...props} />
    </Skeleton>
  ) : (
    <Avatar name={user?.fullName ?? props?.name ?? ''} image={image} {...props} />
  )
}

export default UserAvatar
