import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useStyles } from '../hooks/useStyles'

export interface ContainerProps {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}

const Container: React.FC<ContainerProps> = ({ children, style }) => {
  const styles = useStyles(({ spacing }) => ({
    container: {
      maxWidth: 700,
      width: '100%',
      marginHorizontal: 'auto',
      paddingHorizontal: spacing(4),
    },
  }))

  return <View style={[styles.container, style]}>{children}</View>
}

export default Container
