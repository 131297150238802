import { ActivityIndicator, Platform, ScrollView, View } from 'react-native'
import Button from '../../components/Button'
import Surface from '../../components/Surface'
import Typography from '../../components/Typography'
import { useCourse } from '.'
import { useTranslations } from '../localeContext'
import { useTheme } from '../ThemeContext'
import { useSpacingFn } from '../SpacingContext'
import Container from '../../components/Container'
import ProgressBar from '../../components/ProgressBar'
import Modal from '../../components/modals/Modal'

import React, { useRef, useState } from 'react'
import {
  useCourseParentProgressSummaryQuery,
  useCourseParentQuery,
  useCourseModuleProgressSummaryQuery,
  useMeQuery,
  useCoinAccountSummaryQuery,
} from '../../generated/graphql'
import { useStyles } from '../../hooks/useStyles'
import CircleData from '../../components/CircleData'
import ListCard from '../../components/ListCard'
import Avatar from '../../components/Avatar'
import Badge from '../../components/Badge'
import CircleProgress from '../../components/CircleProgress'
import SafeAreaView from '../../components/SafeAreaView'
import { useNavigation } from '@react-navigation/native'
import HeaderBottomRoundedBox from '../../components/layout/HeaderBottomRoundedBox'
import LinearGradient from '../../components/LinearGradient'
import CourseListCard from '../../components/course/CourseListCard'
import CoinIconSvg from '../../components/graphics/CoinIconSvg'
import Stack from '../../components/Stack'
import chroma from 'chroma-js'
import IconButton from '../../components/IconButton'
import { Lock } from 'iconsax-react-native'
import { useFeatureFlags } from '../FeatureFlags'
import ConfettiCannon, { ConfettiCannonHandle } from '../../components/Confetti'
import LotteryIcon from '../../components/graphics/LotteryIcon'

/**
 * This screen has maybe two parts:
 * - First, since the module is completed, show the user some nice feedback because they did a good job.
 * - Second, if the user has completed all modules in the course, a "continue" button should show the next screen with a nice summary of the whole course.
 */
export type CoinProgressProps = { current: number; total: number; tickerValue?: number }

const CoinProgress: React.FC<CoinProgressProps> = ({ current, total, tickerValue }) => {
  const theme = useTheme()
  const t = useTranslations()

  const styles = useStyles(({ palette, spacing }) => ({
    container: {
      width: '100%',
    },
    cointTickerContainer: {
      alignItems: 'center',
      marginBottom: spacing(3),
    },
    coinTicker: {
      borderRadius: spacing(1),
      padding: spacing(1),
      backgroundColor: palette.success.main,
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: spacing(2),
      alignItems: 'center',
    },
  }))

  return (
    <View style={styles.container}>
      {tickerValue !== undefined && (
        <View style={styles.cointTickerContainer}>
          <Surface style={styles.coinTicker}>
            <Typography variant="subtitle" color="on-success">
              {`+${tickerValue}`}
            </Typography>
          </Surface>
        </View>
      )}
      <ProgressBar
        currentStep={current}
        totalSteps={total}
        backgroundColor={chroma(theme.palette.primary.on).saturate(1).hex()}
      />
      <View style={styles.footer}>
        <Typography color="on-secondary">{t('plural.coin', { count: 0 }) + ':'}</Typography>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            color="on-secondary"
            style={{ marginRight: 5 }}
          >{`${current} / ${total}`}</Typography>
          <CoinIconSvg size={20} color="secondary" />
        </View>
      </View>
    </View>
  )
}

const CourseEnd: React.FC = () => {
  const { course, exit, endCourseModuleResponse, restartCourseProgression, openRatingScreen } =
    useCourse()

  const { flags } = useFeatureFlags()

  const meQuery = useMeQuery()

  const userId = meQuery.data?.me?.id

  const coinAccountSummaryQuery = useCoinAccountSummaryQuery({})
  const courseParentQuery = useCourseParentQuery({ courseParentId: course.parentId })
  const courseModuleProgressSummaryQuery = useCourseModuleProgressSummaryQuery({
    moduleId: course.id,
  })
  const courseParentSummaryQuery = useCourseParentProgressSummaryQuery(
    { userId: userId!, courseParentId: course.parentId },
    { enabled: !!userId }
  )

  const [restartModalIsOpen, setRestartModalIsOpen] = useState<boolean>(false)

  const isSingleModuleCourse = courseParentQuery.data?.appCourseParent?.modules.length === 1

  // Decides whether we show the "module complete screen" or the "course complete screen"
  const [showCourseSummary, setShowCourseSummary] = useState<boolean>(isSingleModuleCourse)

  const t = useTranslations()
  const theme = useTheme()
  const spacing = useSpacingFn()
  const navigation = useNavigation()

  const confettiRef = useRef<ConfettiCannonHandle | null>(null)

  if (
    meQuery.isInitialLoading ||
    courseModuleProgressSummaryQuery.isInitialLoading ||
    courseParentSummaryQuery.isInitialLoading ||
    courseParentQuery.isInitialLoading
  ) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="small" color="#4106C9" />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
        {!showCourseSummary ? (
          <View style={{ flex: 1 }}>
            <LinearGradient
              from="secondary"
              to="accent"
              style={{ flex: 1, justifyContent: 'center', paddingBottom: spacing(10) }}
            >
              <SafeAreaView style={{ flex: 1 }}>
                <Container style={{ flex: 1 }}>
                  <Stack justify="center" spacing={14} flex={1}>
                    <Stack spacing={4}>
                      {courseModuleProgressSummaryQuery.isSuccess &&
                        courseParentSummaryQuery.isSuccess && (
                          <Stack
                            justify="space-between"
                            align="stretch"
                            spacing={10}
                            style={{ marginTop: spacing(4) }}
                          >
                            <CircleData
                              preTitle={t('plural.module', { count: 1 })}
                              title={`${course.normalizedOrder} ${t('of', {
                                capitalized: false,
                              })} ${
                                courseParentSummaryQuery.data.courseParentProgressSummary
                                  .numberOfModulesInCourseParent
                              }`}
                              postTitle={t('complete')}
                              style={{ alignSelf: 'center' }}
                            />

                            <CoinProgress
                              current={
                                courseModuleProgressSummaryQuery.data.courseModuleProgressSummary
                                  .coinsEarnedOnModule
                              }
                              total={
                                courseModuleProgressSummaryQuery.data.courseModuleProgressSummary
                                  .maxCoinsOnModule
                              }
                              tickerValue={
                                endCourseModuleResponse?.coinsEarnedInSession !== 0
                                  ? endCourseModuleResponse?.coinsEarnedInSession
                                  : undefined
                              }
                            />
                          </Stack>
                        )}
                      <Stack align="center" spacing={0}>
                        <Typography variant="h1" color="on-secondary">
                          {t('well_done') + '!'}
                        </Typography>
                        <Typography variant="p1" color="on-secondary">
                          {t('you_have_completed_module') + ':'}
                        </Typography>
                      </Stack>
                      <ListCard
                        heading={course.title}
                        leading={
                          <Avatar
                            image={{ uri: course.imageUrl ?? course.ownerAvatar ?? undefined }}
                          />
                        }
                        trailing={<CircleProgress completed={true} />}
                      >
                        <Badge color="background" label={t('complete')} size="medium" />
                      </ListCard>
                      <Button
                        title={t('restart_module')}
                        variant="outline"
                        color="secondary"
                        onPress={() => setRestartModalIsOpen(true)}
                        style={{ alignSelf: 'center', backgroundColor: 'transparent' }}
                      />
                    </Stack>
                    <Stack align="center" spacing={3}>
                      {courseParentSummaryQuery.isSuccess &&
                      Boolean(
                        courseParentSummaryQuery.data.courseParentProgressSummary
                          .nextIncompleteModuleId
                      ) ? (
                        <Button
                          title={t('next_module')}
                          color="success"
                          tintColor="on-success"
                          size="large"
                          onPress={() =>
                            navigation.navigate('Course', {
                              courseId:
                                courseParentSummaryQuery.data.courseParentProgressSummary
                                  .nextIncompleteModuleId!,
                            })
                          }
                        />
                      ) : (
                        <Button
                          title={t('continue')}
                          color="success"
                          tintColor="on-success"
                          size="large"
                          onPress={() => setShowCourseSummary(true)}
                        />
                      )}
                      <Button
                        title={t('back')}
                        variant="text"
                        color="on-secondary"
                        onPress={() => exit()}
                      />
                    </Stack>
                  </Stack>
                </Container>
              </SafeAreaView>
            </LinearGradient>
          </View>
        ) : (
          <View style={{ flex: 1, paddingBottom: spacing(10) }}>
            <HeaderBottomRoundedBox>
              <LinearGradient to="accent" from="secondary" style={{ flex: 1 }}>
                <SafeAreaView style={{ paddingTop: spacing(4) }}>
                  <Container
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: spacing(6),
                      paddingHorizontal: spacing(7),
                    }}
                  >
                    {coinAccountSummaryQuery.isSuccess && (
                      <CircleData
                        preTitle={t('my_coins')}
                        title={`${coinAccountSummaryQuery.data.coinAccountSummary.balance}`}
                        style={{ marginBottom: spacing(6) }}
                      />
                    )}
                    {courseParentSummaryQuery.isSuccess && (
                      <CoinProgress
                        current={
                          courseParentSummaryQuery.data.courseParentProgressSummary.totalCoinsEarned
                        }
                        total={courseParentSummaryQuery.data.courseParentProgressSummary.maxCoins}
                      />
                    )}
                  </Container>
                </SafeAreaView>
              </LinearGradient>
            </HeaderBottomRoundedBox>
            <Container style={{ paddingVertical: spacing(6) }}>
              <Stack spacing={4}>
                <Stack align="center" spacing={0}>
                  <Typography variant="h1">{t('excellent')}</Typography>
                  <Typography>{t('you_completed_course') + ':'}</Typography>
                </Stack>
                {courseParentQuery.isSuccess && courseParentQuery.data.appCourseParent && (
                  <View style={{ width: '100%' }}>
                    <CourseListCard course={courseParentQuery.data.appCourseParent} />
                  </View>
                )}
              </Stack>
            </Container>
            {flags.Lottery && courseParentSummaryQuery.isSuccess && (
              <Stack spacing={0} style={{ flex: 1 }}>
                <Container>
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Typography variant="p1" textAlign="center" style={{ marginRight: 3 }}>
                      {t('you_released')}
                    </Typography>
                    <Typography
                      variant="p1"
                      textAlign="center"
                      weight="semibold"
                      style={{ marginRight: 3 }}
                    >{`${
                      courseParentSummaryQuery.data.courseParentProgressSummary.ticketsEarned
                    } ${t('of')} ${
                      courseParentSummaryQuery.data.courseParentProgressSummary.maxTickets
                    }`}</Typography>
                    <Typography
                      variant="p1"
                      textAlign="center"
                      style={{ textTransform: 'lowercase' }}
                    >
                      {t('tickets')}
                    </Typography>
                  </View>
                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{
                      paddingVertical: spacing(2),
                      paddingHorizontal: spacing(4),
                    }}
                  >
                    {Array.from({
                      length: courseParentSummaryQuery.data.courseParentProgressSummary.maxTickets,
                    }).map((_, i) => {
                      const isLocked =
                        i >= courseParentSummaryQuery.data.courseParentProgressSummary.ticketsEarned
                      return (
                        <Surface
                          key={i}
                          category="large"
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 80,
                            height: 120,
                            marginLeft: i === 0 ? 0 : spacing(2),
                            backgroundColor: isLocked
                              ? theme.palette['background'].main
                              : theme.palette['surface'].main,
                          }}
                          elevation={2}
                        >
                          <IconButton
                            color={isLocked ? 'background' : 'secondary'}
                            key={i}
                            icon={(proppies) =>
                              isLocked ? <Lock {...proppies} /> : <LotteryIcon {...proppies} />
                            }
                          />
                          <Typography weight="semibold">
                            {isLocked ? t('locked') : t('new')}
                          </Typography>
                          <Typography>{t('ticket')}</Typography>
                        </Surface>
                      )
                    })}
                  </ScrollView>
                </Container>
              </Stack>
            )}
            <Container>
              <Stack spacing={4}>
                <Button
                  title={t('go_to_courses')}
                  size="large"
                  color="success"
                  tintColor="on-success"
                  onPress={exit}
                  style={{ alignSelf: 'center', marginTop: spacing(6) }}
                />
                <Button
                  title={t('rate_course_button')}
                  variant="text"
                  color="on-background"
                  size="large"
                  onPress={() => openRatingScreen()}
                />
                {isSingleModuleCourse ? (
                  <Button
                    title={t('restart_course')}
                    color="on-background"
                    variant="text"
                    onPress={() => setRestartModalIsOpen(true)}
                    style={{ alignSelf: 'center' }}
                  ></Button>
                ) : (
                  <Button
                    title={t('back')}
                    variant="text"
                    color="on-primary"
                    onPress={() => setShowCourseSummary(false)}
                  ></Button>
                )}
              </Stack>
            </Container>
          </View>
        )}
        <Modal open={restartModalIsOpen} onClose={() => setRestartModalIsOpen(false)}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Surface
              style={{
                backgroundColor: theme.palette.background.main,
                paddingHorizontal: spacing(6),
                paddingVertical: spacing(10),
                ...theme.shapes.large,
              }}
              elevation={10}
            >
              <Typography color="on-background" variant="h2">
                {t('restart_module')}
              </Typography>
              <Typography color="on-background">{t('restart_module_confirm')}</Typography>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: spacing(4),
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color="surface"
                  title={t('no')}
                  style={{ marginEnd: spacing(2) }}
                  onPress={() => setRestartModalIsOpen(false)}
                ></Button>
                <Button
                  color="error"
                  tintColor="on-error"
                  title={t('yes')}
                  onPress={() => restartCourseProgression()}
                ></Button>
              </View>
            </Surface>
          </View>
        </Modal>
      </ScrollView>
      {Platform.OS !== 'android' && <ConfettiCannon ref={confettiRef} runOnMount={true} />}
    </View>
  )
}

export default CourseEnd
