import { ReactNode, useMemo } from 'react'
import { View } from 'react-native'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { useTheme } from '../../contexts/ThemeContext'

export interface GridLayoutProps<T> {
  columns?: number
  items: Array<T>
  renderItem(item: T, index: number): ReactNode
}

const GridLayout = <T,>({ columns = 2, items, renderItem }: GridLayoutProps<T>) => {
  const theme = useTheme()
  const spacing = useSpacingFn()

  const batchedItems = useMemo<Array<Array<T>>>(() => batch(items, columns), [columns, items])

  return (
    <View>
      {batchedItems.map((items, i) => (
        <View key={'row' + i} style={{ flexDirection: 'row', marginTop: i === 0 ? 0 : spacing(2) }}>
          {items.map((item, j) => (
            <View key={'column' + j} style={{ marginLeft: j === 0 ? 0 : spacing(2), flex: 1 }}>
              {renderItem(item, i + i + j)}
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

/**
 * Batches an array into chunks of desired size.
 * Example : batch([1,2,3,4,5], 2) becomes [[1,2], [3,4], [5]]
 *
 * Last batch may have size equal to batchSize or less.
 * @param list A list of a type T
 * @param batchSize The size of each batch
 * @returns An array of batches
 */
const batch = <T,>(list: Array<T>, batchSize: number = 2): Array<Array<T>> => {
  let arr = []

  for (let i = 0; i < list.length; i = i + batchSize) {
    const slice = list.slice(i, i + batchSize)
    arr.push(slice)
    if (batchSize < slice.length - 1) {
      return arr
    }
  }
  return arr
}

export default GridLayout
