import { PurchasedUrlProduct } from '../../types/api'
import Card from '../Card'
import { useTranslations } from '../../contexts/localeContext'

export type UrlProductCardProps = {
  urlProduct: PurchasedUrlProduct
  onPress(): void
}

const UrlProductCard: React.FC<UrlProductCardProps> = ({ urlProduct, onPress }) => {
  const isExpired = new Date() > new Date(urlProduct.product.activeTo)
  const t = useTranslations()

  return (
    <Card
      title={urlProduct.product.name}
      subtitle={t('more_info')}
      imageUrl={urlProduct.product.imageUrl ?? undefined}
      onPress={onPress}
      badgeProps={
        isExpired
          ? {
              label: t('expired'),
              color: 'background',
              size: 'large',
            }
          : undefined
      }
    />
  )
}

export default UrlProductCard
