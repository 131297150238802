import React, { useEffect } from 'react'

export interface LinkProps {
  href: string
}

export const Link: React.FC<LinkProps> = ({ href }) => {
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    document.head.appendChild(link)
    return () => {
      document.head.removeChild(link)
    }
  }, [href])
  return null
}
