import { View, ViewStyle } from 'react-native'
import { useTheme } from '../contexts/ThemeContext'
import { useStyles } from '../hooks/useStyles'
import Surface from './Surface'
import Typography from './Typography'
import Svg, { Circle, Defs, LinearGradient, Stop } from 'react-native-svg'

export type CircleDataProps = {
  preTitle?: string
  title: string
  postTitle?: string
  style?: ViewStyle
}
const CircleData: React.FC<CircleDataProps> = ({ preTitle, title, postTitle, style }) => {
  const theme = useTheme()

  const styles = useStyles(({ spacing, palette }) => ({
    container: {
      width: spacing(30),
      height: spacing(30),
      borderRadius: spacing(15),
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: 'transparent',
    },
    gradient: {
      flex: 1,
      padding: spacing(2),
    },
    titleWrapper: {
      position: 'relative',
    },
    preTitleWrapper: {
      position: 'absolute',
      bottom: '100%',
      width: '100%',
      alignSelf: 'center',
    },
    postTitleWrapper: {
      position: 'absolute',
      top: '100%',
      width: '100%',
      alignSelf: 'center',
    },
    content: {
      position: 'absolute',
      top: spacing(2),
      left: spacing(2),
      right: spacing(2),
      bottom: spacing(2),
      backgroundColor: palette.secondary.main,
      borderRadius: spacing(13),
      justifyContent: 'center',
    },
  }))

  return (
    <Surface elevation={15} style={[styles.container, style]}>
      <Svg width="100%" height="100%" viewBox="0 0 100 100">
        <Defs>
          {/* @ts-ignore */}
          <LinearGradient id="grad" x1="1" y1="0" x2="1" y2="1">
            <Stop offset="0%" stopColor={theme.palette.secondary.main} />
            <Stop offset="100%" stopColor={theme.palette.accent.main} />
          </LinearGradient>
        </Defs>
        <Circle cx="50" cy="50" r="50" fill="url(#grad)" />
      </Svg>
      <View style={[styles.content, { flex: 1 }]}>
        <View style={[styles.titleWrapper, { flex: 1 }]}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
              {preTitle && (
                <Typography weight="semibold" variant="p2" color="on-secondary" textAlign="center">
                  {preTitle}
                </Typography>
              )}
            </View>
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Typography weight="semibold" variant="h1" color="on-secondary" textAlign="center">
                {title}
              </Typography>
            </View>
            <View style={{ flex: 1, justifyContent: 'flex-start' }}>
              {postTitle && (
                <Typography weight="semibold" variant="p2" color="on-secondary" textAlign="center">
                  {postTitle}
                </Typography>
              )}
            </View>
          </View>
        </View>
      </View>
    </Surface>
  )
}

export default CircleData
