import { setStatusBarStyle } from 'expo-status-bar'
import { useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { View } from 'react-native'
import Button from '../components/Button'
import Container from '../components/Container'
import TextField from '../components/inputs/TextField'
import SafeAreaView from '../components/SafeAreaView'
import Typography from '../components/Typography'
import { useTranslations } from '../contexts/localeContext'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useRequestResetPasswordMutation } from '../generated/graphql'
import { useStyles } from '../hooks/useStyles'
import { RootStackScreenProps } from '../navigation/types'

type ForgotPasswordFormData = {
  email: string
}

const ForgotPassword = (_props: RootStackScreenProps<'ForgotPassword'>) => {
  setStatusBarStyle('light')
  const [mutationIsLoading, setMutationIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const t = useTranslations()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>()
  const { field } = useController({
    name: 'email',
    control,
    rules: { required: true, pattern: /^\S+@\S+$/i },
  })

  const requestResetPasswordMutation = useRequestResetPasswordMutation({
    onSuccess(data) {
      switch (data.requestResetPassword.__typename) {
        case 'MutationRequestResetPasswordSuccess':
          return setSuccessMessage(t('feedback.reset_password_success'))
        case 'InvalidEmailError':
          return setErrorMessage(t('errors.invalid_email'))
        case 'UserNotFoundError':
          return setErrorMessage(t('errors.user_not_found'))
        default:
          setErrorMessage(t('errors.unexpected_error'))
      }
    },
    onError(error) {
      console.log(error)
      setErrorMessage(t('errors.unexpected_error'))
    },
    onSettled() {
      setMutationIsLoading(false)
    },
  })
  const submit = (formData: ForgotPasswordFormData) => {
    setMutationIsLoading(true)
    setErrorMessage(undefined)
    setSuccessMessage(undefined)

    requestResetPasswordMutation.mutate(formData)
  }

  const spacing = useSpacingFn()

  const styles = useStyles(({ palette, spacing }) => ({
    container: {
      backgroundColor: palette.tertiary.main,
      flex: 1,
    },
    safeAreaContainer: {
      flex: 1,
      paddingHorizontal: spacing(4),
    },
    buttonContainer: {
      marginTop: spacing(4),
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }))

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <Container style={{ flexGrow: 1 }}>
          <Typography variant="h1" color="on-tertiary" style={{ textAlign: 'center' }}>
            {t('reset_password')}
          </Typography>
          <TextField
            label={t('email')}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            error={Boolean(errors.email)}
            keyboardType="email-address"
            textContentType="emailAddress"
            disabled={mutationIsLoading || Boolean(successMessage)}
            style={{ marginTop: spacing(4) }}
          />
          {errors.email && (
            <Typography
              variant="caption"
              color="error"
              style={{ marginTop: spacing(2), textAlign: 'center' }}
            >
              {errors.email?.type === 'required'
                ? t('errors.field_required')
                : t('errors.invalid_email')}
            </Typography>
          )}
          <Typography
            variant="p2"
            color="on-tertiary"
            style={{ marginTop: spacing(2), textAlign: 'center' }}
          >
            {t('we_will_send_reset_link_to_email')}
          </Typography>
          {successMessage && (
            <Typography
              variant="p2"
              color="on-success"
              style={{ marginTop: spacing(2), textAlign: 'center' }}
            >
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              variant="p2"
              color="error"
              style={{ marginTop: spacing(2), textAlign: 'center' }}
            >
              {errorMessage}
            </Typography>
          )}
          <View style={styles.buttonContainer}>
            <Button
              size="large"
              title={t('confirm')}
              onPress={handleSubmit(submit)}
              loading={mutationIsLoading}
              disabled={mutationIsLoading || Boolean(successMessage)}
            />
          </View>
        </Container>
      </SafeAreaView>
    </View>
  )
}

export default ForgotPassword
