import { DependencyList, useMemo } from 'react'
import { Animated, ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native'
import { SpacingFn, useSpacingFn } from '../contexts/SpacingContext'
import { Theme, useTheme } from '../contexts/ThemeContext'
import { useWindowSize, WindowSize } from '../contexts/WindowSize'

type AnimatedStyle<T> = Animated.WithAnimatedValue<StyleProp<T>>
type NamedStyles<T> = {
  [P in keyof T]:
    | ViewStyle
    | TextStyle
    | ImageStyle
    | AnimatedStyle<ViewStyle>
    | AnimatedStyle<TextStyle>
    | AnimatedStyle<ViewStyle>
}

export const useStyles = <T extends NamedStyles<T>>(
  factory: (utils: Theme & { spacing: SpacingFn; windowSize: WindowSize }) => T,
  deps?: DependencyList | undefined
) => {
  const theme = useTheme()
  const spacing = useSpacingFn()
  const windowSize = useWindowSize()

  return useMemo(
    () => factory({ ...theme, spacing, windowSize }),
    [factory, spacing, windowSize, deps]
  )
}
