import { View } from 'react-native'
import { useStyles } from '../../hooks/useStyles'
import LinearGradient from '../LinearGradient'
import Stack from '../Stack'
import Surface from '../Surface'
import Typography from '../Typography'

export interface SquareDataProps {
  preTitle?: string
  title: string
  postTitle?: string
}

export const SquareData: React.FC<SquareDataProps> = ({ preTitle, title, postTitle }) => {
  const styles = useStyles(({ spacing, shapes, palette }) => ({
    squareContainer: {
      height: spacing(26),
      width: spacing(18),
      backgroundColor: 'transparent',
      overflow: 'hidden',
      ...shapes.xxl,
    },
    gradient: {
      flex: 1,
      padding: spacing(2),
    },
    innerSquare: {
      flex: 1,
      backgroundColor: palette.secondary.main,
      borderRadius: shapes.xxl.borderRadius - spacing(2),
    },
    rotatedContainer: {
      position: 'absolute',
      transformOrigin: 'bottom left',
      transform: [{ rotate: '-15deg' }, { translateX: -25 }, { translateY: 5 }],
    },
  }))
  return (
    <View style={{ alignItems: 'center' }}>
      <Surface style={[styles.squareContainer, styles.rotatedContainer]}>
        <LinearGradient from="secondary" to="accent" style={styles.gradient}>
          <View style={styles.innerSquare}></View>
        </LinearGradient>
      </Surface>
      <Surface style={styles.squareContainer}>
        <LinearGradient from="secondary" to="accent" style={styles.gradient}>
          <View style={[styles.innerSquare, { justifyContent: 'center', alignContent: 'center' }]}>
            <Stack spacing={0}>
              <Typography variant="p2" color="on-secondary" textAlign="center">
                {preTitle ?? ''}
              </Typography>
              <Typography variant="h2" color="on-secondary" textAlign="center">
                {title}
              </Typography>
              <Typography variant="p2" color="on-secondary" textAlign="center">
                {postTitle ?? ''}
              </Typography>
            </Stack>
          </View>
        </LinearGradient>
      </Surface>
    </View>
  )
}
