import { useNavigation } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import { ArrowDown, ArrowDown2 } from 'iconsax-react-native'
import { useState } from 'react'
import { LayoutAnimation, Platform, Pressable, View } from 'react-native'
import { useCourse } from '.'
import Button from '../../components/Button'
import ArrowButton from '../../components/buttons/ArrowButton'
import ChapterListItem from '../../components/ChapterListItem'
import CourseHeader from '../../components/course/CourseHeader'
import ScrollView from '../../components/ScrollView'
import Skeleton from '../../components/Skeleton'
import Typography from '../../components/Typography'
import { useTranslations } from '../localeContext'
import Constants from 'expo-constants'
import { useTheme } from '../ThemeContext'
import { useSpacingFn } from '../SpacingContext'
import Container from '../../components/Container'
import Surface from '../../components/Surface'
import CoinBadge from '../../components/CoinBadge'
import IconButton from '../../components/IconButton'

/**
 * CourseIntro displays an overview of the course and lets you start it, or jump directly to chapters.
 */
const CourseIntro = () => {
  const t = useTranslations()
  const theme = useTheme()
  const spacing = useSpacingFn()

  const {
    isCourseStarted,
    difficulty,
    isDebugMode,
    course,
    chapters,
    startCourse,
    canStartCourse,
    openChapterById,
    chapterOverviewList,
    toggleDebugMode,
  } = useCourse()
  const isLoading = false
  const navigation = useNavigation()
  const [descriptionIsCollapsed, setDescriptionIsCollapsed] = useState(true)
  const toggleDescription = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setDescriptionIsCollapsed((state) => !state)
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ paddingBottom: spacing(10) }}>
        <Container style={{ flexGrow: 1, paddingHorizontal: 0 }}>
          <View style={{ position: 'relative' }}>
            <CourseHeader course={isLoading ? undefined : course} />
            <ArrowButton
              style={{ position: 'absolute', top: spacing(10), start: spacing(4) }}
              variant="left"
              color="surface"
              showElevation={true}
              size={32}
              onPress={() => navigation.navigate('Home', { screen: 'Courses' })}
            />
          </View>

          <View style={{ paddingHorizontal: spacing(4), paddingTop: spacing(2) }}>
            <View style={{ alignItems: 'center', paddingBottom: spacing(5) }}>
              <View style={{ marginBottom: spacing(3) }}>
                {isLoading ? (
                  <Skeleton variant="text" textVariant="h3" style={{ width: '66%' }}></Skeleton>
                ) : (
                  <Typography variant="h3">{`${course.title}`}</Typography>
                )}
              </View>
              <Surface
                style={{
                  paddingVertical: spacing(4),
                  paddingHorizontal: spacing(8),
                  borderRadius: spacing(4),
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: Platform.OS === 'web' ? 'space-around' : 'space-between',
                    alignItems: 'center',
                    marginHorizontal: Platform.OS === 'web' ? '20%' : 0,
                  }}
                >
                  <Typography
                    variant="subtitle"
                    weight="semibold"
                    style={{ marginRight: spacing(4) }}
                  >
                    {`${String(course.duration)} ${t('minutes_short', { capitalized: false })}`}
                  </Typography>
                  {course.coinsAreActivated && (
                    <CoinBadge amount={course.maxCoinsReward} size="large" />
                  )}
                  <Typography
                    variant="subtitle"
                    weight="semibold"
                    style={{ marginLeft: spacing(4) }}
                  >{`${t(difficulty)}`}</Typography>
                </View>
              </Surface>
            </View>
            <Pressable onPress={() => toggleDescription()}>
              {isLoading ? (
                <View style={{ marginTop: spacing(3) }}>
                  <Skeleton variant="text" textVariant="p1" />
                  <Skeleton variant="text" textVariant="p1" style={{ marginTop: spacing(3) }} />
                  <Skeleton
                    variant="text"
                    textVariant="p1"
                    style={{ width: '66%', marginTop: spacing(3) }}
                  />
                </View>
              ) : (
                course?.description && (
                  <View
                    style={{
                      position: 'relative',
                      overflow: descriptionIsCollapsed ? 'hidden' : 'visible',
                      height: descriptionIsCollapsed ? spacing(10) : 'auto',
                      paddingBottom: descriptionIsCollapsed ? 0 : spacing(5),
                    }}
                  >
                    <Typography variant="p1" style={{ marginTop: spacing(2) }}>
                      {course.description}
                    </Typography>
                    <LinearGradient
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        height: spacing(5),
                        width: '100%',
                      }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 0, y: 1 }}
                      locations={[0, 0.9]}
                      colors={['rgba(255,255,255,0)', theme.palette.background.main]}
                    ></LinearGradient>
                  </View>
                )
              )}

              <View style={{ marginTop: spacing(1), alignItems: 'center' }}>
                <IconButton
                  size={40}
                  color="surface"
                  onPress={() => toggleDescription()}
                  icon={() => (
                    <ArrowDown2
                      size={18}
                      color={theme.palette.background.on}
                      style={{
                        transform: !descriptionIsCollapsed ? [{ rotate: '180deg' }] : [],
                        padding: spacing(1.5),
                      }}
                    />
                  )}
                />
              </View>
            </Pressable>

            <Button
              disabled={isLoading || !canStartCourse}
              size="large"
              color="success"
              disableElevation
              tintColor="on-success"
              style={{ marginTop: spacing(8) }}
              title={isCourseStarted ? t('continue') : t('start_module')}
              onPress={() => startCourse()}
            ></Button>

            <View style={{ marginTop: spacing(4) }}>
              <Typography variant="h4">{`${t('all_chapters')} (${chapters.length})`}</Typography>
              {isLoading ? (
                <View style={{ marginTop: spacing(2) }}>
                  <View>
                    <Skeleton variant="rectangular" style={theme.shapes.extraLarge}>
                      <ChapterListItem heading="Loading" />
                    </Skeleton>
                  </View>
                  <View style={{ marginTop: spacing(2) }}>
                    <Skeleton variant="rectangular" style={theme.shapes.extraLarge}>
                      <ChapterListItem heading="Loading" />
                    </Skeleton>
                  </View>
                </View>
              ) : (
                <View style={{ marginTop: spacing(2) }}>
                  {chapterOverviewList.map(
                    ({
                      id,
                      name,
                      type,
                      progression: { currentStep, isCompleted, isStarted, totalSteps },
                    }) => {
                      return (
                        <ChapterListItem
                          key={id}
                          heading={name}
                          started={isStarted}
                          completed={isCompleted}
                          currentStep={currentStep}
                          totalSteps={totalSteps}
                          style={{ marginTop: spacing(2) }}
                          type={type}
                          onPress={() => openChapterById(id)}
                        />
                      )
                    }
                  )}
                </View>
              )}
            </View>
          </View>
          {Constants.expoConfig?.extra?.env === 'development' && (
            <View style={{ marginTop: spacing(2), alignItems: 'center' }}>
              <Button
                color={!isDebugMode ? 'error' : 'warning'}
                title={!isDebugMode ? 'start debug mode' : 'end debug mode'}
                onPress={() => toggleDebugMode()}
              ></Button>
            </View>
          )}
        </Container>
      </ScrollView>
    </View>
  )
}

export default CourseIntro
