import React, { useEffect } from 'react'

export interface ScriptProps {
  src: string
}

export const Script: React.FC<ScriptProps> = ({ src }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.type = 'text/javascript'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
  return null
}
