import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTranslations } from '../../contexts/localeContext'
import { HomeTabScreenProps } from '../../navigation/types'
import Container from '../../components/Container'
import Stack from '../../components/Stack'
import Typography from '../../components/Typography'
import SafeAreaView from '../../components/SafeAreaView'
import Surface from '../../components/Surface'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { Elevation, useTheme } from '../../contexts/ThemeContext'
import { useNavigation } from '@react-navigation/native'
import {
  ActiveLotteryQuery,
  useActiveLotteryQuery,
  useEndedLotteriesQuery,
  useMeQuery,
  useUserTicketsQuery,
} from '../../generated/graphql'
import { Lottery } from '../../types/api'
import Badge from '../../components/Badge'
import { format } from 'date-fns'
import { Sheet } from '../../components/Sheet'
import { useStyles } from '../../hooks/useStyles'
import { SquareData } from '../../components/display/SquareData'
import { IncomingOutgoingDisplay } from '../../components/display/IncomingOutgoingDisplay'
import ListCard from '../../components/ListCard'
import IconButton from '../../components/IconButton'
import { ArrowRight2 } from 'iconsax-react-native'
import LotteryIcon from '../../components/graphics/LotteryIcon'
import chroma from 'chroma-js'

type L = NonNullable<ActiveLotteryQuery['activeLottery']>

const Lotteries = (props: HomeTabScreenProps<'Lotteries'>) => {
  const meQuery = useMeQuery()
  const endedLotteriesQuery = useEndedLotteriesQuery({ userId: meQuery.data?.me?.id })
  const activeLotteryQuery = useActiveLotteryQuery()
  const userTicketsQuery = useUserTicketsQuery()
  const spacing = useSpacingFn()
  const t = useTranslations()

  const styles = useStyles(({ spacing }) => ({
    sheetTop: {
      height: spacing(38),
      justifyContent: 'center',
      alignContent: 'center',
    },
  }))

  const isNoLotteries =
    endedLotteriesQuery.data?.endedLotteries.length === 0 && !activeLotteryQuery.data?.activeLottery

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Sheet snapPoints={[260, 380]} clamp>
        {(progress) => (
          <SafeAreaView disableBottomSafeArea>
            <Container>
              <View style={styles.sheetTop}>
                {userTicketsQuery.data !== undefined && (
                  <SquareData
                    preTitle={t('my_tickets')}
                    title={userTicketsQuery.data.userTickets.balance.toString()}
                  />
                )}
              </View>
              {userTicketsQuery.data !== undefined && (
                <IncomingOutgoingDisplay
                  incomingText={t('total_tickets_earned')}
                  outgoingText={t('total_tickets_used')}
                  incomingValue={userTicketsQuery.data.userTickets.totalEarned}
                  outgoingValue={userTicketsQuery.data.userTickets.totalSpent}
                  opacity={progress}
                />
              )}
            </Container>
          </SafeAreaView>
        )}
      </Sheet>
      {isNoLotteries ? (
        <NoLotteriesGraphics />
      ) : (
        <SafeAreaView style={{ flex: 1, paddingBottom: 60 }}>
          <Container style={{ marginTop: spacing(6) }}>
            <Stack spacing={6}>
              {activeLotteryQuery.isSuccess && (
                <Stack>
                  <Stack spacing={0}>
                    <Typography variant="h3">{t('quala_lotteries')}</Typography>
                    <Typography variant="p2">{`${activeLotteryQuery.data.activeLottery ? 1 : 0} ${t(
                      'plural.active.one'
                    )}`}</Typography>
                  </Stack>
                  {activeLotteryQuery.data?.activeLottery && (
                    <LotteryCard lottery={activeLotteryQuery.data.activeLottery} />
                  )}
                </Stack>
              )}
              {endedLotteriesQuery.isSuccess && (
                <Stack>
                  <Stack spacing={0}>
                    <Typography variant="h3">{t('my_history')}</Typography>
                    <Typography variant="p2">
                      {`${endedLotteriesQuery.data?.endedLotteries.length.toString()} ${t(
                        'ended'
                      )}`}
                    </Typography>
                  </Stack>
                  {endedLotteriesQuery.data?.endedLotteries.map((lottery) => (
                    <LotteryListCard
                      key={lottery.id}
                      lottery={lottery}
                      onPress={() =>
                        props.navigation.navigate('Lottery', { lotteryId: lottery.id })
                      }
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Container>
        </SafeAreaView>
      )}
    </ScrollView>
  )
}

export default Lotteries

const LotteryCard: React.FC<{ lottery: Lottery }> = ({ lottery }) => {
  const navigation = useNavigation()
  const theme = useTheme()
  const spacing = useSpacingFn()
  const t = useTranslations()

  return (
    <Pressable onPress={() => navigation.navigate('Lottery', { lotteryId: lottery.id })}>
      <Surface style={{ padding: spacing(3), paddingBottom: spacing(4) }} category="extraLarge">
        <Stack>
          <Surface
            style={{
              overflow: 'hidden',
              position: 'relative',
              aspectRatio: 3 / 2,
              borderRadius: theme.shapes.extraLarge.borderRadius, // - spacing(3),
            }}
          >
            {lottery.imageUrl ? (
              <Image
                source={{ uri: lottery.imageUrl }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                resizeMode="cover"
              />
            ) : (
              <View style={{ width: '100%', height: '100%', backgroundColor: '#999999' }}></View>
            )}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: spacing(4),
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <Badge
                color="surface"
                size="large"
                label={`${t('expires_at')} ${format(new Date(lottery.endDate), 'dd/MM/yyyy')}`}
              ></Badge>
              <Badge
                color="secondary"
                size="large"
                label={`${lottery.prizes.length} ${t('prizes')}`}
              ></Badge>
            </View>
          </Surface>
          <Stack spacing={0}>
            <Typography variant="subtitle">{lottery.title}</Typography>
            <Typography variant="p2">{t('more_info')}</Typography>
          </Stack>
        </Stack>
      </Surface>
    </Pressable>
  )
}

const LotteryListCard: React.FC<{ lottery: Lottery; onPress?: () => void }> = ({
  lottery,
  onPress,
}) => {
  const t = useTranslations()

  const winnersArePending = !lottery.hasBeenDrawn
  const didWin = lottery.userWinnings.length > 0
  return (
    <ListCard
      heading={lottery.title}
      leading={
        <Surface category="full" style={{ overflow: 'hidden' }}>
          {lottery.imageUrl ? (
            <Image source={{ uri: lottery.imageUrl }} style={{ width: 50, height: 50 }} />
          ) : (
            <IconButton
              size={50}
              pointerEvents="none"
              icon={(props) => <LotteryIcon {...props} />}
            />
          )}
        </Surface>
      }
      trailing={
        <IconButton
          color="background"
          pointerEvents="none"
          size={40}
          icon={({ color, size }) => <ArrowRight2 color={color} size={size} />}
        />
      }
      onPress={onPress}
    >
      <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
        <Badge
          label={winnersArePending ? t('pending') : didWin ? t('won') : t('lost')}
          size="medium"
          color={didWin ? 'secondary' : 'background'}
        />
        <Typography style={{ marginLeft: 10 }} variant="p2">
          {`${lottery.userTicketsConsumed} ${t('tickets')}`}
        </Typography>
      </View>
    </ListCard>
  )
}

const NoLotteriesGraphics: React.FC = () => {
  const theme = useTheme()
  const color = chroma(theme.palette.background.main).saturate(0.2).hex()
  const t = useTranslations()

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ position: 'relative', width: 90, height: 135, marginBottom: 30 }}>
        {Array.from({ length: 2 }).map((_, i) => (
          <Surface
            key={i}
            category="large"
            elevation={(1 + i) as Elevation}
            style={{
              width: 90,
              height: 135,
              position: 'absolute',
              padding: 20,
              transform:
                i === 0
                  ? [{ translateX: -25 }, { translateY: 5 }, { rotate: '-10deg' }]
                  : [{ translateX: 10 }],
            }}
          >
            <IconButton
              size={50}
              color={color}
              icon={(props) => <LotteryIcon {...props} color="#FFF" />}
              pointerEvents="none"
              style={{ alignSelf: 'center' }}
            />
            <Surface
              category="full"
              style={{ height: 10, width: '100%', backgroundColor: color, marginTop: 15 }}
            ></Surface>
            <Surface
              category="full"
              style={{ height: 10, width: '70%', backgroundColor: color, marginTop: 10 }}
            ></Surface>
          </Surface>
        ))}
      </View>
      <View>
        <Typography textAlign="center" weight="semibold">
          {t('no_lotteries')}
        </Typography>
        <Typography textAlign="center">{t('no_lotteries_description')}</Typography>
      </View>
    </View>
  )
}
