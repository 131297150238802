import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import { deleteTokens, refreshCredentials } from './auth'

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  // it is safe to call React Hooks here.

  return async (variables?: TVariables) => {
    const backendUrl = Constants.expoConfig?.extra?.BACKEND_URL

    const token = await AsyncStorage.getItem('userToken')

    const res = await fetch(backendUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...options,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (json.errors) {
      if (json.errors.some((error: any) => error.message === 'token_expired')) {
        console.log('Token expired, refreshing credentials…')

        try {
          // Our user token is expired, we need to refresh it?
          await refreshCredentials()
          // Retry the request.
          return useFetchData(query, options)(variables)
        } catch (error) {
          await deleteTokens()
          throw new Error('Could not refresh credentials')
        }
      } else if (json.errors.some((error: any) => error.message === 'invalid_token')) {
        // Our user token is invalid, we need to log the user out.
        await deleteTokens()
        throw new Error('Invalid token')
      }

      const { message } = json.errors[0] || {}

      throw new Error(message || 'Error…')
    }

    return json.data
  }
}
