import { Star1 } from 'iconsax-react-native'
import { useCallback } from 'react'
import { View } from 'react-native'
import { useTheme } from '../../contexts/ThemeContext'
import { usePaletteColor } from '../../hooks/usePaletteColor'
import { useStyles } from '../../hooks/useStyles'
import IconButton from '../IconButton'

export type Rating = 1 | 2 | 3 | 4 | 5

export interface CourseRatingProps {
  value: undefined | Rating
  onRatingChange(rating: Rating): void
}

const indexToRating: Record<number, Rating> = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
}

const CourseRating = ({ value, onRatingChange }: CourseRatingProps) => {
  const isFilled = useCallback(
    (index: number) => {
      if (value === undefined) return false

      return index < value
    },
    [value]
  )

  const surface = usePaletteColor('surface')
  const warning = usePaletteColor('warning')
  const styles = useStyles(({ spacing }) => ({
    container: {
      marginTop: spacing(2),
      flexDirection: 'row',
    },
  }))
  return (
    <View style={styles.container}>
      {Array.from(new Array(5).keys()).map((key) => {
        return (
          <IconButton
            key={'rating-star-' + key}
            color="background"
            size={50}
            icon={(props) => (
              <Star1
                {...props}
                variant={isFilled(key) ? 'Bold' : 'Outline'}
                color={isFilled(key) ? warning.main : surface.on}
              />
            )}
            onPress={() => onRatingChange(indexToRating[key])}
          ></IconButton>
        )
      })}
    </View>
  )
}

export default CourseRating
