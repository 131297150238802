import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { I18n, TranslateOptions as I18NTranslateOptions } from 'i18n-js'
import en from '../translations/en.json'
import no from '../translations/no.json'
import { Language } from '../generated/graphql'

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export type Locale = Language.En | Language.No

type TranslateOptions = {
  count?: number
  capitalized?: boolean
  [k: string]: unknown
}

/**
 * This interface offers the service of translations
 */
interface LocalizationService {
  /**
   * Method for getting a localized string from the given key.
   * @param key
   * @param options Some options for manipulating the string value.
   */
  translate: (key: string, options?: TranslateOptions) => string
}

class GuiltyLocalizationService implements LocalizationService {
  public i18n: I18n

  constructor(locale: Locale) {
    this.i18n = new I18n(
      { no, en },
      {
        locale: locale,
        defaultLocale: 'en',
        enableFallback: true,
      }
    )

    this.translate = this.translate.bind(this)
  }

  translate(key: string, options?: TranslateOptions) {
    let defaultOptions: TranslateOptions = {
      capitalized: true,
    }

    options = {
      ...defaultOptions,
      ...options,
    }

    let text = this.i18n.translate(key, options)

    // Handle failed plural attempt
    if (typeof text === 'object' && text !== null) {
      console.error(
        `The key ${key} has plural translations. You need to specify a count in translation options`
      )
      if ((text as object).hasOwnProperty('other')) {
        text = (text as { other: string }).other
      } else {
        text = ''
      }
    }

    if (options.capitalized) {
      text = capitalize(text)
    }

    return text
  }
}

interface ILocaleContext extends LocalizationService {
  locale: Locale
  switchLocale: (locale: Locale) => void
}

interface LocaleProviderProps {
  children: ReactNode
}

export const LocaleContext = createContext<ILocaleContext | null>(null)

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(Language.No)

  const client = useMemo<LocalizationService>(() => {
    return new GuiltyLocalizationService(locale.toLowerCase() as Locale)
  }, [locale])

  const localeContext = useMemo<ILocaleContext>(() => {
    return {
      translate: client.translate,
      locale: locale as Locale,
      switchLocale: (locale) => setLocale(locale),
    }
  }, [client, locale])

  return <LocaleContext.Provider value={localeContext}>{children}</LocaleContext.Provider>
}

// Some hooks for using context
export const useLocalization = () => {
  const localeContextValue = useContext(LocaleContext)

  if (localeContextValue === null)
    throw new Error('useLocalization must be used within a LocaleProvider')

  return localeContextValue
}

export const useTranslations = () => {
  const localization = useLocalization()

  return localization.translate
}

export const LocaleConsumer = ({
  children,
}: {
  children: (value: ILocaleContext | null) => ReactNode
}) => <LocaleContext.Consumer children={children} />

export default LocaleContext
