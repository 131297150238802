import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useWindowDimensions } from 'react-native'

export type Breakpoint = string | number

export type Breakpoints = { [key in Breakpoint]: number }

export const ActiveBreakpointContext = createContext<Breakpoint | null>(null)
export const BreakpointsContext = createContext<Breakpoints | null>(null)

const useActiveBreakpoint = () => useContext(ActiveBreakpointContext)
const useBreakpoints = () => useContext(BreakpointsContext)

export interface ProviderProps {
  breakpoints: Breakpoints
  children?: ReactNode
}

export const Provider: React.FC<ProviderProps> = ({ breakpoints, children }) => {
  const { width } = useWindowDimensions()

  const windowSizeClass = useMemo(() => {
    const keys = Object.keys(breakpoints).reverse() as Breakpoint[]
    const values = Object.values(breakpoints).reverse()

    return keys[values.findIndex((size) => width >= size)]
  }, [width, breakpoints])

  return (
    <BreakpointsContext.Provider value={breakpoints}>
      <ActiveBreakpointContext.Provider value={windowSizeClass}>
        {children}
      </ActiveBreakpointContext.Provider>
    </BreakpointsContext.Provider>
  )
}
export interface WindowSize {
  /**
   * Matches screen widths greater than the screen size given by the breakpoint key (inclusive).
   * @param breakpoint
   */
  up(breakpoint: Breakpoint): boolean

  /**
   * Matches screen widths less than the screen size given by the breakpoint key (exclusive).
   * @param breakpoint
   */
  down(breakpoint: Breakpoint): boolean

  /**
   * Matches screen widths greater than the screen size given by the breakpoint key in the first
   * argument (inclusive) and less than the screen size given by the breakpoint key in the second argument (exclusive).
   * @param breakpoint
   */
  between(min: Breakpoint, max: Breakpoint): boolean

  only(breakpoint: Breakpoint): boolean

  only(...breakpoints: Breakpoint[]): boolean

  not(breakpoint: Breakpoint): boolean

  not(...breakpoints: Breakpoint[]): boolean
}

export const useWindowSize = (): WindowSize => {
  const breakpoints = useBreakpoints()
  const activeBreakpoint = useActiveBreakpoint()

  if (!breakpoints || !activeBreakpoint) {
    throw new Error('useWindowSize must be used within a WindowSizeProvider')
  }

  return useMemo(() => {
    const keys = Object.keys(breakpoints) as Breakpoint[]

    return {
      up(breakpoint) {
        return breakpoints[activeBreakpoint] >= breakpoints[breakpoint]
      },
      down(breakpoint) {
        return breakpoints[activeBreakpoint] < breakpoints[breakpoint]
      },
      between(min, max) {
        return (
          breakpoints[activeBreakpoint] >= breakpoints[min] &&
          breakpoints[activeBreakpoint] < breakpoints[max]
        )
      },
      only(...breakpoint) {
        return breakpoint.includes(activeBreakpoint)
      },
      not(...breakpoint) {
        return !breakpoint.includes(activeBreakpoint)
      },
    }
  }, [breakpoints, activeBreakpoint])
}
