import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { Language, useLoginMutation, useMeQuery } from '../generated/graphql'
import { Locale, useLocalization, useTranslations } from './localeContext'
import jwt_decode from 'jwt-decode'
import { deleteTokens, updateTokens } from '../auth'

export const tokenIsExpired = (token: string) => {
  const decoded = jwt_decode<{ exp: number }>(token)
  return decoded.exp < Date.now() / 1000
}

type LoginError = {
  error?: unknown
  message: string
}
export interface IAuthContext {
  isLoggedIn: boolean
  logIn: (email: string, password: string) => void
  logOut: () => void
  isLoginError: boolean
  loginError?: LoginError
  isLoginLoading: boolean
  isReady: boolean
}

const AuthContext = createContext<IAuthContext | null>(null)
export type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const t = useTranslations()
  const localization = useLocalization()
  const [authState, setAuthState] = useState<'loggedIn' | 'loggedOut' | 'resolving'>()

  const loginMutation = useLoginMutation({
    async onSuccess(data) {
      switch (data.login.__typename) {
        case 'MutationLoginSuccess': {
          console.log('Login success')

          const { token, refreshToken } = data.login.data

          await updateTokens(token, refreshToken)

          console.log('Refetching user query')
          return userQuery.refetch()
        }
        case 'InvalidUsernamePasswordCombinationError':
          return setError({ error: null, message: t('errors.invalid_credentials') })
        case 'UserNotFoundError':
          return setError({
            error: null,
            message: t('errors.user_not_found'),
          })
        case 'UserWithoutCompanyError':
          return setError({ error: null, message: t('errors.user_without_company') })
      }
    },
    onError(error) {
      setError({ error, message: t('errors.log_in') })
    },
    onSettled() {},
  })

  const userQuery = useMeQuery(
    {},
    {
      onSuccess(data) {
        if (!data?.me) {
          setAuthState('loggedOut')
        } else {
          // Set the locale based on user data.
          const language = data.me?.company.defaultLanguage ?? Language.En
          if (language) {
            localization.switchLocale(language as Locale)
          }

          setAuthState('loggedIn')
        }
      },
      async onError(err) {},
      onSettled() {},
    }
  )

  const [error, setError] = useState<LoginError>()

  const handleLogin = (email: string, password: string) => {
    setError(undefined)
    loginMutation.mutate({ email, password })
  }

  const isLoginError = useMemo(() => {
    return error !== undefined
  }, [error])

  const isReady = useMemo(() => {
    return userQuery.isError || userQuery.isSuccess
  }, [userQuery])

  const authContext: IAuthContext = useMemo(
    () => ({
      isReady,
      isLoggedIn: authState === 'loggedIn',
      logIn: handleLogin,
      isLoginError,
      loginError: error,
      isLoginLoading: loginMutation.isLoading,
      logOut: async () => {
        await deleteTokens()
        setAuthState('loggedOut')
        userQuery.refetch()
      },
    }),
    [userQuery, loginMutation]
  )

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}

export default AuthContext

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export const AuthConsumer: React.FC<{ children: (value: IAuthContext | null) => ReactNode }> = ({
  children,
}) => <AuthContext.Consumer>{children}</AuthContext.Consumer>
