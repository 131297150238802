import { ContentText } from '../../../types/course'

type TextBlockProps = {
  content: ContentText
}
const TextBlock = ({ content }: TextBlockProps) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
          <style>
            h1 {
              font-size: 34px;
              font-family: 'Inter-SemiBold'
            }
            h2 {
              font-size: 28px;
              font-family: 'Inter-SemiBold'
            }
            h3 {
              font-size: 22px;
              font-family: 'Inter-SemiBold'
            }
            h4 {
              font-size: 20px;
              font-family: 'Inter-SemiBold'
            }
            
            p {
              font-size: 16px;
              font-family: 'Inter-Regular'
            }
            
            li {
              font-family: 'Inter-Regular'
            }
          </style>
        ${content.text}
        `,
      }}
    ></div>
  )
}

export default TextBlock
