import { Add, ArrowRight2, ArrowRotateLeft, MessageQuestion } from 'iconsax-react-native'
import { useCallback, useMemo, useState } from 'react'
import { Image, View, StyleSheet } from 'react-native'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import ListCard from '../../components/ListCard'
import ScrollView from '../../components/ScrollView'
import Surface from '../../components/Surface'
import Typography from '../../components/Typography'
import { useCourse } from '.'
import ArrowButton from '../../components/buttons/ArrowButton'
import ImageQuestionSummary from '../../components/course/question/ImageQuestionSummary'
import TextQuestionSummary from '../../components/course/question/TextQuestionSummary'
import ImageQuestion from '../../components/course/question/ImageQuestion'
import TextQuestion from '../../components/course/question/TextQuestion'
import { useTranslations } from '../localeContext'
import { CourseChapterSession, Question, Quiz as QuizType } from '../../types/api'
import { useQuizQuery } from '../../generated/graphql'
import { useTheme } from '../ThemeContext'
import { useStyles } from '../../hooks/useStyles'
import { useSpacingFn } from '../SpacingContext'
import Container from '../../components/Container'
import SafeAreaView from '../../components/SafeAreaView'

type QuizStatus = 'not_started' | 'started' | 'complete'

type QuizState = {
  status: QuizStatus
  questionIndex: number
  answers: Map<string, string>
  activeQuestionSummaryId: undefined | string
}

interface QuizProps {
  quizId: string
}

const Quiz: React.FC<QuizProps> = ({ quizId }) => {
  const { currentChapterSession } = useCourse()
  const { data, isLoading, isSuccess } = useQuizQuery({ id: quizId })
  const t = useTranslations()
  if (isLoading) {
    return <Typography>{t('please_wait')}</Typography>
  } else if (data?.quiz === null) {
    return <Typography>{t('quiz_not_found')}</Typography>
  } else if (isSuccess && data.quiz) {
    return <QuizPlayer quiz={data.quiz} key={currentChapterSession?.id} />
  } else {
    return null
  }
}
interface QuizPlayerProps {
  quiz: QuizType
}

const createDefaultState = (): QuizState => {
  return {
    status: 'not_started',
    questionIndex: 0,
    answers: new Map<string, string>(),
    activeQuestionSummaryId: undefined,
  }
}

const syncQuizProgression = (quiz: QuizType, chapterSession: CourseChapterSession): QuizState => {
  if (chapterSession === null) {
    return createDefaultState()
  }

  const answers = new Map(
    chapterSession.quizSession.map(({ questionId, correctAnswersId, incorrectAnswersId }) => [
      questionId!,
      correctAnswersId.concat(incorrectAnswersId)[0],
    ])
  )

  const isStarted = chapterSession.quizSession.length !== 0
  const isCompleted = answers.size === quiz.questions.length
  const answeredQuestionIds = Array.from(answers.keys())
  const firstUnansweredQuestionIndex = quiz.questions.findIndex(
    ({ id }) => !answeredQuestionIds.includes(id)
  )
  // Find first question index that is not answered.
  const questionIndex = isCompleted
    ? quiz.questions.length - 1
    : firstUnansweredQuestionIndex !== -1
    ? firstUnansweredQuestionIndex
    : 0

  return {
    status: isCompleted ? 'complete' : isStarted ? 'started' : 'not_started',
    questionIndex,
    answers,
    activeQuestionSummaryId: undefined,
  }
}

const QuizPlayer = ({ quiz }: QuizPlayerProps) => {
  if (quiz === null) throw new Error('Quiz is null')

  const t = useTranslations()
  const {
    currentChapter,
    nextChapter,
    previousChapter,
    answerQuizQuestion,
    showIntro,
    restartQuizProgression,
    currentChapterSession,
    isCourseAlreadyCompleted,
  } = useCourse()

  const initialState = syncQuizProgression(quiz, currentChapterSession!)

  const [state, setState] = useState<QuizState>(initialState)

  const isLastQuestion = useMemo<boolean>(
    () => state.questionIndex === quiz.questions.length - 1,
    [state.questionIndex]
  )

  const question = useMemo<QuizType['questions'][0]>(
    () => quiz.questions[state.questionIndex],
    [state.questionIndex]
  )

  const getCorrectAnswerIds = (question: Question) => {
    if (question.answersType === 'IMAGE') {
      return question.imageAnswers?.filter(({ isCorrect }) => isCorrect).map(({ id }) => id) ?? []
    } else {
      return question.textAnswers?.filter(({ isCorrect }) => isCorrect).map(({ id }) => id) ?? []
    }
  }

  const numberOfQuestions = useMemo<number>(() => quiz.questions.length ?? 0, [quiz])

  const answerQuestion = (answerId: string) => {
    // Notify backend.
    answerQuizQuestion(currentChapter.id, question.id, [answerId])
    setState(({ answers, ...state }) => ({
      ...state,
      answers: new Map(answers.set(question.id, answerId)),
    }))
  }

  const start = () => {
    setState((state) => ({ ...state, status: 'started' }))
  }

  const end = () => {
    setState((state) => ({ ...state, status: 'complete' }))
  }

  const next = () => {
    if (!state.answers.has(question.id)) {
      throw new Error('Cannot go next when question has not been answered')
    }

    if (isLastQuestion) {
      throw new Error('Cannot go next because this is the last question')
    }

    setState(({ questionIndex, ...state }) => ({
      ...state,
      questionIndex: questionIndex + 1,
    }))
  }

  const questionIsAnswered = useCallback<(questionId: string) => boolean>(
    (questionId: string) => state.answers.has(questionId),
    [state.answers]
  )

  const questionIsAnsweredCorrectly = useCallback(
    (questionId: string) => {
      const question = quiz.questions.find((q) => q.id === questionId)

      if (!question) {
        return false
      }

      const answer = state.answers.get(questionId)
      if (answer === undefined) {
        return false
      }
      return getCorrectAnswerIds(question).includes(answer)
    },
    [state.answers]
  )

  /**
   * Returns the current question selected by the user
   * in quiz summary.
   */
  const currentQuestionSummary = useMemo<Question | undefined>(() => {
    if (state.activeQuestionSummaryId === undefined) {
      return undefined
    }

    return quiz.questions.find((question) => question.id === state.activeQuestionSummaryId)
  }, [state.activeQuestionSummaryId])

  const setActiveQuestionSummary = (questionId: string) => {
    setState((state) => ({ ...state, activeQuestionSummaryId: questionId }))
  }

  const closeQuestionSummary = () =>
    setState((state) => ({ ...state, activeQuestionSummaryId: undefined }))

  const spacing = useSpacingFn()

  const styles = useStyles(({ spacing, palette }) => ({
    container: {
      backgroundColor: palette.primary.main,
      flex: 1,
      overflow: 'scroll',
      width: '100%',
    },
    header: {
      position: 'absolute',
      top: spacing(5),
      start: 0,
      end: 0,
      zIndex: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerImage: {
      height: spacing(68),
      backgroundColor: palette.background.main,
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
    },
    quizViewContainer: {
      paddingTop: spacing(15),
      paddingBottom: spacing(10),
      flexGrow: 1,
      justifyContent: 'center',
    },
  }))

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <Container style={{ flexGrow: 1 }}>
          <View style={{ flex: 1 }}>
            <View style={styles.header}>
              {state.status !== 'started' && (
                <ArrowButton
                  variant="left"
                  onPress={() =>
                    state.activeQuestionSummaryId !== undefined
                      ? closeQuestionSummary()
                      : previousChapter()
                  }
                />
              )}
              {state.activeQuestionSummaryId === undefined && (
                <IconButton
                  size={32}
                  color="surface"
                  onPress={() => showIntro()}
                  icon={(props) => (
                    <Add {...props} size={28} style={{ transform: [{ rotate: '45deg' }] }} />
                  )}
                />
              )}
            </View>
            <ScrollView
              contentContainerStyle={styles.quizViewContainer}
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={false}
            >
              {state.status === 'not_started' && (
                <View>
                  <Surface
                    style={{
                      borderRadius: spacing(8),
                      paddingHorizontal: spacing(4),
                      paddingVertical: spacing(8),
                    }}
                    elevation={2}
                  >
                    <Typography variant="h3" style={{ textAlign: 'center' }}>{`${t('quiz')}: ${
                      quiz.title ?? t('quiz')
                    }`}</Typography>
                    <View style={{ marginTop: spacing(8), alignItems: 'center' }}>
                      {quiz.imageUrl ? (
                        <View style={[styles.headerImage]}>
                          <Image
                            style={[StyleSheet.absoluteFill]}
                            source={{
                              uri: quiz.imageUrl ?? undefined,
                            }}
                          ></Image>
                        </View>
                      ) : (
                        <IconButton
                          size={130}
                          color="success"
                          tintColor="on-success"
                          pointerEvents="none"
                          icon={(props) => <MessageQuestion {...props} />}
                        />
                      )}
                    </View>
                    <View>
                      <Typography
                        variant="h1"
                        style={{ textAlign: 'center', marginTop: spacing(5) }}
                      >
                        {t('quiz_are_you_ready')}
                      </Typography>
                      <Typography
                        variant="p1"
                        style={{ textAlign: 'center', marginTop: spacing(2) }}
                      >
                        {quiz?.description ?? ''}
                      </Typography>
                    </View>
                    <View
                      style={{
                        marginTop: spacing(8),
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        title={t('quiz_start_button')}
                        size="large"
                        onPress={() => start()}
                      ></Button>
                    </View>
                  </Surface>
                </View>
              )}
              {state.status === 'complete' &&
                (state.activeQuestionSummaryId === undefined ? (
                  <>
                    <Typography variant="h1" color="on-success">
                      {t('quiz_your_answers')}
                    </Typography>
                    <View style={{ marginTop: spacing(4) }}>
                      {quiz.questions.map((question, i) => {
                        const isCorrect = questionIsAnsweredCorrectly(question.id)
                        return (
                          <ListCard
                            key={question.id}
                            heading={
                              <Typography
                                variant="subtitle"
                                color={isCorrect ? 'success' : 'error'}
                              >{`${t('question')} ${i + 1}`}</Typography>
                            }
                            trailing={
                              <IconButton
                                size={40}
                                color="background"
                                pointerEvents="none"
                                icon={(props) => <ArrowRight2 {...props} />}
                              />
                            }
                            onPress={() => setActiveQuestionSummary(question.id)}
                            style={{ marginTop: i === 0 ? 0 : spacing(2) }}
                          >
                            <Typography variant="p2" color="on-primary">
                              {isCorrect ? t('quiz_correct_answer') : t('quiz_wrong_answer')}
                            </Typography>
                          </ListCard>
                        )
                      })}
                    </View>
                    <View
                      style={{
                        marginTop: spacing(8),
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <View style={{ justifyContent: 'center' }}>
                        <Button
                          title={t('continue')}
                          size="large"
                          color="surface"
                          onPress={() => nextChapter()}
                        ></Button>

                        {!isCourseAlreadyCompleted && (
                          <Button
                            title={t('try_again')}
                            variant="text"
                            size="large"
                            color="surface"
                            trailing={(props) => <ArrowRotateLeft {...props} />}
                            onPress={() => {
                              restartQuizProgression(currentChapter.id)
                              setState(createDefaultState())
                            }}
                            style={{ marginTop: spacing(4) }}
                          ></Button>
                        )}
                      </View>
                    </View>
                  </>
                ) : (
                  <View style={{ marginTop: spacing(4) }}>
                    {currentQuestionSummary?.answersType === 'IMAGE' ? (
                      <ImageQuestionSummary
                        question={currentQuestionSummary}
                        currentQuestionNumber={
                          quiz.questions.findIndex((q) => q.id === currentQuestionSummary.id) + 1
                        }
                        numberOfQuestions={numberOfQuestions}
                        selectedAnswerId={state.answers.get(currentQuestionSummary.id)!}
                      />
                    ) : (
                      <TextQuestionSummary
                        question={currentQuestionSummary!}
                        currentQuestionNumber={
                          quiz.questions.findIndex((q) => q.id === currentQuestionSummary!.id) + 1
                        }
                        numberOfQuestions={numberOfQuestions}
                        selectedAnswerId={state.answers.get(currentQuestionSummary!.id)!}
                      />
                    )}
                  </View>
                ))}
              {state.status === 'started' && (
                <View>
                  {questionIsAnswered(question.id) ? (
                    <Surface
                      style={{
                        paddingVertical: spacing(8),
                        paddingHorizontal: spacing(4),
                        borderRadius: spacing(8),
                      }}
                    >
                      {questionIsAnsweredCorrectly(question.id) ? (
                        <>
                          <Typography variant="h1" style={{ textAlign: 'center' }}>
                            {t('quiz_correct')}
                          </Typography>
                          <Typography
                            variant="p1"
                            style={{ marginTop: spacing(2), textAlign: 'center' }}
                          >
                            {t('quiz_correct_answer_feedback')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="h1" style={{ textAlign: 'center' }}>
                            {t('quiz_wrong')}
                          </Typography>
                          <Typography
                            variant="p1"
                            style={{ marginTop: spacing(2), textAlign: 'center' }}
                          >
                            {t('quiz_wrong_answer_feedback')}
                          </Typography>
                        </>
                      )}
                      <View
                        style={{
                          marginTop: spacing(6),
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          title={isLastQuestion ? t('quiz_finish_quiz') : t('quiz_next_question')}
                          size="large"
                          onPress={() => {
                            isLastQuestion ? end() : next()
                          }}
                        ></Button>
                      </View>
                    </Surface>
                  ) : question.answersType === 'IMAGE' ? (
                    <ImageQuestion
                      question={question}
                      currentQuestionNumber={state.questionIndex + 1}
                      numberOfQuestions={numberOfQuestions}
                      onAnswer={(id) => answerQuestion(id)}
                    />
                  ) : (
                    <TextQuestion
                      question={question}
                      currentQuestionNumber={state.questionIndex + 1}
                      numberOfQuestions={numberOfQuestions}
                      onAnswer={(id) => answerQuestion(id)}
                    />
                  )}
                </View>
              )}
            </ScrollView>
          </View>
        </Container>
      </SafeAreaView>
    </View>
  )
}

export default Quiz
