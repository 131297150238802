import { Image, StyleSheet, View } from 'react-native'
import { useStyles } from '../../hooks/useStyles'
import { CourseModule } from '../../types/api'
import Avatar from '../Avatar'
import Skeleton from '../Skeleton'
import Surface from '../Surface'

export interface CourseHeaderProps {
  course?: CourseModule
}

export default ({ course }: CourseHeaderProps) => {
  const isLoading = course === undefined
  const styles = useStyles(({ spacing, palette, shapes }) => ({
    container: {
      paddingBottom: spacing(8),
    },
    surface: {
      backgroundColor: palette.background.main,
      height: spacing(68),
      borderBottomLeftRadius: spacing(6),
      borderBottomRightRadius: spacing(6),
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
    },
    avatarContainer: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      zIndex: 10,
      transform: [
        {
          translateY: spacing(8),
        },
        {
          translateX: spacing(-8),
        },
      ],
    },
    avatarSkeleton: {
      borderColor: palette.surface.main,
      ...shapes.full,
      borderWidth: 4,
    },
  }))
  return (
    <View style={styles.container}>
      <View style={{ position: 'relative' }}>
        <Surface elevation={10} style={styles.surface}>
          {isLoading ? (
            <Skeleton style={[StyleSheet.absoluteFill, { height: '100%' }]}></Skeleton>
          ) : (
            <Image
              style={[StyleSheet.absoluteFill]}
              source={{
                uri: course.imageUrl ?? undefined,
              }}
            ></Image>
          )}
        </Surface>
        <View style={styles.avatarContainer}>
          {isLoading ? (
            <View style={{ position: 'relative' }}>
              <Skeleton variant="circular" style={{ flexDirection: 'row' }}>
                <Avatar size="large"></Avatar>
              </Skeleton>
              <View style={[StyleSheet.absoluteFill, styles.avatarSkeleton]}></View>
            </View>
          ) : (
            <Avatar
              size="large"
              image={{
                uri: course.ownerAvatar ? course.ownerAvatar : course.imageUrl ?? undefined,
              }}
              borderColor="background"
            ></Avatar>
          )}
        </View>
      </View>
    </View>
  )
}
