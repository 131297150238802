import chroma from 'chroma-js'
import { Add, ArrowDown2, ArrowLeft2, ArrowRight2 } from 'iconsax-react-native'
import { LayoutAnimation, Pressable, StyleSheet, View } from 'react-native'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import Surface from '../../components/Surface'
import Typography from '../../components/Typography'
import { useTranslations } from '../localeContext'
import CourseBlock from '../../components/course/blocks/CourseBlock'
import Quiz from './Quiz'
import { useCourse } from '.'
import { useEffect, useState } from 'react'
import { CourseChapterType } from '../../generated/graphql'
import ScrollView from '../../components/ScrollView'
import Icon from '@expo/vector-icons/Feather'
import { LinearGradient } from 'expo-linear-gradient'
import { useTheme } from '../ThemeContext'
import { useStyles } from '../../hooks/useStyles'
import { useSpacingFn } from '../SpacingContext'
import Container from '../../components/Container'

/**
 * CourseChapter renders either a info-chapter or a quiz-chapter
 */
const CourseChapter = () => {
  const { currentChapter } = useCourse()
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setOpen((state) => !state)
  }

  const theme = useTheme()
  const spacing = useSpacingFn()

  const styles = useStyles(({ spacing }) => ({
    container: {
      position: 'relative',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    gradientContainer: {
      position: 'relative',
      height: '100%',
      flexShrink: 1,
    },
    gradient: {
      position: 'absolute',
      top: 0,
      start: 0,
      end: 0,
      zIndex: 10,
      height: spacing(2),
    },
    scrollContainer: {
      position: 'relative',
      height: '100%',
      flexShrink: 1,
      flexGrow: 1,
    },
  }))
  return (
    <View style={styles.container}>
      {currentChapter.type === CourseChapterType.Info && (
        <View style={{ flex: 1, width: '100%' }}>
          <ChapterHeader />
          {!open && (
            <View style={[styles.gradientContainer, { flex: open ? 0 : 1 }]}>
              <LinearGradient
                style={styles.gradient}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                locations={[0, 0.99]}
                colors={['rgba(255,255,255,0)', theme.palette.background.main]}
              ></LinearGradient>
              <ScrollView
                style={styles.scrollContainer}
                contentContainerStyle={{
                  position: 'relative',
                  paddingTop: spacing(4),
                  paddingBottom: spacing(10),
                }}
                key={currentChapter.id}
              >
                <Container style={{ flexGrow: 1 }}>
                  {currentChapter.blocks.map((block, i) => {
                    return (
                      <View key={block.id} style={{ marginTop: i === 0 ? 0 : spacing(12) }}>
                        <CourseBlock block={block} />
                      </View>
                    )
                  })}
                </Container>
              </ScrollView>
            </View>
          )}
          <CoursePagination open={open} onToggle={() => toggle()} />
        </View>
      )}
      {currentChapter.type === CourseChapterType.Quiz && <Quiz quizId={currentChapter.quiz!.id} />}
    </View>
  )
}

/**
 * The thing that sits on top of the screen on courses and shows you a title
 * some navigation and current chapter
 */
const ChapterHeader = () => {
  const { activeChapterIndex, chapters, currentChapter, showIntro, chapterOverviewList } =
    useCourse()
  const theme = useTheme()
  const spacing = useSpacingFn()

  const offColor = chroma(theme.palette.background.main).darken(0.5).hex()

  const chapterTitle = currentChapter.title

  const styles = useStyles(({ palette, spacing }) => ({
    surface: {
      backgroundColor: palette.background.main,
      zIndex: 10,
      width: '100%',
      paddingTop: spacing(10),
      paddingBottom: spacing(4),
    },
    topRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    bottomRow: {
      marginTop: spacing(3),
      flexDirection: 'row',
    },
  }))
  return (
    <Surface style={styles.surface}>
      <Container style={{ flexGrow: 1 }}>
        <View style={styles.topRow}>
          <Typography variant="h2">{chapterTitle}</Typography>
          <IconButton
            size={32}
            color="surface"
            onPress={() => showIntro()}
            icon={(props) => (
              <Add {...props} size={28} style={{ transform: [{ rotate: '45deg' }] }} />
            )}
          />
        </View>
        <View style={styles.bottomRow}>
          {chapters.map((_course, i) => {
            const isCompleted = chapterOverviewList[i].progression.isCompleted
            const isActive = activeChapterIndex === i
            return (
              <View
                key={'progression' + i}
                style={[
                  {
                    marginLeft: i === 0 ? 0 : spacing(2),
                    height: 6,
                    backgroundColor:
                      !isActive && !isCompleted ? offColor : theme.palette.primary.main,
                    flexGrow: activeChapterIndex === i ? 2 : 1,
                  },
                  theme.shapes.full,
                ]}
              ></View>
            )
          })}
        </View>
      </Container>
    </Surface>
  )
}

/**
 * The thing that sits on the bottom of screen on courses and lets you navigate
 */
const CoursePagination = ({ open, onToggle }: { open: boolean; onToggle(): void }) => {
  const {
    activeChapterIndex,
    previousChapter,
    nextChapter,
    endCourse,
    chapterOverviewList,
    openChapterById,
    allChaptersAreCompleted,
    isLastChapter,
    isCourseAlreadyCompleted,
  } = useCourse()
  const t = useTranslations()
  const theme = useTheme()
  const spacing = useSpacingFn()

  const handleChapterPress = (chapterId: string): void => {
    openChapterById(chapterId)
  }

  const [showCompleteCourseButton, setShowCompleteCourseButton] = useState(false)

  useEffect(() => {
    if (allChaptersAreCompleted) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
      setShowCompleteCourseButton(true)
    }
  }, [allChaptersAreCompleted])

  const styles = useStyles(({ spacing, palette, shapes }) => ({
    container: {
      width: '100%',
      flexGrow: open ? 1 : 0,
      flexShrink: 0,
    },
    surfaceContainer: {
      paddingHorizontal: spacing(4),
      paddingBottom: spacing(4),
    },
    surface: {
      borderRadius: spacing(6),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(4),
    },
    scrollContainer: {
      flex: 1,
    },
    chapterSurface: {
      ...shapes.large,
      flexDirection: 'row',
      padding: spacing(3),
    },
  }))
  return (
    <View style={styles.container}>
      <Container style={styles.surfaceContainer}>
        <Surface style={styles.surface} elevation={1}>
          <IconButton
            color="tertiary"
            disabled={activeChapterIndex === 0}
            onPress={() => previousChapter()}
            icon={(props) => <ArrowLeft2 {...props} />}
          />
          <View>
            {!showCompleteCourseButton && (
              <Button
                rounded={true}
                size="large"
                variant="outline"
                titleStyle={{
                  fontSize: 14,
                }}
                trailing={({ color }) => <ArrowDown2 color={color} size={18} />}
                color="on-tertiary"
                title={t('plural.chapter', { count: 0 })}
                onPress={() => onToggle()}
              />
            )}

            {showCompleteCourseButton && !isCourseAlreadyCompleted && (
              <Button
                rounded={true}
                size="large"
                color="primary"
                title={t('continue')}
                onPress={() => endCourse()}
              />
            )}
          </View>
          <IconButton
            color="tertiary"
            onPress={() => nextChapter()}
            disabled={isLastChapter}
            icon={(props) => <ArrowRight2 {...props} />}
          />
        </Surface>
      </Container>
      {open && (
        <ScrollView style={styles.scrollContainer}>
          <Container style={{ flexGrow: 1 }}>
            {chapterOverviewList.map((chapterOverview, i) => {
              const isStarted = chapterOverview.progression.isStarted
              const isCompleted = chapterOverview.progression.isCompleted

              return (
                <Pressable
                  key={chapterOverview.id + chapterOverview.name}
                  onPress={() => handleChapterPress(chapterOverview.id)}
                  style={{ marginTop: i === 0 ? 0 : spacing(2) }}
                >
                  <Surface style={styles.chapterSurface}>
                    <View
                      style={{
                        position: 'relative',
                        marginEnd: spacing(2),
                        width: spacing(4),
                        height: spacing(4),
                        overflow: 'hidden',
                        borderRadius: theme.shapes.full.borderRadius,
                        borderWidth: 1,
                        borderColor:
                          isStarted || isCompleted ? theme.palette.primary.main : '#E7E5EA',
                      }}
                    >
                      <View
                        style={[
                          StyleSheet.absoluteFill,
                          {
                            backgroundColor: chapterOverview.progression.isCompleted
                              ? theme.palette.success.main
                              : theme.palette.surface.main,
                            alignItems: 'center',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Icon name="check" color="#FFF" />
                      </View>
                    </View>
                    <Typography variant="p1" style={{ fontFamily: 'Inter-SemiBold' }}>
                      {chapterOverview.name}
                    </Typography>
                  </Surface>
                </Pressable>
              )
            })}
          </Container>
        </ScrollView>
      )}
    </View>
  )
}
export default CourseChapter
