import { useNavigation } from '@react-navigation/native'
import { ReactNode, useState } from 'react'
import { Image, Platform, Pressable, ScrollView, View } from 'react-native'
import { useTranslations } from '../../contexts/localeContext'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { useTheme } from '../../contexts/ThemeContext'
import { CourseModuleListItem, CourseParentListItem } from '../../types/api'
import Avatar from '../Avatar'
import Badge from '../Badge'
import ArrowButton from '../buttons/ArrowButton'
import CircleProgress from '../CircleProgress'
import CoinBadge from '../CoinBadge'
import IconButton from '../IconButton'
import ListCard from '../ListCard'
import Modal from '../modals/Modal'
import Surface from '../Surface'
import Typography from '../Typography'

export type CourseModuleListCardProps = {
  course: CourseModuleListItem
  onPress?(): void
}

export type CourseListCardProps = {
  course: CourseParentListItem
}

/**
 * Component for displaying Course Modules as ListCard item
 */
export const CourseModuleListCard = ({ course, onPress }: CourseModuleListCardProps) => {
  const theme = useTheme()
  const spacing = useSpacingFn()

  const navigation = useNavigation()
  const t = useTranslations()

  const getLeading = () => {
    return course.imageUrl ? (
      <Image
        source={{ uri: course.imageUrl }}
        style={{ height: '100%', width: '100%', ...theme.shapes.full }}
      />
    ) : (
      <IconButton
        color="warning"
        pointerEvents="none"
        icon={
          <Typography variant="caption" style={{ fontFamily: 'Inter-SemiBold' }}>
            Course
          </Typography>
        }
      />
    )
  }

  const getTrailing = (): ReactNode => {
    if (!course.isStarted) {
      return (
        <IconButton
          pointerEvents="none"
          color="success"
          icon={
            <Typography variant="p1" color="on-success" style={{ fontFamily: 'Inter-SemiBold' }}>
              {t('start', { capitalized: false })}
            </Typography>
          }
        ></IconButton>
      )
    } else {
      return (
        <CircleProgress
          variant="percentage"
          currentStep={course.percentageCompleted ?? 0}
          totalSteps={100}
          completed={course.isCompleted}
        />
      )
    }
  }

  /**
   * We show different things in the status badge based on course state.
   * If it is complete: show "Completed"
   * Else if course is not started: show duration.
   * Else: show "Started"
   */
  const getStatusBadgeContent = (): string => {
    if (course.isCompleted) {
      return t('complete')
    } else if (!course.isStarted && course.difficulty) {
      return t(course.difficulty.toLowerCase())
    } else {
      return t('started')
    }
  }

  return (
    <ListCard
      heading={course.title}
      leading={getLeading()}
      trailing={getTrailing()}
      onPress={
        onPress === undefined
          ? () => navigation.navigate('Course', { courseId: course.id })
          : onPress
      }
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {course.coinsAreActivated ? (
          <CoinBadge
            amount={course.maxCoinsReward}
            color={course.totalCoinsEarned > 0 ? 'success' : 'secondary'}
            partialAmount={
              course.totalCoinsEarned > 0 && course.totalCoinsEarned < course.maxCoinsReward
                ? course.totalCoinsEarned
                : undefined
            }
          />
        ) : (
          <Badge color="background" label={course.duration + ' min'}></Badge>
        )}
        {course.difficulty && (
          <Badge
            color={!course.coinsAreActivated ? 'surface' : 'background'}
            label={getStatusBadgeContent()}
            style={{ marginLeft: spacing(1) }}
          ></Badge>
        )}
      </View>
    </ListCard>
  )
}

/**
 * Component for displaying Course Parent as ListCard item
 */
const CourseListCard = ({ course }: CourseListCardProps) => {
  const theme = useTheme()
  const spacing = useSpacingFn()

  const [open, setOpen] = useState(false)

  const toggleOpen = (value?: boolean) => {
    setOpen((state) => (value ? value : !state))
  }
  // If the course parent has only 1 module we treat it as CourseModuleListCard instead.
  if (course.modulesCount <= 1) {
    return (
      <CourseModuleListCard
        course={{
          ...course.modules[0],
          title: course.title,
          imageUrl: course.imageUrl ? course.imageUrl : course.owner.avatarUrl,
        }}
      />
    )
  }

  const t = useTranslations()

  const navigation = useNavigation()

  const isMultipleModules = course.modulesCount > 1
  const isStarted = course.startedModulesCount > 0
  const isComplete = course.modulesCount === course.completedModulesCount

  /**
   * Show the course image if it exists
   * else show the course owners avatarUrl
   * else show a fallback iconButton, or something.
   * @returns
   */
  const getLeading = () => {
    return course.imageUrl ? (
      <Image
        source={{ uri: course.imageUrl }}
        style={{ height: '100%', width: '100%', borderRadius: 9999 }}
      />
    ) : course.owner.avatarUrl ? (
      <Avatar image={{ uri: course.owner.avatarUrl }} />
    ) : (
      <IconButton
        color="warning"
        pointerEvents="none"
        icon={
          <Typography variant="caption" style={{ fontFamily: 'Inter-SemiBold' }}>
            Course
          </Typography>
        }
      />
    )
  }

  /**
   * In the trailing node of the CourseListCard we show
   * a round button that says "start" if the course is not started,
   * otherwise we should a CircleProgress based on the course progress.
   * @returns A ReactNode
   */
  const getTrailing = (): ReactNode => {
    if (!isStarted) {
      return (
        <IconButton
          pointerEvents="none"
          color="success"
          icon={
            <Typography variant="p1" color="on-success" style={{ fontFamily: 'Inter-SemiBold' }}>
              {t('start', { capitalized: false })}
            </Typography>
          }
        ></IconButton>
      )
    } else if (isMultipleModules) {
      return (
        <CircleProgress
          currentStep={course.startedModulesCount}
          totalSteps={course.modulesCount}
          variant="counter"
          completed={isComplete}
        />
      )
    }
  }

  /**
   * We show different things in the status badge based on course state.
   * If it is complete: show "Completed"
   * Else if course is not started: show duration.
   * Else: show "Started"
   */
  const getStatusBadgeContent = (): string => {
    if (course.modulesCount === course.completedModulesCount) {
      return t('complete')
    } else if (!isStarted) {
      return t(course.difficulty.toLowerCase())
    } else {
      return t('started')
    }
  }
  return (
    <View style={{ paddingBottom: isMultipleModules ? spacing(2) : 0 }}>
      <ListCard
        heading={course.title}
        showAsStack={isMultipleModules}
        leading={getLeading()}
        trailing={getTrailing()}
        onPress={() => toggleOpen(true)}
      >
        <View style={{ flexDirection: 'row' }}>
          {course.coinsAreActivated ? (
            <CoinBadge
              amount={course.maxCoinsReward}
              color={course.totalCoinsEarned > 0 ? 'success' : 'secondary'}
              partialAmount={
                course.totalCoinsEarned > 0 && course.totalCoinsEarned !== course.maxCoinsReward
                  ? course.totalCoinsEarned
                  : undefined
              }
            />
          ) : (
            <Badge color="background" label={course.duration + ' min'}></Badge>
          )}
          {course.difficulty && (
            <Badge
              color="background"
              label={getStatusBadgeContent()}
              style={{ marginLeft: spacing(1) }}
            ></Badge>
          )}
        </View>
      </ListCard>
      {open && (
        <Modal open={open} onClose={() => toggleOpen(false)}>
          <>
            <Pressable
              style={{ backgroundColor: 'surface', height: spacing(25) }}
              onPress={() => toggleOpen(false)}
            ></Pressable>
            <Surface
              elevation={10}
              style={{
                flex: 1,
                borderTopLeftRadius: spacing(6),
                borderTopRightRadius: spacing(6),
                paddingVertical: spacing(8),
                paddingHorizontal: spacing(4),
                backgroundColor: theme.palette.background.main,
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <ArrowButton
                  color="surface"
                  showElevation
                  size={48}
                  variant="left"
                  onPress={() => toggleOpen(false)}
                />
                <View
                  style={{
                    marginLeft: spacing(-10),
                    flex: 1,
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: 'center',
                      marginHorizontal: 'auto',
                      maxWidth: Platform.OS === 'web' ? 'unset' : 220,
                    }}
                    numberOfLines={1}
                  >
                    {course.title}
                  </Typography>
                </View>
              </View>
              <ScrollView
                contentContainerStyle={{ paddingBottom: spacing(8) }}
                style={{ marginTop: spacing(4) }}
              >
                {course.modules.map((courseModule, index) => (
                  <View key={courseModule.id} style={{ marginTop: index === 0 ? 0 : spacing(2) }}>
                    <CourseModuleListCard
                      key={courseModule.id}
                      course={courseModule}
                      onPress={() => {
                        toggleOpen(false)
                        navigation.navigate('Course', { courseId: courseModule.id })
                      }}
                    />
                  </View>
                ))}
              </ScrollView>
            </Surface>
          </>
        </Modal>
      )}
    </View>
  )
}

export default CourseListCard
