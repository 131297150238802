import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useWindowDimensions, View } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { PaletteColorName, useTheme } from '../contexts/ThemeContext'

const shapes = [
  {
    width: 20,
    height: 20,
  },
  {
    width: 40,
    height: 20,
  },
  {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
]

type ConfettiPieceProps = {
  animation: Animated.SharedValue<number>
}

const ConfettiPiece: React.FC<ConfettiPieceProps> = ({ animation }) => {
  const dimensions = useWindowDimensions()

  const x = dimensions.width / 2
  const y = Math.random() * -300 - 100
  const yVelocity = Math.random() * 300 + 300
  const xVelocity = Math.random() * 800 - 400
  const angleVelocity = (Math.random() * 3 - 1.5) * Math.PI
  const duration = 4000
  const color: PaletteColorName = ['error', 'warning', 'success'][Math.floor(Math.random() * 3)]
  const delay = Math.random() * 2000
  const shape = shapes[Math.floor(Math.random() * 3)]
  const theme = useTheme()

  const animatedStyle = useAnimatedStyle(() => {
    const time = Math.max((animation.value * duration) / 1000 - delay / 1000, 0)
    const angle = time * angleVelocity

    // when animation value is 9/10 of the way through, start fading out
    const opacity = animation.value > 0.9 ? 1 - (animation.value - 0.9) * 10 : 1
    return {
      opacity: opacity,
      transform: [
        {
          translateX: time * xVelocity + x,
        },
        {
          translateY: time * yVelocity + y,
        },
        {
          rotateX: `${angle}rad`,
        },
        {
          rotateY: `${angle}rad`,
        },
        {
          rotateZ: `${angle}rad`,
        },
      ],
    }
  })

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          backgroundColor: theme.palette[color].main,
          top: 0,
          left: 0,
          ...shape,
        },
        animatedStyle,
      ]}
    ></Animated.View>
  )
}

const createConfettiPieces = (n: number) => {
  return Array.from({ length: n }).map((_, i) => i)
}

export type ConfettiCannonHandle = {
  start: () => void
}

export type ConfettiCannonProps = {
  runOnMount?: boolean
  onMount?: () => void
}

const ConfettiCannon = forwardRef<ConfettiCannonHandle, ConfettiCannonProps>((props, ref) => {
  const [pieces, setPieces] = useState<number[]>(createConfettiPieces(50))

  const animation = useSharedValue(0)
  const duration = 5000

  const start = () => {
    animation.value = 0
    animation.value = withTiming(1, { duration, easing: Easing.linear })
  }

  useImperativeHandle(ref, () => ({
    start,
  }))

  useEffect(() => {
    props.onMount?.()
    if (props.runOnMount) {
      start()
    }
  }, [])

  return (
    <Animated.View
      pointerEvents="none"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <View>
          {pieces.map((i) => (
            <ConfettiPiece key={i} animation={animation} />
          ))}
        </View>
      </View>
    </Animated.View>
  )
})

export default ConfettiCannon
