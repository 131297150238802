import { ReactNode, useMemo } from 'react'
import { Pressable, PressableProps, StyleSheet, ViewStyle } from 'react-native'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'
import { useSurfaceScale } from '../hooks/useSurfaceScale'
import Surface from './Surface'

export interface IconButtonProps extends PressableProps {
  icon?: ReactNode | ((props: { color: string; size: number }) => ReactNode)
  color?: Color
  tintColor?: Color
  size?: number
  transparent?: boolean
  showElevation?: boolean
  style?: ViewStyle
}

const IconButton = ({
  transparent = false,
  icon,
  size = 50,
  color = 'primary',
  tintColor,
  style,
  showElevation = false,
  disabled,
  ...rest
}: IconButtonProps) => {
  const themeColor = usePaletteColor(color, tintColor)
  const surfaceScale = useSurfaceScale()

  const palette = useMemo(() => {
    return {
      main: disabled ? surfaceScale(0.12).hex() : themeColor.main,
      on: disabled ? surfaceScale(0.35).hex() : themeColor.on,
    }
  }, [color, disabled])
  return (
    <Surface
      elevation={showElevation ? 4 : 0}
      style={[
        {
          overflow: 'hidden',
          borderRadius: 9999,
          borderColor: palette.on,
          backgroundColor: transparent ? 'transparent' : palette.main,
          width: size,
          height: size,
        },
        style,
      ]}
    >
      <Pressable
        {...rest}
        style={[StyleSheet.absoluteFill, { alignItems: 'center', justifyContent: 'center' }]}
      >
        {icon
          ? typeof icon === 'function'
            ? icon({ color: palette.on, size: size / 2 })
            : icon
          : null}
      </Pressable>
    </Surface>
  )
}

export default IconButton
