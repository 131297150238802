import { Platform, View, ViewProps } from 'react-native'
import { useCourse } from '../../../contexts/CourseContext'
import { BlockType } from '../../../generated/graphql'
import { CourseBlock as APICourseBlock } from '../../../types/api'
import { ContentImage, ContentQuiz, ContentText, ContentVideo } from '../../../types/course'
import ImageBlock from './ImageBlock'
import QuizBlock from './QuizBlock'
import TextBlock from './TextBlock'
import VideoBlock from './VideoBlock'

interface CourseBlockProps extends ViewProps {
  block: APICourseBlock
}
const CourseBlock = ({ block, ...props }: CourseBlockProps) => {
  const content = JSON.parse(block.content)
  const { currentChapter, completeBlock } = useCourse()

  if (block.isHidden) return null
  return (
    <View style={{ width: '100%' }} {...props}>
      {block.type === BlockType.Image && <ImageBlock content={content as ContentImage} />}
      {block.type === BlockType.Video && (
        <VideoBlock
          content={content as ContentVideo}
          onComplete={() => completeBlock(currentChapter.id, block.id)}
        />
      )}
      {block.type === BlockType.Text && <TextBlock content={content as ContentText} />}
      {block.type === BlockType.Question && (
        <QuizBlock
          content={content as ContentQuiz}
          onComplete={() => completeBlock(currentChapter.id, block.id)}
        />
      )}
    </View>
  )
}

export default CourseBlock
