import {
  createContext,
  Dispatch,
  Key,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { StyleSheet, View } from 'react-native'

export const PortalContext = createContext<{
  portals: PortalProps[]
  setPortals: Dispatch<SetStateAction<PortalProps[]>>
} | null>(null)

const usePortalContext = () => {
  const portal = useContext(PortalContext)
  if (!portal) {
    throw new Error('usePortalContext must be used within a PortalContext')
  }

  return portal
}

export const PortalProvider = ({ children }: { children: ReactNode }) => {
  const [portals, setPortals] = useState<PortalProps[]>([])

  return <PortalContext.Provider value={{ portals, setPortals }}>{children}</PortalContext.Provider>
}

export const Outlet = () => {
  const { portals } = usePortalContext()

  return (
    <>
      {portals.map(({ key, children }) => (
        <View
          key={key}
          pointerEvents="box-none"
          style={[{ overflow: 'hidden' }, StyleSheet.absoluteFill]}
        >
          {children}
        </View>
      ))}
    </>
  )
}

export interface PortalProps {
  key?: Key
  children?: ReactNode
}

export const Portal = ({ key, children }: PortalProps) => {
  const { setPortals } = usePortalContext()
  const _key = useMemo(() => key ?? `${Date.now() + Math.random()}`, [key])

  useEffect(() => {
    setPortals((portals) => [...portals, { key: _key, children }])

    return () => {
      setPortals((portals) => portals.filter((portal) => portal.key !== _key))
    }
  }, [_key, setPortals, children])

  return null
}
