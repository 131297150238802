import { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useTranslations } from '../../../contexts/localeContext'
import { useSpacingFn } from '../../../contexts/SpacingContext'
import { ContentQuiz } from '../../../types/course'
import Button from '../../Button'
import Typography from '../../Typography'
import QuestionButton from '../QuestionButton'

export type QuizBlockProps = {
  content: ContentQuiz
  onComplete(): void
}

const QuizBlock = ({ content, onComplete }: QuizBlockProps) => {
  const isMultipleAnswer = useMemo(() => {
    return content.options?.reduce((acc, x) => (x.isCorrect ? acc + 1 : 0), 0) > 1
  }, [])

  const [selected, setSelected] = useState<number[]>([])
  const [isAnswered, setIsAnswered] = useState<boolean>(false)
  const isAnsweredCorrectly = useMemo(() => {
    return (
      isAnswered &&
      selected.some((id) => {
        return content.options?.find((_option, i) => id === i).isCorrect
      })
    )
  }, [isAnswered])
  const t = useTranslations()

  const spacing = useSpacingFn()

  const renderButton = useCallback(
    (id, text, isCorrect) => {
      const isSelected = selected.includes(id)
      return (
        <View key={text + id} style={{ width: '100%', marginTop: id === 0 ? 0 : spacing(3) }}>
          <QuestionButton
            questionText={text}
            disabled={isAnswered}
            state={
              isSelected
                ? isAnswered
                  ? isCorrect
                    ? 'success'
                    : 'error'
                  : 'selected'
                : 'unselected'
            }
            onPress={() => {
              if (isSelected) {
                setSelected((state) => state.filter((s) => s !== id))
              } else if (isMultipleAnswer) {
                setSelected((state) => [...state, id])
              } else {
                setSelected([id])
              }
            }}
          />
        </View>
      )
    },
    [selected, isAnswered]
  )

  return (
    <View>
      <Typography variant="h2">{content.question}</Typography>
      <View>
        {isMultipleAnswer ? (
          <Typography style={{ marginVertical: spacing(6) }}>
            {t('select_multiple_alternatives')}
          </Typography>
        ) : (
          <Typography style={{ marginVertical: spacing(6) }}>
            {t('select_one_alternative')}
          </Typography>
        )}
        <View style={{ alignItems: 'center' }}>
          {content.options?.map((option, i) => renderButton(i, option.text, option.isCorrect))}
        </View>
        <View style={{ marginTop: spacing(4) }}>
          {isAnswered ? (
            <Typography color={isAnsweredCorrectly ? 'success' : 'error'}>
              {isAnsweredCorrectly ? t('quiz_correct') : t('quiz_wrong_answer')}
            </Typography>
          ) : (
            <Button
              title={t('quiz_check_answer')}
              disabled={selected === undefined || isAnswered}
              onPress={() => {
                onComplete()
                setIsAnswered(true)
              }}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export default QuizBlock
