import { ArrowDown, ArrowUp } from 'iconsax-react-native'
import { View } from 'react-native'
import { useSpacingFn } from '../../contexts/SpacingContext'
import React from 'react'
import FormattedNumber from '../FormattedNumber'
import IconButton from '../IconButton'
import Typography from '../Typography'
import Animated, { useAnimatedStyle } from 'react-native-reanimated'

export interface IncomingOutgoingDisplayProps {
  incomingText: string
  outgoingText: string
  incomingValue: number
  outgoingValue: number
  opacity?: Animated.SharedValue<number>
}

export const IncomingOutgoingDisplay: React.FC<IncomingOutgoingDisplayProps> = ({
  incomingText,
  outgoingText,
  incomingValue,
  outgoingValue,
  opacity,
}) => {
  const spacing = useSpacingFn()

  const uas = useAnimatedStyle(() => ({
    opacity: opacity ? opacity.value : 1,
  }))

  return (
    <Animated.View
      style={[
        {
          height: 120,
          flexDirection: 'row',
        },
        uas,
      ]}
    >
      <View
        style={{
          flex: 1,
          alignItems: 'flex-end',
          paddingEnd: spacing(3),
        }}
      >
        <IconButton
          color="secondary"
          size={spacing(8)}
          icon={(props) => <ArrowDown {...props} style={{ transform: [{ rotate: '45deg' }] }} />}
        />
        <Typography color="on-secondary" variant="p2" style={{ marginTop: spacing(2) }}>
          {incomingText}
        </Typography>
        <FormattedNumber variant="h4" color="on-secondary">
          {incomingValue.toString()}
        </FormattedNumber>
      </View>
      <View style={{ flex: 1, paddingStart: spacing(3) }}>
        <IconButton
          color="secondary"
          size={spacing(8)}
          icon={(props) => <ArrowUp {...props} style={{ transform: [{ rotate: '45deg' }] }} />}
        />
        <Typography color="on-secondary" variant="p2" style={{ marginTop: spacing(2) }}>
          {outgoingText}
        </Typography>
        <FormattedNumber variant="h4" color="on-secondary">
          {outgoingValue.toString()}
        </FormattedNumber>
      </View>
    </Animated.View>
  )
}
