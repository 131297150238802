import React from 'react'
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native'
import { useTheme } from '../contexts/ThemeContext'

export interface SplashScreenProps {}

export const SplashScreen: React.FC<SplashScreenProps> = ({}) => {
  const theme = useTheme()
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View style={StyleSheet.absoluteFill}>
        <Image
          source={require('../assets/splash.png')}
          style={{ height: '100%', width: '100%' }}
          resizeMode="cover"
        />
      </View>
      <ActivityIndicator color={theme.palette.secondary.main} />
    </View>
  )
}

export default SplashScreen
