import chroma from 'chroma-js'
import * as React from 'react'
import Svg, { Defs, LinearGradient, Stop, G, Rect, Circle, Path } from 'react-native-svg'
import { PaletteColorName, useTheme } from '../../contexts/ThemeContext'
/* SVGR has dropped some elements not supported by react-native-svg: style, filter */

const CoinIconSvg: React.FC<{ size?: number; color: PaletteColorName }> = ({ size, color }) => {
  const theme = useTheme()
  const palette = theme.palette[color]

  return (
    <Svg
      // @ts-ignore@
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      width={size}
      height={size}
    >
      <Defs>
        {/* @ts-ignore */}
        <LinearGradient
          id={`linear-gradient-${color}`}
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <Stop offset={0} stopColor={palette.main} />
          <Stop offset={1} stopColor={chroma(palette.main).brighten(0.9).hex()} />
        </LinearGradient>
      </Defs>
      {/* @ts-ignore */}
      <G id="Group_5637">
        <Rect id="Rectangle_4132" width={36} height={36} rx={18} fill={palette.main} />
        {/* @ts-ignore */}
        <G id="Group_5658">
          <Circle id="Ellipse_306" cx={18} cy={18} r={18} fill={palette.main} />
          {/* @ts-ignore */}
          <G filter="url(#Subtraction_156)">
            <Path
              id="Subtraction_156-2"
              d="M18.2 34.4a16.2 16.2 90 1116.2-16.2 16.2 16.2 90 01-16.2 16.2zm-.113-22.5a8.1 8.1 90 000 16.2 7.817 7.817 90 005.62-2.374l-2.254-2.293a4.68 4.68 90 01-3.368 1.425 4.86 4.86 90 110-9.72 4.68 4.68 90 013.368 1.42l2.251-2.287a7.848 7.848 90 00-5.617-2.371z"
              fill={`url(#linear-gradient-${color})`}
            />
          </G>
          <Path
            id="Subtraction_158"
            d="M18 27a9 9 90 010-18 8.688 8.688 90 016.24 2.634l-2.496 2.544A5.186 5.186 90 0018 12.596a5.4 5.4 90 000 10.8 5.2 5.2 90 003.742-1.584l2.496 2.546A8.688 8.688 90 0118 27z"
            fill={palette.on}
          />
        </G>
      </G>
    </Svg>
  )
}

export default CoinIconSvg
