import {
  ArrowRight2,
  EmojiHappy,
  Lock1,
  UserSquare,
  ScanBarcode,
  ShieldSecurity,
} from 'iconsax-react-native'
import { Platform, View } from 'react-native'
import Button from '../../components/Button'
import IconButton, { IconButtonProps } from '../../components/IconButton'
import ListCard from '../../components/ListCard'
import { useAuth } from '../../contexts/authContext'
import {
  Language,
  useCurrentCompanyQuery,
  useGlobalSettingsQuery,
  useMeQuery,
} from '../../generated/graphql'
import { HomeTabScreenProps } from '../../navigation/types'
import ScrollView from '../../components/ScrollView'
import Typography from '../../components/Typography'
import UserAvatar from '../../components/user/UserAvatar'
import CompanyInformation from '../../components/company/CompanyInformation'
import * as Linking from 'expo-linking'
import Skeleton from '../../components/Skeleton'
import { useLocalization, useTranslations } from '../../contexts/localeContext'
import { setStatusBarStyle } from 'expo-status-bar'
import { useTheme } from '../../contexts/ThemeContext'
import { useStyles } from '../../hooks/useStyles'
import { useSpacingFn } from '../../contexts/SpacingContext'
import Container from '../../components/Container'
import Constants from 'expo-constants'
import SafeAreaView from '../../components/SafeAreaView'
import React, { ReactNode } from 'react'
import Stack from '../../components/Stack'
import Surface from '../../components/Surface'
import { useOnboarding } from '../../components/OnboardingOverlay'
import CompanyInviteCode from '../../components/CompanyInviteCode'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSharedValue, withTiming } from 'react-native-reanimated'
import BottomSheet from '../../components/BottomSheet'

type ProfileMenuItemProps = {
  title: string
  isHidden?: boolean
  icon: ReactNode | ((props: IconButtonProps) => ReactNode)
  action(): void
}
const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({ title, icon, action }) => {
  return (
    <ListCard
      heading={title}
      leading={<IconButton color="background" pointerEvents="none" icon={icon} />}
      trailing={
        <IconButton
          color="background"
          pointerEvents="none"
          size={30}
          icon={({ size, color }) => <ArrowRight2 color={color} size={size} />}
        />
      }
      onPress={action}
    ></ListCard>
  )
}

const Profile = ({ navigation }: HomeTabScreenProps<'Profile'>) => {
  const theme = useTheme()
  const spacing = useSpacingFn()
  const { open: openOnboarding } = useOnboarding()
  setStatusBarStyle('dark')
  const { data, isLoading } = useMeQuery()
  const { data: currentCompanyData } = useCurrentCompanyQuery()
  const { data: globalSettingsData } = useGlobalSettingsQuery()

  const { logOut } = useAuth()

  const { switchLocale, locale } = useLocalization()

  const t = useTranslations()

  const insets = useSafeAreaInsets()
  const height = useSharedValue(0 - (insets.bottom + insets.top))

  const styles = useStyles(({ spacing, palette }) => ({
    container: {
      flex: 1,
      backgroundColor: palette.background.main,
    },
    safeAreaContainer: {
      flex: 1,
    },
    scrollListItem: {
      marginTop: spacing(8),
    },
    headerContainer: {
      backgroundColor: palette.background.main,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    profileMenu: {
      marginTop: spacing(4),
      marginBottom: spacing(8),
    },
    buttonContainer: {
      marginBottom: spacing(8),
      marginTop: spacing(-2),
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }))

  const renderMenu = () => {
    const environment = Constants.expoConfig?.extra?.env
    const adminDashboardUrl =
      environment === 'production'
        ? 'https://admin.qualaapp.com'
        : environment === 'staging'
        ? 'https://admin-test.qualaapp.com'
        : 'http://localhost:3000'

    const menuItems: ProfileMenuItemProps[] = [
      {
        title: t('titles.personal_information'),
        icon: ({ size, color }) => <UserSquare color={color} size={size} />,
        action: () => navigation.navigate('PersonalInformation'),
      },
      {
        title: t('titles.change_password'),
        icon: ({ size, color }) => <Lock1 color={color} size={size} />,
        action: () => navigation.navigate('ChangePassword'),
      },
      {
        title: t('titles.company_invite_link'),
        isHidden: !currentCompanyData?.currentCompany?.inviteLink,
        icon: ({ size, color }) => <ScanBarcode color={color} size={size} />,
        action: () => (height.value = withTiming(500, { duration: 200 })),
      },
      {
        title: t('titles.admin_dashboard'),
        icon: ({ size, color }) => <ShieldSecurity color={color} size={size} />,
        isHidden: !data?.me?.roles.includes('ADMIN') && !data?.me?.roles.includes('EDITOR'),
        action: () =>
          Platform.OS === 'web'
            ? window.open(adminDashboardUrl, '_blank')
            : Linking.openURL(adminDashboardUrl),
      },
      {
        title: t('titles.help_desk'),
        icon: ({ size, color }) => <EmojiHappy color={color} size={size} />,
        action: () =>
          Platform.OS === 'web'
            ? window.open(globalSettingsData?.globalSettings?.supportFormUrl ?? undefined, '_blank')
            : Linking.openURL(globalSettingsData?.globalSettings?.supportFormUrl ?? ''),
      },
    ]

    return menuItems
      .filter((item) => !item.isHidden)
      .map((item, index) => <ProfileMenuItem key={index} {...item} />)
  }
  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <ScrollView style={{ paddingTop: spacing(6) }}>
          <Container style={{ flexGrow: 1 }}>
            <Surface style={styles.headerContainer}>
              <Stack spacing={2} style={{ flex: 1 }}>
                {isLoading ? (
                  <Skeleton variant="text" textVariant="h3" />
                ) : (
                  <Typography variant="h3">
                    {data?.me?.fullName ?? data?.me?.email ?? undefined}
                  </Typography>
                )}
                <Typography variant="p1" color="on-background">
                  {data?.me?.roles[0] !== undefined
                    ? data?.me?.roles[0].slice(0, 1) + data?.me?.roles[0].slice(1).toLowerCase()
                    : undefined}
                </Typography>
              </Stack>

              <UserAvatar size="medium" user={data?.me} />
            </Surface>

            <View style={styles.scrollListItem}>
              <View>
                <Typography variant="h3">{t('titles.my_team')}</Typography>
              </View>
              <CompanyInformation
                company={currentCompanyData?.currentCompany}
                style={{ marginTop: spacing(4) }}
              />
            </View>

            <View style={styles.scrollListItem}>
              <View>
                <Typography variant="h3">{t('titles.profile_settings')}</Typography>
              </View>
              <View style={styles.profileMenu}>
                {/** Menu */}
                <Stack spacing={2} direction="column">
                  {renderMenu()}
                </Stack>
              </View>

              <View style={styles.buttonContainer}>
                <Button
                  title={t('open_onboarding')}
                  color="surface"
                  titleStyle={{ color: theme.palette.surface.on, fontWeight: 'bold' }}
                  disableElevation
                  onPress={() => openOnboarding()}
                ></Button>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  title={t('log_out')}
                  color="error"
                  disableElevation
                  titleStyle={{ color: theme.palette.error.on, fontWeight: 'bold' }}
                  onPress={() => logOut()}
                ></Button>
              </View>
              {Constants.expoConfig?.extra?.env === 'development' && (
                <View style={styles.buttonContainer}>
                  <Button
                    title="Switch language"
                    size="large"
                    color="warning"
                    variant="text"
                    onPress={() => switchLocale(locale === Language.En ? Language.No : Language.En)}
                  ></Button>
                </View>
              )}
            </View>
          </Container>
        </ScrollView>
      </SafeAreaView>
      <BottomSheet height={height}>
        {currentCompanyData?.currentCompany?.inviteLink && (
          <CompanyInviteCode code={currentCompanyData?.currentCompany.inviteLink} />
        )}
        <View style={{ paddingTop: spacing(4) }}>
          <Button
            title={t('close')}
            tintColor="on-success"
            color="success"
            onPress={() => (height.value = withTiming(0, { duration: 200 }))}
          />
        </View>
      </BottomSheet>
    </View>
  )
}

export default Profile
