import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Lottery, Prize, UserTickets } from '../../types/api'
import Badge from '../Badge'
import ArrowButton from '../buttons/ArrowButton'
import ImageHeader from '../ImageHeader'
import { useTranslations } from '../../contexts/localeContext'

export interface PrizeImageHeaderProps {
  prize: Prize
}

export const PrizeImageHeader: React.FC<PrizeImageHeaderProps> = ({ prize }) => {
  const navigation = useNavigation()
  const t = useTranslations()

  return (
    <ImageHeader
      title="Lottery prize"
      image={{ uri: prize.imageUrl }}
      topLeftNode={
        <ArrowButton
          size={40}
          variant="left"
          onPress={() => navigation.navigate('Lottery', { lotteryId: prize.lotteryId! })}
        />
      }
      bottomLeftNode={
        <Badge label={`${prize.prizeTier}. ${t('prize')}`} size="extra-large" color="secondary" />
      }
    />
  )
}

export default PrizeImageHeader
