import { Image, View } from 'react-native'
import { HomeTabScreenProps } from '../../navigation/types'
import Typography from '../../components/Typography'
import { useTranslations } from '../../contexts/localeContext'
import { useStyles } from '../../hooks/useStyles'
import Container from '../../components/Container'
import { useSpacingFn } from '../../contexts/SpacingContext'
import SafeAreaView from '../../components/SafeAreaView'
import {
  useCoinAccountSummaryQuery,
  useProductsQuery,
  usePurchasedUrlProductsQuery,
} from '../../generated/graphql'
import ScrollView from '../../components/ScrollView'
import ProductCard from '../../components/product/ProductCard'
import UrlProductCard from '../../components/product/UrlProductCard'
import CircleData from '../../components/CircleData'
import { Sheet } from '../../components/Sheet'
import { IncomingOutgoingDisplay } from '../../components/display/IncomingOutgoingDisplay'
import Surface from '../../components/Surface'
import { Elevation, useTheme } from '../../contexts/ThemeContext'
import chroma from 'chroma-js'

const Shop = (props: HomeTabScreenProps<'Shop'>) => {
  const coinAccountSummaryQuery = useCoinAccountSummaryQuery()
  const productsQuery = useProductsQuery()
  const purchasedProductsQuery = usePurchasedUrlProductsQuery()

  const t = useTranslations()

  const spacing = useSpacingFn()

  const placeholderStyles = useStyles(({ palette }) => ({
    surface: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: spacing(4),
      paddingVertical: spacing(16),
    },
    coinContainer: {
      backgroundColor: palette.background.main,
      position: 'relative',
      height: spacing(40),
      width: spacing(40),
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing(2),
    },
    coinImage: {
      marginBottom: spacing(-2),
      height: '100%',
      width: '100%',
    },
    badge: { position: 'absolute', alignSelf: 'center', bottom: spacing(-1) },
    sheetTop: {
      height: spacing(38),
      justifyContent: 'center',
      alignItems: 'center',
    },
  }))

  const isEmptyScreen =
    purchasedProductsQuery.data?.purchasedUrlProducts.list.length === 0 &&
    productsQuery.data?.products.count === 0

  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
      <Sheet snapPoints={[260, 380]} clamp>
        {(progress) => (
          <SafeAreaView disableBottomSafeArea>
            <Container>
              <View style={placeholderStyles.sheetTop}>
                {coinAccountSummaryQuery.data !== undefined && (
                  <CircleData
                    preTitle={t('my_coins')}
                    title={coinAccountSummaryQuery.data.coinAccountSummary.balance.toString()}
                  />
                )}
              </View>
              {coinAccountSummaryQuery.data !== undefined && (
                <IncomingOutgoingDisplay
                  incomingText={t('total_coins_earned')}
                  outgoingText={t('total_coins_spent')}
                  incomingValue={coinAccountSummaryQuery.data.coinAccountSummary.totalEarned}
                  outgoingValue={coinAccountSummaryQuery.data.coinAccountSummary.totalSpent}
                  opacity={progress}
                />
              )}
            </Container>
          </SafeAreaView>
        )}
      </Sheet>
      {isEmptyScreen ? (
        <NoProducts />
      ) : (
        <Container style={{ paddingHorizontal: 0, paddingBottom: spacing(6) }}>
          <View style={{ marginTop: spacing(6) }}>
            <View style={{ paddingHorizontal: spacing(2) }}>
              <Typography variant="h3">{t('titles.quala_shop')}</Typography>
              {productsQuery.data && (
                <Typography variant="p1" style={{ marginTop: spacing(1) }}>
                  {`${productsQuery.data.products.count} ${t('products')}`}
                </Typography>
              )}
            </View>
            <ScrollView
              horizontal={true}
              style={{
                marginTop: spacing(2),
                width: '100%',
              }}
              showsHorizontalScrollIndicator={false}
            >
              {productsQuery.data &&
                productsQuery.data.products.list.map((product, index) => (
                  <View
                    key={product.id}
                    style={{ marginRight: spacing(2), marginLeft: index === 0 ? spacing(2) : 0 }}
                  >
                    <ProductCard
                      product={product}
                      onPress={() =>
                        props.navigation.navigate('Product', { productId: product.id })
                      }
                    />
                  </View>
                ))}
            </ScrollView>
          </View>
          <View style={{ marginTop: spacing(6) }}>
            <View style={{ paddingHorizontal: spacing(2) }}>
              <Typography variant="h3">{t('my_purchases')}</Typography>
              {purchasedProductsQuery.data && (
                <Typography variant="p1" style={{ marginTop: spacing(1) }}>
                  {`${purchasedProductsQuery.data.purchasedUrlProducts.count} ${t('products')}`}
                </Typography>
              )}
            </View>
            <ScrollView
              horizontal={true}
              style={{
                marginTop: spacing(2),
                width: '100%',
              }}
              showsHorizontalScrollIndicator={false}
            >
              {purchasedProductsQuery.data &&
                purchasedProductsQuery.data.purchasedUrlProducts.list.map((product, index) => (
                  <View
                    key={index}
                    style={{
                      marginLeft: index === 0 ? spacing(2) : 0,
                      marginRight: spacing(2),
                    }}
                  >
                    <UrlProductCard
                      urlProduct={product}
                      onPress={() =>
                        props.navigation.navigate('PurchasedUrlProduct', {
                          productId: product.id,
                        })
                      }
                    />
                  </View>
                ))}
            </ScrollView>
          </View>
        </Container>
      )}
    </ScrollView>
  )
}

const NoProducts: React.FC = () => {
  const theme = useTheme()
  const color = chroma(theme.palette.background.main).brighten(0).saturate(0.2).hex()
  const t = useTranslations()

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={{ width: 140, height: 100, position: 'relative', marginBottom: 40 }}>
        {Array.from({ length: 2 }).map((_, i) => (
          <Surface
            elevation={(i + 1) as Elevation}
            key={i}
            category="large"
            style={{
              position: 'absolute',
              width: 140,
              height: 100,
              padding: 10,
              transform: i === 0 ? [{ translateX: -30 }, { translateY: 25 }] : [{ translateX: 35 }],
            }}
          >
            <Surface category="medium" style={{ flex: 1, backgroundColor: color }}></Surface>
            <Surface
              category="medium"
              style={{ backgroundColor: color, height: 15, width: '50%', marginTop: 10 }}
            ></Surface>
          </Surface>
        ))}
      </View>
      <View>
        <Typography textAlign="center" weight="semibold">
          {t('no_products')}
        </Typography>
        <Typography textAlign="center">{t('no_products_description')}</Typography>
      </View>
    </View>
  )
}
export default Shop
