import { View } from 'react-native'
import { useSpacingFn } from '../../../contexts/SpacingContext'
import { ContentImage } from '../../../types/course'
import ImageSlideshow from '../../ImageSlideshow'
import Typography from '../../Typography'

export type ImageBlockProps = {
  content: ContentImage
}
const ImageBlock = ({ content }: ImageBlockProps) => {
  const spacing = useSpacingFn()

  return (
    <View style={{ marginHorizontal: 'auto' }}>
      {typeof content.url === 'string' ? (
        <img src={content.url} style={{ width: '100%', display: 'block', maxWidth: 'auto' }} />
      ) : (
        <ImageSlideshow images={content.url} />
      )}

      {content.caption?.length !== 0 && (
        <Typography variant="p2" style={{ marginLeft: spacing(3), marginTop: spacing(1) }}>
          {content.caption}
        </Typography>
      )}
    </View>
  )
}

export default ImageBlock
