/**
 * Copied from: https://github.com/th3rdwave/react-native-safe-area-context/issues/114#issuecomment-667518410
 *
 * Should fix an issue with SafeAreaView where it jumps on initial load.
 */

import { ReactNode } from 'react'
import { View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

type SafeAreaViewProps = {
  disableBottomSafeArea?: boolean
  disableTopSafeArea?: boolean
  disableSidesSafeArea?: boolean
  children: ReactNode
  style?: ViewStyle
}

const SafeAreaView: React.FC<SafeAreaViewProps> = (props: SafeAreaViewProps) => {
  const {
    disableBottomSafeArea = false,
    disableTopSafeArea = false,
    disableSidesSafeArea = false,
    children,
    style: styleProp,
  } = props

  const insets = useSafeAreaInsets()

  const style: ViewStyle = {}

  if (!disableBottomSafeArea) {
    style.marginBottom = insets.bottom
  }

  if (!disableTopSafeArea) {
    style.marginTop = insets.top
  }

  if (!disableSidesSafeArea) {
    style.marginRight = insets.right
    style.marginLeft = insets.left
  }

  return <View style={[{ flex: 1 }, style, styleProp]}>{children}</View>
}

export default SafeAreaView
