import chroma from 'chroma-js'
import { LinearGradient } from 'expo-linear-gradient'
import { View } from 'react-native'
import { PaletteColorName, useTheme } from '../contexts/ThemeContext'
import { useStyles } from '../hooks/useStyles'
import FormattedNumber from './FormattedNumber'
import CoinIconSvg from './graphics/CoinIconSvg'
import Surface from './Surface'
import Typography from './Typography'

type CoinBadgeProps = {
  amount: number
  partialAmount?: number
  size?: 'large' | 'medium'
  color?: PaletteColorName
}

const CoinBadge: React.FC<CoinBadgeProps> = ({
  amount,
  partialAmount,
  size = 'medium',
  color = 'secondary',
}) => {
  const theme = useTheme()

  const badgeColor = theme.palette[color]

  const styles = useStyles(({ palette, spacing, shapes }) => ({
    container: {
      paddingEnd: size === 'large' ? spacing(3) : spacing(2),
      backgroundColor: badgeColor.main,
      flexDirection: 'row',
      alignItems: 'center',
      ...shapes.full,
    },
    c: {
      marginEnd: size === 'large' ? spacing(2) : spacing(1),
    },
  }))

  const textVariant = size === 'large' ? 'subtitle' : 'p1'

  return (
    <Surface style={styles.container}>
      <View style={styles.c}>
        <CoinIconSvg size={20} color={color} />
      </View>
      {partialAmount !== undefined && (
        <>
          <FormattedNumber color="on-secondary" weight="semibold" variant={textVariant}>
            {`${partialAmount}`}
          </FormattedNumber>
          <Typography variant="p2" color="on-secondary">{` / `}</Typography>
        </>
      )}
      <FormattedNumber color="on-secondary" weight="semibold" variant={textVariant}>
        {`${amount}`}
      </FormattedNumber>
    </Surface>
  )
}

export default CoinBadge
