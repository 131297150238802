import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'

const LotteryIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 24,
  color = 'black',
}) => {
  return (
    <Svg
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      {/* @ts-ignore */}
      <G data-name="vuesax/outline/slider-horizontal" fill={color}>
        <Path
          data-name="Subtraction 1"
          d="M3516.564-5032.845c-1.8 0-2.954-.882-3.726-2.86a13.472 13.472 0 01-.537-1.658l-1.811-6.762a12.083 12.083 0 01-.363-1.736c-.512-3.308 1.235-4.451 4.428-5.306l.977-.262a6.4 6.4 0 00-.3 1.408 8.435 8.435 0 00-.031.233l-.257.069c-3.192.856-3.633 1.664-3.337 3.658a13.191 13.191 0 00.332 1.547l1.811 6.762a12.547 12.547 0 00.478 1.477c.487 1.256 1 1.937 2.337 1.937a9.669 9.669 0 002.39-.408l2.812-.753h3.114c.493 0 .918-.014 1.3-.044a9.6 9.6 0 01-2.976 1.211l-3.864 1.035a10.974 10.974 0 01-2.777.452z"
          transform="translate(-556 -188) translate(-2951.88 5243.75)"
        />
        <Path
          d="M9.75 18.5h-4c-3.3 0-5.29-.66-5.66-4.01A13.214 13.214 0 010 12.75v-7a12.077 12.077 0 01.1-1.77C.46.66 2.45 0 5.75 0h4c3.3 0 5.29.66 5.66 4.01a13.213 13.213 0 01.09 1.74v7a12.077 12.077 0 01-.1 1.77c-.36 3.32-2.35 3.98-5.65 3.98zm-4-17c-3.31 0-3.94.67-4.17 2.67a13.04 13.04 0 00-.08 1.58v7a12.15 12.15 0 00.08 1.55c.22 2.03.86 2.7 4.17 2.7h4c3.31 0 3.94-.67 4.17-2.67a11.262 11.262 0 00.08-1.58v-7a12.15 12.15 0 00-.08-1.55c-.22-2.03-.86-2.7-4.17-2.7z"
          transform="translate(-556 -188) translate(563.25 189.75)"
        />
        <Path
          data-name="Vector"
          d="M2.153 7.3a1.2 1.2 0 01-.68-.22 1.149 1.149 0 01-.46-1.14l.21-1.19-.87-.85a1.171 1.171 0 01-.3-1.19 1.16 1.16 0 01.94-.79l1.2-.18.54-1.09A1.162 1.162 0 013.773 0a1.14 1.14 0 011.04.65l.54 1.09 1.2.18a1.157 1.157 0 01.64 1.98l-.87.85.21 1.19a1.149 1.149 0 01-.46 1.14 1.169 1.169 0 01-1.22.09l-1.07-.56-1.083.56a1.172 1.172 0 01-.547.13zm-.27-4l.51.5a1.164 1.164 0 01.34 1.03l-.12.7.63-.33a1.148 1.148 0 011.08 0l.63.33-.12-.7a1.147 1.147 0 01.34-1.03l.51-.5-.7-.1a1.193 1.193 0 01-.88-.64l-.31-.64-.31.64a1.193 1.193 0 01-.88.64z"
          transform="translate(-556 -188) translate(567.224 193.35)"
        />
        <Path
          data-name="Vector"
          d="M6.75 1.5h-6A.755.755 0 010 .75.755.755 0 01.75 0h6a.755.755 0 01.75.75.755.755 0 01-.75.75z"
          transform="translate(-556 -188) translate(567.25 202.5)"
        />
      </G>
    </Svg>
  )
}

export default LotteryIcon
