import { useMemo } from 'react'
import { View } from 'react-native'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { Company } from '../../types/api'
import AvatarGroup, { AvatarGroupProps } from '../AvatarGroup'
import Skeleton from '../Skeleton'

export interface CompanyAvatarGroupProps extends Omit<AvatarGroupProps, 'avatars'> {
  company: Company
}

const AvatarGroupSkeleton = () => {
  const spacing = useSpacingFn()
  return (
    <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
      <View style={{ flexDirection: 'row' }}>
        {Array.from(new Array(6).keys()).map((i) => {
          return (
            <Skeleton
              key={'avatar-skeleton' + i}
              variant="circular"
              style={{ height: spacing(8), width: spacing(8) }}
            ></Skeleton>
          )
        })}
      </View>
      <Skeleton variant="circular" style={{ height: spacing(8), width: spacing(8) }}></Skeleton>
    </View>
  )
}
const CompanyAvatarGroup = ({ company, ...props }: CompanyAvatarGroupProps) => {
  const avatars = company?.employees?.length
    ? company.employees.map(({ avatarUrl, fullName }) => ({
        avatarUrl,
        fullName,
      }))
    : []

  const isLoading = useMemo(() => {
    return company === undefined
  }, [company])

  return isLoading ? <AvatarGroupSkeleton /> : <AvatarGroup avatars={avatars} {...props} />
}

export default CompanyAvatarGroup
