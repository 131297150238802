import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Element3, VideoSquare, UserSquare, Bag2 } from 'iconsax-react-native'
import IconButton from '../../components/IconButton'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { useTheme } from '../../contexts/ThemeContext'
import Achievements from '../../screens/HomeTabs/Shop'
import Courses from '../../screens/HomeTabs/Courses'
import Lobby from '../../screens/HomeTabs/Lobby'
import Profile from '../../screens/HomeTabs/Profile'
import Lotteries from '../../screens/HomeTabs/Lotteries'
import { HomeTabParamList } from '../types'
import { useFeatureFlags } from '../../contexts/FeatureFlags'
import LotteryIcon from '../../components/graphics/LotteryIcon'

const Tab = createBottomTabNavigator<HomeTabParamList>()

const HomeNavigator = () => {
  const { flags } = useFeatureFlags()

  const theme = useTheme()
  const spacing = useSpacingFn()

  return (
    <Tab.Navigator
      initialRouteName="Lobby"
      screenOptions={({ route }) => ({
        tabBarShowLabel: false,
        tabBarActiveTintColor: theme.palette.accent.on,
        tabBarInactiveTintColor: theme.palette.surface.on,
        tabBarIcon({ color, size, focused }) {
          const getIcon = () => {
            switch (route.name) {
              case 'Lobby':
                return <Element3 color={color} size={size} />
              case 'Courses':
                return <VideoSquare color={color} size={size} />
              case 'Shop':
                return <Bag2 color={color} size={size} />
              case 'Lotteries':
                return <LotteryIcon color={color} size={size} />
              case 'Profile':
                return <UserSquare color={color} size={size} />
            }
          }

          return (
            <IconButton
              color={focused ? 'accent' : 'surface'}
              icon={getIcon()}
              pointerEvents="none"
            />
          )
        },
        tabBarStyle: {
          borderTopLeftRadius: spacing(6),
          borderTopRightRadius: spacing(6),
          minHeight: spacing(17),
          maxWidth: 700,
          width: '100%',
          marginHorizontal: 'auto',
        },
      })}
    >
      <Tab.Screen
        name="Lobby"
        component={Lobby}
        options={{
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="Courses"
        component={Courses}
        options={{
          headerShown: false,
        }}
      />
      {flags.Shop && (
        <Tab.Screen
          name="Shop"
          component={Achievements}
          options={{
            headerShown: false,
          }}
        />
      )}
      {flags.Lottery && (
        <Tab.Screen
          name="Lotteries"
          component={Lotteries}
          options={{
            headerShown: false,
          }}
        />
      )}
      <Tab.Screen
        name="Profile"
        component={Profile}
        options={{
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  )
}

export default HomeNavigator
