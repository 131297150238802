import { useNavigation } from '@react-navigation/native'
import { useQueryClient } from '@tanstack/react-query'
import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { View } from 'react-native'
import Typography from '../../components/Typography'
import Constants from 'expo-constants'

import {
  CourseChapterType,
  Difficulty,
  useAnswerQuizQuestionMutation,
  useCompleteCourseBlockMutation,
  useCreateCourseModuleProgressionMutation,
  useEndCourseModuleMutation,
  useOpenChapterMutation,
  useRestartCourseModuleMutation,
  useRestartQuizMutation,
} from '../../generated/graphql'

import {
  CourseChapter,
  CourseChapterSession,
  CourseModule,
  EndCourseModuleResponse,
  MyCourseRating,
} from '../../types/api'
import { useSpacingFn } from '../SpacingContext'

export type ChapterOverview = {
  id: string
  name: string
  type: CourseChapterType
  progression: ChapterProgressionInfo
}

export type ChapterProgressionInfo = {
  currentStep: number
  totalSteps: number
  isStarted: boolean
  isCompleted: boolean
}

export type CourseView = 'intro' | 'chapter' | 'end' | 'rate_course'

export interface ICourseContext {
  /**
   * Data
   */
  course: CourseModule
  chapters: Array<CourseChapter>
  difficulty: string
  currentChapter: CourseChapter
  currentChapterSession: CourseChapterSession | null
  chapterOverviewList: Array<ChapterOverview>
  endCourseModuleResponse: EndCourseModuleResponse | undefined

  /**
   * State
   */
  activeView: CourseView
  activeChapterIndex: number
  isCourseAlreadyCompleted: boolean
  isLastChapter: boolean
  allChaptersAreCompleted: boolean
  isProgessionUpdating: boolean
  isCourseStarted: boolean
  canStartCourse: boolean
  myRating: MyCourseRating | null
  /**
   * Operations to manipulate state and perform graphql-mutations.
   */
  nextChapter: () => void
  previousChapter: () => void
  openChapterById: (id: string) => void
  startCourse: () => void
  endCourse: () => void
  showIntro: () => void
  openRatingScreen: () => void
  completeBlock(chapterId: string, blockId: string): void
  answerQuizQuestion(chapterId: string, questionId: string, answerIds: Array<string>): void
  restartQuizProgression(chapterId: string): void
  restartCourseProgression(): void

  /**
   * Exit course.
   */
  exit: () => void

  /**
   * Dev
   */
  isDebugMode: boolean
  toggleDebugMode(): void
}

export interface CourseProviderProps {
  course: CourseModule
  children: ReactNode
}

export const CourseContext = createContext<ICourseContext | null>(null)

export const CourseProvider = ({ course, children }: CourseProviderProps) => {
  const [debugModeActive, setDebugModeActive] = useState(false)

  const navigation = useNavigation()

  const queryClient = useQueryClient()

  const createCourseModuleProgressionMutation = useCreateCourseModuleProgressionMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const restartCourseModuleProgressionMutation = useRestartCourseModuleMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
      setActiveView('intro')
      setActiveChapterIndex(0)
    },
  })

  const openChapterMutation = useOpenChapterMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const completeCourseBlockMutation = useCompleteCourseBlockMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const answerQuizQuestionMutation = useAnswerQuizQuestionMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const restartQuizMutation = useRestartQuizMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const endCourseModuleMutation = useEndCourseModuleMutation({
    onSuccess(data) {
      // We now have what we need here.
      setEndCourseData(data.endCourse)
    },
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
      queryClient.invalidateQueries(['CoinAccountSummary'])
      queryClient.invalidateQueries(['UserTickets'])
    },
  })

  /**
   * Data
   */

  const chapters = useMemo(() => {
    return course.chapters.sort((a, b) => a.order - b.order)
  }, [course])

  const difficulty = useMemo<Difficulty>(
    () => (course.difficulty ? (course.difficulty as Difficulty) : Difficulty.Medium),
    [course]
  )
  /**
   * State
   */

  const isCourseAlreadyCompleted = useMemo(() => {
    if (course.currentSession === null) return false
    if (course.currentSession?.endedAt === null) return false
    return true
  }, [course])

  const [activeView, setActiveView] = useState<CourseView>(
    isCourseAlreadyCompleted ? 'end' : 'intro'
  )
  const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0)
  const [endCourseData, setEndCourseData] = useState<EndCourseModuleResponse | undefined>(undefined)

  const canStartCourse = useMemo(() => {
    return chapters.length !== 0 && !isCourseAlreadyCompleted
  }, [course])

  const isProgessionUpdating = useMemo(() => {
    return (
      createCourseModuleProgressionMutation.isLoading ||
      openChapterMutation.isLoading ||
      completeCourseBlockMutation.isLoading ||
      answerQuizQuestionMutation.isLoading
    )
  }, [
    createCourseModuleProgressionMutation,
    openChapterMutation,
    completeCourseBlockMutation,
    answerQuizQuestionMutation,
  ])

  const currentChapter = useMemo(() => chapters[activeChapterIndex], [activeChapterIndex])

  const isCourseStarted = useMemo(() => course.currentSession !== null, [course])

  const isLastChapter = useMemo(
    () => activeChapterIndex === chapters.length - 1,
    [activeChapterIndex]
  )

  const allChaptersAreCompleted = useMemo<boolean>(() => {
    if (course.currentSession === null) {
      return false
    }

    const chapterSessions = course.currentSession?.chapterSessions

    if (chapterSessions === undefined) {
      return false
    }

    return (
      chapterSessions.length === chapters.length &&
      chapterSessions.every((chapterSession) =>
        isChapterCompleted(chapterSession.courseChapterId, course)
      )
    )
  }, [course])

  /**
   * The index of the first chapter that is not yet completed.
   */
  const firstIncompleteChapterIndex = useMemo<number | null>(() => {
    if (allChaptersAreCompleted) return null

    const index = chapters.findIndex(({ id }) => !isChapterCompleted(id, course))

    // if (index === -1) {
    //   throw new Error('Could not find first incomplete chapter though course is not complete')
    // }

    return index
  }, [course, allChaptersAreCompleted])

  /**
   * The chapter session belonging to the current active chapter.
   */
  const currentChapterSession = useMemo<CourseChapterSession | null>(() => {
    const session = course?.currentSession?.chapterSessions?.find(({ courseChapterId }) => {
      return courseChapterId === chapters[activeChapterIndex].id
    })

    if (session === undefined) {
      return null
    }
    return session
  }, [activeChapterIndex, course])

  const myRating = useMemo<MyCourseRating>(() => course.myRating, [course])

  /**
   * Opens a chapter with given id.
   * Will also create a chapter session for this chapter if none exists.
   *
   * @param chapterId
   */
  const openChapterById = async (chapterId: string): Promise<void> => {
    if (!canStartCourse) {
      return
    }

    const n = chapters.findIndex((chapter) => chapter.id === chapterId)
    if (n === undefined || n === -1) throw new Error('Chapter not found')
    setActiveChapterIndex(n)

    // If there is no progression we need to create it first
    if (course.currentSession === null) {
      await createCourseModuleProgressionMutation.mutateAsync({
        moduleId: course.id,
      })
    }

    setActiveView('chapter')

    const userHasChapterSession =
      course.currentSession?.chapterSessions.find(
        ({ courseChapterId }) => courseChapterId === chapterId
      ) !== undefined

    if (!userHasChapterSession) {
      openChapterMutation.mutate({ moduleId: course.id, chapterId: chapterId })
    }
  }

  /**
   * Navigates to the previous chapter.
   * @returns
   */
  const previousChapter = () => {
    if (activeChapterIndex === 0) {
      return
    }

    openChapterById(chapters[activeChapterIndex - 1].id)
  }

  /**
   * Navigates to the next chapter.
   * If the currently active chapter is the last chapter, it will either end the course, or move to
   * the first incomplete chapter in the course, depending on if there are any incomplete chapters.
   */
  const nextChapter = () => {
    if (isLastChapter && firstIncompleteChapterIndex !== null) {
      // Some chapters are incomplete. Redirect to the first incomplete chapter.
      openChapterById(chapters[firstIncompleteChapterIndex].id)
    } else if (isLastChapter && isCourseAlreadyCompleted) {
      // Entire course is complete. Go to intro.
      showIntro()
    } else if (isLastChapter && firstIncompleteChapterIndex === null) {
      // All chapters complete. We can safely end the course.
      endCourse()
    } else {
      openChapterById(chapters[activeChapterIndex + 1].id)
    }
  }

  /**
   * Literally ends a course irl
   */
  const endCourse = () => {
    if (isCourseAlreadyCompleted) {
      setActiveView('end')
      return
    }

    endCourseModuleMutation.mutate(
      { moduleId: course.id },
      {
        onSuccess() {
          setActiveView('end')
        },
      }
    )
  }

  /**
   * Navigate to the rating screen.
   */
  const openRatingScreen = () => {
    setActiveView('rate_course')
  }

  /**
   * Starts the course. Will ask API kindly if it can create a new
   * course module progression for this user on this module, then navigate to the first chapter.
   */
  const startCourse = (): void => {
    // We cannot start a course without any chapters
    if (chapters.length === 0) {
      return
    }

    openChapterById(currentChapter.id)
  }

  /**
   * This must be called whenever a user completes a block, in info chapters.
   *
   * @param chapterId
   * @param blockId
   * @returns Nothing at all.
   */
  const completeBlock = (chapterId: string, blockId: string): void => {
    // If the course is already completed we should do nothing.
    if (isCourseAlreadyCompleted) {
      return
    }

    // Find the chapter session where the block should be marked as completed.
    const chapterSession = course.currentSession?.chapterSessions.find(
      ({ courseChapterId }) => courseChapterId === chapterId
    )

    if (chapterSession === undefined) {
      // Not finding a chapter session when completing a block is a major error.
      throw new Error('Tried to complete a course block on non existing chapter session.')
    }

    // Check if the block is already completed.
    if (chapterSession.blocksCompleted.includes(blockId)) {
      return
    }

    completeCourseBlockMutation.mutate({
      moduleId: course.id,
      chapterId,
      blockId,
    })
  }

  /**
   * Ask the API if we can wipe the chapter session of a given
   * quiz-type chapter.
   *
   * @param chapterId
   * @returns
   */
  const restartQuizProgression = (chapterId: string): void => {
    // Dont let user restart quiz is the course is completed.
    if (isCourseAlreadyCompleted) {
      throw new Error('Cannot restart quiz because the course module is completed.')
    }

    restartQuizMutation.mutate({ moduleId: course.id, chapterId })
  }

  /**
   * Ask the API if we can restart this entire course!
   */
  const restartCourseProgression = (): void => {
    // Dont let user restart quiz is the course is completed.
    if (!isCourseStarted) {
      throw new Error('Cannot restart a course when it is not started.')
    }

    restartCourseModuleProgressionMutation.mutate({ moduleId: course.id })
  }

  /**
   * Tell API that user has answered a question, so that it will be
   * remembered.
   *
   * @param chapterId
   * @param questionId
   * @param answerIds
   */
  const answerQuizQuestion = (chapterId: string, questionId: string, answerIds: Array<string>) => {
    answerQuizQuestionMutation.mutate({ moduleId: course.id, chapterId, questionId, answerIds })
  }

  /**
   * Navigates to the Intro-screen of the course.
   */
  const showIntro = () => setActiveView('intro')

  /** Exit */
  const exit = () => navigation.navigate('Home', { screen: 'Courses' })

  const context: ICourseContext = {
    endCourseModuleResponse: endCourseData,
    activeView,
    course,
    chapters,
    activeChapterIndex,
    isCourseAlreadyCompleted,
    currentChapter,
    allChaptersAreCompleted,
    currentChapterSession,
    isLastChapter,
    isCourseStarted,
    isProgessionUpdating,
    canStartCourse,
    myRating,
    isDebugMode: debugModeActive,
    difficulty,
    nextChapter,
    previousChapter,
    startCourse,
    endCourse,
    openChapterById,
    showIntro,
    openRatingScreen,
    exit,
    completeBlock,
    answerQuizQuestion,
    restartQuizProgression,
    restartCourseProgression,
    chapterOverviewList: useMemo(() => {
      return chapters.map((chapter) => {
        return {
          id: chapter.id,
          name: chapter.title,
          type: chapter.type as CourseChapterType,
          progression: getChapterProgressionInfo(chapter.id, course),
        }
      })
    }, [course]),
    toggleDebugMode: () => setDebugModeActive((state) => !state),
  }
  const spacing = useSpacingFn()
  return (
    <CourseContext.Provider value={context}>
      <View style={{ position: 'relative', flex: 1 }}>
        {Constants.expoConfig?.extra?.env === 'development' &&
          debugModeActive && ( // For debugging purposes only
            <View
              style={{ position: 'absolute', top: spacing(10), start: spacing(4), zIndex: 50 }}
              pointerEvents="none"
            >
              <Typography variant="caption" color="error">
                {`
Started: ${course.currentSession !== null}
Number of chapterSessions: ${course?.currentSession?.chapterSessions.length}
All chapters in session complete: ${allChaptersAreCompleted}
Course already completed: ${isCourseAlreadyCompleted}
First incomplete chapter index: ${firstIncompleteChapterIndex}
Progression loading: ${isProgessionUpdating}
            `}
              </Typography>
            </View>
          )}
        <View style={{ flex: 1 }}>{children}</View>
      </View>
    </CourseContext.Provider>
  )
}

export const useCourse = () => {
  const context = useContext(CourseContext)
  if (context === null) {
    throw new Error('useCourse must be used within a CourseProvider')
  }
  return context
}

/**
 * Some chapter progression helpers below.
 */

const getCourseChapterSteps = (chapterId: string, course: CourseModule) => {
  // A chapters total steps is the required amount of blocks to complete.
  const chapter = course.chapters.find((chapter) => chapter.id === chapterId)
  if (!chapter) throw new Error('Chapter not found')

  return chapter.numberOfRequiredBlocks
}

const getCourseChapterCurrentStep = (chapterId: string, course: CourseModule) => {
  // The current amount of steps completed in this chapter session.
  const chapter = course.chapters.find((chap) => chap.id === chapterId)
  if (!chapter) throw new Error('Chapter not found')

  if (course.currentSession === null) {
    return 0
  }

  const session = course.currentSession?.chapterSessions.find(
    (chapterSession) => chapterSession.courseChapterId === chapterId
  )

  if (session === undefined) {
    // Chapter has no session
    return 0
  }

  return session.numberOfRequiredBlocksCompleted
}

const isChapterStarted = (chapterId: string, course: CourseModule) => {
  // // A chapter is started if there exists a chapter session for that chapter.
  const chapterSession = course.currentSession?.chapterSessions?.find(
    (session) => session.courseChapterId === chapterId
  )

  return chapterSession !== undefined
}

const isChapterCompleted = (chapterId: string, course: CourseModule) => {
  // // A chapter is completed if: there exists a chapter session for that chapter
  // // and all the required blocks within a chapter is completed.
  // // Note: completed does not mean answered correctly for quiz chapters.

  const chapter = course.chapters.find(({ id }) => id === chapterId)
  if (chapter === undefined) {
    // Chapter not found is a major error
    throw new Error(
      'Attempt to check for completeness on a chapter that does not exist on course module.'
    )
  }

  const chapterSession = course.currentSession?.chapterSessions.find(
    (session) => session.courseChapterId === chapterId
  )

  // If there is no session then it is per definition not completed.
  if (chapterSession === undefined) return false

  if (chapterSession.completedAt !== null) {
    return true
  }

  return getCourseChapterCurrentStep(chapterId, course) === getCourseChapterSteps(chapterId, course)
}

const getChapterProgressionInfo = (
  chapterId: string,
  course: CourseModule
): ChapterProgressionInfo => {
  return {
    currentStep: getCourseChapterCurrentStep(chapterId, course),
    totalSteps: getCourseChapterSteps(chapterId, course),
    isStarted: isChapterStarted(chapterId, course),
    isCompleted: isChapterCompleted(chapterId, course),
  }
}
