import { Platform } from 'react-native'
import { Elevations, Theme } from '.'

const totalElevations = 25

function mapRange(value: number, fromRange: [number, number], toRange: [number, number]): number {
  const [fromMin, fromMax] = fromRange
  const [toMin, toMax] = toRange
  const fromRangeSize = fromMax - fromMin
  const toRangeSize = toMax - toMin
  const valueScaledToFromRange = (value - fromMin) / fromRangeSize
  const mappedValue = toMin + valueScaledToFromRange * toRangeSize
  return mappedValue
}

const m = (value: number, start: number, end: number): number => {
  if (value === 0) return 0
  return mapRange(value, [1, totalElevations], [start, end])
}

const elevations: Elevations = Array.from(
  { length: totalElevations },
  (_, i) => i
).reduce<Elevations>(
  (acc, i) => ({
    ...acc,
    [i]: Platform.select({
      android: {
        elevation: i,
      },
      default: {
        shadowColor: '#13023C50',
        shadowOffset: {
          width: 0,
          height: m(i, 1, 12),
        },
        shadowOpacity: m(i, 0.18, 0.58),
        shadowRadius: m(i, 5.0, 80.0),
      },
      web: {
        boxShadow:
          i === 0
            ? 'none'
            : `0px ${m(i, 5, 18)}px ${m(i, 10, 20)}px ${m(i, -5, -18)}px rgba(19,2,60,0.1)`,
      },
    }),
  }),
  {} as Elevations
)

export const defaultTheme: Theme = {
  colorScheme: 'light',
  elevations,
  palette: {
    primary: {
      main: '#4BC1AD',
      on: '#13023C',
    },
    secondary: {
      // main: '#8A4DDD',
      main: '#8250D5',
      on: '#FFF',
    },
    tertiary: {
      main: '#13023C',
      on: '#FFF',
    },
    accent: {
      main: '#4106C9',
      on: '#FFF',
    },
    success: {
      main: '#4BC1AD',
      on: '#FFF',
    },
    warning: {
      main: '#FFAB4A',
      on: '#FFF',
    },
    error: {
      main: '#EA6D6D',
      on: '#FFF',
    },
    background: {
      main: '#F8F5FD',
      on: '#13023C',
    },
    surface: {
      main: '#FFF',
      on: '#13023C',
    },
  },
  shapes: {
    small: {
      borderRadius: 5,
    },
    medium: {
      borderRadius: 10,
    },
    large: {
      borderRadius: 15,
    },
    extraLarge: {
      borderRadius: 20,
    },
    xxl: { borderRadius: 30 },
    '3xl': { borderRadius: 40 },
    full: {
      borderRadius: 9999,
    },
  },
  typography: {
    h1: {
      fontSize: 28,
      fontFamily: 'Inter-SemiBold',
    },
    h2: {
      fontSize: 22,
      fontFamily: 'Inter-SemiBold',
    },
    h3: {
      fontSize: 20,
      fontFamily: 'Inter-SemiBold',
    },
    h4: {
      fontSize: 18,
      fontFamily: 'Inter-SemiBold',
    },
    subtitle: {
      fontSize: 15,
      fontFamily: 'Inter-Medium',
    },
    p1: {
      fontSize: 14,
      fontFamily: 'Inter-Regular',
    },
    p2: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
    },
    caption: {
      fontSize: 10,
      fontFamily: 'Inter-Regular',
    },
  },
}

export const darkTheme: Theme = {
  ...defaultTheme,
  colorScheme: 'dark',
  palette: {
    primary: {
      main: '#4BC1AD',
      on: '#13023C',
    },
    secondary: {
      main: '#8A4DDD',
      on: '#FFF',
    },
    tertiary: {
      main: '#13023C',
      on: '#FFF',
    },
    accent: {
      main: '#4106C9',
      on: '#FFF',
    },
    success: {
      main: '#4BC1AD',
      on: '#FFF',
    },
    warning: {
      main: '#FFAB4A',
      on: '#FFF',
    },
    error: {
      main: '#EA6D6D',
      on: '#FFF',
    },
    background: {
      main: '#F8F5FD',
      on: '#13023C',
    },
    surface: {
      main: '#FFF',
      on: '#13023C',
    },
  },
}
