import { setStatusBarStyle } from 'expo-status-bar'
import { useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { View } from 'react-native'
import AvatarPicker from '../components/AvatarPicker'
import Button from '../components/Button'
import Container from '../components/Container'
import TextField from '../components/inputs/TextField'
import ScrollView from '../components/ScrollView'
import Typography from '../components/Typography'
import { useTranslations } from '../contexts/localeContext'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useMeQuery, useUpdateProfileMutation } from '../generated/graphql'
import { useStyles } from '../hooks/useStyles'
import { RootStackScreenProps } from '../navigation/types'
import CloudinaryImageUploader, {
  ImageUploadClient,
  ReactNativeFile,
} from '../services/CloudinaryImageUploader'

type PersonalInformationFormData = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

const PersonalInformation = ({ navigation }: RootStackScreenProps<'PersonalInformation'>) => {
  setStatusBarStyle('dark')
  const [error, setError] = useState<string>()
  const [successMessage, setSuccessMessage] = useState<string>()
  const [pickedImage, setPickedImage] = useState<ReactNativeFile | string>()
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const t = useTranslations()
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<PersonalInformationFormData>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  })

  const { field: firstNameField } = useController({
    name: 'firstName',
    control,
    rules: { required: true },
  })

  const { field: lastNameField } = useController({
    name: 'lastName',
    control,
    rules: { required: true },
  })

  const { field: phoneNumberField } = useController({
    name: 'phone',
    control,
  })

  const { field: emailField } = useController({
    name: 'email',
    control,
  })

  const meQuery = useMeQuery()

  const updateProfileMutation = useUpdateProfileMutation({
    onSuccess(data) {
      const { updateProfile } = data
      if (updateProfile.__typename === 'ZodError') {
        setError(t('errors.zod_error'))
        return
      }

      setPickedImage(undefined)
      setSuccessMessage(t('feedback.update_profile_success'))
      meQuery.refetch()
    },
    onSettled() {
      setUpdateLoading(false)
    },
  })

  const syncPersonalInformation = () => {
    if (!meQuery.isSuccess) {
      return
    }

    if (!meQuery.data.me) {
      throw new Error('Attempt to sync personal information with null')
    }

    const { email, firstName, lastName, phone } = meQuery.data.me

    if (firstName) setValue('firstName', firstName)
    if (lastName) setValue('lastName', lastName)
    if (email) setValue('email', email)
    if (phone) setValue('phone', phone)
    trigger(['email', 'firstName', 'lastName', 'phone'])
  }

  useEffect(() => {
    // Sync the form whenever me changes
    syncPersonalInformation()
  }, [meQuery.data])

  const onSubmit = async (formData: PersonalInformationFormData): Promise<void> => {
    if (!formHasChanged) {
      // Why update if the form has not changed
      return
    }

    setError(undefined)
    setSuccessMessage(undefined)

    setUpdateLoading(true)

    // If pickedImage exists we should upload to cloudinary
    let avatarUrl: string | undefined = undefined

    if (pickedImage) {
      const uploadClient: ImageUploadClient = new CloudinaryImageUploader()
      avatarUrl = await uploadClient.upload(pickedImage)
    }

    updateProfileMutation.mutate({ ...formData, avatarUrl })
  }

  // Whether the form has diverged from the backend state
  const formHasChanged = useMemo<boolean>(() => {
    if (!meQuery.isSuccess) {
      // if (data === undefined) {
      return false
    }

    if (!meQuery.data.me) {
      return false
    }

    return (
      firstNameField.value !== meQuery.data.me.firstName ||
      lastNameField.value !== meQuery.data.me.lastName ||
      phoneNumberField.value !== meQuery.data.me.phone ||
      emailField.value !== meQuery.data.me.email ||
      pickedImage !== undefined
    )
  }, [
    firstNameField.value,
    lastNameField.value,
    phoneNumberField.value,
    emailField.value,
    meQuery.data,
    pickedImage,
  ])

  const spacing = useSpacingFn()

  const styles = useStyles(({ spacing, palette }) => ({
    scrollContainer: {
      backgroundColor: palette.surface.main,
      flex: 1,
    },
    scrollContentContainer: {
      paddingBottom: spacing(8),
    },
    scrollListItem: {
      marginTop: spacing(4),
    },
    avatarPickerContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: spacing(4),
    },
    saveButtonContainer: {
      marginTop: spacing(12),
      flexDirection: 'row',
      justifyContent: 'center',
    },
    cancelButtonContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    centeredText: {
      textAlign: 'center',
    },
    inputFeedbackText: {
      marginTop: spacing(1),
      marginLeft: spacing(4),
    },
  }))
  return (
    <ScrollView
      style={styles.scrollContainer}
      contentContainerStyle={styles.scrollContentContainer}
    >
      <Container style={{ flexGrow: 1 }}>
        <View style={[styles.scrollListItem, styles.avatarPickerContainer]}>
          <AvatarPicker
            user={meQuery.data?.me}
            image={pickedImage}
            onPick={(file) => setPickedImage(file)}
          />
        </View>
        <View style={styles.scrollListItem}>
          <View>
            <TextField
              color="background"
              label={t('first_name')}
              value={firstNameField.value}
              onChangeText={firstNameField.onChange}
              onBlur={firstNameField.onBlur}
              error={Boolean(errors.firstName)}
            ></TextField>
            {errors.firstName && (
              <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                {t('errors.field_required')}
              </Typography>
            )}
          </View>
          <View style={{ marginTop: spacing(3) }}>
            <TextField
              color="background"
              label={t('last_name')}
              value={lastNameField.value}
              onChangeText={lastNameField.onChange}
              onBlur={lastNameField.onBlur}
              error={Boolean(errors.lastName)}
            ></TextField>
            {errors.lastName && (
              <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                {t('errors.field_required')}
              </Typography>
            )}
          </View>
          <View style={{ marginTop: spacing(3) }}>
            <TextField
              color="background"
              label={t('phone_number')}
              value={phoneNumberField.value}
              onChangeText={phoneNumberField.onChange}
              onBlur={phoneNumberField.onBlur}
              error={Boolean(errors.phone)}
              disabled={true}
            ></TextField>
            {errors.phone && (
              <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                {t('errors.field_required')}
              </Typography>
            )}
          </View>
          <View style={{ marginTop: spacing(3) }}>
            <TextField
              color="background"
              label={t('email')}
              value={emailField.value}
              onChangeText={emailField.onChange}
              onBlur={emailField.onBlur}
              error={Boolean(errors.email)}
              disabled={true}
            ></TextField>
            {errors.email && (
              <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                {t('errors.field_required')}
              </Typography>
            )}
          </View>
        </View>
        <View style={styles.scrollListItem}>
          {error !== undefined && (
            <Typography variant="caption" color="error" style={styles.centeredText}>
              {error}
            </Typography>
          )}
          {successMessage !== undefined && (
            <Typography variant="caption" color="on-success" style={styles.centeredText}>
              {successMessage}
            </Typography>
          )}
        </View>
        <View style={[styles.scrollListItem, styles.saveButtonContainer]}>
          <Button
            title={t('save')}
            color="primary"
            size="extra-large"
            disabled={!formHasChanged || updateLoading}
            loading={updateLoading}
            onPress={handleSubmit(onSubmit)}
          ></Button>
        </View>
        <View style={[styles.scrollListItem, styles.cancelButtonContainer]}>
          <Button
            title={t('cancel')}
            variant="text"
            color="tertiary"
            size="extra-large"
            onPress={() => navigation.navigate('Home', { screen: 'Profile' })}
          ></Button>
        </View>
      </Container>
    </ScrollView>
  )
}

export default PersonalInformation
