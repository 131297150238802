import { useMemo } from 'react'
import { Image, ImageSourcePropType, View, ViewStyle } from 'react-native'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useTheme } from '../contexts/ThemeContext'
import { Color } from '../hooks/usePaletteColor'
import Surface, { SurfaceProps } from './Surface'
import Typography from './Typography'

type AvatarSize = 'small' | 'medium' | 'large' | 'extra-large'
export interface AvatarProps extends SurfaceProps {
  /**
   * Name of the user this avatar belongs to.
   */
  name?: string

  /**
   * Image of the user.
   */
  image?: ImageSourcePropType | string

  /**
   * The color of the avatar if no image is supplied
   */
  color?: Color

  /**
   * The color of the border around the avatar
   */
  borderColor?: Color

  /**
   * Size of the avatar
   */
  size?: AvatarSize

  /**
   * Whether or not to show initials
   */
  initials?: boolean
}

const Avatar = ({
  name = '',
  image,
  color = 'secondary',
  borderColor = 'surface',
  size = 'medium',
  initials = true,
  style,
  ...props
}: AvatarProps) => {
  const { palette } = useTheme()
  const spacing = useSpacingFn()

  const avatarSizeStyles: { [K in AvatarSize]: ViewStyle } = useMemo(() => {
    const defaultStyles: ViewStyle = {
      backgroundColor: image ? 'transparent' : palette[color].main,
      borderColor: palette[borderColor].main,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 9999,
    }

    return {
      small: {
        ...defaultStyles,
        height: spacing(8),
        width: spacing(8),
        borderWidth: 2,
      },
      medium: {
        ...defaultStyles,
        borderWidth: 3,
        height: spacing(10),
        width: spacing(10),
      },
      large: {
        ...defaultStyles,
        borderWidth: 4,
        height: spacing(16),
        width: spacing(16),
      },
      'extra-large': {
        ...defaultStyles,
        borderWidth: 4,
        height: spacing(24),
        width: spacing(24),
      },
    }
  }, [spacing, palette, borderColor, color])

  return (
    <Surface style={[avatarSizeStyles[size], style]} {...props}>
      {image && (
        <View
          style={{
            height: '100%',
            width: '100%',
            borderRadius: 9999,
            backgroundColor: palette.background.main,
          }}
        >
          <Image
            source={typeof image === 'string' ? { uri: image } : image}
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 9999,
            }}
          />
        </View>
      )}
      {!image && (
        <Typography
          variant={size === 'small' ? 'p2' : size === 'medium' ? 'p1' : 'h2'}
          color={palette[color].on}
          style={{
            fontFamily: 'Inter-SemiBold',
          }}
        >
          {initials ? getInitialsFromName(name) : name}
        </Typography>
      )}
    </Surface>
  )
}

/**
 * Extracts the initials from a name.
 * Splits the string by whitespace and takes first letter from first and last resulting array.
 * If only name then a one letter initial is returned.
 * @param name A full name
 * @returns A string consisting of initials from first and last name
 */
const getInitialsFromName = (name: string): string => {
  if (name.length === 0) return ''

  const words = name.split(' ')

  if (words.length === 1) return words[0][0]?.toUpperCase()

  return `${words[0][0]?.toUpperCase() ?? ''}${words[words.length - 1][0]?.toUpperCase() ?? ''}`
}

export default Avatar
