import { useRef } from 'react'
import { View } from 'react-native'

import { useSpacingFn } from '../contexts/SpacingContext'

export type ImageSlideshowProps = {
  images: string[]
}

const ImageSlideshow: React.FC<ImageSlideshowProps> = ({ images }) => {
  const spacing = useSpacingFn()

  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollTo = (index: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: index * scrollRef.current.clientWidth,
        behavior: 'smooth',
      })
    }
  }

  return (
    <View>
      <div
        ref={scrollRef}
        style={{
          overflow: 'auto',
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
          gap: spacing(2),
          scrollSnapType: 'x mandatory',
          marginBottom: spacing(4),
        }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            style={{
              maxWidth: '100%',
              aspectRatio: 3 / 2,
              display: 'block',
              width: 'auto',
              flexShrink: 0,
              objectFit: 'cover',
              scrollSnapAlign: 'center',
            }}
          />
        ))}
      </div>
      <div
        style={{ flexDirection: 'row', display: 'flex', gap: spacing(2), justifyContent: 'center' }}
      >
        {images.map((image, index) => (
          <button key={index} onClick={() => scrollTo(index)}>
            {index + 1}
          </button>
        ))}
      </div>
    </View>
  )
}

export default ImageSlideshow
