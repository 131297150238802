import { useState } from 'react'
import { View } from 'react-native'
import Button from '../../components/Button'
import CourseHeader from '../../components/course/CourseHeader'
import ScrollView from '../../components/ScrollView'
import Typography from '../../components/Typography'
import { useCourse } from '.'
import CourseRating, { Rating } from '../../components/course/CourseRating'
import { useTranslations } from '../localeContext'
import { useQueryClient } from '@tanstack/react-query'
import { useRateCourseParentMutation } from '../../generated/graphql'
import { useSpacingFn } from '../SpacingContext'
import Container from '../../components/Container'
import SafeAreaView from '../../components/SafeAreaView'

/**
 * CourseRate view lets the user rate the course.
 */
const CourseRate = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useRateCourseParentMutation({
    onSettled() {
      queryClient.invalidateQueries(['CourseModule', { courseModuleId: course.id }])
    },
  })

  const { course, endCourse, myRating, exit } = useCourse()
  const initialRating = myRating?.rating as Rating | undefined
  const hasRated = initialRating !== undefined
  const [rating, setRating] = useState<Rating>(initialRating)

  const t = useTranslations()

  const rateCourseButtonHandler = () => {
    mutate({
      courseParentId: course.parentId,
      rating: rating,
      comment: null,
    })
  }

  const spacing = useSpacingFn()

  return (
    <SafeAreaView>
      <ScrollView contentContainerStyle={{ paddingBottom: spacing(10) }}>
        <Container style={{ flexGrow: 1 }}>
          <CourseHeader course={course} />
          <View
            style={{ marginTop: spacing(4), paddingHorizontal: spacing(4), alignItems: 'center' }}
          >
            <Typography variant="h3">{`${course.title}`}</Typography>

            {!hasRated && (
              <Typography variant="p1" style={{ marginTop: spacing(10) }}>
                {t('rate_course_prompt')}
              </Typography>
            )}
            {hasRated && (
              <Typography variant="p1" style={{ marginTop: spacing(10) }}>
                {t('thanks_for_rating')}
              </Typography>
            )}

            <CourseRating
              value={rating}
              onRatingChange={(rating) =>
                setRating((state) => (state === rating ? undefined : rating))
              }
            />

            {hasRated && rating !== initialRating && rating !== undefined && (
              <Button
                title={t('update_rating')}
                size="large"
                style={{ marginTop: spacing(8) }}
                onPress={() => rateCourseButtonHandler()}
              />
            )}
            {hasRated && (
              <Button
                title={t('complete_course_button')}
                size="large"
                style={{ marginTop: spacing(8) }}
                onPress={() => exit()}
              />
            )}
            {!hasRated && (
              <Button
                title={t('rate_course_button')}
                size="large"
                style={{ marginTop: spacing(8) }}
                loading={isLoading}
                disableElevation
                loadingIndicatorPosition="trailing"
                onPress={() => rateCourseButtonHandler()}
                disabled={rating === initialRating || rating === undefined}
              ></Button>
            )}
            {!hasRated && (
              <Button
                style={{ marginTop: spacing(2) }}
                title={t('skip')}
                variant="text"
                size="large"
                color="tertiary"
                onPress={() => endCourse()}
              ></Button>
            )}
          </View>
        </Container>
      </ScrollView>
    </SafeAreaView>
  )
}

export default CourseRate
