import { createContext, ReactNode, useContext, useMemo } from 'react'

export type Spacing = number
export type SpacingFn = (size: number) => Spacing
const defaultSpacingFn: SpacingFn = (space) => space

export const SpacingFnContext = createContext(defaultSpacingFn)
export const useSpacingFn = () => useContext(SpacingFnContext)

export const useSpacing = (size: number) => {
  const spacingFn = useSpacingFn()
  return useMemo(() => spacingFn(size), [spacingFn, size])
}

export interface SpacingFnProviderProps {
  spacingFn?: SpacingFn
  children?: ReactNode
}

export const SpacingFnProvider = ({
  spacingFn = defaultSpacingFn,
  children,
}: SpacingFnProviderProps) => (
  <SpacingFnContext.Provider value={spacingFn}>{children}</SpacingFnContext.Provider>
)
