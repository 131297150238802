import React, { useEffect, useRef } from 'react'
export interface VideoPlayerProps {
  source: string
  width: number
  aspectRatio: number
}

declare global {
  interface Window {
    cloudinary: any
  }
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ source, width, aspectRatio }) => {
  const videoRef = useRef(null)
  let pollCount = useRef(0)
  let intervalRef = useRef<any>(null)

  // Cloudinary script needs to populate window.cloudinary before we can use the video player.
  // We poll for it to be populated and then initialize the video player.
  const poll = () => {
    // First, check if the Cloudinary object is available
    if (!window.cloudinary || !videoRef.current) {
      // If not, increment the poll count and check again
      pollCount.current++
      if (pollCount.current > 10) {
        // If the Cloudinary object is still not available after 10 attempts, stop polling
        clearTimeout(intervalRef.current)
      } else {
        intervalRef.current = setTimeout(poll, 1000)
      }
      return
    } else {
      // If the Cloudinary object is available, clear the interval and render the video player
      clearTimeout(intervalRef.current)
      window.cloudinary
        .videoPlayer(videoRef.current, {
          sourceTypes: ['hls', 'dash'],
        })
        .source(source)
    }
  }

  useEffect(() => {
    intervalRef.current = setTimeout(poll, 1000)
  }, [window.cloudinary])
  return (
    <video
      ref={videoRef}
      src={source}
      id="video"
      className="cld-video-player cld-fluid"
      controls
      // data-cld-public-id="development/videos/file_zbxlf8"
    ></video>
  )
}

export default VideoPlayer
