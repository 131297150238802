import { ReactNode, useEffect, useMemo } from 'react'
import { Animated, Easing, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'
import Typography from './Typography'
import chroma from 'chroma-js'
import { TypographyVariant } from '../contexts/ThemeContext'
import { useStyles } from '../hooks/useStyles'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'

/**
 * Usage:
 * This acts almost like a normal view (with a background color) if no props are supplied.
 */

type VariantRenamed = {
  /**
   * Use the textVariant prop with the variant="text" to render a skeleton at desired font size.
   */
  textVariant?: TypographyVariant
}

interface SkeletonProps extends Omit<ViewProps, 'style'>, VariantRenamed {
  /**
   * This element can either be a rectangle, circular or mimic a line of text.
   */
  variant?: 'text' | 'rectangular' | 'circular'

  /**
   * Use the color prop to set the skeletons color. Default is background-color.
   */
  color?: Color

  /**
   * It can wrap other elements to take on its size. E.g. an avatar.
   * Pass the wrapee-component as a child, and it will mimic its size and height.
   * However it cannot mimic the childs border radius. Use variant prop, or
   * border-radius style for that.
   */
  children?: ReactNode | ReactNode[]

  style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>
}
const Skeleton = ({
  variant = 'rectangular',
  color = 'background',
  textVariant = 'p1',
  children,
  style,
  ...props
}: SkeletonProps) => {
  const themeColor = usePaletteColor(color)

  const animatedValue = useMemo(() => new Animated.Value(0), [])

  useEffect(() => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 2000,
        easing: Easing.ease,
        useNativeDriver: false,
      })
    ).start()
  }, [])

  const lightColor = chroma(themeColor.main).darken(0).hex()
  const darkColor = chroma(themeColor.main).darken(0.27).hex()
  const backgroundColor = animatedValue.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [lightColor, darkColor, lightColor],
  })

  const styles = useStyles(({ spacing }) => ({
    container: {
      width: '100%',
      height: variant !== 'text' ? spacing(8) : 'auto',
      borderRadius: variant === 'circular' ? 9999 : 0,
    },
  }))

  return (
    <Animated.View
      style={[
        !children && styles.container,
        !children && style,
        {
          backgroundColor: !children ? backgroundColor : undefined,
        },
      ]}
      {...props}
    >
      {variant === 'text' && (
        <Typography variant={textVariant} style={{ width: '100%', color: 'transparent' }}>
          Test
        </Typography>
      )}
      {children && (
        <View style={{ position: 'relative', width: 'auto' }}>
          <View>
            {/* Remove artifacts by transforming the wrapper */}
            <View style={{ transform: [{ scale: 0.99 }] }}>{children}</View>
          </View>

          {/* Overlay for the children */}
          <Animated.View
            style={[
              StyleSheet.absoluteFill,
              { borderRadius: variant === 'circular' ? 9999 : 0 },
              { backgroundColor },
              style,
            ]}
          ></Animated.View>
        </View>
      )}
    </Animated.View>
  )
}

export default Skeleton
