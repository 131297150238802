import { useMemo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import Svg, { Circle } from 'react-native-svg'
import Typography from './Typography'
import Icon from '@expo/vector-icons/Feather'
import { useTheme } from '../contexts/ThemeContext'
import { useSpacingFn } from '../contexts/SpacingContext'

export interface CircleProgressProps {
  totalSteps?: number
  currentStep?: number
  variant?: 'counter' | 'percentage'
  completed?: boolean
  style?: ViewStyle
}

const CircleProgress = ({
  totalSteps = 3,
  currentStep = 0,
  variant = 'counter',
  completed = false,
  style,
  ...rest
}: CircleProgressProps) => {
  const theme = useTheme()
  const spacing = useSpacingFn()

  const radius = 42

  const circumference = radius * 2 * Math.PI

  const stepLength = 100 / totalSteps
  const progress = stepLength * currentStep

  const calculateStrokeDashOffset = (percentage: number): number => {
    return circumference - (percentage / 100) * circumference
  }

  const currentPercentage = Math.round((currentStep / totalSteps) * 100)

  const isComplete = useMemo(() => {
    return completed
  }, [currentStep, totalSteps, completed])

  const strokeColor = useMemo(() => {
    if (isComplete) {
      return theme.palette.success.main
    }

    if (currentPercentage > 67) {
      return theme.palette.success.main
    } else if (currentPercentage > 34) {
      return theme.palette.warning.main
    } else {
      return theme.palette.error.main
    }
  }, [isComplete, currentPercentage, theme])

  return (
    <View
      style={[
        {
          position: 'relative',
          marginVertical: spacing(4),
          height: spacing(10),
          width: spacing(10),
          backgroundColor: 'transparent',
        },
        style,
      ]}
      {...rest}
    >
      <Svg width="100%" height="100%" viewBox="0 0 100 100">
        <Circle
          transform="rotate(-90 50 50)"
          stroke={theme.palette.background.main}
          fill={isComplete ? theme.palette.success.main : 'transparent'}
          strokeWidth={8}
          r={radius}
          cx={50}
          cy={50}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={calculateStrokeDashOffset(100)}
        ></Circle>
        <Circle
          transform="rotate(-90 50 50)"
          stroke={strokeColor}
          strokeWidth={8}
          r={radius}
          fill={isComplete ? theme.palette.success.main : 'transparent'}
          cx={50}
          cy={50}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={
            isComplete ? calculateStrokeDashOffset(100) : calculateStrokeDashOffset(progress)
          }
          strokeLinecap="round"
        ></Circle>
      </Svg>

      <View
        style={[
          StyleSheet.absoluteFill,
          { alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' },
        ]}
      >
        {!isComplete && (
          <Typography variant="p2" style={{ fontFamily: 'Inter-SemiBold' }}>
            {variant === 'counter' ? `${currentStep}/${totalSteps}` : `${currentPercentage}%`}
          </Typography>
        )}
        {isComplete && <Icon name="check" color={theme.palette.success.on} size={24} />}
      </View>
    </View>
  )
}

export default CircleProgress
