import { setStatusBarStyle } from 'expo-status-bar'
import { ImageBackground, View } from 'react-native'
import Container from '../components/Container'
import LoginForm from '../components/LoginForm'
import SafeAreaView from '../components/SafeAreaView'
import { useStyles } from '../hooks/useStyles'

import { RootStackScreenProps } from '../navigation/types'

const Login = (_props: RootStackScreenProps<'Login'>) => {
  setStatusBarStyle('light')

  const styles = useStyles(({ palette, spacing }) => ({
    container: {
      backgroundColor: palette['tertiary'].main,
      flex: 1,
    },
    safeAreaContainer: {
      flex: 1,
    },
    imageBackground: {
      height: '75%',
      flex: 1,
      justifyContent: 'center',
    },
    loginFormContainer: {},
  }))

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <Container style={{ flexGrow: 1 }}>
          <ImageBackground
            source={require('../assets/bg-blur.png')}
            resizeMode="contain"
            style={styles.imageBackground}
          >
            <LoginForm style={styles.loginFormContainer} />
          </ImageBackground>
        </Container>
      </SafeAreaView>
    </View>
  )
}

export default Login
