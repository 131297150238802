import { DefaultTheme } from '@react-navigation/native'
import { useTheme } from '../contexts/ThemeContext'

const useNavigationTheme = () => {
  const theme = useTheme()

  const navigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: theme.palette.primary.on,
      background: theme.palette.background.main,
      card: theme.palette.surface.main,
      text: theme.palette.background.on,
      border: theme.palette.background.main,
      notification: theme.palette.accent.main,
    },
  }
  return navigationTheme
}

export default useNavigationTheme
