import { ReactNode } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler'
import Animated, {
  interpolate,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { snapPoint } from '../animation/worklets'
import { Portal } from '../contexts/PortalContext'
import { useSpacingFn } from '../contexts/SpacingContext'
import Surface from './Surface'

export type BottomSheetProps = {
  children?: ReactNode
  height: Animated.SharedValue<number>
  snapPoints?: number[]
}

const BottomSheet: React.FC<BottomSheetProps> = ({ children, height, snapPoints }) => {
  const spacing = useSpacingFn()
  const dimensions = useWindowDimensions()
  const safeArea = useSafeAreaInsets()
  if (snapPoints === undefined) snapPoints = [-(safeArea.bottom + safeArea.top), 500]
  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { offset: number }
  >({
    onStart: (_, ctx) => {
      ctx.offset = height.value
    },
    onActive: (event, ctx) => {
      height.value = ctx.offset - event.translationY
    },
    onEnd(event, context) {
      const point = snapPoint(
        context.offset - event.translationY,
        -event.velocityY,
        snapPoints ?? [0, 500]
      )
      height.value = withTiming(point, {
        duration: 200,
      })
    },
  })

  const sheetStyle = useAnimatedStyle(() => ({
    flex: 1,
    transform: [
      {
        translateY: interpolate(height.value, [0, dimensions.height], [dimensions.height, 0], {
          extrapolateRight: 'clamp',
        }),
      },
    ],
  }))

  return (
    <Portal>
      <PanGestureHandler onGestureEvent={onGestureEvent}>
        <Animated.View style={sheetStyle}>
          <Surface
            elevation={20}
            style={{
              flex: 1,
              borderTopLeftRadius: spacing(6),
              borderTopRightRadius: spacing(6),
              paddingHorizontal: spacing(4),
              paddingTop: spacing(6),
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: 50,
                height: 5,
                backgroundColor: '#ccc',
                borderRadius: 10,
              }}
            ></View>
            {children}
          </Surface>
        </Animated.View>
      </PanGestureHandler>
    </Portal>
  )
}
export default BottomSheet
