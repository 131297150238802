import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useStyles } from '../../../hooks/useStyles'
import GridLayout from '../../layout/GridLayout'
import Typography from '../../Typography'
import QuestionTemplate, {
  QuestionSummaryProps,
  QuestionTemplatePropsModified,
} from './QuestionTemplate'

interface ImageQuestionSummaryProps extends QuestionSummaryProps, QuestionTemplatePropsModified {}

export const ImageQuestionSummary = ({
  question,
  selectedAnswerId,
  ...props
}: ImageQuestionSummaryProps) => {
  const correctAnswerIds = question.imageAnswers
    .filter(({ isCorrect }) => isCorrect)
    .map(({ id }) => id)

  const selectedAnswer = question.imageAnswers.find(({ id }) => id === selectedAnswerId)
  const selectedAnswerIsCorrect = correctAnswerIds.includes(selectedAnswerId)

  const styles = useStyles(({ spacing, palette, shapes }) => ({
    questionContainer: {
      marginBottom: spacing(4),
    },
    answersContainer: {
      width: '100%',
    },
    imageAnswerContainer: {
      ...shapes.large,
      position: 'relative',
      height: spacing(36),
      overflow: 'hidden',
      padding: spacing(2),
    },
    imageContainer: {
      borderRadius: shapes.large.borderRadius - spacing(2) + 4,
      flex: 1,
    },
    imageOverlay: {
      ...shapes.large,
      borderWidth: 4,
    },
    correctAnswerOverlay: {
      borderColor: palette.success.main,
    },
    wrongAnswerOverlay: {
      borderColor: palette.error.main,
    },
    unselectedImageOverlay: {
      borderColor: 'transparent',
    },
    feedbackContainer: {
      marginTop: spacing(4),
      paddingHorizontal: spacing(4),
    },
  }))
  return (
    <QuestionTemplate
      {...props}
      onButtonPress={() => undefined}
      showButton={false}
      headerImageUrl={question?.imageUrl}
    >
      <Typography variant="subtitle" color="on-surface" style={styles.questionContainer}>
        {question.question}
      </Typography>
      <View style={styles.answersContainer}>
        <GridLayout
          columns={2}
          items={question.imageAnswers}
          renderItem={(item) => {
            const answerIsCorrect = correctAnswerIds.includes(item.id)
            const isAnsweredWrongly = item.id === selectedAnswerId

            return (
              <View style={styles.imageAnswerContainer}>
                <Image source={{ uri: item.imageUrl }} style={styles.imageContainer} />
                <View
                  style={[
                    StyleSheet.absoluteFill,
                    styles.imageOverlay,
                    answerIsCorrect
                      ? styles.correctAnswerOverlay
                      : isAnsweredWrongly
                      ? styles.wrongAnswerOverlay
                      : styles.unselectedImageOverlay,
                  ]}
                ></View>
              </View>
            )
          }}
        />
      </View>
      {selectedAnswer?.answerFeedback && (
        <View style={styles.feedbackContainer}>
          <Typography variant="p2" color={selectedAnswerIsCorrect ? 'success' : 'error'}>
            {selectedAnswer.answerFeedback}
          </Typography>
        </View>
      )}
    </QuestionTemplate>
  )
}

export default ImageQuestionSummary
