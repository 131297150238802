import { useState } from 'react'
import { View } from 'react-native'
import { useTheme } from '../contexts/ThemeContext'
import { User } from '../generated/graphql'
import { useStyles } from '../hooks/useStyles'
import Avatar from './Avatar'

export interface AvatarGroupProps {
  /**
   * Maximum avatars visible until they are grouped
   */
  max?: number

  /**
   * A list of urls
   */
  avatars: string[] | Array<Pick<User, 'avatarUrl' | 'fullName'>>
}

const AvatarGroup = ({ avatars, max = 6 }: AvatarGroupProps) => {
  const theme = useTheme()
  const [state, setState] = useState({
    shouldFlex: false,
    hasUpdated: false,
  })

  if (avatars.length === 0) {
    return null
  }

  const styles = useStyles(
    ({ spacing }) => ({
      container: {
        width: '100%',
        flexDirection: 'row',
      },
      topBarContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: state.shouldFlex ? 'space-between' : 'flex-start',
      },
      avatarsContainer: {
        width: spacing(12),
        alignItems: 'flex-end',
      },
    }),
    [state]
  )

  return (
    <View style={styles.container}>
      <View
        style={styles.topBarContainer}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout
          setState({ shouldFlex: width < 45 * max, hasUpdated: true })
        }}
      >
        {avatars.slice(0, max).map((avatar, i, arr) => {
          const getAvatar = () => {
            if (typeof avatar === 'string') {
              return <Avatar image={{ uri: avatar }} size="small" />
            } else {
              return (
                <Avatar
                  image={avatar?.avatarUrl ? { uri: avatar?.avatarUrl } : undefined}
                  name={avatar?.fullName}
                  size="small"
                />
              )
            }
          }

          return (
            <View
              style={{
                width: !state.hasUpdated
                  ? 0
                  : state.shouldFlex && arr.length - 1 !== i
                  ? 0
                  : 'auto',
              }}
              key={`avatar-group-${i}`}
            >
              {getAvatar()}
            </View>
          )
        })}
      </View>
      {avatars.length > max && (
        <View style={styles.avatarsContainer}>
          <Avatar
            initials={false}
            name={`+${Math.min(avatars.length - max, 99)}`}
            color="background"
            size="small"
          ></Avatar>
        </View>
      )}
    </View>
  )
}

export default AvatarGroup
