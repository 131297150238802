import React, { useCallback } from 'react'
import { View } from 'react-native'
import { useSpacingFn } from '../../../contexts/SpacingContext'
import Typography from '../../Typography'
import QuestionButton from '../QuestionButton'
import QuestionTemplate, {
  QuestionSummaryProps,
  QuestionTemplatePropsModified,
} from './QuestionTemplate'

export interface TextQuestionSummaryProps
  extends Omit<QuestionSummaryProps, 'answers'>,
    QuestionTemplatePropsModified {}

export const TextQuestionSummary = ({
  question,
  selectedAnswerId,
  ...props
}: TextQuestionSummaryProps) => {
  const filteredAnswers = question.textAnswers.filter(
    ({ id, isCorrect }) => isCorrect || selectedAnswerId === id
  )

  const correctAnswerIds = question.textAnswers
    .filter(({ isCorrect }) => isCorrect)
    .map(({ id }) => id)

  const selectedAnswer = question.textAnswers.find(({ id }) => id === selectedAnswerId)
  const setSelectedState = useCallback(
    (answerIndex: number) => {
      const { id } = filteredAnswers[answerIndex]

      if (!correctAnswerIds.includes(id) && selectedAnswerId === id) {
        return 'error'
      } else {
        return 'success'
      }
    },
    [filteredAnswers, correctAnswerIds, selectedAnswerId]
  )

  const selectedAnswerIsCorrect = correctAnswerIds.includes(selectedAnswerId)

  const spacing = useSpacingFn()

  return (
    <QuestionTemplate {...props} showButton={false} onButtonPress={() => undefined}>
      <Typography variant="subtitle" color="on-surface" style={{ marginBottom: spacing(4) }}>
        {question.question}
      </Typography>
      <View>
        {filteredAnswers.map(({ answer }, i) => (
          <QuestionButton
            key={answer + i}
            questionText={answer}
            state={setSelectedState(i)}
            style={{ marginTop: i === 0 ? 0 : spacing(2) }}
          />
        ))}
      </View>
      {selectedAnswer?.answerFeedback && (
        <View style={{ marginTop: spacing(4), paddingHorizontal: spacing(4) }}>
          <Typography variant="p2" color={selectedAnswerIsCorrect ? 'success' : 'error'}>
            {selectedAnswer.answerFeedback}
          </Typography>
        </View>
      )}
    </QuestionTemplate>
  )
}

export default TextQuestionSummary
