import { Add } from 'iconsax-react-native'
import { Alert, Platform, View } from 'react-native'
import IconButton from '../components/IconButton'
import * as ImagePicker from 'expo-image-picker'
import UserAvatar, { UserAvatarProps } from '../components/user/UserAvatar'
import { ReactNativeFile } from '../services/CloudinaryImageUploader'

interface AvatarPickerProps extends UserAvatarProps {
  /**
   * A function that retrieves the contents of a file in base64-format. (For now.)
   */
  onPick: (file: ReactNativeFile | string) => void
}

const AvatarPicker = ({ onPick, ...rest }: AvatarPickerProps) => {
  const pickImage = async (): Promise<void> => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [1, 1],
      quality: 0.2,
    })

    if (Platform.OS === 'web') {
      handleImagePickedWeb(result)
    } else {
      handleImagePicked(result)
    }
  }

  const handleImagePickedWeb = async (pickerResult: ImagePicker.ImagePickerResult) => {
    if (pickerResult.cancelled === true) {
      return
    } else if (pickerResult.uri) {
      const { uri } = pickerResult
      // URI is just a base64 string
      onPick(uri)
    }
  }

  const handleImagePicked = async (pickerResult: ImagePicker.ImagePickerResult) => {
    try {
      if (pickerResult.cancelled === true) {
        return
      } else if (
        pickerResult.cancelled === false &&
        pickerResult.type === 'image' &&
        pickerResult.uri
      ) {
        let { uri, fileName, assetId } = pickerResult

        const file = {
          uri,
          filename: fileName ?? 'expo-image.jpg',
          name: assetId ?? 'expo-image',
          type: 'image/jpeg',
        }

        onPick(file)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <View style={{ position: 'relative' }}>
      <UserAvatar size="extra-large" borderColor="background" {...rest} />
      <View style={{ position: 'absolute', bottom: 0, end: 0 }}>
        <IconButton
          color="primary"
          size={40}
          icon={({ size, color }) => <Add size={size} color={color}></Add>}
          onPress={() => pickImage()}
        ></IconButton>
      </View>
    </View>
  )
}

export default AvatarPicker
