import React, { useMemo } from 'react'
import { ActivityIndicator, Image, ScrollView, View } from 'react-native'
import Badge from '../components/Badge'
import Container from '../components/Container'
import LinearGradient from '../components/LinearGradient'
import { LotteryImageHeader } from '../components/lottery/LotteryImageHeader'
import { PrizeCard } from '../components/lottery/PrizeCard'
import SafeAreaView from '../components/SafeAreaView'
import Stack from '../components/Stack'
import Surface from '../components/Surface'
import Typography from '../components/Typography'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useLotteryQuery, useMeQuery, useUserTicketsQuery } from '../generated/graphql'
import { useCountdown } from '../hooks/useCountdown'
import { RootStackScreenProps } from '../navigation/types'
import { useTranslations } from '../contexts/localeContext'

const Lottery = (props: RootStackScreenProps<'Lottery'>) => {
  const lotteryId = props.route.params.lotteryId

  const meQuery = useMeQuery()
  const lotteryQuery = useLotteryQuery({
    id: parseInt(lotteryId.toString()),
    userId: meQuery.data?.me?.id,
  })
  const userTicketsQuery = useUserTicketsQuery()
  const spacing = useSpacingFn()

  const { isExpired, timeString } = useCountdown(new Date(lotteryQuery.data?.lottery?.endDate))
  const t = useTranslations()

  if (lotteryQuery.data === undefined || userTicketsQuery.data === undefined) {
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="small" color="#4106C9" />
      </SafeAreaView>
    )
  }

  if (!lotteryQuery.data.lottery) {
    return (
      <SafeAreaView>
        <Typography>{t('lottery_not_found')}</Typography>
      </SafeAreaView>
    )
  }

  const hasExpired = lotteryQuery.data.lottery.isExpired || isExpired
  const didWin = lotteryQuery.data.lottery.userWinnings.length > 0
  const winnings = lotteryQuery.data.lottery.userWinnings[0]

  return (
    <ScrollView contentContainerStyle={{ paddingBottom: spacing(12) }}>
      <LotteryImageHeader
        lottery={lotteryQuery.data.lottery}
        userTickets={userTicketsQuery.data.userTickets}
      />
      <Container style={{ marginTop: spacing(6) }}>
        <Stack spacing={6}>
          <Stack>
            <Typography variant="h2">{lotteryQuery.data.lottery.title}</Typography>
            {lotteryQuery.data.lottery.description && (
              <Typography variant="p1">{lotteryQuery.data.lottery.description}</Typography>
            )}
          </Stack>
          {didWin ? (
            <Surface category="large" style={{ overflow: 'hidden' }}>
              <LinearGradient from="secondary" to="accent" style={{ padding: spacing(6) }}>
                <Stack spacing={4}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle" textAlign="center" color="on-secondary">
                      {t('youve_won')}
                    </Typography>
                    <Typography textAlign="center" color="on-secondary" variant="h2">
                      {winnings.title}
                    </Typography>
                  </Stack>
                  <Surface
                    style={{ aspectRatio: 3 / 2, overflow: 'hidden', width: '100%' }}
                    category="large"
                  >
                    <Image
                      source={{ uri: winnings.imageUrl }}
                      style={{ width: '100%', height: '100%' }}
                      resizeMode="cover"
                    />
                    <Badge
                      label={`${winnings.prizeTier}. ${t('prize')}`}
                      size="extra-large"
                      color="surface"
                      style={{ position: 'absolute', left: spacing(2), bottom: spacing(2) }}
                    />
                  </Surface>
                  <Stack spacing={1}>
                    <Typography variant="h2" textAlign="center" color="on-secondary">
                      {t('congratulations') + '!'}
                    </Typography>
                    <Typography variant="p1" textAlign="center" color="on-secondary">
                      {t('won_description')}
                    </Typography>
                  </Stack>
                  <Typography variant="p2" color="on-secondary" textAlign="center">
                    {t('any_questions')}
                  </Typography>
                </Stack>
              </LinearGradient>
            </Surface>
          ) : (
            <View>
              <Surface
                elevation={5}
                category="extraLarge"
                style={{
                  paddingTop: spacing(8),
                  paddingBottom: spacing(8),
                  paddingHorizontal: spacing(6),
                }}
              >
                {hasExpired ? (
                  <>
                    <Stack align="center" spacing={0}>
                      <Typography textAlign="center" variant="h1">
                        😔
                      </Typography>
                      <Typography variant="subtitle" weight="semibold" textAlign="center">
                        {t('didnt_win')}
                      </Typography>
                      <Typography textAlign="center" variant="p2">
                        {t('didnt_win_description')}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <View>
                    <Stack align="center">
                      <Typography>⏳</Typography>
                      <Typography variant="subtitle" textAlign="center">
                        {t('more_time')}
                      </Typography>
                      <Typography variant="p2" textAlign="center">
                        {t('collect_more_tickets')}
                      </Typography>
                      <Typography variant="h4" textAlign="center">
                        {timeString}
                      </Typography>
                    </Stack>
                  </View>
                )}
              </Surface>
            </View>
          )}
          {!didWin && (
            <Stack>
              <View>
                <Typography variant="h4">{t('prizes_in_lottery')}</Typography>
                <Typography variant="p2">
                  {`${lotteryQuery.data.lottery.prizes.length} ${t('prizes')}`}
                </Typography>
              </View>

              <Stack>
                {lotteryQuery.data.lottery.prizes.map((prize) => (
                  <PrizeCard
                    key={prize.id}
                    prize={prize}
                    onPress={() => props.navigation.navigate('Prize', { prizeId: prize.id })}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </ScrollView>
  )
}

export default Lottery
