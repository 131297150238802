import { SearchZoomIn, SearchZoomOut } from 'iconsax-react-native'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { Animated, Easing, Image, LayoutAnimation, Pressable, View } from 'react-native'
import { useTranslations } from '../../../contexts/localeContext'
import { useStyles } from '../../../hooks/useStyles'
import { Question } from '../../../types/api'
import Button from '../../Button'
import Surface from '../../Surface'
import Typography from '../../Typography'

export interface QuestionProps {
  /**
   * The question that is asked, per se.
   */
  question: Question

  /**
   * A callback to be fired whenever the the answer has been submitted.
   * @param id The id of the answer
   */
  onAnswer(id: string): void
}

export interface QuestionSummaryProps extends Omit<QuestionProps, 'onAnswer'> {
  /**
   * The id of the selected answer
   */
  selectedAnswerId: string
}

export interface QuestionTemplateProps {
  /**
   * Optioal url of a image that will be shown as a header at the top of the component.
   */
  headerImageUrl?: string

  /**
   * Props used to see quiz progress
   */
  currentQuestionNumber: number
  numberOfQuestions: number

  /**
   * Whether to show button at the bottom of the component.
   */
  showButton: boolean

  /**
   * Callback that is fired whenever the button at the bottom of the component is pressed.
   */
  onButtonPress(): void

  /**
   * Children :D
   */
  children: ReactNode
}

// Convenient QuestionTemplateProp modification to be used in combination with other interfaces.
export interface QuestionTemplatePropsModified
  extends Omit<QuestionTemplateProps, 'onButtonPress' | 'showButton' | 'children'> {}

const QuestionTemplate = ({
  headerImageUrl,
  currentQuestionNumber,
  numberOfQuestions,
  showButton,
  onButtonPress,
  children,
}: QuestionTemplateProps) => {
  const [zoom, setZoom] = useState(false)
  const zoomAnimation = useMemo(() => new Animated.Value(0), [])
  const isMounted = useRef(false)
  const t = useTranslations()

  useEffect(() => {
    Animated.timing(zoomAnimation, {
      toValue: zoom ? 1 : 0,
      duration: 400,
      easing: Easing.elastic(1.1),
      useNativeDriver: false,
    }).start()
  }, [zoom])

  useEffect(() => {
    // Only run animation when value is updated, not on initial mount.
    if (isMounted.current) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
    } else {
      isMounted.current = true
    }
  }, [showButton])

  const styles = useStyles(({ spacing, palette, shapes }) => ({
    surface: {
      borderRadius: spacing(8),
      width: '100%',
      overflow: 'hidden',
    },
    bodyContainer: {
      padding: spacing(4),
      paddingBottom: spacing(9),
    },
    questionContainer: {
      marginBottom: spacing(3),
    },
    answerButtonContainer: {
      marginTop: spacing(4),
      flexDirection: 'row',
      justifyContent: 'center',
    },
    zoomButtonContainer: {
      position: 'absolute',
      end: spacing(4),
      bottom: spacing(4),
    },
  }))

  return (
    <Surface elevation={2} style={styles.surface}>
      {headerImageUrl && (
        <Animated.View
          style={{
            height: zoomAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [200, 420],
            }),
          }}
        >
          <Pressable onPress={() => setZoom(!zoom)} style={{ flex: 1 }}>
            <Image
              source={{
                uri: headerImageUrl,
              }}
              style={{ width: '100%', height: '100%' }}
            />
            <View style={styles.zoomButtonContainer}>
              {zoom ? <SearchZoomOut color="#fff" /> : <SearchZoomIn color="#fff" />}
            </View>
          </Pressable>
        </Animated.View>
      )}
      {!zoom && (
        <View style={styles.bodyContainer}>
          <Typography variant="h3" color="on-surface" style={styles.questionContainer}>
            {`${t('question')} ${currentQuestionNumber} / ${numberOfQuestions}`}
          </Typography>
          {children}
          {showButton && (
            <View style={styles.answerButtonContainer}>
              <Button
                title={t('quiz_check_answer')}
                size="extra-large"
                onPress={() => onButtonPress()}
              />
            </View>
          )}
        </View>
      )}
    </Surface>
  )
}

export default QuestionTemplate
