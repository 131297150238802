import { ProductListItem } from '../../types/api'

import Card from '../Card'
import { useTranslations } from '../../contexts/localeContext'

type ProductCardProps = {
  product: ProductListItem
  onPress(): void
}
const ProductCard: React.FC<ProductCardProps> = ({ product, onPress }) => {
  const isSoldOut = product.stock === 0
  const t = useTranslations()

  return (
    <Card
      title={product.name}
      subtitle={t('more_info')}
      imageUrl={product.imageUrl ?? undefined}
      onPress={onPress}
      badgeProps={{
        label: isSoldOut ? t('sold_out') : `${product.price} ${t('plural.coin.other')}`,
        color: isSoldOut ? 'background' : 'secondary',
        size: 'large',
      }}
    />
  )
}

export default ProductCard
