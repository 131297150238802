import { Component, TickSquare, CloseSquare } from 'iconsax-react-native'
import { Pressable, Text, View } from 'react-native'
import chroma from 'chroma-js'
import { useMemo } from 'react'
import Surface, { SurfaceProps } from '../Surface'
import Typography from '../Typography'
import { useTheme } from '../../contexts/ThemeContext'
import { useStyles } from '../../hooks/useStyles'

type QuestionState = 'unselected' | 'selected' | 'success' | 'error'
export interface QuestionButtonProps extends SurfaceProps {
  /**
   * The state this button is in.
   */
  state?: QuestionState

  /**
   * The text this button should display
   */
  questionText: string

  /**
   * Callback that runs when the button is pressed
   */
  onPress?: () => void

  disabled?: boolean
}

const QuestionButton = ({
  questionText,
  onPress,
  state = 'unselected',
  style,
  disabled = false,
}: QuestionButtonProps) => {
  const theme = useTheme()

  const borderColor = useMemo(() => {
    if (state === 'unselected') {
      return chroma(theme.palette.background.on).alpha(0.1).hex()
    } else if (state === 'selected') {
      return theme.palette.background.on
    } else if (state === 'success') {
      return theme.palette.success.main
    } else if (state === 'error') {
      return theme.palette.error.main
    }
  }, [state])

  const styles = useStyles(
    ({ spacing, palette, shapes }) => ({
      surface: {
        ...shapes.large,
        flexDirection: 'row',
        borderWidth: 2,
        paddingVertical: spacing(4),
        paddingHorizontal: spacing(3),
        backgroundColor:
          state === 'success'
            ? palette.success.main
            : state === 'error'
            ? palette.error.main
            : palette.surface.main,
      },
      checkBox: {
        marginRight: spacing(3),
        transform:
          state === 'unselected'
            ? [
                {
                  rotate: '45deg',
                },
                {
                  scale: 1.1,
                },
              ]
            : [],
      },
    }),
    [state]
  )

  return (
    <Pressable onPress={onPress} disabled={disabled}>
      <Surface
        style={[
          styles.surface,
          style,
          {
            borderColor,
          },
        ]}
      >
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View>
            <View style={styles.checkBox}>
              {state === 'selected' && <TickSquare color={borderColor} />}
              {state === 'success' && <TickSquare color={'white'} variant="Bold" />}
              {state === 'unselected' && <Component color={borderColor} />}
              {state === 'error' && <CloseSquare color={'white'} variant="Bold" />}
            </View>
          </View>
          <Typography
            variant="p1"
            style={{ flex: 1, flexWrap: 'wrap' }}
            color={
              state === 'selected'
                ? 'on-surface'
                : state === 'unselected'
                ? 'on-surface'
                : state === 'success'
                ? 'on-success'
                : state === 'error'
                ? 'on-error'
                : 'on-surface'
            }
          >
            {questionText}
          </Typography>
        </View>
      </Surface>
    </Pressable>
  )
}

export default QuestionButton
