import { ViewStyle } from 'react-native'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'
import { LinearGradient as EXPOLinearGradient } from 'expo-linear-gradient'
import { ReactNode } from 'react'

type LinearGradientProps = {
  from: Color
  to: Color
  style?: ViewStyle
  children?: ReactNode
}

const LinearGradient: React.FC<LinearGradientProps> = ({ from, to, style, children }) => {
  const palette = usePaletteColor(from, to)
  return (
    <EXPOLinearGradient colors={[palette.main, palette.on]} style={[{ flex: 1 }, style]}>
      {children}
    </EXPOLinearGradient>
  )
}

export default LinearGradient
