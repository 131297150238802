import { Text, TextProps, TextStyle } from 'react-native'
import { TypographyVariant, useTheme } from '../contexts/ThemeContext'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'

export type FontWeight = 'semibold' | 'medium' | 'regular'
export type TextAlign = 'left' | 'right' | 'center'
export interface TypographyProps extends TextProps {
  children?: string
  /**
   * Typography variant
   * @default "p1"
   */
  variant?: TypographyVariant
  weight?: FontWeight
  textAlign?: TextAlign
  /**
   * The color of the text.
   *
   * @default "on-background"
   */
  color?: Color
}

const Typography: React.FC<TypographyProps> = ({
  weight,
  textAlign = 'left',
  variant = 'p1',
  color = 'on-background',
  style,
  ...rest
}) => {
  const { typography } = useTheme()
  const palette = usePaletteColor(color)

  const weightStyle: TextStyle = {
    fontFamily:
      weight === 'semibold'
        ? 'Inter-SemiBold'
        : weight === 'medium'
        ? 'Inter-Medium'
        : 'Inter-Regular',
  }

  return (
    <Text
      {...rest}
      style={[
        typography[variant],
        { color: palette.main, textAlign },
        weight ? weightStyle : undefined,
        style,
      ]}
    />
  )
}

export default Typography
