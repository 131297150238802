import { ActivityIndicator, Animated, Pressable, useWindowDimensions, View } from 'react-native'
import { HomeTabScreenProps } from '../../navigation/types'
import IconButton from '../../components/IconButton'
import React, { useEffect, useMemo } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Typography from '../../components/Typography'
import ScrollView from '../../components/ScrollView'
import Surface from '../../components/Surface'
import UserAvatar from '../../components/user/UserAvatar'
import {
  useMeQuery,
  useStartedCourseParentsQuery,
  useTestPushNotificationMutation,
} from '../../generated/graphql'
import Skeleton from '../../components/Skeleton'
import { useTranslations } from '../../contexts/localeContext'
import CourseListCard from '../../components/course/CourseListCard'
import Icon from '@expo/vector-icons/Feather'
import { setStatusBarStyle } from 'expo-status-bar'
import { ArrowRight2, VideoSquare } from 'iconsax-react-native'
import ListCard from '../../components/ListCard'
import { useTheme } from '../../contexts/ThemeContext'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { useStyles } from '../../hooks/useStyles'
import Container from '../../components/Container'
import SafeAreaView from '../../components/SafeAreaView'
import Stack from '../../components/Stack'
import { ActiveLotteryCard } from '../../components/lottery/ActiveLotteryCard'
import Button from '../../components/Button'
import Constants from 'expo-constants'

const Lobby = ({ navigation }: HomeTabScreenProps<'Lobby'>) => {
  const spacing = useSpacingFn()

  setStatusBarStyle('dark')
  const t = useTranslations()
  const { data, isLoading } = useMeQuery()
  const { mutate: testPushNotification } = useTestPushNotificationMutation()
  const {
    data: startedCourseParentsData,
    isLoading: startedCourseParentsQueryIsLoading,
    refetch,
  } = useStartedCourseParentsQuery()

  const scrollY = useMemo(() => new Animated.Value(0), [])

  useEffect(() => {
    // Refetch courses when screen is focused.
    const unsubscribe = navigation.addListener('focus', () => refetch())
    return unsubscribe
  }, [navigation])

  const styles = useStyles(({ palette }) => ({
    container: {
      flex: 1,
      backgroundColor: palette.background.main,
    },
    safeAreaContainer: {
      position: 'relative',
      flex: 1,
    },
    headerStyle: {
      backgroundColor: palette.background.main,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }))

  const dimensions = useWindowDimensions()
  const environment = Constants.expoConfig?.extra?.env

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <ScrollView
          scrollEventThrottle={1}
          showsVerticalScrollIndicator={false}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrollY,
                  },
                },
              },
            ],
            {
              useNativeDriver: false,
            }
          )}
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingTop: spacing(6),
            flexGrow: 1,
          }}
        >
          <Container style={{ flex: 1 }}>
            <Surface style={styles.headerStyle}>
              <Stack spacing={2} style={{ flex: 1 }}>
                <Typography variant="p1" color="on-background">
                  {`${t('welcome_back')},`}
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" textVariant="h3" />
                ) : (
                  <Typography variant="h3">{data?.me?.fullName ?? undefined}</Typography>
                )}
              </Stack>

              <Pressable onPress={() => navigation.navigate('Profile')}>
                <UserAvatar size="medium" user={data?.me} />
              </Pressable>
            </Surface>

            <Stack style={{ marginTop: spacing(4) }} spacing={4}>
              <ActiveLotteryCard />
              {environment !== 'production' && (
                <Button onPress={() => testPushNotification({})} title="Test push" />
              )}
              <ListCard
                onPress={() => navigation.navigate('Courses')}
                heading={
                  <Typography variant="subtitle" weight="semibold">
                    {t('go_to_course_overview')}
                  </Typography>
                }
                leading={
                  <IconButton
                    pointerEvents="none"
                    color="secondary"
                    size={50}
                    icon={({ color, size }) => <VideoSquare color={color} size={size} />}
                  />
                }
                trailing={
                  <IconButton
                    pointerEvents="none"
                    color="background"
                    size={35}
                    icon={({ color, size }) => <ArrowRight2 color={color} size={size} />}
                  />
                }
              />
            </Stack>

            <View style={{ flexGrow: 1, marginTop: spacing(8) }}>
              {!startedCourseParentsQueryIsLoading &&
                (startedCourseParentsData?.startedCourseParents?.count ?? 0) > 0 && (
                  <View>
                    <Typography variant="h3">{t('titles.continue_courses')}</Typography>
                  </View>
                )}
              <View
                style={{
                  justifyContent: 'center',
                  paddingVertical: spacing(8),
                  flexGrow: startedCourseParentsData?.startedCourseParents?.count === 0 ? 1 : 0,
                }}
              >
                {startedCourseParentsQueryIsLoading ? (
                  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator size="small" color="#4106C9" />
                  </View>
                ) : (
                  startedCourseParentsData?.startedCourseParents?.list.map(
                    (courseParent, index) => (
                      <View
                        key={courseParent.id}
                        style={{ marginTop: index === 0 ? 0 : spacing(3) }}
                      >
                        <CourseListCard course={courseParent} />
                      </View>
                    )
                  )
                )}
                {!startedCourseParentsQueryIsLoading &&
                  startedCourseParentsData?.startedCourseParents?.count === 0 && (
                    <EmptyListGraphics />
                  )}
              </View>
            </View>
          </Container>
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}
export default Lobby

const EmptyListGraphics = () => {
  const t = useTranslations()
  const theme = useTheme()
  const spacing = useSpacingFn()

  const styles = useStyles(() => ({
    container: {
      marginHorizontal: 'auto',
      maxWidth: spacing(75),
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      zIndex: 1,
      position: 'relative',
      width: '80%',
      flexDirection: 'row',
      alignItems: 'center',
      padding: spacing(2),
      minHeight: spacing(15),
      ...theme.shapes.extraLarge,
    },
    card2: {
      top: spacing(-6),
      marginStart: 'auto',
      marginEnd: 0,
    },
    textContainer: {
      marginHorizontal: spacing(8),
    },
    cardContentContainer: {
      marginHorizontal: spacing(2),
      height: '100%',
      flex: 1,
    },
    lineContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    line1: {
      backgroundColor: theme.palette.background.main,
      height: spacing(2),
      width: '83%',
      borderRadius: 9999,
    },
    line2: {
      backgroundColor: theme.palette.background.main,
      height: spacing(2),
      width: '66%',
      borderRadius: 9999,
      marginTop: spacing(2),
    },
  }))

  return (
    <View style={styles.container}>
      <View>
        <Surface style={styles.card} elevation={1}>
          <IconButton
            size={40}
            color="background"
            tintColor="surface"
            icon={(props) => <Icon name="check" {...props}></Icon>}
          />
          <View style={styles.cardContentContainer}>
            <View style={styles.lineContainer}>
              <View style={styles.line1}></View>
              <View style={styles.line2}></View>
            </View>
          </View>
        </Surface>
        <Surface style={[styles.card, styles.card2]} elevation={1}>
          <IconButton
            size={40}
            color="background"
            tintColor="surface"
            icon={(props) => <Icon name="check" {...props}></Icon>}
          />
          <View style={styles.cardContentContainer}>
            <View style={styles.lineContainer}>
              <View style={styles.line1}></View>
              <View style={styles.line2}></View>
            </View>
          </View>
        </Surface>
        <View style={styles.textContainer}>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {t('no_started_courses_heading')}
          </Typography>
          <Typography variant="p2" style={{ textAlign: 'center', marginTop: spacing(2) }}>
            {t('no_started_courses_text')}
          </Typography>
        </View>
      </View>
    </View>
  )
}
