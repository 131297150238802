import React, { useEffect } from 'react'
import { useAuth } from '../contexts/authContext'
import { useFeatureFlags } from '../contexts/FeatureFlags'
import SplashScreen from './SplashScreen'
import * as Splash from 'expo-splash-screen'

export interface LoadingBoundaryProps {
  children: React.ReactNode
}

/**
 * This component will halt rendering of its children until the auth and feature flags are ready.
 * Also it will hide the splash screen when the auth and feature flags are ready.
 */
export const LoadingBoundary: React.FC<LoadingBoundaryProps> = ({ children }) => {
  const auth = useAuth()
  const flags = useFeatureFlags()

  useEffect(() => {
    if (auth.isReady && flags.isReady) {
      Splash.hideAsync()
    }
  }, [auth.isReady, flags.isReady])

  if (!auth.isReady || !flags.isReady) {
    return <SplashScreen />
  }

  return <>{children}</>
}

export default LoadingBoundary
