import { ReactNode, useCallback, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import Badge, { BadgeProps } from './Badge'
import Typography from './Typography'
import Surface, { SurfaceProps } from './Surface'
import { useAnimatedElevation } from '../hooks/useAnimatedElevation'
import { useTheme } from '../contexts/ThemeContext'
import { useStyles } from '../hooks/useStyles'
import { useSpacingFn } from '../contexts/SpacingContext'

export interface ListCardProps extends Omit<SurfaceProps, 'elevation'> {
  /**
   * A leading element to be displayed on the card.
   */
  leading?: ReactNode

  /**
   * A trailing element to be displayed on the card.
   */
  trailing?: ReactNode

  /**
   * A heading will be shown in the center of the card in bold text.
   */
  heading: string | ReactNode

  /**
   * Display a badge on the top left side of this card, for example
   * to indicate that this is a NEW item, or something.
   */
  badgeLeft?: BadgeProps

  /**
   * Display a badge on the top right side of this card, for example
   * to indicate that this is a NEW item, or something.
   */
  badgeRight?: BadgeProps

  /**
   * Show the card as a stack of cards on top of each other.
   * The bottom of the card will look like there are some cards below it. :D
   */
  showAsStack?: boolean

  /**
   * Display a shadow on this element
   */
  elevation?: boolean
  onPress?: () => void
}

const ListCard = ({
  leading,
  trailing,
  heading,
  children,
  badgeLeft,
  badgeRight,
  elevation = false,
  showAsStack = false,
  style,
  onPress,
  ...rest
}: ListCardProps) => {
  const theme = useTheme()
  const spacing = useSpacingFn()

  const [pressed, setPressed] = useState(false)

  const handlePressIn = useCallback(() => {
    setPressed(true)
  }, [])

  const handlePressOut = useCallback(() => {
    setPressed(false)
  }, [])

  const getHeading = () => {
    if (typeof heading === 'string') {
      return <Typography variant="subtitle">{heading}</Typography>
    } else {
      return heading
    }
  }

  const animatedElevation = useAnimatedElevation(pressed ? 4 : elevation || showAsStack ? 1 : 0)

  const styles = useStyles(({ shapes, spacing }) => ({
    pressable: {
      position: 'relative',
    },
    stackedCard: {
      ...shapes.extraLarge,
      zIndex: 0,
    },
    card: {
      ...shapes.extraLarge,
      zIndex: 1,
      minHeight: spacing(15),
      position: 'relative',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      padding: spacing(3),
    },
    leadingContainer: {
      height: spacing(10),
      width: spacing(10),
      alignItems: 'center',
      justifyContent: 'center',
    },
    trailingContainer: {
      height: spacing(10),
      width: spacing(10),
      alignItems: 'center',
      justifyContent: 'center',
    },
    childrenContainer: {
      marginTop: spacing(1),
      flexDirection: 'row',
      alighItems: 'center',
    },
    contentContainer: {
      marginHorizontal: spacing(2),
      height: '100%',
      flex: 1,
    },
    leadingBadgeContainer: {
      position: 'absolute',
      top: spacing(-2),
      start: spacing(3),
    },
    trailingBadgeContainer: {
      position: 'absolute',
      top: spacing(-2),
      right: spacing(3),
    },
  }))

  return (
    <Pressable
      style={styles.pressable}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onHoverIn={handlePressIn}
      onHoverOut={handlePressOut}
    >
      {showAsStack && (
        <Surface
          elevation={1}
          style={[
            styles.stackedCard,
            StyleSheet.absoluteFill,
            {
              transform: [
                {
                  translateY: spacing(2),
                },
                {
                  scaleX: 0.9,
                },
              ],
            },
          ]}
        ></Surface>
      )}

      {showAsStack && (
        <Surface
          elevation={1}
          style={[
            styles.stackedCard,
            StyleSheet.absoluteFill,
            {
              transform: [
                {
                  translateY: spacing(1),
                },
                {
                  scaleX: 0.95,
                },
              ],
            },
          ]}
        ></Surface>
      )}

      <Surface style={[styles.card, style, animatedElevation]} {...rest}>
        {leading && <View style={styles.leadingContainer}>{leading}</View>}
        <View style={styles.contentContainer}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <View>{getHeading()}</View>
            {children && <View style={styles.childrenContainer}>{children}</View>}
          </View>
        </View>

        {trailing && <View style={styles.trailingContainer}>{trailing}</View>}

        {badgeLeft && (
          <View style={styles.leadingBadgeContainer}>
            <Badge {...badgeLeft} />
          </View>
        )}
        {badgeRight && (
          <View style={styles.trailingBadgeContainer}>
            <Badge {...badgeRight} />
          </View>
        )}
      </Surface>
    </Pressable>
  )
}

export default ListCard
