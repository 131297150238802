import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { LinkingOptions, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { AuthProvider } from './contexts/authContext'
import { RootStackParamList } from './navigation/types'
import RootNavigator from './navigation/navigators/RootNavigator'
import * as SplashScreen from 'expo-splash-screen'
import useNavigationTheme from './hooks/useNavigationTheme'
import { LocaleProvider } from './contexts/localeContext'
import { useFonts } from 'expo-font'
import { Platform, UIManager } from 'react-native'
import { ThemeProvider } from './contexts/ThemeContext'
import { SpacingFnProvider } from './contexts/SpacingContext'
import { Outlet, PortalProvider } from './contexts/PortalContext'
import { Provider as WindowSizeProvider } from './contexts/WindowSize'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { FeatureFlagProvider } from './contexts/FeatureFlags'

import { Script } from './components/html/Script'
import { Link } from './components/html/Link'
import LoadingBoundary from './components/LoadingBoundary'
import OnboardingOverlay from './components/OnboardingOverlay'
import { PushNotificationProvider } from './contexts/PushNotifications'

// Prevent the splash screen from hiding. Only hide it once when we are ready.
// This will be hidden in LoadingBoundary component.
SplashScreen.preventAutoHideAsync()

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

const queryClient = new QueryClient()

export const Stack = createNativeStackNavigator<RootStackParamList>()

const createLinkingConfig = () => {
  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [],
    config: {
      screens: {
        Home: {
          path: '',
          screens: {
            Lobby: 'lobby',
            Courses: 'courses',
            Profile: 'profile',
            Shop: 'shop',
            Lotteries: 'lotteries',
          },
        },
        Login: 'login',
        Lottery: 'lottery/:lotteryId',
        Course: 'course/:courseId',
        ChangePassword: 'change-password',
        ForgotPassword: 'forgot-password',
        PersonalInformation: 'personal-information',
        Product: 'product/:productId',
        PurchasedUrlProduct: 'purchased-url-product/:productId',
        Prize: 'prize/:prizeId',
      },
    },
  }

  return linking
}

export default function App() {
  const navigationTheme = useNavigationTheme()

  // Load fonts. Block all rendering until loaded.
  const [fontsLoaded] = useFonts({
    'Inter-SemiBold': require('./assets/fonts/Inter-SemiBold.ttf'),
    'Inter-Medium': require('./assets/fonts/Inter-Medium.ttf'),
    'Inter-Regular': require('./assets/fonts/Inter-Regular.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      {Platform.OS === 'web' && (
        <Script src="https://unpkg.com/cloudinary-video-player@1.9.4/dist/cld-video-player.min.js" />
      )}
      {Platform.OS === 'web' && (
        <Link href="https://unpkg.com/cloudinary-video-player@1.9.4/dist/cld-video-player.min.css" />
      )}
      <WindowSizeProvider
        breakpoints={{
          xs: 0,
          sm: 600,
          md: 905,
          lg: 1240,
          xl: 1440,
        }}
      >
        <ThemeProvider>
          <SpacingFnProvider spacingFn={(spacing) => spacing * 5}>
            <LocaleProvider>
              <SafeAreaProvider>
                <QueryClientProvider client={queryClient}>
                  <AuthProvider>
                    <PushNotificationProvider>
                      <FeatureFlagProvider>
                        <LoadingBoundary>
                          <NavigationContainer
                            theme={navigationTheme}
                            linking={createLinkingConfig()}
                          >
                            <PortalProvider>
                              <OnboardingOverlay>
                                <RootNavigator />
                              </OnboardingOverlay>
                              <Outlet />
                            </PortalProvider>
                          </NavigationContainer>
                        </LoadingBoundary>
                      </FeatureFlagProvider>
                    </PushNotificationProvider>
                  </AuthProvider>
                </QueryClientProvider>
              </SafeAreaProvider>
            </LocaleProvider>
          </SpacingFnProvider>
        </ThemeProvider>
      </WindowSizeProvider>
    </GestureHandlerRootView>
  )
}
