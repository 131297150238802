import { Animated, StyleProp, ViewProps, ViewStyle } from 'react-native'
import { Elevation, ShapeCategory } from '../contexts/ThemeContext'
import { useStyles } from '../hooks/useStyles'

export interface SurfaceProps extends Omit<ViewProps, 'style'> {
  /**
   * The elevation of the surface.
   *
   * @default 0
   */
  elevation?: Elevation
  category?: ShapeCategory
  style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>
}

const Surface = ({ elevation = 0, category, style, ...rest }: SurfaceProps) => {
  const styles = useStyles(({ elevations, palette, shapes }) => ({
    surface: {
      ...elevations[elevation],
      ...(category ? shapes[category] : {}),
      backgroundColor: palette.surface.main,
    },
  }))

  return <Animated.View style={[styles.surface, style]} {...rest} />
}

export default Surface
