import { Image } from 'react-native'
import { SimplePrize } from '../../types/api'
import ListCard from '../ListCard'
import Surface from '../Surface'
import Typography from '../Typography'
import { useTranslations } from '../../contexts/localeContext'

export interface PrizeCardProps {
  prize: SimplePrize
  onPress?: () => void
}

export const PrizeCard: React.FC<PrizeCardProps> = ({ prize, onPress }) => {
  const t = useTranslations()

  return (
    <ListCard
      heading={prize.title}
      leading={
        <Surface category="full" style={{ overflow: 'hidden' }}>
          <Image source={{ uri: prize.imageUrl }} style={{ width: 50, height: 50 }} />
        </Surface>
      }
      onPress={onPress}
    >
      <Typography variant="p2">{`${prize.prizeTier}. ${t('prize')}`}</Typography>
    </ListCard>
  )
}
