import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Container from '../components/Container'
import { PrizeCard } from '../components/lottery/PrizeCard'
import PrizeImageHeader from '../components/lottery/PrizeImageHeader'
import Stack from '../components/Stack'
import Typography from '../components/Typography'
import { useSpacingFn } from '../contexts/SpacingContext'
import { usePrizeQuery } from '../generated/graphql'
import { RootStackScreenProps } from '../navigation/types'
import SafeAreaView from '../components/SafeAreaView'

export const Prize = (props: RootStackScreenProps<'Prize'>) => {
  const { data } = usePrizeQuery({ id: parseInt(props.route.params.prizeId.toString()) }) // Weird bug! If I don't convert to string and then to number, it doesn't work

  const spacing = useSpacingFn()

  // Create a function to convert a number into an ordinal string

  if (data === undefined) {
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="small" color="#4106C9" />
      </SafeAreaView>
    )
  }

  if (!data.prize) {
    return (
      <View>
        <Typography>Prize not found</Typography>
      </View>
    )
  }

  return (
    <ScrollView>
      <PrizeImageHeader prize={data.prize} />
      <Container style={{ marginTop: spacing(6) }}>
        <Stack spacing={6}>
          <Stack>
            <Typography variant="h2">{data.prize.title}</Typography>
            <Typography variant="p1">{data.prize.description}</Typography>
          </Stack>

          <Stack>
            <Stack spacing={0}>
              <Typography variant="h4">Other prizes in this lottery</Typography>
              <Typography variant="p2">{`${data.prize.relatedPrizes.length} prizes`}</Typography>
            </Stack>
            {data.prize.relatedPrizes.map((prize) => (
              <PrizeCard key={prize.id} prize={prize} />
            ))}
          </Stack>
        </Stack>
      </Container>
    </ScrollView>
  )
}
