import Typography from '../components/Typography'
import { CourseProvider } from '../contexts/CourseContext'
import { RootStackScreenProps } from '../navigation/types'
import CourseView from '../contexts/CourseContext/CourseView'
import { useCourseModuleQuery } from '../generated/graphql'
import { ActivityIndicator, View } from 'react-native'
import { setStatusBarStyle } from 'expo-status-bar'
import React from 'react'

/**
 * Navigate to this course with a course Id-param to display a specific course of that id.
 */
const Course = ({ route }: RootStackScreenProps<'Course'>) => {
  const { courseId } = route.params
  setStatusBarStyle('dark')
  if (!courseId) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h1" color="error">
          No course id provided
        </Typography>
      </View>
    )
  }

  const { data } = useCourseModuleQuery({ courseModuleId: courseId })

  return data === undefined ? (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <ActivityIndicator size="small" color="secondary" />
    </View>
  ) : data.courseModule === null || data.courseModule === undefined ? (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h1" color="error">
        Course not found
      </Typography>
    </View>
  ) : (
    <CourseProvider course={data.courseModule}>
      <CourseView />
    </CourseProvider>
  )
}

export default Course
