import { useNavigation } from '@react-navigation/native'
import React, { ReactNode, useRef, useState } from 'react'
import { Image, ImageSourcePropType, LayoutChangeEvent, StyleSheet, View } from 'react-native'
import { NativeEvent } from 'react-native-reanimated/lib/types/lib/reanimated2/commonTypes'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useStyles } from '../hooks/useStyles'
import Badge from './Badge'
import ArrowButton from './buttons/ArrowButton'
import Container from './Container'
import IconButton from './IconButton'
import SafeAreaView from './SafeAreaView'
import Stack from './Stack'
import Surface from './Surface'
import Typography from './Typography'
import { LinearGradient } from 'expo-linear-gradient'

export type ImageHeaderProps = {
  title: string
  image: ImageSourcePropType
  topLeftNode?: ReactNode
  topRightNode?: ReactNode
  bottomLeftNode?: ReactNode
  bottomRightNode?: ReactNode
}

const ImageHeader: React.FC<ImageHeaderProps> = ({
  title,
  image,
  topLeftNode,
  topRightNode,
  bottomLeftNode,
  bottomRightNode,
}) => {
  const [edgeWidth, setEdgeWidth] = useState(0)

  const styles = useStyles(
    ({ shapes, spacing }) => ({
      headerContainer: {
        borderBottomRightRadius: shapes.xxl.borderRadius,
        borderBottomLeftRadius: shapes.xxl.borderRadius,
        overflow: 'hidden',
        position: 'relative',
      },
      image: {
        width: '100%',
        height: spacing(68),
        borderBottomRightRadius: shapes.xxl.borderRadius,
        borderBottomLeftRadius: shapes.xxl.borderRadius,
      },
      badge: {
        alignSelf: 'flex-start',
        marginBottom: spacing(4),
      },
      safeAreaView: {
        flex: 1,
        paddingTop: spacing(2),
      },
      container: {
        flex: 1,
        justifyContent: 'space-between',
        paddingBottom: spacing(6),
      },
      row: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
      middleElement: { flexDirection: 'row', alignItems: 'center' },
      edgeElement: {
        flexGrow: 1,
        width: edgeWidth || undefined,
      },
    }),
    [edgeWidth]
  )

  const spacing = useSpacingFn()
  const handleEdgeLayout = (event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width

    setEdgeWidth((prev) => {
      return Math.min(60, prev < width ? width : prev)
    })
  }

  return (
    <Surface style={styles.headerContainer}>
      <Image source={image} style={styles.image} resizeMode="cover" />
      <LinearGradient
        colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: '50%',
        }}
      />
      <View style={StyleSheet.absoluteFill}>
        <SafeAreaView style={styles.safeAreaView} disableBottomSafeArea>
          <Container style={styles.container}>
            <View style={styles.row}>
              <View style={styles.edgeElement} onLayout={handleEdgeLayout}>
                {topLeftNode}
              </View>
              <View style={{ width: spacing(2) }}></View>
              <View style={styles.middleElement}>
                <Typography textAlign="center" variant="h2" color={'on-secondary'}>
                  {title}
                </Typography>
              </View>
              <View style={{ width: spacing(2) }}></View>
              <View style={styles.edgeElement} onLayout={handleEdgeLayout}>
                {topRightNode}
              </View>
            </View>
            <View style={styles.row}>
              <View>{bottomLeftNode}</View>
              <View>{bottomRightNode}</View>
            </View>
          </Container>
        </SafeAreaView>
      </View>
    </Surface>
  )
}

export default ImageHeader
