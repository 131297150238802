import { View } from 'react-native'
import QrCode from 'react-qr-code'
import Typography from './Typography'
import { useTranslations } from '../contexts/localeContext'
import { useSpacingFn } from '../contexts/SpacingContext'

type Props = {
  code: string
}

const CompanyInviteCode = ({ code }: Props) => {
  const spacing = useSpacingFn()
  const t = useTranslations()

  return (
    <View style={{ paddingTop: spacing(6) }}>
      <Typography variant="h4" style={{ paddingBottom: spacing(4) }}>
        {t('titles.company_invite_link')}
      </Typography>
      <Typography style={{ paddingBottom: spacing(4) }}>
        {t('company_invite_link_description')}
      </Typography>
      <QrCode value={code} />
    </View>
  )
}

export default CompanyInviteCode
