import { useCourse } from '../../contexts/CourseContext'
import CourseChapter from '../../contexts/CourseContext/CourseChapter'
import CourseEnd from '../../contexts/CourseContext/CourseEnd'
import CourseIntro from '../../contexts/CourseContext/CourseIntro'
import CourseRate from '../../contexts/CourseContext/CourseRate'

/**
 * This component is responsible for rendering the correct view based on the activeView-state from CourseContext.
 */
const CourseView = () => {
  const { activeView } = useCourse()

  const renderActiveView = () => {
    switch (activeView) {
      case 'intro':
        return <CourseIntro />
      case 'chapter':
        return <CourseChapter />
      case 'end':
        return <CourseEnd />
      case 'rate_course':
        return <CourseRate />
      default:
        return null
    }
  }
  return renderActiveView()
}

export default CourseView
