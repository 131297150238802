import React from 'react'
import { View, Pressable, Image, StyleSheet } from 'react-native'
import useAnswerSelect from '../../../hooks/useAnswerSelect'
import { useStyles } from '../../../hooks/useStyles'
import GridLayout from '../../layout/GridLayout'
import Typography from '../../Typography'
import QuestionTemplate, { QuestionProps, QuestionTemplatePropsModified } from './QuestionTemplate'

export interface ImageQuestionProps
  extends Omit<QuestionProps, 'answers'>,
    QuestionTemplatePropsModified {}

export const ImageQuestion = ({ question, onAnswer, ...props }: ImageQuestionProps) => {
  const [selectedAnswer, selectAnswer] = useAnswerSelect()

  const onSubmit = () => {
    const { id } = question.imageAnswers[selectedAnswer]
    onAnswer(id)
  }

  const styles = useStyles(({ spacing, palette, shapes }) => ({
    questionContainer: {
      marginBottom: spacing(4),
    },
    answersContainer: {
      width: '100%',
    },
    imageAnswerContainer: {
      ...shapes.large,
      position: 'relative',
      height: spacing(36),
      overflow: 'hidden',
      padding: spacing(2),
    },
    imageContainer: {
      borderRadius: shapes.large.borderRadius - spacing(2) + 4,
      flex: 1,
    },
    selectedAnswer: {
      ...shapes.large,
      borderWidth: 4,
      borderColor: palette.primary.on,
    },
    unselectedAnswer: {
      ...shapes.large,
      borderWidth: 4,
      borderColor: 'transparent',
    },
  }))
  return (
    <QuestionTemplate
      {...props}
      onButtonPress={() => onSubmit()}
      showButton={selectedAnswer !== undefined}
      headerImageUrl={question?.imageUrl}
    >
      <Typography variant="subtitle" color="on-surface" style={styles.questionContainer}>
        {question.question}
      </Typography>
      <View style={styles.answersContainer}>
        <GridLayout
          columns={2}
          items={question.imageAnswers}
          renderItem={(item, index) => {
            return (
              <Pressable onPress={() => selectAnswer(index)}>
                <View style={styles.imageAnswerContainer}>
                  <Image source={{ uri: item.imageUrl }} style={styles.imageContainer} />
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      selectedAnswer === index ? styles.selectedAnswer : styles.unselectedAnswer,
                    ]}
                  ></View>
                </View>
              </Pressable>
            )
          }}
        />
      </View>
    </QuestionTemplate>
  )
}

export default ImageQuestion
