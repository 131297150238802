import { Image, Pressable, View } from 'react-native'
import { SharedElement } from 'react-navigation-shared-element'
import { useStyles } from '../hooks/useStyles'
import Badge, { BadgeProps } from './Badge'
import Surface from './Surface'
import Typography from './Typography'

export type CardProps = {
  title: string
  subtitle: string
  imageUrl?: string
  badgeProps?: BadgeProps
  onPress?(): void
}

const Card: React.FC<CardProps> = ({ title, subtitle, imageUrl, badgeProps, onPress }) => {
  const styles = useStyles(({ palette, spacing, shapes }) => ({
    container: {
      backgroundColor: palette.background.main,
    },
    imageContainer: { position: 'relative' },
    image: { width: 140, height: 100, ...shapes.medium },
    badge: {
      position: 'absolute',
      bottom: spacing(2),
      left: spacing(2),
    },
    title: { marginTop: spacing(2), flex: 1, maxWidth: 140 },
    subtitle: { marginTop: spacing(1) },
  }))

  return (
    <Pressable onPress={onPress}>
      <Surface style={styles.container}>
        <View style={styles.imageContainer}>
          <Image source={{ uri: imageUrl }} style={styles.image} resizeMode="cover" />

          {badgeProps !== undefined && (
            <View style={styles.badge}>
              <Badge {...badgeProps} />
            </View>
          )}
        </View>

        <Typography numberOfLines={1} variant="p1" weight="semibold" style={styles.title}>
          {title}
        </Typography>
        <Typography variant="p2" style={styles.subtitle}>
          {subtitle}
        </Typography>
      </Surface>
    </Pressable>
  )
}

export default Card
