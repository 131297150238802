import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'

export type RefreshCredentialsMutationVariables = {
  refreshToken: string
}

export type RefreshCredentialsMutation = {
  __typename?: 'Mutation'
  refreshCredentials: {
    __typename?: 'LoginPayload'
    token: string
    refreshToken: string
  }
}

const RefreshCredentialsDocument = `
  mutation refreshCredentials($refreshToken: String!) {
    refreshCredentials(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`

export async function refreshCredentials(): Promise<void> {
  const backendUrl = Constants.expoConfig?.extra?.BACKEND_URL
  const refreshToken = await AsyncStorage.getItem('refreshToken')

  if (!refreshToken) {
    // No refresh token found, so we need to log the user out.
    await deleteTokens()
    throw new Error('No refresh token found')
  }

  return fetch(backendUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: RefreshCredentialsDocument,
      variables: <RefreshCredentialsMutationVariables>{
        refreshToken,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data as RefreshCredentialsMutation)
    .then(({ refreshCredentials }) =>
      updateTokens(refreshCredentials.token, refreshCredentials.refreshToken)
    )
    .then(() => {
      console.log('Credentials refreshed!')
    })
}

export async function updateTokens(token: string, refreshToken: string): Promise<void> {
  await Promise.all([
    AsyncStorage.setItem('userToken', token),
    AsyncStorage.setItem('refreshToken', refreshToken),
  ])
}

export async function deleteTokens(): Promise<void> {
  await Promise.all([AsyncStorage.removeItem('userToken'), AsyncStorage.removeItem('refreshToken')])
}
