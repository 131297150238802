import Typography, { TypographyProps } from './Typography'

export interface FormattedNumberProps extends TypographyProps {}

const formatText = (text: string) =>
  text
    .split('')
    .reverse()
    .reduce((acc, x, i) => (i !== 0 && i % 3 === 0 ? acc.concat([' ', x]) : acc.concat([x])), [])
    .reverse()
    .join('')

const FormattedNumber: React.FC<FormattedNumberProps> = ({ children, ...props }) => {
  return <Typography {...props}>{formatText(children)}</Typography>
}

export default FormattedNumber
