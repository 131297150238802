import { useCallback, useEffect, useMemo, useState } from 'react'
import { Locale, useLocalization, useTranslations } from '../contexts/localeContext'

const calculateTimeLeft = (to?: Date) => {
  if (to === undefined) return 0
  return to.getTime() - Date.now()
}

export const useCountdown = (to?: Date | string) => {
  const date: Date | undefined = typeof to === 'string' ? new Date(to) : to

  const t = useTranslations()

  const time = calculateTimeLeft(date)
  const [timeLeft, setTimeLeft] = useState<number>(time)
  const [isExpired, setIsExpired] = useState<boolean>(timeLeft < 1)

  const d = t('time.days_short', { capitalized: false })
  const h = t('time.hours_short', { capitalized: false })
  const m = t('time.minutes_short', { capitalized: false })
  const s = t('time.seconds_short', { capitalized: false })

  const toTimeString = useCallback(
    (date: Date | undefined) => {
      if (!date) {
        return '-- -- --'
      }

      const timeInMillis = calculateTimeLeft(date)

      if (timeInMillis < 0) {
        return '00:00:00'
      }

      const days = Math.floor(timeInMillis / 86400000)
      const hours = Math.floor((timeInMillis % 86400000) / 3600000)
      const minutes = Math.floor((timeInMillis % 3600000) / 60000)
      const seconds = Math.floor((timeInMillis % 60000) / 1000)

      const isMoreThanOneDay = days > 0

      return isMoreThanOneDay
        ? `${days} ${d} ${hours} ${h} ${minutes} ${m}`
        : `${hours} ${h} ${minutes} ${m} ${seconds} ${s}`
    },
    [to]
  )

  const timeString = useMemo(() => toTimeString(date), [timeLeft, to])

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft(date)

      setTimeLeft(timeLeft)
      setIsExpired(timeLeft <= 0)

      if (timeLeft <= 0) clearInterval(interval)
    }, 1000)
    return () => clearInterval(interval)
  }, [to])

  if (to === undefined) return { timeLeft: 0, isExpired: true, timeString: '0 h 0 m 0 s' }
  return { timeLeft, isExpired, timeString }
}
