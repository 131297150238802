import { useAuth } from '../../contexts/authContext'
import ChangePassword from '../../screens/ChangePassword'
import Course from '../../screens/Course'
import ForgotPassword from '../../screens/ForgotPassword'
import { HomeTabs } from '../../screens/HomeTabs'
import Login from '../../screens/Login'
import PersonalInformation from '../../screens/PersonalInformation'
import { useTranslations } from '../../contexts/localeContext'
import { useTheme } from '../../contexts/ThemeContext'
import Product from '../../screens/Product'
import { createSharedElementStackNavigator } from 'react-navigation-shared-element'
import PurchasedUrlProduct from '../../screens/PurchasedUrlProduct'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { RootStackParamList } from '../types'
import Lottery from '../../screens/Lottery'
import { Prize } from '../../screens/Prize'

const RootStack = createNativeStackNavigator<RootStackParamList>()

const RootNavigator = () => {
  const { isLoggedIn } = useAuth()
  const t = useTranslations()
  const theme = useTheme()
  return (
    <RootStack.Navigator>
      {isLoggedIn ? (
        <>
          <RootStack.Screen name="Home" component={HomeTabs} options={{ headerShown: false }} />
          <RootStack.Screen name="Course" component={Course} options={{ headerShown: false }} />
          <RootStack.Screen
            name="ChangePassword"
            component={ChangePassword}
            options={{
              title: t('titles.change_password'),
              headerShadowVisible: false,
              headerStyle: {
                backgroundColor: theme.palette.surface.main,
              },
            }}
          />
          <RootStack.Screen
            name="PersonalInformation"
            component={PersonalInformation}
            options={{
              title: t('titles.personal_information'),
              headerShadowVisible: false,
              headerStyle: {
                backgroundColor: theme.palette.surface.main,
              },
            }}
          />
          <RootStack.Screen name="Product" component={Product} options={{ headerShown: false }} />
          <RootStack.Screen
            name="PurchasedUrlProduct"
            component={PurchasedUrlProduct}
            options={{ headerShown: false }}
          />
          <RootStack.Screen name="Lottery" component={Lottery} options={{ headerShown: false }} />
          <RootStack.Screen name="Prize" component={Prize} options={{ headerShown: false }} />
        </>
      ) : (
        <>
          <RootStack.Screen name="Login" component={Login} options={{ headerShown: false }} />
          <RootStack.Screen
            name="ForgotPassword"
            component={ForgotPassword}
            options={{
              title: t('titles.forgot_password'),
              headerShadowVisible: false,
              headerStyle: {
                backgroundColor: theme.palette.tertiary.main,
              },
              headerTintColor: theme.palette.tertiary.on,
            }}
          />
        </>
      )}
    </RootStack.Navigator>
  )
}

export default RootNavigator
