import { ReactNode } from 'react'
import { useStyles } from '../../hooks/useStyles'
import Surface from '../Surface'

export type HeaderBottomRoundedBoxProps = {
  height?: number
  children?: ReactNode
}

const HeaderBottomRoundedBox: React.FC<HeaderBottomRoundedBoxProps> = ({
  children,
  height = 320,
}) => {
  const styles = useStyles(({ spacing, shapes }) => ({
    container: {
      height,
      borderBottomLeftRadius: shapes['3xl'].borderRadius,
      borderBottomRightRadius: shapes['3xl'].borderRadius,
      overflow: 'hidden',
    },
  }))

  return <Surface style={styles.container}>{children}</Surface>
}

export default HeaderBottomRoundedBox
