import { ReactNode, useCallback, useMemo, useState } from 'react'
import {
  Pressable,
  View,
  PressableProps,
  ActivityIndicator,
  ViewStyle,
  TextStyle,
  GestureResponderEvent,
} from 'react-native'
import Typography from './Typography'
import Surface, { SurfaceProps } from './Surface'
import { useAnimatedElevation } from '../hooks/useAnimatedElevation'
import { useStyles } from '../hooks/useStyles'
import { useSurfaceScale } from '../hooks/useSurfaceScale'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'
import { useTheme } from '../contexts/ThemeContext'
import { useSpacingFn } from '../contexts/SpacingContext'

type ButtonSize = 'small' | 'medium' | 'large' | 'extra-large'
export interface ButtonProps
  extends Omit<SurfaceProps, 'hitSlop'>,
    Omit<PressableProps, 'children' | 'style'> {
  title: string
  leading?: ReactNode | (({ color, size }: { color: string; size: number }) => ReactNode)
  trailing?: ReactNode | (({ color, size }: { color: string; size: number }) => ReactNode)
  loading?: boolean
  loadingIndicatorPosition?: 'leading' | 'trailing'
  variant?: 'standard' | 'outline' | 'text'
  color?: Color
  tintColor?: Color
  size?: ButtonSize
  rounded?: boolean
  disabled?: boolean
  disableElevation?: boolean
  style?: ViewStyle
  titleStyle?: TextStyle
}

const Button = ({
  title,
  leading,
  trailing,
  loading,
  loadingIndicatorPosition = 'leading',
  disableElevation = false,
  variant = 'standard',
  color = 'primary',
  tintColor,
  size = 'medium',
  rounded = false,
  titleStyle,
  style,

  onPress,
  onPressIn,
  onPressOut,
  onLongPress,
  onBlur,
  onFocus,
  delayLongPress,
  disabled = false,
  hitSlop,
  pressRetentionOffset,
  android_disableSound,
  android_ripple,
  testOnly_pressed,
  ...rest
}: ButtonProps) => {
  const theme = useTheme()
  const spacing = useSpacingFn()
  const surfaceScale = useSurfaceScale()

  const palette = usePaletteColor(
    disabled ? surfaceScale(0.12).hex() : color,
    disabled ? surfaceScale(0.35).hex() : tintColor
  )

  const contentColor = useMemo(
    () =>
      variant === 'standard'
        ? palette.on
        : variant === 'outline'
        ? palette.on
        : disabled
        ? palette.on
        : palette.main,
    [variant, palette, disabled]
  )

  const hasLeading = useMemo(() => {
    return !!leading || (loading && loadingIndicatorPosition === 'leading')
  }, [leading, loading, loadingIndicatorPosition])

  const hasTrailing = useMemo(() => {
    return !!trailing || (loading && loadingIndicatorPosition === 'trailing')
  }, [trailing, loading, loadingIndicatorPosition])

  const getLeading = () => {
    if (loading && loadingIndicatorPosition === 'leading') {
      return getLoadingIndicator()
    }

    if (typeof leading === 'function') {
      return leading({ color: contentColor, size: 25 })
    }
    return leading
  }

  const getTrailing = () => {
    if (loading && loadingIndicatorPosition === 'trailing') {
      return getLoadingIndicator()
    }

    if (typeof trailing === 'function') {
      return trailing({
        color: contentColor,
        size: 25,
      })
    }

    return trailing
  }

  const getLoadingIndicator = () => {
    return <ActivityIndicator color={contentColor} size={20} />
  }

  const [pressed, setPressed] = useState(false)

  const handlePressIn = useCallback(
    (event: GestureResponderEvent) => {
      onPressIn?.(event)
      setPressed(true)
    },
    [onPressIn]
  )

  const handlePressOut = useCallback(
    (event: GestureResponderEvent) => {
      onPressOut?.(event)
      setPressed(false)
    },
    [onPressOut]
  )

  const animatedElevation = useAnimatedElevation(
    variant === 'standard' && !disableElevation && !disabled ? (pressed ? 8 : 2) : 0
  )

  const shape = useMemo(() => {
    if (rounded) {
      return theme.shapes.full
    }

    if (size === 'small') {
      return theme.shapes.small
    } else if (size === 'medium') {
      return theme.shapes.medium
    } else {
      return theme.shapes.large
    }
  }, [size, theme])

  const pressableSizeStyles: { [K in ButtonSize]: ViewStyle } = useMemo(() => {
    const defaultStyles: ViewStyle = {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }
    return {
      small: {
        ...defaultStyles,
        minWidth: spacing(16),
        height: spacing(6),
        paddingStart: hasLeading ? spacing(1) : spacing(2),
        paddingEnd: hasTrailing ? spacing(1) : spacing(2),
      },
      medium: {
        ...defaultStyles,
        minWidth: spacing(20),
        height: spacing(8),
        paddingStart: hasLeading ? spacing(2) : spacing(4),
        paddingEnd: hasTrailing ? spacing(2) : spacing(4),
      },
      large: {
        ...defaultStyles,
        minWidth: spacing(20),
        height: spacing(10),
        paddingStart: hasLeading ? spacing(4) : spacing(6),
        paddingEnd: hasTrailing ? spacing(4) : spacing(6),
      },
      'extra-large': {
        ...defaultStyles,
        minWidth: spacing(20),
        height: spacing(12),
        paddingStart: hasLeading ? spacing(4) : spacing(6),
        paddingEnd: hasTrailing ? spacing(4) : spacing(6),
      },
    }
  }, [theme, hasLeading, hasTrailing])

  const styles = useStyles(
    ({ spacing, shapes }) => ({
      container: {
        backgroundColor: variant !== 'text' ? palette.main : 'transparent',
        borderWidth: variant === 'outline' ? (size === 'small' || size === 'medium' ? 1 : 2) : 0,
        borderColor: palette.on,
        ...shapes.small,
      },
      pressableContainer: {
        ...shapes.small,
        overflow: 'hidden',
      },
      pressable: {
        ...pressableSizeStyles[size],
      },
      leadingContainer: {
        marginEnd: spacing(1),
      },
      trailingContainer: {
        marginStart: spacing(1),
      },
    }),
    [variant, loading, loadingIndicatorPosition, palette, hasLeading, hasTrailing, surfaceScale]
  )

  return (
    <Surface style={[styles.container, shape, style, animatedElevation]} {...rest}>
      <View style={[styles.pressableContainer]}>
        <Pressable
          style={[styles.pressable, shape]}
          onPress={onPress}
          onPressIn={handlePressIn}
          onPressOut={handlePressOut}
          onHoverIn={() => setPressed(true)}
          onHoverOut={() => setPressed(false)}
          onLongPress={onLongPress}
          onBlur={onBlur}
          onFocus={onFocus}
          delayLongPress={delayLongPress}
          disabled={disabled}
          hitSlop={hitSlop}
          pressRetentionOffset={pressRetentionOffset}
          android_disableSound={android_disableSound}
          testOnly_pressed={testOnly_pressed}
        >
          {hasLeading && <View style={styles.leadingContainer}>{getLeading()}</View>}
          <Typography
            variant={
              size === 'small' ? 'p2' : size === 'medium' ? 'p1' : size === 'large' ? 'h4' : 'h2'
            }
            color={variant !== 'text' ? palette.on : palette.main}
            style={[titleStyle]}
          >
            {title}
          </Typography>
          {hasTrailing && <View style={styles.trailingContainer}>{getTrailing()}</View>}
        </Pressable>
      </View>
    </Surface>
  )
}

export default Button
