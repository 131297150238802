import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useFetchData } from '../fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  File: any;
  Json: any;
};

export type AdminLotteries = {
  __typename?: 'AdminLotteries';
  active: Array<Lottery>;
  drawing: Array<Lottery>;
  ended: Array<Lottery>;
  pending: Array<Lottery>;
};

export type AnsweredQuestion = {
  __typename?: 'AnsweredQuestion';
  chapterSession: CourseChapterSession;
  correctAnswersId: Array<Scalars['String']>;
  id: Scalars['String'];
  incorrectAnswersId: Array<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
};

export enum AnswersType {
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type BaseError = Error & {
  __typename?: 'BaseError';
  message: Scalars['String'];
};

export enum BlockType {
  Image = 'IMAGE',
  Question = 'QUESTION',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type CoinAccountSummary = {
  __typename?: 'CoinAccountSummary';
  balance: Scalars['Int'];
  totalEarned: Scalars['Int'];
  totalSpent: Scalars['Int'];
};

export type CompaniesPayload = {
  __typename?: 'CompaniesPayload';
  companies: Array<Company>;
  company?: Maybe<Company>;
  count: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  activeCoursesCount: Scalars['Int'];
  availableCoursesCount?: Maybe<Scalars['Int']>;
  avatarUrl?: Maybe<Scalars['String']>;
  childCompanies: Array<Company>;
  childCompaniesCount: Scalars['Int'];
  coinBalance?: Maybe<Scalars['Int']>;
  defaultLanguage: Scalars['String'];
  draftCoursesCount: Scalars['Int'];
  employees: Array<User>;
  employeesCount: Scalars['Int'];
  id: Scalars['String'];
  inviteExpiryDate?: Maybe<Scalars['Float']>;
  inviteLink?: Maybe<Scalars['String']>;
  isSystemAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  parentCompanies: Array<Company>;
  slug: Scalars['String'];
  sumGeneratedToExternalCompany?: Maybe<Scalars['Int']>;
  sumIncomingTransactions?: Maybe<Scalars['Int']>;
  sumInternalSystemTransactions?: Maybe<Scalars['Int']>;
  sumOutgoingTransactions?: Maybe<Scalars['Int']>;
  sumSpentInShop?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type CompanyCourseFilters = {
  __typename?: 'CompanyCourseFilters';
  company: Company;
  courseCount: Scalars['Int'];
};

export enum CompanyOrderBy {
  ChildCompanies = 'childCompanies',
  CreatedAt = 'createdAt',
  Employees = 'employees',
  Name = 'name'
}

export type CompanyStats = {
  __typename?: 'CompanyStats';
  activeCoursesCount: Scalars['Int'];
  dealersCount: Scalars['Int'];
  storesCount: Scalars['Int'];
  systemActiveCoursesCount: Scalars['Int'];
  systemModulesCompletedCount: Scalars['Int'];
  totalEmployeesCount: Scalars['Int'];
  totalModulesCompletedCount: Scalars['Int'];
  totalSessionsCompletedCount: Scalars['Int'];
};

export enum CompanyType {
  Brand = 'BRAND',
  Dealer = 'DEALER',
  Store = 'STORE',
  System = 'SYSTEM'
}

export type CourseBlock = {
  __typename?: 'CourseBlock';
  content: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  order: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CourseChapter = {
  __typename?: 'CourseChapter';
  blocks: Array<CourseBlock>;
  chapterSession?: Maybe<CourseChapterSession>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  moduleId: Scalars['String'];
  numberOfRequiredBlocks: Scalars['Int'];
  order: Scalars['Int'];
  quiz?: Maybe<Quiz>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CourseChapterSession = {
  __typename?: 'CourseChapterSession';
  blocksCompleted: Array<Scalars['String']>;
  completedAt?: Maybe<Scalars['Date']>;
  courseChapter: CourseChapter;
  courseChapterId: Scalars['String'];
  id: Scalars['String'];
  moduleSession: CourseModuleSession;
  numberOfRequiredBlocksCompleted: Scalars['Int'];
  pointsEarned: Scalars['Int'];
  quizSession: Array<AnsweredQuestion>;
  startedAt?: Maybe<Scalars['Date']>;
};

export enum CourseChapterType {
  Info = 'INFO',
  Quiz = 'QUIZ'
}

export type CourseModule = {
  __typename?: 'CourseModule';
  chapters: Array<CourseChapter>;
  chaptersCount: Scalars['Int'];
  coinsAreActivated: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  currentSession?: Maybe<CourseModuleSession>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  maxCoinsReward: Scalars['Int'];
  myRating?: Maybe<CourseParentRating>;
  normalizedOrder: Scalars['Int'];
  order: Scalars['Int'];
  ownerAvatar?: Maybe<Scalars['String']>;
  parentId: Scalars['String'];
  percentageCompleted?: Maybe<Scalars['Int']>;
  reward: CourseModuleReward;
  title: Scalars['String'];
  totalCoinsEarned: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type CourseModuleProgressSummary = {
  __typename?: 'CourseModuleProgressSummary';
  coinsEarnedOnModule: Scalars['Int'];
  maxCoinsOnModule: Scalars['Int'];
  maxTicketsOnModule: Scalars['Int'];
  ticketsEarned: Scalars['Int'];
};

export type CourseModuleProgression = {
  __typename?: 'CourseModuleProgression';
  archivedSessions: Array<CourseModuleSession>;
  currentSession?: Maybe<CourseModuleSession>;
  id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  module: CourseModule;
  rewards: Array<CourseModuleReward>;
  user: User;
};

export type CourseModuleReward = {
  __typename?: 'CourseModuleReward';
  code?: Maybe<Scalars['String']>;
  moduleId: Scalars['String'];
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type CourseModuleSession = {
  __typename?: 'CourseModuleSession';
  chapterSessions: Array<CourseChapterSession>;
  coinsWereActivated: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  progression: CourseModuleProgression;
};

export enum CourseOrderBy {
  CreatedAt = 'createdAt',
  Language = 'language',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type CourseParent = {
  __typename?: 'CourseParent';
  averageRating: Scalars['Float'];
  coinsAreActivated: Scalars['Boolean'];
  coinsAreActivatedOnParent: Scalars['Boolean'];
  completedModulesCount: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  difficulty: Difficulty;
  duration: Scalars['Int'];
  expiryDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
  isGlobal: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  language: Scalars['String'];
  maxCoinsReward: Scalars['Int'];
  modules: Array<CourseModule>;
  modulesCount: Scalars['Int'];
  myRating?: Maybe<CourseParentRating>;
  owner: Company;
  publishDate?: Maybe<Scalars['Date']>;
  startedModulesCount: Scalars['Int'];
  status: Scalars['String'];
  title: Scalars['String'];
  totalCoinsEarned: Scalars['Float'];
  /** Total number of GLOBAL course completions for this course */
  totalCourseCompletions: Scalars['Int'];
  /** Total number of GLOBAL course starts for this course */
  totalCourseStarts: Scalars['Int'];
  /** Total number of unique progressions for this course. A user can have one progression for each course module they have started */
  totalProgressionsCount: Scalars['Int'];
  /** Total number of sessions for this course. */
  totalSessionCount: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  userCompletedDate?: Maybe<Scalars['String']>;
  /** Percentage of quizzes completed by the user in this course. A number from 0 to 100 */
  userQuizCompletionPercentage?: Maybe<Scalars['Int']>;
  userStartedDate?: Maybe<Scalars['String']>;
};


export type CourseParentCompletedModulesCountArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type CourseParentStartedModulesCountArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type CourseParentUserCompletedDateArgs = {
  userId: Scalars['String'];
};


export type CourseParentUserQuizCompletionPercentageArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type CourseParentUserStartedDateArgs = {
  userId: Scalars['String'];
};

export type CourseParentPayload = {
  __typename?: 'CourseParentPayload';
  count: Scalars['Int'];
  list: Array<CourseParent>;
  user?: Maybe<User>;
};

export type CourseParentProgressSummaryObject = {
  __typename?: 'CourseParentProgressSummaryObject';
  isCompleted: Scalars['Boolean'];
  maxCoins: Scalars['Int'];
  maxTickets: Scalars['Int'];
  nextIncompleteModuleId?: Maybe<Scalars['String']>;
  numberOfModulesCompleted: Scalars['Int'];
  numberOfModulesInCourseParent: Scalars['Int'];
  ticketsEarned: Scalars['Int'];
  totalCoinsEarned: Scalars['Int'];
};

export type CourseParentRating = {
  __typename?: 'CourseParentRating';
  comment?: Maybe<Scalars['String']>;
  courseParentId: Scalars['String'];
  createdAt: Scalars['Date'];
  rating: Scalars['Int'];
  user: User;
  userId: Scalars['String'];
};

export type CourseParentRatingPayload = {
  __typename?: 'CourseParentRatingPayload';
  count: Scalars['Int'];
  ratings: Array<CourseParentRating>;
};

export type CourseParentStats = {
  __typename?: 'CourseParentStats';
  completedCourseParents: Scalars['Int'];
  requiredCourseParents: Scalars['Int'];
  startedCourseParents: Scalars['Int'];
};

export enum CourseStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED'
}

export enum CourseType {
  Optional = 'OPTIONAL',
  Recommended = 'RECOMMENDED',
  Required = 'REQUIRED'
}

export type CreateLotteryInput = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
};

export enum Difficulty {
  Easy = 'EASY',
  Hard = 'HARD',
  Medium = 'MEDIUM'
}

export type DrawLotteryOutput = {
  __typename?: 'DrawLotteryOutput';
  numberOfWinners: Scalars['Int'];
  winners: Array<UserPrize>;
};

export type EndCourseResponse = {
  __typename?: 'EndCourseResponse';
  coinsEarnedInSession: Scalars['Int'];
  coinsWereActivated: Scalars['Boolean'];
  completesCourseParent: Scalars['Boolean'];
  ticketsEarned: Scalars['Int'];
  totalCoinsEarned: Scalars['Int'];
  userTotalCoinBalance: Scalars['Int'];
};

export type Error = {
  message: Scalars['String'];
};

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  id: Scalars['Int'];
  lotteryIsEnabled?: Maybe<Scalars['Boolean']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  privacyPolicyUpdatedAt?: Maybe<Scalars['Date']>;
  shopIsEnabled?: Maybe<Scalars['Boolean']>;
  supportFormUrl?: Maybe<Scalars['String']>;
  termsOfUse?: Maybe<Scalars['String']>;
  termsOfUseUpdatedAt?: Maybe<Scalars['Date']>;
  troubleshootingUrl?: Maybe<Scalars['String']>;
};

export type ImageAnswer = {
  __typename?: 'ImageAnswer';
  answerFeedback?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  questionId: Scalars['String'];
};

export type IncorrectPasswordError = Error & {
  __typename?: 'IncorrectPasswordError';
  message: Scalars['String'];
};

export type InvalidEmailError = Error & {
  __typename?: 'InvalidEmailError';
  message: Scalars['String'];
};

export type InvalidPasswordError = Error & {
  __typename?: 'InvalidPasswordError';
  message: Scalars['String'];
};

export type InvalidUsernamePasswordCombinationError = Error & {
  __typename?: 'InvalidUsernamePasswordCombinationError';
  message: Scalars['String'];
};

export enum Language {
  Da = 'DA',
  De = 'DE',
  En = 'EN',
  Fi = 'FI',
  No = 'NO',
  Swe = 'SWE'
}

export type LoginPayload = {
  __typename?: 'LoginPayload';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export type Lottery = {
  __typename?: 'Lottery';
  creator: User;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  hasBeenDrawn: Scalars['Boolean'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  isActivated: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  lotteryState: LotteryState;
  pendingTickets: Scalars['Int'];
  prizes: Array<Prize>;
  startDate?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  totalTicketsConsumed: Scalars['Int'];
  userTicketsConsumed: Scalars['Int'];
  userWinnings: Array<Prize>;
  winners: Array<User>;
};


export type LotteryUserTicketsConsumedArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type LotteryUserWinningsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

export enum LotteryState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Drawing = 'DRAWING',
  Pending = 'PENDING'
}

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
  /** success, info, error, warning */
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCompanyInvite?: Maybe<LoginPayload>;
  acceptInvite?: Maybe<LoginPayload>;
  addPrize: Prize;
  answerQuizQuestion: AnsweredQuestion;
  archiveCourseParent: CourseParent;
  archiveUser: User;
  /** Changes the password of the current user. Password must be at least 8 characters long. */
  changePassword: MutationChangePasswordResult;
  /** This is called when a video is seen or a question is answered */
  completeCourseBlock?: Maybe<CourseChapterSession>;
  createCourseBlock: CourseBlock;
  createCourseChapter: CourseChapter;
  createCourseModule: CourseModule;
  /** Start a new module-progression for a course. */
  createCourseModuleProgression?: Maybe<CourseModuleProgression>;
  createCourseParent: CourseParent;
  createImageAnswer?: Maybe<ImageAnswer>;
  createLottery: Lottery;
  createManyStores: Array<Company>;
  createOrEditReward?: Maybe<CourseModule>;
  createProduct: MutationCreateProductResult;
  createQuestion?: Maybe<Question>;
  /** Create a new store. */
  createSubCompany?: Maybe<Company>;
  createTextAnswer?: Maybe<TextAnswer>;
  createUrlProducts: Product;
  deleteCompany: Company;
  deleteCourseBlock: Message;
  deleteCourseChapter: Message;
  deleteCourseModule: Message;
  deleteCourseParent: CourseParent;
  deleteCourseParentRating?: Maybe<CourseParent>;
  deleteImageAnswer: Message;
  deleteLottery: Scalars['Boolean'];
  deleteProduct: Product;
  deleteQuestion: Message;
  deleteTextAnswer: Message;
  deleteUser: User;
  deleteVideoUpload: Scalars['Boolean'];
  drawLottery: DrawLotteryOutput;
  duplicateCourseParent: CourseParent;
  /** Ends a course. */
  endCourse: EndCourseResponse;
  inviteUser?: Maybe<UserInvite>;
  login: MutationLoginResult;
  /** Open a specific chapter in the module. If no progression exists, create one. Give rewards if this was the last chapter. */
  openChapter?: Maybe<CourseChapterSession>;
  publishCourseParent: CourseParent;
  purchaseUrlProduct: MutationPurchaseUrlProductResult;
  rateCourseParent?: Maybe<CourseParent>;
  refreshCompanyInvite: Company;
  refreshCredentials: LoginPayload;
  refreshInvite: UserInvite;
  removeInvite: Message;
  /** If the email exists, send a link with a reset token to the email. The token can be used to reset the password with the resetPassword mutation. */
  requestResetPassword?: Maybe<MutationRequestResetPasswordResult>;
  /** Reset the user password by supplying the resetToken returned from requestReset */
  resetPassword?: Maybe<LoginPayload>;
  /** Archive the current user session on the module and start a new one. */
  restartCourseModule: CourseModuleProgression;
  /** Restarts the quiz progression in your current session */
  restartQuiz: CourseChapterSession;
  setOnboardingCompleted: Scalars['Boolean'];
  testPushNotification: Scalars['Boolean'];
  toggleLottery: Scalars['Boolean'];
  updateBlocksOrder?: Maybe<Array<CourseBlock>>;
  updateChaptersOrder?: Maybe<Array<CourseChapter>>;
  /** Update a company that you own or administrate. */
  updateCompany: Company;
  updateCourseBlock?: Maybe<CourseBlock>;
  updateCourseChapter?: Maybe<CourseChapter>;
  updateCourseModule?: Maybe<CourseModule>;
  updateCourseParent?: Maybe<CourseParent>;
  updateCourseParentAccess?: Maybe<Array<CourseParent>>;
  /** Update a company that you own or administrate. */
  updateCurrentCompany: Company;
  updateGlobalSettings: GlobalSettings;
  updateImageAnswer?: Maybe<ImageAnswer>;
  updateLottery: Lottery;
  updatePrize: Prize;
  updateProduct: Product;
  /** Updates the profile of the current user */
  updateProfile: MutationUpdateProfileResult;
  updatePushToken: User;
  updateQuestion?: Maybe<Question>;
  updateQuiz?: Maybe<Quiz>;
  updateTextAnswer?: Maybe<TextAnswer>;
  updateUser: User;
  updateUserEmail: User;
  updateUserLanguage: User;
  updateUserPhone: User;
  uploadVideo: CourseBlock;
};


export type MutationAcceptCompanyInviteArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviteToken: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAcceptInviteArgs = {
  confirmPassword: Scalars['String'];
  inviteToken: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAddPrizeArgs = {
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  lotteryId: Scalars['Int'];
  prizeTier: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationAnswerQuizQuestionArgs = {
  answerIds: Array<Scalars['String']>;
  chapterId: Scalars['String'];
  moduleId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationArchiveCourseParentArgs = {
  courseParentId: Scalars['String'];
};


export type MutationArchiveUserArgs = {
  userId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  confirmNewPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCompleteCourseBlockArgs = {
  blockId: Scalars['String'];
  chapterId: Scalars['String'];
  moduleId: Scalars['String'];
};


export type MutationCreateCourseBlockArgs = {
  chapterId: Scalars['String'];
  type: BlockType;
};


export type MutationCreateCourseChapterArgs = {
  description?: InputMaybe<Scalars['String']>;
  moduleId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: CourseChapterType;
};


export type MutationCreateCourseModuleArgs = {
  description?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateCourseModuleProgressionArgs = {
  moduleId: Scalars['String'];
};


export type MutationCreateCourseParentArgs = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateImageAnswerArgs = {
  imageUrl?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
};


export type MutationCreateLotteryArgs = {
  input: CreateLotteryInput;
};


export type MutationCreateManyStoresArgs = {
  companyNames: Scalars['String'];
  parentCompanySlug: Scalars['String'];
};


export type MutationCreateOrEditRewardArgs = {
  code?: InputMaybe<Scalars['String']>;
  courseModuleId: Scalars['String'];
  text: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};


export type MutationCreateProductArgs = {
  activeFrom?: InputMaybe<Scalars['String']>;
  activeTo?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProductType>;
};


export type MutationCreateQuestionArgs = {
  answersType: AnswersType;
  question?: InputMaybe<Scalars['String']>;
  quizId: Scalars['String'];
};


export type MutationCreateSubCompanyArgs = {
  defaultLanguage?: InputMaybe<Language>;
  name: Scalars['String'];
  parentCompanySlug: Scalars['String'];
};


export type MutationCreateTextAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
};


export type MutationCreateUrlProductsArgs = {
  productId: Scalars['Int'];
  urlProductData: Array<Scalars['String']>;
};


export type MutationDeleteCompanyArgs = {
  slug: Scalars['String'];
};


export type MutationDeleteCourseBlockArgs = {
  courseBlockId: Scalars['String'];
};


export type MutationDeleteCourseChapterArgs = {
  courseChapterId: Scalars['String'];
};


export type MutationDeleteCourseModuleArgs = {
  courseModuleId: Scalars['String'];
};


export type MutationDeleteCourseParentArgs = {
  courseParentId: Scalars['String'];
};


export type MutationDeleteCourseParentRatingArgs = {
  courseParentId: Scalars['String'];
};


export type MutationDeleteImageAnswerArgs = {
  imageAnswerId: Scalars['String'];
};


export type MutationDeleteLotteryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['String'];
};


export type MutationDeleteTextAnswerArgs = {
  textAnswerId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteVideoUploadArgs = {
  courseBlockId: Scalars['String'];
};


export type MutationDrawLotteryArgs = {
  lotteryId: Scalars['Int'];
};


export type MutationDuplicateCourseParentArgs = {
  courseParentId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationEndCourseArgs = {
  moduleId: Scalars['String'];
};


export type MutationInviteUserArgs = {
  companySlug?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationOpenChapterArgs = {
  chapterId: Scalars['String'];
  moduleId: Scalars['String'];
};


export type MutationPublishCourseParentArgs = {
  courseParentId: Scalars['String'];
};


export type MutationPurchaseUrlProductArgs = {
  productId: Scalars['Int'];
};


export type MutationRateCourseParentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  courseParentId: Scalars['String'];
  rating: Scalars['Int'];
};


export type MutationRefreshCompanyInviteArgs = {
  companySlug: Scalars['String'];
};


export type MutationRefreshCredentialsArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshInviteArgs = {
  inviteId: Scalars['String'];
};


export type MutationRemoveInviteArgs = {
  inviteId: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String'];
  isApp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResetPasswordArgs = {
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
  resetToken: Scalars['String'];
};


export type MutationRestartCourseModuleArgs = {
  moduleId: Scalars['String'];
};


export type MutationRestartQuizArgs = {
  chapterId: Scalars['String'];
  moduleId: Scalars['String'];
};


export type MutationSetOnboardingCompletedArgs = {
  completed: Scalars['Boolean'];
};


export type MutationToggleLotteryArgs = {
  lotteryId: Scalars['Int'];
  shouldActivate: Scalars['Boolean'];
};


export type MutationUpdateBlocksOrderArgs = {
  blockIds: Array<Scalars['String']>;
};


export type MutationUpdateChaptersOrderArgs = {
  chapterIds: Array<Scalars['String']>;
};


export type MutationUpdateCompanyArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  companySlug: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateCourseBlockArgs = {
  content?: InputMaybe<Scalars['String']>;
  courseBlockId: Scalars['String'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCourseChapterArgs = {
  courseChapterId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCourseModuleArgs = {
  courseModuleId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  difficulty?: InputMaybe<Difficulty>;
  duration?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCourseParentArgs = {
  coinsAreActivatedOnParent?: InputMaybe<Scalars['Boolean']>;
  courseParentId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  publishDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CourseStatus>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CourseType>;
};


export type MutationUpdateCourseParentAccessArgs = {
  companySlug: Scalars['String'];
  courseParentIds: Array<Scalars['String']>;
};


export type MutationUpdateCurrentCompanyArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateGlobalSettingsArgs = {
  privacyPolicy?: InputMaybe<Scalars['String']>;
  supportFormUrl?: InputMaybe<Scalars['String']>;
  termsOfUse?: InputMaybe<Scalars['String']>;
  troubleshootingUrl?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateImageAnswerArgs = {
  answerFeedback?: InputMaybe<Scalars['String']>;
  answerId: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateLotteryArgs = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lotteryId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePrizeArgs = {
  id: Scalars['Int'];
  input: UpdatePrizeInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
  productId: Scalars['Int'];
};


export type MutationUpdateProfileArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePushTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateQuestionArgs = {
  imageUrl?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
};


export type MutationUpdateQuizArgs = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  quizId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTextAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  answerFeedback?: InputMaybe<Scalars['String']>;
  answerId: Scalars['String'];
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Role>>;
  userId: Scalars['String'];
};


export type MutationUpdateUserEmailArgs = {
  newEmail: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserLanguageArgs = {
  language: Scalars['String'];
};


export type MutationUpdateUserPhoneArgs = {
  phone: Scalars['String'];
};


export type MutationUploadVideoArgs = {
  courseBlockId: Scalars['String'];
  input: UploadVideoInput;
};

export type MutationChangePasswordResult = IncorrectPasswordError | InvalidPasswordError | MutationChangePasswordSuccess | NewPasswordsDoNotMatchError | UserNotFoundError;

export type MutationChangePasswordSuccess = {
  __typename?: 'MutationChangePasswordSuccess';
  data: Message;
};

export type MutationCreateProductResult = MutationCreateProductSuccess;

export type MutationCreateProductSuccess = {
  __typename?: 'MutationCreateProductSuccess';
  data: Product;
};

export type MutationLoginResult = InvalidUsernamePasswordCombinationError | MutationLoginSuccess | UserNotFoundError | UserWithoutCompanyError;

export type MutationLoginSuccess = {
  __typename?: 'MutationLoginSuccess';
  data: LoginPayload;
};

export type MutationPurchaseUrlProductResult = MutationPurchaseUrlProductSuccess | NotEnoughCoinsError;

export type MutationPurchaseUrlProductSuccess = {
  __typename?: 'MutationPurchaseUrlProductSuccess';
  data: PurchaseUrlProductResponse;
};

export type MutationRequestResetPasswordResult = InvalidEmailError | MutationRequestResetPasswordSuccess | UserNotFoundError;

export type MutationRequestResetPasswordSuccess = {
  __typename?: 'MutationRequestResetPasswordSuccess';
  data: Message;
};

export type MutationUpdateProfileResult = MutationUpdateProfileSuccess | ZodError;

export type MutationUpdateProfileSuccess = {
  __typename?: 'MutationUpdateProfileSuccess';
  data: User;
};

export type NewPasswordsDoNotMatchError = Error & {
  __typename?: 'NewPasswordsDoNotMatchError';
  message: Scalars['String'];
};

export type NotEnoughCoinsError = Error & {
  __typename?: 'NotEnoughCoinsError';
  message: Scalars['String'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Prize = {
  __typename?: 'Prize';
  description: Scalars['String'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  lotteryId?: Maybe<Scalars['Int']>;
  prizeTier: Scalars['Int'];
  relatedPrizes: Array<Prize>;
  title: Scalars['String'];
  wonBy?: Maybe<User>;
};

export type Product = {
  __typename?: 'Product';
  activeFrom: Scalars['Date'];
  activeTo?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  creator: User;
  description: Scalars['String'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isGlobal: Scalars['Boolean'];
  language: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  stock: Scalars['Int'];
  updatedAt: Scalars['Date'];
  urlProductsCount: Scalars['Int'];
};

export type ProductNotFoundError = Error & {
  __typename?: 'ProductNotFoundError';
  message: Scalars['String'];
};

export enum ProductType {
  UrlProduct = 'URL_PRODUCT'
}

export type ProductsPayload = {
  __typename?: 'ProductsPayload';
  count: Scalars['Int'];
  list: Array<Product>;
};

export type PurchaseUrlProductResponse = {
  __typename?: 'PurchaseUrlProductResponse';
  urlProductId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeLottery?: Maybe<Lottery>;
  adminActiveLotteries: Array<Lottery>;
  adminEndedLotteries: Array<Lottery>;
  adminLotteries: AdminLotteries;
  adminPendingLotteries: Array<Lottery>;
  adminProduct: Product;
  adminProducts: Array<Product>;
  appCompanyCourseFilters: Array<CompanyCourseFilters>;
  appCourseParent?: Maybe<CourseParent>;
  appCourseParents: CourseParentPayload;
  availableCourseParents: Array<CourseParent>;
  /** A list of companies that your company administrates. */
  childCompanies?: Maybe<CompaniesPayload>;
  coinAccountSummary: CoinAccountSummary;
  /** Find a company by its slug. Falls back to your current company if no slug is provided */
  company?: Maybe<Company>;
  /** Find a company by its invite token. */
  companyByInviteToken: Company;
  companyStats: CompanyStats;
  courseBlock: CourseBlock;
  courseChapter?: Maybe<CourseChapter>;
  courseChapterBlocks: Array<CourseBlock>;
  courseModule?: Maybe<CourseModule>;
  courseModuleChapters: Array<CourseChapter>;
  courseModuleProgressSummary: CourseModuleProgressSummary;
  courseModules: Array<CourseModule>;
  courseParent?: Maybe<CourseParent>;
  courseParentProgressSummary: CourseParentProgressSummaryObject;
  courseParentRatings: CourseParentRatingPayload;
  courseParents?: Maybe<CourseParentPayload>;
  /** The company of your current user context. */
  currentCompany?: Maybe<Company>;
  /** Get a user by their ID */
  employee: User;
  employees: UsersPayload;
  endedLotteries: Array<Lottery>;
  expiredInvites: UserInvitePayload;
  globalSettings?: Maybe<GlobalSettings>;
  invite?: Maybe<UserInvite>;
  lottery?: Maybe<Lottery>;
  me?: Maybe<User>;
  ownedCourseParentsIds: Array<Scalars['String']>;
  pendingInvites: UserInvitePayload;
  prize?: Maybe<Prize>;
  product: QueryProductResult;
  products: ProductsPayload;
  purchasedUrlProduct: QueryPurchasedUrlProductResult;
  purchasedUrlProducts: UrlProductsPayload;
  /** Get a quiz by id */
  quiz?: Maybe<Quiz>;
  scheduledCourseParents?: Maybe<CourseParentPayload>;
  startedCourseParents?: Maybe<CourseParentPayload>;
  statsByCompany: SingleCompanyStats;
  userCourseParents: CourseParentPayload;
  userTickets: CoinAccountSummary;
};


export type QueryActiveLotteryArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryAdminProductArgs = {
  productId: Scalars['Int'];
};


export type QueryAppCourseParentArgs = {
  courseParentId: Scalars['String'];
};


export type QueryAppCourseParentsArgs = {
  companies?: InputMaybe<Array<Scalars['String']>>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CourseStatus>;
  take?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CourseType>;
};


export type QueryAvailableCourseParentsArgs = {
  companySlug: Scalars['String'];
};


export type QueryChildCompaniesArgs = {
  companySlug: Scalars['String'];
  language?: InputMaybe<Language>;
  orderBy?: InputMaybe<CompanyOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  searchString?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  slug: Scalars['String'];
};


export type QueryCompanyByInviteTokenArgs = {
  inviteToken: Scalars['String'];
};


export type QueryCourseBlockArgs = {
  courseBlockId: Scalars['String'];
};


export type QueryCourseChapterArgs = {
  courseChapterId: Scalars['String'];
};


export type QueryCourseChapterBlocksArgs = {
  courseChapterId: Scalars['String'];
  fetchHidden?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCourseModuleArgs = {
  courseModuleId: Scalars['String'];
};


export type QueryCourseModuleChaptersArgs = {
  courseModuleId: Scalars['String'];
  fetchHidden?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCourseModuleProgressSummaryArgs = {
  moduleId: Scalars['String'];
};


export type QueryCourseModulesArgs = {
  courseParentId: Scalars['String'];
};


export type QueryCourseParentArgs = {
  courseParentId: Scalars['String'];
};


export type QueryCourseParentProgressSummaryArgs = {
  courseParentId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryCourseParentRatingsArgs = {
  courseParentId: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCourseParentsArgs = {
  includeModules?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  orderBy?: InputMaybe<CourseOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  searchString?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CourseStatus>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryEmployeeArgs = {
  userId: Scalars['String'];
};


export type QueryEmployeesArgs = {
  companySlug: Scalars['String'];
  orderBy?: InputMaybe<UserOrderByInput>;
  orderDirection?: InputMaybe<OrderDirection>;
  role?: InputMaybe<Role>;
  searchString?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryEndedLotteriesArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryExpiredInvitesArgs = {
  companySlug?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryInviteArgs = {
  inviteToken: Scalars['String'];
};


export type QueryLotteryArgs = {
  id: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryPendingInvitesArgs = {
  companySlug?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPrizeArgs = {
  id: Scalars['Int'];
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryPurchasedUrlProductArgs = {
  id: Scalars['String'];
};


export type QueryQuizArgs = {
  id: Scalars['String'];
};


export type QueryScheduledCourseParentsArgs = {
  isOwner?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CourseStatus>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryStatsByCompanyArgs = {
  companySlug: Scalars['String'];
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};


export type QueryUserCourseParentsArgs = {
  companies?: InputMaybe<Array<Scalars['String']>>;
  orderBy?: InputMaybe<CourseOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  searchString?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CourseType>;
  userId: Scalars['String'];
};


export type QueryUserTicketsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryProductResult = ProductNotFoundError | QueryProductSuccess;

export type QueryProductSuccess = {
  __typename?: 'QueryProductSuccess';
  data: Product;
};

export type QueryPurchasedUrlProductResult = ProductNotFoundError | QueryPurchasedUrlProductSuccess;

export type QueryPurchasedUrlProductSuccess = {
  __typename?: 'QueryPurchasedUrlProductSuccess';
  data: UrlProduct;
};

export type Question = {
  __typename?: 'Question';
  answersType: Scalars['String'];
  id: Scalars['String'];
  imageAnswers?: Maybe<Array<ImageAnswer>>;
  imageUrl?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  quizId: Scalars['String'];
  textAnswers?: Maybe<Array<TextAnswer>>;
};

export type Quiz = {
  __typename?: 'Quiz';
  chapterId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  questionCount: Scalars['Int'];
  questions: Array<Question>;
  title?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  User = 'USER'
}

export type SingleCompanyStats = {
  __typename?: 'SingleCompanyStats';
  activeUsers: Scalars['Int'];
  coinsEarned: Scalars['Int'];
  coursesCompleted: Scalars['Int'];
  ticketsEarned: Scalars['Int'];
  totalActiveUsers: Scalars['Int'];
  usersCreated: Scalars['Int'];
};

export type TextAnswer = {
  __typename?: 'TextAnswer';
  answer: Scalars['String'];
  answerFeedback?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  questionId: Scalars['String'];
};

export type UrlProduct = {
  __typename?: 'URLProduct';
  code?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  product: Product;
  purchasedAt?: Maybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type UrlProductsPayload = {
  __typename?: 'URLProductsPayload';
  count: Scalars['Int'];
  list: Array<UrlProduct>;
};

export type UpdatePrizeInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateProductInput = {
  /** ISO string */
  activeFrom?: InputMaybe<Scalars['String']>;
  /** ISO string */
  activeTo?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  /** Defaults to english */
  language?: InputMaybe<Language>;
  name: Scalars['String'];
  price: Scalars['Int'];
  type: ProductType;
};

export type UploadVideoInput = {
  playbackUrl: Scalars['String'];
  resourceId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  company: Company;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdAtFormatted: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: Scalars['String'];
  lastActiveAt?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  onBoardingCompleted: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  company: Company;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  expiryDate: Scalars['Float'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invitedBy: User;
  message?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

export type UserInvitePayload = {
  __typename?: 'UserInvitePayload';
  count: Scalars['Int'];
  invites: Array<UserInvite>;
};

export type UserNotAccessToCourseError = Error & {
  __typename?: 'UserNotAccessToCourseError';
  message: Scalars['String'];
};

export type UserNotFoundError = Error & {
  __typename?: 'UserNotFoundError';
  message: Scalars['String'];
};

export enum UserOrderByInput {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  Role = 'role'
}

export type UserPrize = {
  __typename?: 'UserPrize';
  prize: Prize;
  user: User;
};

export type UserWithoutCompanyError = Error & {
  __typename?: 'UserWithoutCompanyError';
  message: Scalars['String'];
};

export type UsersPayload = {
  __typename?: 'UsersPayload';
  count: Scalars['Int'];
  users: Array<User>;
};

export type ZodError = Error & {
  __typename?: 'ZodError';
  fieldErrors: Array<ZodFieldError>;
  message: Scalars['String'];
};

export type ZodFieldError = {
  __typename?: 'ZodFieldError';
  message: Scalars['String'];
  path: Array<Scalars['String']>;
};

export type ChapterSessionFieldsFragment = { __typename?: 'CourseChapterSession', id: string, courseChapterId: string, pointsEarned: number, startedAt?: any | null, completedAt?: any | null, blocksCompleted: Array<string>, numberOfRequiredBlocksCompleted: number, quizSession: Array<{ __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> }> };

export type AnsweredQuestionFieldsFragment = { __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> };

export type CourseModuleProgressionFieldsFragment = { __typename?: 'CourseModuleProgression', id: string, isCompleted: boolean };

export type CreateCourseModuleProgressionMutationVariables = Exact<{
  moduleId: Scalars['String'];
}>;


export type CreateCourseModuleProgressionMutation = { __typename?: 'Mutation', createCourseModuleProgression?: { __typename?: 'CourseModuleProgression', id: string, isCompleted: boolean } | null };

export type OpenChapterMutationVariables = Exact<{
  moduleId: Scalars['String'];
  chapterId: Scalars['String'];
}>;


export type OpenChapterMutation = { __typename?: 'Mutation', openChapter?: { __typename?: 'CourseChapterSession', id: string, courseChapterId: string, pointsEarned: number, startedAt?: any | null, completedAt?: any | null, blocksCompleted: Array<string>, numberOfRequiredBlocksCompleted: number, quizSession: Array<{ __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> }> } | null };

export type CompleteCourseBlockMutationVariables = Exact<{
  moduleId: Scalars['String'];
  chapterId: Scalars['String'];
  blockId: Scalars['String'];
}>;


export type CompleteCourseBlockMutation = { __typename?: 'Mutation', completeCourseBlock?: { __typename?: 'CourseChapterSession', id: string, courseChapterId: string, pointsEarned: number, startedAt?: any | null, completedAt?: any | null, blocksCompleted: Array<string>, numberOfRequiredBlocksCompleted: number, quizSession: Array<{ __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> }> } | null };

export type AnswerQuizQuestionMutationVariables = Exact<{
  moduleId: Scalars['String'];
  chapterId: Scalars['String'];
  questionId: Scalars['String'];
  answerIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AnswerQuizQuestionMutation = { __typename?: 'Mutation', answerQuizQuestion: { __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> } };

export type RestartQuizMutationVariables = Exact<{
  moduleId: Scalars['String'];
  chapterId: Scalars['String'];
}>;


export type RestartQuizMutation = { __typename?: 'Mutation', restartQuiz: { __typename?: 'CourseChapterSession', id: string, courseChapterId: string, pointsEarned: number, startedAt?: any | null, completedAt?: any | null, blocksCompleted: Array<string>, numberOfRequiredBlocksCompleted: number, quizSession: Array<{ __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> }> } };

export type EndCourseModuleMutationVariables = Exact<{
  moduleId: Scalars['String'];
}>;


export type EndCourseModuleMutation = { __typename?: 'Mutation', endCourse: { __typename?: 'EndCourseResponse', coinsEarnedInSession: number, totalCoinsEarned: number, coinsWereActivated: boolean, completesCourseParent: boolean, ticketsEarned: number } };

export type RateCourseParentMutationVariables = Exact<{
  courseParentId: Scalars['String'];
  rating: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
}>;


export type RateCourseParentMutation = { __typename?: 'Mutation', rateCourseParent?: { __typename?: 'CourseParent', id: string, title: string, createdAt: any, description?: string | null, imageUrl?: string | null, modulesCount: number, startedModulesCount: number, completedModulesCount: number, status: string, type: string, difficulty: Difficulty, duration: number, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, modules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }>, owner: { __typename?: 'Company', id: string, avatarUrl?: string | null } } | null };

export type RestartCourseModuleMutationVariables = Exact<{
  moduleId: Scalars['String'];
}>;


export type RestartCourseModuleMutation = { __typename?: 'Mutation', restartCourseModule: { __typename?: 'CourseModuleProgression', id: string, isCompleted: boolean } };

export type PurchaseUrlProductMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type PurchaseUrlProductMutation = { __typename?: 'Mutation', purchaseUrlProduct: { __typename: 'MutationPurchaseUrlProductSuccess', data: { __typename?: 'PurchaseUrlProductResponse', urlProductId: string } } | { __typename: 'NotEnoughCoinsError', message: string } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename: 'InvalidUsernamePasswordCombinationError', message: string } | { __typename: 'MutationLoginSuccess', data: { __typename?: 'LoginPayload', token: string, refreshToken: string, user: { __typename?: 'User', id: string, email: string, avatarUrl?: string | null, language: string, phone?: string | null, fullName?: string | null, firstName?: string | null, lastName?: string | null, roles: Array<string>, pushToken?: string | null, onBoardingCompleted: boolean, company: { __typename?: 'Company', id: string, defaultLanguage: string } } } } | { __typename: 'UserNotFoundError', message: string } | { __typename: 'UserWithoutCompanyError', message: string } };

export type RefreshCredentialsMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshCredentialsMutation = { __typename?: 'Mutation', refreshCredentials: { __typename?: 'LoginPayload', token: string, refreshToken: string, user: { __typename?: 'User', id: string, email: string, avatarUrl?: string | null, language: string, phone?: string | null, fullName?: string | null, firstName?: string | null, lastName?: string | null, roles: Array<string>, pushToken?: string | null, onBoardingCompleted: boolean, company: { __typename?: 'Company', id: string, defaultLanguage: string } } } };

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  confirmNewPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename: 'IncorrectPasswordError', message: string } | { __typename: 'InvalidPasswordError', message: string } | { __typename: 'MutationChangePasswordSuccess', data: { __typename?: 'Message', type: string, message: string } } | { __typename: 'NewPasswordsDoNotMatchError', message: string } | { __typename: 'UserNotFoundError', message: string } };

export type UpdateUserLanguageMutationVariables = Exact<{
  language: Scalars['String'];
}>;


export type UpdateUserLanguageMutation = { __typename?: 'Mutation', updateUserLanguage: { __typename?: 'User', id: string, language: string } };

export type UpdateProfileMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename: 'MutationUpdateProfileSuccess', data: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, fullName?: string | null, avatarUrl?: string | null, phone?: string | null, email: string } } | { __typename: 'ZodError', message: string } };

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword?: { __typename: 'InvalidEmailError', message: string } | { __typename: 'MutationRequestResetPasswordSuccess', data: { __typename?: 'Message', type: string, message: string } } | { __typename: 'UserNotFoundError', message: string } | null };

type UpdateProfileFields_MutationUpdateProfileSuccess_Fragment = { __typename: 'MutationUpdateProfileSuccess', data: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, fullName?: string | null, avatarUrl?: string | null, phone?: string | null, email: string } };

type UpdateProfileFields_ZodError_Fragment = { __typename: 'ZodError', message: string };

export type UpdateProfileFieldsFragment = UpdateProfileFields_MutationUpdateProfileSuccess_Fragment | UpdateProfileFields_ZodError_Fragment;

export type SetOnboardingCompletedMutationVariables = Exact<{
  completed: Scalars['Boolean'];
}>;


export type SetOnboardingCompletedMutation = { __typename?: 'Mutation', setOnboardingCompleted: boolean };

export type UpdatePushTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePushTokenMutation = { __typename?: 'Mutation', updatePushToken: { __typename?: 'User', id: string, email: string, avatarUrl?: string | null, language: string, phone?: string | null, fullName?: string | null, firstName?: string | null, lastName?: string | null, roles: Array<string>, pushToken?: string | null, onBoardingCompleted: boolean, company: { __typename?: 'Company', id: string, defaultLanguage: string } } };

export type TestPushNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type TestPushNotificationMutation = { __typename?: 'Mutation', testPushNotification: boolean };

export type CurrentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentCompanyQuery = { __typename?: 'Query', currentCompany?: { __typename?: 'Company', id: string, name: string, avatarUrl?: string | null, defaultLanguage: string, inviteLink?: string | null, parentCompanies: Array<{ __typename?: 'Company', id: string, name: string }>, employees: Array<{ __typename?: 'User', id: string, avatarUrl?: string | null, firstName?: string | null, lastName?: string | null, fullName?: string | null }> } | null };

export type CourseModuleListFieldsFragment = { __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null };

export type CourseParentListFieldsFragment = { __typename?: 'CourseParent', id: string, title: string, createdAt: any, description?: string | null, imageUrl?: string | null, modulesCount: number, startedModulesCount: number, completedModulesCount: number, status: string, type: string, difficulty: Difficulty, duration: number, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, modules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }>, owner: { __typename?: 'Company', id: string, avatarUrl?: string | null } };

export type AppCompanyCourseFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type AppCompanyCourseFiltersQuery = { __typename?: 'Query', appCompanyCourseFilters: Array<{ __typename?: 'CompanyCourseFilters', courseCount: number, company: { __typename?: 'Company', id: string, name: string, avatarUrl?: string | null } }> };

export type AppCourseParentsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  companies?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  type?: InputMaybe<CourseType>;
  status?: InputMaybe<CourseStatus>;
}>;


export type AppCourseParentsQuery = { __typename?: 'Query', appCourseParents: { __typename?: 'CourseParentPayload', count: number, list: Array<{ __typename?: 'CourseParent', id: string, title: string, createdAt: any, description?: string | null, imageUrl?: string | null, modulesCount: number, startedModulesCount: number, completedModulesCount: number, status: string, type: string, difficulty: Difficulty, duration: number, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, modules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }>, owner: { __typename?: 'Company', id: string, avatarUrl?: string | null } }> } };

export type StartedCourseParentsQueryVariables = Exact<{ [key: string]: never; }>;


export type StartedCourseParentsQuery = { __typename?: 'Query', startedCourseParents?: { __typename?: 'CourseParentPayload', count: number, list: Array<{ __typename?: 'CourseParent', id: string, title: string, createdAt: any, description?: string | null, imageUrl?: string | null, modulesCount: number, startedModulesCount: number, completedModulesCount: number, status: string, type: string, difficulty: Difficulty, duration: number, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, modules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }>, owner: { __typename?: 'Company', id: string, avatarUrl?: string | null } }> } | null };

export type QuizQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuizQuery = { __typename?: 'Query', quiz?: { __typename?: 'Quiz', title?: string | null, description?: string | null, imageUrl?: string | null, questions: Array<{ __typename?: 'Question', id: string, question: string, imageUrl?: string | null, answersType: string, textAnswers?: Array<{ __typename?: 'TextAnswer', id: string, answer: string, isCorrect: boolean, answerFeedback?: string | null }> | null, imageAnswers?: Array<{ __typename?: 'ImageAnswer', id: string, imageUrl: string, isCorrect: boolean, answerFeedback?: string | null }> | null }> } | null };

export type CourseModuleQueryVariables = Exact<{
  courseModuleId: Scalars['String'];
}>;


export type CourseModuleQuery = { __typename?: 'Query', courseModule?: { __typename?: 'CourseModule', id: string, title: string, order: number, normalizedOrder: number, description?: string | null, duration?: number | null, difficulty?: string | null, imageUrl?: string | null, parentId: string, ownerAvatar?: string | null, coinsAreActivated: boolean, maxCoinsReward: number, myRating?: { __typename?: 'CourseParentRating', rating: number, comment?: string | null } | null, currentSession?: { __typename?: 'CourseModuleSession', id: string, endedAt?: any | null, coinsWereActivated: boolean, chapterSessions: Array<{ __typename?: 'CourseChapterSession', id: string, courseChapterId: string, pointsEarned: number, startedAt?: any | null, completedAt?: any | null, blocksCompleted: Array<string>, numberOfRequiredBlocksCompleted: number, quizSession: Array<{ __typename?: 'AnsweredQuestion', id: string, questionId?: string | null, correctAnswersId: Array<string>, incorrectAnswersId: Array<string> }> }> } | null, chapters: Array<{ __typename?: 'CourseChapter', id: string, title: string, type: string, order: number, numberOfRequiredBlocks: number, blocks: Array<{ __typename?: 'CourseBlock', id: string, type: string, isHidden: boolean, content: string }>, quiz?: { __typename?: 'Quiz', id: string, questionCount: number } | null }> } | null };

export type CourseModulesQueryVariables = Exact<{
  courseParentId: Scalars['String'];
}>;


export type CourseModulesQuery = { __typename?: 'Query', courseModules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }> };

export type CourseParentQueryVariables = Exact<{
  courseParentId: Scalars['String'];
}>;


export type CourseParentQuery = { __typename?: 'Query', appCourseParent?: { __typename?: 'CourseParent', id: string, title: string, createdAt: any, description?: string | null, imageUrl?: string | null, modulesCount: number, startedModulesCount: number, completedModulesCount: number, status: string, type: string, difficulty: Difficulty, duration: number, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, modules: Array<{ __typename?: 'CourseModule', id: string, parentId: string, createdAt: any, updatedAt: any, title: string, imageUrl?: string | null, description?: string | null, order: number, normalizedOrder: number, difficulty?: string | null, duration?: number | null, isStarted: boolean, isCompleted: boolean, percentageCompleted?: number | null, coinsAreActivated: boolean, maxCoinsReward: number, totalCoinsEarned: number, currentSession?: { __typename?: 'CourseModuleSession', id: string } | null }>, owner: { __typename?: 'Company', id: string, avatarUrl?: string | null } } | null };

export type GlobalSettingsFieldsFragment = { __typename?: 'GlobalSettings', id: number, troubleshootingUrl?: string | null, supportFormUrl?: string | null, shopIsEnabled?: boolean | null, lotteryIsEnabled?: boolean | null };

export type GlobalSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalSettingsQuery = { __typename?: 'Query', globalSettings?: { __typename?: 'GlobalSettings', id: number, troubleshootingUrl?: string | null, supportFormUrl?: string | null, shopIsEnabled?: boolean | null, lotteryIsEnabled?: boolean | null } | null };

export type LotteryFieldsFragment = { __typename?: 'Lottery', id: number, title: string, description?: string | null, imageUrl: string, startDate?: any | null, endDate?: any | null, isExpired: boolean, hasBeenDrawn: boolean, userTicketsConsumed: number, userWinnings: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }>, prizes: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }> };

export type PrizeFieldsFragment = { __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null };

export type ActiveLotteryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type ActiveLotteryQuery = { __typename?: 'Query', activeLottery?: { __typename?: 'Lottery', id: number, title: string, description?: string | null, imageUrl: string, startDate?: any | null, endDate?: any | null, isExpired: boolean, hasBeenDrawn: boolean, userTicketsConsumed: number, userWinnings: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }>, prizes: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }> } | null };

export type LotteryQueryVariables = Exact<{
  id: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
}>;


export type LotteryQuery = { __typename?: 'Query', lottery?: { __typename?: 'Lottery', id: number, title: string, description?: string | null, imageUrl: string, startDate?: any | null, endDate?: any | null, isExpired: boolean, hasBeenDrawn: boolean, userTicketsConsumed: number, userWinnings: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }>, prizes: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }> } | null };

export type EndedLotteriesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type EndedLotteriesQuery = { __typename?: 'Query', endedLotteries: Array<{ __typename?: 'Lottery', id: number, title: string, description?: string | null, imageUrl: string, startDate?: any | null, endDate?: any | null, isExpired: boolean, hasBeenDrawn: boolean, userTicketsConsumed: number, userWinnings: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }>, prizes: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }> }> };

export type PrizeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PrizeQuery = { __typename?: 'Query', prize?: { __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null, relatedPrizes: Array<{ __typename?: 'Prize', id: number, prizeTier: number, title: string, description: string, imageUrl: string, lotteryId?: number | null }> } | null };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPayload', count: number, list: Array<{ __typename?: 'Product', description: string, id: number, imageUrl?: string | null, name: string, price: number, stock: number }> } };

export type ProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename: 'ProductNotFoundError', message: string } | { __typename: 'QueryProductSuccess', data: { __typename?: 'Product', description: string, id: number, imageUrl?: string | null, name: string, price: number, stock: number } } };

export type PurchasedUrlProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchasedUrlProductsQuery = { __typename?: 'Query', purchasedUrlProducts: { __typename?: 'URLProductsPayload', count: number, list: Array<{ __typename?: 'URLProduct', code?: string | null, expiresAt?: any | null, id: string, purchasedAt?: any | null, url: string, product: { __typename?: 'Product', description: string, id: number, imageUrl?: string | null, name: string, price: number, stock: number, activeTo?: any | null } }> } };

export type PurchasedUrlProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PurchasedUrlProductQuery = { __typename?: 'Query', purchasedUrlProduct: { __typename: 'ProductNotFoundError', message: string } | { __typename: 'QueryPurchasedUrlProductSuccess', data: { __typename?: 'URLProduct', code?: string | null, expiresAt?: any | null, id: string, purchasedAt?: any | null, url: string, product: { __typename?: 'Product', description: string, id: number, imageUrl?: string | null, name: string, price: number, stock: number, activeTo?: any | null } } } };

export type CourseParentProgressSummaryQueryVariables = Exact<{
  userId: Scalars['String'];
  courseParentId: Scalars['String'];
}>;


export type CourseParentProgressSummaryQuery = { __typename?: 'Query', courseParentProgressSummary: { __typename?: 'CourseParentProgressSummaryObject', isCompleted: boolean, numberOfModulesInCourseParent: number, numberOfModulesCompleted: number, totalCoinsEarned: number, maxCoins: number, nextIncompleteModuleId?: string | null, ticketsEarned: number, maxTickets: number } };

export type CourseModuleProgressSummaryQueryVariables = Exact<{
  moduleId: Scalars['String'];
}>;


export type CourseModuleProgressSummaryQuery = { __typename?: 'Query', courseModuleProgressSummary: { __typename?: 'CourseModuleProgressSummary', coinsEarnedOnModule: number, maxCoinsOnModule: number, ticketsEarned: number, maxTicketsOnModule: number } };

export type UserFieldsFragment = { __typename?: 'User', id: string, email: string, avatarUrl?: string | null, language: string, phone?: string | null, fullName?: string | null, firstName?: string | null, lastName?: string | null, roles: Array<string>, pushToken?: string | null, onBoardingCompleted: boolean, company: { __typename?: 'Company', id: string, defaultLanguage: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, avatarUrl?: string | null, language: string, phone?: string | null, fullName?: string | null, firstName?: string | null, lastName?: string | null, roles: Array<string>, pushToken?: string | null, onBoardingCompleted: boolean, company: { __typename?: 'Company', id: string, defaultLanguage: string } } | null };

export type CoinAccountSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type CoinAccountSummaryQuery = { __typename?: 'Query', coinAccountSummary: { __typename?: 'CoinAccountSummary', balance: number, totalEarned: number, totalSpent: number } };

export type UserTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTicketsQuery = { __typename?: 'Query', userTickets: { __typename?: 'CoinAccountSummary', balance: number, totalEarned: number, totalSpent: number } };

export const ChapterSessionFieldsFragmentDoc = `
    fragment ChapterSessionFields on CourseChapterSession {
  id
  courseChapterId
  pointsEarned
  startedAt
  completedAt
  blocksCompleted
  quizSession {
    id
    questionId
    correctAnswersId
    incorrectAnswersId
  }
  numberOfRequiredBlocksCompleted
}
    `;
export const AnsweredQuestionFieldsFragmentDoc = `
    fragment AnsweredQuestionFields on AnsweredQuestion {
  id
  questionId
  correctAnswersId
  incorrectAnswersId
}
    `;
export const CourseModuleProgressionFieldsFragmentDoc = `
    fragment CourseModuleProgressionFields on CourseModuleProgression {
  id
  isCompleted
}
    `;
export const UpdateProfileFieldsFragmentDoc = `
    fragment updateProfileFields on MutationUpdateProfileResult {
  __typename
  ... on ZodError {
    message
  }
  ... on MutationUpdateProfileSuccess {
    data {
      id
      firstName
      lastName
      fullName
      avatarUrl
      phone
      email
    }
  }
}
    `;
export const CourseModuleListFieldsFragmentDoc = `
    fragment CourseModuleListFields on CourseModule {
  id
  parentId
  createdAt
  updatedAt
  title
  imageUrl
  description
  order
  normalizedOrder
  difficulty
  duration
  isStarted
  isCompleted
  percentageCompleted
  coinsAreActivated
  maxCoinsReward
  totalCoinsEarned
  currentSession {
    id
  }
}
    `;
export const CourseParentListFieldsFragmentDoc = `
    fragment courseParentListFields on CourseParent {
  id
  title
  createdAt
  description
  imageUrl
  modulesCount
  startedModulesCount
  completedModulesCount
  status
  type
  difficulty
  duration
  coinsAreActivated
  maxCoinsReward
  totalCoinsEarned
  modules {
    ...CourseModuleListFields
  }
  owner {
    id
    avatarUrl
  }
}
    ${CourseModuleListFieldsFragmentDoc}`;
export const GlobalSettingsFieldsFragmentDoc = `
    fragment GlobalSettingsFields on GlobalSettings {
  id
  troubleshootingUrl
  supportFormUrl
  shopIsEnabled
  lotteryIsEnabled
}
    `;
export const PrizeFieldsFragmentDoc = `
    fragment PrizeFields on Prize {
  id
  prizeTier
  title
  description
  imageUrl
  lotteryId
}
    `;
export const LotteryFieldsFragmentDoc = `
    fragment LotteryFields on Lottery {
  id
  title
  description
  imageUrl
  startDate
  endDate
  isExpired
  userWinnings(userId: $userId) {
    ...PrizeFields
  }
  prizes {
    ...PrizeFields
  }
  hasBeenDrawn
  userTicketsConsumed(userId: $userId)
}
    ${PrizeFieldsFragmentDoc}`;
export const UserFieldsFragmentDoc = `
    fragment userFields on User {
  id
  email
  avatarUrl
  language
  phone
  fullName
  firstName
  lastName
  roles
  pushToken
  onBoardingCompleted
  company {
    id
    defaultLanguage
  }
}
    `;
export const CreateCourseModuleProgressionDocument = `
    mutation CreateCourseModuleProgression($moduleId: String!) {
  createCourseModuleProgression(moduleId: $moduleId) {
    ...CourseModuleProgressionFields
  }
}
    ${CourseModuleProgressionFieldsFragmentDoc}`;
export const useCreateCourseModuleProgressionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCourseModuleProgressionMutation, TError, CreateCourseModuleProgressionMutationVariables, TContext>) =>
    useMutation<CreateCourseModuleProgressionMutation, TError, CreateCourseModuleProgressionMutationVariables, TContext>(
      ['CreateCourseModuleProgression'],
      useFetchData<CreateCourseModuleProgressionMutation, CreateCourseModuleProgressionMutationVariables>(CreateCourseModuleProgressionDocument),
      options
    );
export const OpenChapterDocument = `
    mutation OpenChapter($moduleId: String!, $chapterId: String!) {
  openChapter(moduleId: $moduleId, chapterId: $chapterId) {
    ...ChapterSessionFields
  }
}
    ${ChapterSessionFieldsFragmentDoc}`;
export const useOpenChapterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OpenChapterMutation, TError, OpenChapterMutationVariables, TContext>) =>
    useMutation<OpenChapterMutation, TError, OpenChapterMutationVariables, TContext>(
      ['OpenChapter'],
      useFetchData<OpenChapterMutation, OpenChapterMutationVariables>(OpenChapterDocument),
      options
    );
export const CompleteCourseBlockDocument = `
    mutation CompleteCourseBlock($moduleId: String!, $chapterId: String!, $blockId: String!) {
  completeCourseBlock(
    moduleId: $moduleId
    chapterId: $chapterId
    blockId: $blockId
  ) {
    ...ChapterSessionFields
  }
}
    ${ChapterSessionFieldsFragmentDoc}`;
export const useCompleteCourseBlockMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompleteCourseBlockMutation, TError, CompleteCourseBlockMutationVariables, TContext>) =>
    useMutation<CompleteCourseBlockMutation, TError, CompleteCourseBlockMutationVariables, TContext>(
      ['CompleteCourseBlock'],
      useFetchData<CompleteCourseBlockMutation, CompleteCourseBlockMutationVariables>(CompleteCourseBlockDocument),
      options
    );
export const AnswerQuizQuestionDocument = `
    mutation AnswerQuizQuestion($moduleId: String!, $chapterId: String!, $questionId: String!, $answerIds: [String!]!) {
  answerQuizQuestion(
    moduleId: $moduleId
    chapterId: $chapterId
    questionId: $questionId
    answerIds: $answerIds
  ) {
    ...AnsweredQuestionFields
  }
}
    ${AnsweredQuestionFieldsFragmentDoc}`;
export const useAnswerQuizQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AnswerQuizQuestionMutation, TError, AnswerQuizQuestionMutationVariables, TContext>) =>
    useMutation<AnswerQuizQuestionMutation, TError, AnswerQuizQuestionMutationVariables, TContext>(
      ['AnswerQuizQuestion'],
      useFetchData<AnswerQuizQuestionMutation, AnswerQuizQuestionMutationVariables>(AnswerQuizQuestionDocument),
      options
    );
export const RestartQuizDocument = `
    mutation RestartQuiz($moduleId: String!, $chapterId: String!) {
  restartQuiz(moduleId: $moduleId, chapterId: $chapterId) {
    ...ChapterSessionFields
  }
}
    ${ChapterSessionFieldsFragmentDoc}`;
export const useRestartQuizMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RestartQuizMutation, TError, RestartQuizMutationVariables, TContext>) =>
    useMutation<RestartQuizMutation, TError, RestartQuizMutationVariables, TContext>(
      ['RestartQuiz'],
      useFetchData<RestartQuizMutation, RestartQuizMutationVariables>(RestartQuizDocument),
      options
    );
export const EndCourseModuleDocument = `
    mutation EndCourseModule($moduleId: String!) {
  endCourse(moduleId: $moduleId) {
    coinsEarnedInSession
    totalCoinsEarned
    coinsWereActivated
    completesCourseParent
    ticketsEarned
  }
}
    `;
export const useEndCourseModuleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EndCourseModuleMutation, TError, EndCourseModuleMutationVariables, TContext>) =>
    useMutation<EndCourseModuleMutation, TError, EndCourseModuleMutationVariables, TContext>(
      ['EndCourseModule'],
      useFetchData<EndCourseModuleMutation, EndCourseModuleMutationVariables>(EndCourseModuleDocument),
      options
    );
export const RateCourseParentDocument = `
    mutation RateCourseParent($courseParentId: String!, $rating: Int!, $comment: String) {
  rateCourseParent(
    courseParentId: $courseParentId
    rating: $rating
    comment: $comment
  ) {
    ...courseParentListFields
  }
}
    ${CourseParentListFieldsFragmentDoc}`;
export const useRateCourseParentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RateCourseParentMutation, TError, RateCourseParentMutationVariables, TContext>) =>
    useMutation<RateCourseParentMutation, TError, RateCourseParentMutationVariables, TContext>(
      ['RateCourseParent'],
      useFetchData<RateCourseParentMutation, RateCourseParentMutationVariables>(RateCourseParentDocument),
      options
    );
export const RestartCourseModuleDocument = `
    mutation RestartCourseModule($moduleId: String!) {
  restartCourseModule(moduleId: $moduleId) {
    ...CourseModuleProgressionFields
  }
}
    ${CourseModuleProgressionFieldsFragmentDoc}`;
export const useRestartCourseModuleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RestartCourseModuleMutation, TError, RestartCourseModuleMutationVariables, TContext>) =>
    useMutation<RestartCourseModuleMutation, TError, RestartCourseModuleMutationVariables, TContext>(
      ['RestartCourseModule'],
      useFetchData<RestartCourseModuleMutation, RestartCourseModuleMutationVariables>(RestartCourseModuleDocument),
      options
    );
export const PurchaseUrlProductDocument = `
    mutation PurchaseUrlProduct($productId: Int!) {
  purchaseUrlProduct(productId: $productId) {
    ... on MutationPurchaseUrlProductSuccess {
      __typename
      data {
        urlProductId
      }
    }
    ... on NotEnoughCoinsError {
      __typename
      message
    }
  }
}
    `;
export const usePurchaseUrlProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PurchaseUrlProductMutation, TError, PurchaseUrlProductMutationVariables, TContext>) =>
    useMutation<PurchaseUrlProductMutation, TError, PurchaseUrlProductMutationVariables, TContext>(
      ['PurchaseUrlProduct'],
      useFetchData<PurchaseUrlProductMutation, PurchaseUrlProductMutationVariables>(PurchaseUrlProductDocument),
      options
    );
export const LoginDocument = `
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    __typename
    ... on UserNotFoundError {
      message
    }
    ... on UserWithoutCompanyError {
      message
    }
    ... on InvalidUsernamePasswordCombinationError {
      message
    }
    ... on MutationLoginSuccess {
      data {
        token
        refreshToken
        user {
          ...userFields
        }
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['login'],
      useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
      options
    );
export const RefreshCredentialsDocument = `
    mutation refreshCredentials($refreshToken: String!) {
  refreshCredentials(refreshToken: $refreshToken) {
    token
    refreshToken
    user {
      ...userFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export const useRefreshCredentialsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RefreshCredentialsMutation, TError, RefreshCredentialsMutationVariables, TContext>) =>
    useMutation<RefreshCredentialsMutation, TError, RefreshCredentialsMutationVariables, TContext>(
      ['refreshCredentials'],
      useFetchData<RefreshCredentialsMutation, RefreshCredentialsMutationVariables>(RefreshCredentialsDocument),
      options
    );
export const ChangePasswordDocument = `
    mutation changePassword($currentPassword: String!, $newPassword: String!, $confirmNewPassword: String!) {
  changePassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
    confirmNewPassword: $confirmNewPassword
  ) {
    __typename
    ... on NewPasswordsDoNotMatchError {
      message
    }
    ... on UserNotFoundError {
      message
    }
    ... on InvalidPasswordError {
      message
    }
    ... on IncorrectPasswordError {
      message
    }
    ... on MutationChangePasswordSuccess {
      data {
        type
        message
      }
    }
  }
}
    `;
export const useChangePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>) =>
    useMutation<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>(
      ['changePassword'],
      useFetchData<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument),
      options
    );
export const UpdateUserLanguageDocument = `
    mutation updateUserLanguage($language: String!) {
  updateUserLanguage(language: $language) {
    id
    language
  }
}
    `;
export const useUpdateUserLanguageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserLanguageMutation, TError, UpdateUserLanguageMutationVariables, TContext>) =>
    useMutation<UpdateUserLanguageMutation, TError, UpdateUserLanguageMutationVariables, TContext>(
      ['updateUserLanguage'],
      useFetchData<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument),
      options
    );
export const UpdateProfileDocument = `
    mutation updateProfile($firstName: String, $lastName: String, $phone: String, $avatarUrl: String) {
  updateProfile(
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    avatarUrl: $avatarUrl
  ) {
    ...updateProfileFields
  }
}
    ${UpdateProfileFieldsFragmentDoc}`;
export const useUpdateProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>) =>
    useMutation<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>(
      ['updateProfile'],
      useFetchData<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument),
      options
    );
export const RequestResetPasswordDocument = `
    mutation requestResetPassword($email: String!) {
  requestResetPassword(email: $email) {
    __typename
    ... on InvalidEmailError {
      message
    }
    ... on UserNotFoundError {
      message
    }
    ... on MutationRequestResetPasswordSuccess {
      data {
        type
        message
      }
    }
  }
}
    `;
export const useRequestResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestResetPasswordMutation, TError, RequestResetPasswordMutationVariables, TContext>) =>
    useMutation<RequestResetPasswordMutation, TError, RequestResetPasswordMutationVariables, TContext>(
      ['requestResetPassword'],
      useFetchData<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument),
      options
    );
export const SetOnboardingCompletedDocument = `
    mutation SetOnboardingCompleted($completed: Boolean!) {
  setOnboardingCompleted(completed: $completed)
}
    `;
export const useSetOnboardingCompletedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetOnboardingCompletedMutation, TError, SetOnboardingCompletedMutationVariables, TContext>) =>
    useMutation<SetOnboardingCompletedMutation, TError, SetOnboardingCompletedMutationVariables, TContext>(
      ['SetOnboardingCompleted'],
      useFetchData<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>(SetOnboardingCompletedDocument),
      options
    );
export const UpdatePushTokenDocument = `
    mutation updatePushToken($token: String) {
  updatePushToken(token: $token) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const useUpdatePushTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePushTokenMutation, TError, UpdatePushTokenMutationVariables, TContext>) =>
    useMutation<UpdatePushTokenMutation, TError, UpdatePushTokenMutationVariables, TContext>(
      ['updatePushToken'],
      useFetchData<UpdatePushTokenMutation, UpdatePushTokenMutationVariables>(UpdatePushTokenDocument),
      options
    );
export const TestPushNotificationDocument = `
    mutation testPushNotification {
  testPushNotification
}
    `;
export const useTestPushNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TestPushNotificationMutation, TError, TestPushNotificationMutationVariables, TContext>) =>
    useMutation<TestPushNotificationMutation, TError, TestPushNotificationMutationVariables, TContext>(
      ['testPushNotification'],
      useFetchData<TestPushNotificationMutation, TestPushNotificationMutationVariables>(TestPushNotificationDocument),
      options
    );
export const CurrentCompanyDocument = `
    query currentCompany {
  currentCompany {
    id
    name
    avatarUrl
    defaultLanguage
    inviteLink
    parentCompanies {
      id
      name
    }
    employees {
      id
      avatarUrl
      firstName
      lastName
      fullName
    }
  }
}
    `;
export const useCurrentCompanyQuery = <
      TData = CurrentCompanyQuery,
      TError = unknown
    >(
      variables?: CurrentCompanyQueryVariables,
      options?: UseQueryOptions<CurrentCompanyQuery, TError, TData>
    ) =>
    useQuery<CurrentCompanyQuery, TError, TData>(
      variables === undefined ? ['currentCompany'] : ['currentCompany', variables],
      useFetchData<CurrentCompanyQuery, CurrentCompanyQueryVariables>(CurrentCompanyDocument).bind(null, variables),
      options
    );
export const AppCompanyCourseFiltersDocument = `
    query AppCompanyCourseFilters {
  appCompanyCourseFilters {
    company {
      id
      name
      avatarUrl
    }
    courseCount
  }
}
    `;
export const useAppCompanyCourseFiltersQuery = <
      TData = AppCompanyCourseFiltersQuery,
      TError = unknown
    >(
      variables?: AppCompanyCourseFiltersQueryVariables,
      options?: UseQueryOptions<AppCompanyCourseFiltersQuery, TError, TData>
    ) =>
    useQuery<AppCompanyCourseFiltersQuery, TError, TData>(
      variables === undefined ? ['AppCompanyCourseFilters'] : ['AppCompanyCourseFilters', variables],
      useFetchData<AppCompanyCourseFiltersQuery, AppCompanyCourseFiltersQueryVariables>(AppCompanyCourseFiltersDocument).bind(null, variables),
      options
    );
export const AppCourseParentsDocument = `
    query AppCourseParents($skip: Int, $take: Int, $companies: [String!], $type: CourseType, $status: CourseStatus) {
  appCourseParents(
    skip: $skip
    take: $take
    companies: $companies
    type: $type
    status: $status
  ) {
    count
    list {
      ...courseParentListFields
    }
  }
}
    ${CourseParentListFieldsFragmentDoc}`;
export const useAppCourseParentsQuery = <
      TData = AppCourseParentsQuery,
      TError = unknown
    >(
      variables?: AppCourseParentsQueryVariables,
      options?: UseQueryOptions<AppCourseParentsQuery, TError, TData>
    ) =>
    useQuery<AppCourseParentsQuery, TError, TData>(
      variables === undefined ? ['AppCourseParents'] : ['AppCourseParents', variables],
      useFetchData<AppCourseParentsQuery, AppCourseParentsQueryVariables>(AppCourseParentsDocument).bind(null, variables),
      options
    );
export const StartedCourseParentsDocument = `
    query StartedCourseParents {
  startedCourseParents {
    count
    list {
      ...courseParentListFields
    }
  }
}
    ${CourseParentListFieldsFragmentDoc}`;
export const useStartedCourseParentsQuery = <
      TData = StartedCourseParentsQuery,
      TError = unknown
    >(
      variables?: StartedCourseParentsQueryVariables,
      options?: UseQueryOptions<StartedCourseParentsQuery, TError, TData>
    ) =>
    useQuery<StartedCourseParentsQuery, TError, TData>(
      variables === undefined ? ['StartedCourseParents'] : ['StartedCourseParents', variables],
      useFetchData<StartedCourseParentsQuery, StartedCourseParentsQueryVariables>(StartedCourseParentsDocument).bind(null, variables),
      options
    );
export const QuizDocument = `
    query Quiz($id: String!) {
  quiz(id: $id) {
    title
    description
    imageUrl
    questions {
      id
      question
      imageUrl
      answersType
      textAnswers {
        id
        answer
        isCorrect
        answerFeedback
      }
      imageAnswers {
        id
        imageUrl
        isCorrect
        answerFeedback
      }
    }
  }
}
    `;
export const useQuizQuery = <
      TData = QuizQuery,
      TError = unknown
    >(
      variables: QuizQueryVariables,
      options?: UseQueryOptions<QuizQuery, TError, TData>
    ) =>
    useQuery<QuizQuery, TError, TData>(
      ['Quiz', variables],
      useFetchData<QuizQuery, QuizQueryVariables>(QuizDocument).bind(null, variables),
      options
    );
export const CourseModuleDocument = `
    query CourseModule($courseModuleId: String!) {
  courseModule(courseModuleId: $courseModuleId) {
    id
    title
    order
    normalizedOrder
    description
    duration
    difficulty
    imageUrl
    parentId
    ownerAvatar
    coinsAreActivated
    maxCoinsReward
    parentId
    myRating {
      rating
      comment
    }
    currentSession {
      id
      endedAt
      coinsWereActivated
      chapterSessions {
        ...ChapterSessionFields
      }
    }
    chapters {
      id
      title
      type
      order
      numberOfRequiredBlocks
      blocks {
        id
        type
        isHidden
        content
      }
      quiz {
        id
        questionCount
      }
    }
  }
}
    ${ChapterSessionFieldsFragmentDoc}`;
export const useCourseModuleQuery = <
      TData = CourseModuleQuery,
      TError = unknown
    >(
      variables: CourseModuleQueryVariables,
      options?: UseQueryOptions<CourseModuleQuery, TError, TData>
    ) =>
    useQuery<CourseModuleQuery, TError, TData>(
      ['CourseModule', variables],
      useFetchData<CourseModuleQuery, CourseModuleQueryVariables>(CourseModuleDocument).bind(null, variables),
      options
    );
export const CourseModulesDocument = `
    query CourseModules($courseParentId: String!) {
  courseModules(courseParentId: $courseParentId) {
    ...CourseModuleListFields
  }
}
    ${CourseModuleListFieldsFragmentDoc}`;
export const useCourseModulesQuery = <
      TData = CourseModulesQuery,
      TError = unknown
    >(
      variables: CourseModulesQueryVariables,
      options?: UseQueryOptions<CourseModulesQuery, TError, TData>
    ) =>
    useQuery<CourseModulesQuery, TError, TData>(
      ['CourseModules', variables],
      useFetchData<CourseModulesQuery, CourseModulesQueryVariables>(CourseModulesDocument).bind(null, variables),
      options
    );
export const CourseParentDocument = `
    query CourseParent($courseParentId: String!) {
  appCourseParent(courseParentId: $courseParentId) {
    ...courseParentListFields
  }
}
    ${CourseParentListFieldsFragmentDoc}`;
export const useCourseParentQuery = <
      TData = CourseParentQuery,
      TError = unknown
    >(
      variables: CourseParentQueryVariables,
      options?: UseQueryOptions<CourseParentQuery, TError, TData>
    ) =>
    useQuery<CourseParentQuery, TError, TData>(
      ['CourseParent', variables],
      useFetchData<CourseParentQuery, CourseParentQueryVariables>(CourseParentDocument).bind(null, variables),
      options
    );
export const GlobalSettingsDocument = `
    query globalSettings {
  globalSettings {
    ...GlobalSettingsFields
  }
}
    ${GlobalSettingsFieldsFragmentDoc}`;
export const useGlobalSettingsQuery = <
      TData = GlobalSettingsQuery,
      TError = unknown
    >(
      variables?: GlobalSettingsQueryVariables,
      options?: UseQueryOptions<GlobalSettingsQuery, TError, TData>
    ) =>
    useQuery<GlobalSettingsQuery, TError, TData>(
      variables === undefined ? ['globalSettings'] : ['globalSettings', variables],
      useFetchData<GlobalSettingsQuery, GlobalSettingsQueryVariables>(GlobalSettingsDocument).bind(null, variables),
      options
    );
export const ActiveLotteryDocument = `
    query ActiveLottery($userId: String) {
  activeLottery(userId: $userId) {
    ...LotteryFields
  }
}
    ${LotteryFieldsFragmentDoc}`;
export const useActiveLotteryQuery = <
      TData = ActiveLotteryQuery,
      TError = unknown
    >(
      variables?: ActiveLotteryQueryVariables,
      options?: UseQueryOptions<ActiveLotteryQuery, TError, TData>
    ) =>
    useQuery<ActiveLotteryQuery, TError, TData>(
      variables === undefined ? ['ActiveLottery'] : ['ActiveLottery', variables],
      useFetchData<ActiveLotteryQuery, ActiveLotteryQueryVariables>(ActiveLotteryDocument).bind(null, variables),
      options
    );
export const LotteryDocument = `
    query Lottery($id: Int!, $userId: String) {
  lottery(id: $id) {
    ...LotteryFields
  }
}
    ${LotteryFieldsFragmentDoc}`;
export const useLotteryQuery = <
      TData = LotteryQuery,
      TError = unknown
    >(
      variables: LotteryQueryVariables,
      options?: UseQueryOptions<LotteryQuery, TError, TData>
    ) =>
    useQuery<LotteryQuery, TError, TData>(
      ['Lottery', variables],
      useFetchData<LotteryQuery, LotteryQueryVariables>(LotteryDocument).bind(null, variables),
      options
    );
export const EndedLotteriesDocument = `
    query EndedLotteries($userId: String) {
  endedLotteries(userId: $userId) {
    ...LotteryFields
  }
}
    ${LotteryFieldsFragmentDoc}`;
export const useEndedLotteriesQuery = <
      TData = EndedLotteriesQuery,
      TError = unknown
    >(
      variables?: EndedLotteriesQueryVariables,
      options?: UseQueryOptions<EndedLotteriesQuery, TError, TData>
    ) =>
    useQuery<EndedLotteriesQuery, TError, TData>(
      variables === undefined ? ['EndedLotteries'] : ['EndedLotteries', variables],
      useFetchData<EndedLotteriesQuery, EndedLotteriesQueryVariables>(EndedLotteriesDocument).bind(null, variables),
      options
    );
export const PrizeDocument = `
    query Prize($id: Int!) {
  prize(id: $id) {
    ...PrizeFields
    relatedPrizes {
      ...PrizeFields
    }
  }
}
    ${PrizeFieldsFragmentDoc}`;
export const usePrizeQuery = <
      TData = PrizeQuery,
      TError = unknown
    >(
      variables: PrizeQueryVariables,
      options?: UseQueryOptions<PrizeQuery, TError, TData>
    ) =>
    useQuery<PrizeQuery, TError, TData>(
      ['Prize', variables],
      useFetchData<PrizeQuery, PrizeQueryVariables>(PrizeDocument).bind(null, variables),
      options
    );
export const ProductsDocument = `
    query Products {
  products {
    list {
      description
      id
      imageUrl
      name
      price
      stock
    }
    count
  }
}
    `;
export const useProductsQuery = <
      TData = ProductsQuery,
      TError = unknown
    >(
      variables?: ProductsQueryVariables,
      options?: UseQueryOptions<ProductsQuery, TError, TData>
    ) =>
    useQuery<ProductsQuery, TError, TData>(
      variables === undefined ? ['Products'] : ['Products', variables],
      useFetchData<ProductsQuery, ProductsQueryVariables>(ProductsDocument).bind(null, variables),
      options
    );
export const ProductDocument = `
    query Product($id: Int!) {
  product(id: $id) {
    ... on ProductNotFoundError {
      __typename
      message
    }
    ... on QueryProductSuccess {
      __typename
      data {
        description
        id
        imageUrl
        name
        price
        stock
      }
    }
  }
}
    `;
export const useProductQuery = <
      TData = ProductQuery,
      TError = unknown
    >(
      variables: ProductQueryVariables,
      options?: UseQueryOptions<ProductQuery, TError, TData>
    ) =>
    useQuery<ProductQuery, TError, TData>(
      ['Product', variables],
      useFetchData<ProductQuery, ProductQueryVariables>(ProductDocument).bind(null, variables),
      options
    );
export const PurchasedUrlProductsDocument = `
    query PurchasedUrlProducts {
  purchasedUrlProducts {
    list {
      code
      expiresAt
      id
      purchasedAt
      url
      product {
        description
        id
        imageUrl
        name
        price
        stock
        activeTo
      }
    }
    count
  }
}
    `;
export const usePurchasedUrlProductsQuery = <
      TData = PurchasedUrlProductsQuery,
      TError = unknown
    >(
      variables?: PurchasedUrlProductsQueryVariables,
      options?: UseQueryOptions<PurchasedUrlProductsQuery, TError, TData>
    ) =>
    useQuery<PurchasedUrlProductsQuery, TError, TData>(
      variables === undefined ? ['PurchasedUrlProducts'] : ['PurchasedUrlProducts', variables],
      useFetchData<PurchasedUrlProductsQuery, PurchasedUrlProductsQueryVariables>(PurchasedUrlProductsDocument).bind(null, variables),
      options
    );
export const PurchasedUrlProductDocument = `
    query PurchasedUrlProduct($id: String!) {
  purchasedUrlProduct(id: $id) {
    ... on QueryPurchasedUrlProductSuccess {
      __typename
      data {
        code
        expiresAt
        id
        purchasedAt
        url
        product {
          description
          id
          imageUrl
          name
          price
          stock
          activeTo
        }
      }
    }
    ... on ProductNotFoundError {
      __typename
      message
    }
  }
}
    `;
export const usePurchasedUrlProductQuery = <
      TData = PurchasedUrlProductQuery,
      TError = unknown
    >(
      variables: PurchasedUrlProductQueryVariables,
      options?: UseQueryOptions<PurchasedUrlProductQuery, TError, TData>
    ) =>
    useQuery<PurchasedUrlProductQuery, TError, TData>(
      ['PurchasedUrlProduct', variables],
      useFetchData<PurchasedUrlProductQuery, PurchasedUrlProductQueryVariables>(PurchasedUrlProductDocument).bind(null, variables),
      options
    );
export const CourseParentProgressSummaryDocument = `
    query CourseParentProgressSummary($userId: String!, $courseParentId: String!) {
  courseParentProgressSummary(userId: $userId, courseParentId: $courseParentId) {
    isCompleted
    numberOfModulesInCourseParent
    numberOfModulesCompleted
    totalCoinsEarned
    maxCoins
    nextIncompleteModuleId
    ticketsEarned
    maxTickets
  }
}
    `;
export const useCourseParentProgressSummaryQuery = <
      TData = CourseParentProgressSummaryQuery,
      TError = unknown
    >(
      variables: CourseParentProgressSummaryQueryVariables,
      options?: UseQueryOptions<CourseParentProgressSummaryQuery, TError, TData>
    ) =>
    useQuery<CourseParentProgressSummaryQuery, TError, TData>(
      ['CourseParentProgressSummary', variables],
      useFetchData<CourseParentProgressSummaryQuery, CourseParentProgressSummaryQueryVariables>(CourseParentProgressSummaryDocument).bind(null, variables),
      options
    );
export const CourseModuleProgressSummaryDocument = `
    query CourseModuleProgressSummary($moduleId: String!) {
  courseModuleProgressSummary(moduleId: $moduleId) {
    coinsEarnedOnModule
    maxCoinsOnModule
    ticketsEarned
    maxTicketsOnModule
  }
}
    `;
export const useCourseModuleProgressSummaryQuery = <
      TData = CourseModuleProgressSummaryQuery,
      TError = unknown
    >(
      variables: CourseModuleProgressSummaryQueryVariables,
      options?: UseQueryOptions<CourseModuleProgressSummaryQuery, TError, TData>
    ) =>
    useQuery<CourseModuleProgressSummaryQuery, TError, TData>(
      ['CourseModuleProgressSummary', variables],
      useFetchData<CourseModuleProgressSummaryQuery, CourseModuleProgressSummaryQueryVariables>(CourseModuleProgressSummaryDocument).bind(null, variables),
      options
    );
export const MeDocument = `
    query me {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['me'] : ['me', variables],
      useFetchData<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
      options
    );
export const CoinAccountSummaryDocument = `
    query CoinAccountSummary {
  coinAccountSummary {
    balance
    totalEarned
    totalSpent
  }
}
    `;
export const useCoinAccountSummaryQuery = <
      TData = CoinAccountSummaryQuery,
      TError = unknown
    >(
      variables?: CoinAccountSummaryQueryVariables,
      options?: UseQueryOptions<CoinAccountSummaryQuery, TError, TData>
    ) =>
    useQuery<CoinAccountSummaryQuery, TError, TData>(
      variables === undefined ? ['CoinAccountSummary'] : ['CoinAccountSummary', variables],
      useFetchData<CoinAccountSummaryQuery, CoinAccountSummaryQueryVariables>(CoinAccountSummaryDocument).bind(null, variables),
      options
    );
export const UserTicketsDocument = `
    query UserTickets {
  userTickets {
    balance
    totalEarned
    totalSpent
  }
}
    `;
export const useUserTicketsQuery = <
      TData = UserTicketsQuery,
      TError = unknown
    >(
      variables?: UserTicketsQueryVariables,
      options?: UseQueryOptions<UserTicketsQuery, TError, TData>
    ) =>
    useQuery<UserTicketsQuery, TError, TData>(
      variables === undefined ? ['UserTickets'] : ['UserTickets', variables],
      useFetchData<UserTicketsQuery, UserTicketsQueryVariables>(UserTicketsDocument).bind(null, variables),
      options
    );