import { Add } from 'iconsax-react-native'
import React, { createContext, ReactNode, useMemo, useState } from 'react'
import { Image, Platform, ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native'
import { Portal } from '../contexts/PortalContext'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useMeQuery, useSetOnboardingCompletedMutation } from '../generated/graphql'
import { useStyles } from '../hooks/useStyles'
import Button from './Button'
import Container from './Container'
import IconButton from './IconButton'
import ResponsiveImage from './ResponsiveImage'
import SafeAreaView from './SafeAreaView'
import Stack from './Stack'
import Typography from './Typography'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { useTranslations } from '../contexts/localeContext'
import onboardingImage1 from '../assets/onboarding/onboarding-1.png'
import onboardingImage2 from '../assets/onboarding/onboarding-2.png'
import onboardingImage3 from '../assets/onboarding/onboarding-3.png'
import onboardingImage4 from '../assets/onboarding/onboarding-4.png'

const OnboardingContext = createContext<{ open(): void } | null>(null)

export const useOnboarding = () => {
  const context = React.useContext(OnboardingContext)
  if (context === undefined || context === null) {
    throw new Error('useOnboarding must be used within a OnboardingContext')
  }

  return context
}

export interface OnboardingOverlayProps {
  children?: ReactNode
}

const OnboardingOverlay: React.FC<OnboardingOverlayProps> = ({ children }) => {
  const userQuery = useMeQuery()
  const setOnboardingCompletedMutation = useSetOnboardingCompletedMutation({
    onSettled() {
      userQuery.refetch()
    },
  })

  const [hasBeenReopened, setHasBeenReopened] = useState(false)
  const [hasBeenDismissed, setHasBeenDismissed] = useState(false)

  const shouldShowOnboarding = useMemo(() => {
    const isCompleted = userQuery.data?.me?.onBoardingCompleted ?? true
    return !isCompleted
  }, [userQuery.data])

  const onToggle = () => {
    setHasBeenDismissed(true)
    setHasBeenReopened(false)

    if (shouldShowOnboarding) {
      setOnboardingCompletedMutation.mutate({ completed: true })
    }
  }

  return (
    <OnboardingContext.Provider
      value={{
        open: () => setHasBeenReopened(true),
      }}
    >
      {children}
      {((shouldShowOnboarding && !hasBeenDismissed) || hasBeenReopened) && (
        <Portal>{<Onboarding onToggle={onToggle} />}</Portal>
      )}
    </OnboardingContext.Provider>
  )
}

const Onboarding: React.FC<{ onToggle(): void }> = ({ onToggle }) => {
  const t = useTranslations()

  const spacing = useSpacingFn()
  const styles = useStyles(({ palette, spacing }) => ({
    root: {
      backgroundColor: palette.background.main,
    },
    image: {
      width: '100%',
      resizeMode: 'contain',
    },
    scrollView: {},
    scrollContent: {
      paddingBottom: spacing(12),
    },
  }))

  const { width } = useWindowDimensions()

  const resolveImageUri = (source: any) => {
    if (Platform.OS === 'web') {
      return source
    }

    return Image.resolveAssetSource(source).uri
  }
  const image1 = resolveImageUri(onboardingImage1)
  const image2 = resolveImageUri(onboardingImage2)
  const image3 = resolveImageUri(onboardingImage3)
  const image4 = resolveImageUri(onboardingImage4)

  type Item =
    | {
        type: 'text'
        title: string
        text: string
      }
    | {
        type: 'image'
        source: string
      }

  const items: Item[] = [
    {
      type: 'text',
      title: t('onboarding.section_1_title'),
      text: t('onboarding.section_1_text'),
    },
    {
      type: 'image',
      source: image2,
    },
    {
      type: 'text',
      title: t('onboarding.section_2_title'),
      text: t('onboarding.section_2_text'),
    },
    {
      type: 'image',
      source: image3,
    },
    {
      type: 'text',
      title: t('onboarding.section_3_title'),
      text: t('onboarding.section_3_text'),
    },
    {
      type: 'image',
      source: image4,
    },
  ]

  return (
    <Animated.View
      style={[StyleSheet.absoluteFill, styles.root]}
      entering={Platform.OS !== 'web' ? FadeIn : undefined}
      exiting={Platform.OS !== 'web' ? FadeOut : undefined}
    >
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollContent}>
        <Stack spacing={8}>
          <Container style={{ paddingHorizontal: 0 }}>
            <ResponsiveImage
              source={image1}
              webStyle={{
                maxHeight: 500,
                width: '100%',
                objectFit: 'cover',
                borderBottomRightRadius: spacing(8),
                borderBottomLeftRadius: spacing(8),
              }}
            />
          </Container>
          <SafeAreaView disableTopSafeArea>
            <Container>
              <Stack spacing={8}>
                {items.map((item, index) => {
                  if (item.type === 'image') {
                    return (
                      <ResponsiveImage
                        key={index}
                        source={item.source}
                        width={width - spacing(8)}
                      />
                    )
                  }

                  if (item.type === 'text') {
                    return (
                      <Stack spacing={2} key={index}>
                        <Typography variant="h1">{item.title}</Typography>
                        <Typography variant="h4" weight="regular">
                          {item.text}
                        </Typography>
                      </Stack>
                    )
                  }
                })}
                <Button
                  size="extra-large"
                  title={t('get_started')}
                  style={{ alignSelf: 'center' }}
                  disableElevation
                  onPress={() => onToggle()}
                />
              </Stack>
            </Container>
          </SafeAreaView>
        </Stack>
      </ScrollView>
      <View style={{ position: 'absolute', top: spacing(8), right: spacing(4) }}>
        <IconButton
          size={spacing(6)}
          color="surface"
          icon={(props) => (
            <Add {...props} size={spacing(5)} style={{ transform: [{ rotate: '45deg' }] }} />
          )}
          onPress={() => onToggle()}
        />
      </View>
    </Animated.View>
  )
}

export default OnboardingOverlay
