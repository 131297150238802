import { View } from 'react-native'
import { RootStackScreenProps } from '../navigation/types'
import Button from '../components/Button'
import { useState } from 'react'
import ScrollView from '../components/ScrollView'
import Typography from '../components/Typography'
import { useController, useForm } from 'react-hook-form'
import PasswordField from '../components/inputs/PasswordField'
import { useChangePasswordMutation } from '../generated/graphql'
import { useTranslations } from '../contexts/localeContext'
import { setStatusBarStyle } from 'expo-status-bar'
import { useStyles } from '../hooks/useStyles'
import { useSpacingFn } from '../contexts/SpacingContext'
import Container from '../components/Container'

interface ChangePasswordFormData {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

const ChangePassword = ({ navigation }: RootStackScreenProps<'ChangePassword'>) => {
  setStatusBarStyle('dark')

  const [changePasswordMutationIsLoading, setChangePasswordMutationIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const t = useTranslations()
  const changePasswordMutation = useChangePasswordMutation({
    onSuccess(data) {
      switch (data.changePassword.__typename) {
        case 'MutationChangePasswordSuccess':
          return setSuccessMessage(t('feedback.change_password_success'))
        case 'InvalidPasswordError':
          return setErrorMessage(t('errors.new_password_invalid'))
        case 'IncorrectPasswordError':
          return setErrorMessage(t('errors.wrong_current_password'))
        case 'NewPasswordsDoNotMatchError':
          return setErrorMessage(t('errors.new_passwords_not_matching'))
        case 'UserNotFoundError':
          return setErrorMessage(t('errors.user_not_found'))
        default:
          return setErrorMessage(t('errors.unexpected_error'))
      }
    },
    onError(error) {
      console.log('Unexpected error happened when trying to change password.')
      console.log(JSON.stringify({ error }))
    },
    onSettled() {
      setChangePasswordMutationIsLoading(false)
      setValue('confirmNewPassword', '')
      setValue('currentPassword', '')
      setValue('newPassword', '')
    },
  })
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordFormData>()

  const { field: currentPasswordField } = useController({
    name: 'currentPassword',
    control,
    rules: {
      required: true,
    },
  })

  const { field: newPasswordField } = useController({
    name: 'newPassword',
    control,
    rules: {
      required: true,
      minLength: 8,
      validate: {
        cannotBeEqualToCurrent: (value) => value !== currentPasswordField.value,
      },
    },
  })
  const { field: confirmNewPasswordField } = useController({
    name: 'confirmNewPassword',
    control,
    rules: {
      required: true,
      minLength: 8,
      validate: {
        mustMatchNewPassword: (value) => value === newPasswordField.value,
      },
    },
  })

  const onSubmit = (formData: ChangePasswordFormData) => {
    setChangePasswordMutationIsLoading(true)
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    changePasswordMutation.mutate(formData)
  }

  const getErrorByType = (type: string): string => {
    switch (type) {
      case 'required':
        return t('errors.field_required')
      case 'minLength':
        return t('errors.too_short')
      case 'mustMatchNewPassword':
        return t('errors.passwords_do_not_match')
      case 'cannotBeEqualToCurrent':
        return t('errors.new_cannot_be_old_password')
      default:
        return 'error'
    }
  }

  const spacing = useSpacingFn()

  const styles = useStyles(({ spacing, palette }) => ({
    scrollContainer: {
      backgroundColor: palette.surface.main,
      flex: 1,
    },
    scrollContentContainer: {
      paddingBottom: spacing(8),
    },
    scrollListItem: {
      marginTop: spacing(4),
    },
    inputFeedbackText: {
      marginTop: spacing(1),
      marginLeft: spacing(4),
    },
    inputSuccessFeedbackText: {
      marginTop: spacing(4),
      marginLeft: spacing(4),
    },
    passwordTextContainer: {
      marginTop: spacing(8),
      flexDirection: 'row',
      justifyContent: 'center',
    },
    saveButtonContainer: {
      marginTop: spacing(8),
      flexDirection: 'row',
      justifyContent: 'center',
    },
    cancelButtonContainer: {
      marginTop: spacing(4),
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }))

  return (
    <ScrollView
      style={styles.scrollContainer}
      contentContainerStyle={styles.scrollContentContainer}
    >
      <Container style={{ flexGrow: 1 }}>
        <View style={styles.scrollListItem}>
          <PasswordField
            label={t('current_password')}
            color="background"
            value={currentPasswordField.value}
            onChangeText={currentPasswordField.onChange}
            onBlur={currentPasswordField.onBlur}
            error={Boolean(errors.currentPassword)}
            disableToggle={true}
          />
          {errors.currentPassword && (
            <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
              {errors.currentPassword?.type && getErrorByType(errors.currentPassword.type)}
            </Typography>
          )}
        </View>
        <View style={styles.passwordTextContainer}>
          <Typography variant="p2" color="on-surface">
            {t('password_rules')}
          </Typography>
        </View>
        <View style={styles.scrollListItem}>
          <View>
            <View>
              <PasswordField
                label={t('new_password')}
                color="background"
                value={newPasswordField.value}
                onChangeText={newPasswordField.onChange}
                onBlur={newPasswordField.onBlur}
                error={Boolean(errors.newPassword)}
                disableToggle={true}
              />
              {errors.newPassword && (
                <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                  {errors.newPassword?.type && getErrorByType(errors.newPassword.type)}
                </Typography>
              )}
            </View>
            <View style={{ marginTop: spacing(3) }}>
              <PasswordField
                label={t('repeat_new_password')}
                color="background"
                value={confirmNewPasswordField.value}
                onChangeText={confirmNewPasswordField.onChange}
                onBlur={confirmNewPasswordField.onBlur}
                error={Boolean(errors.confirmNewPassword)}
                disableToggle={true}
              />
              {errors.confirmNewPassword && (
                <Typography variant="caption" color="error" style={styles.inputFeedbackText}>
                  {errors.confirmNewPassword?.type &&
                    getErrorByType(errors.confirmNewPassword.type)}
                </Typography>
              )}
            </View>
          </View>
        </View>
        <View style={styles.scrollListItem}>
          {successMessage && (
            <Typography
              style={styles.inputSuccessFeedbackText}
              color="on-success"
              variant="caption"
            >
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography style={styles.inputSuccessFeedbackText} color="error" variant="caption">
              {errorMessage}
            </Typography>
          )}
        </View>
        <View style={styles.saveButtonContainer}>
          <Button
            title={t('save')}
            color="primary"
            size="extra-large"
            disabled={changePasswordMutationIsLoading}
            loading={changePasswordMutationIsLoading}
            loadingIndicatorPosition={'trailing'}
            onPress={handleSubmit(onSubmit)}
          ></Button>
        </View>
        <View style={styles.cancelButtonContainer}>
          <Button
            title={t('cancel')}
            color="surface"
            size="extra-large"
            disableElevation={true}
            onPress={() => navigation.navigate('Home', { screen: 'Profile' })}
          ></Button>
        </View>
      </Container>
    </ScrollView>
  )
}

export default ChangePassword
