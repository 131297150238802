import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Image, Pressable, View } from 'react-native'
import { useFeatureFlags } from '../../contexts/FeatureFlags'
import { useActiveLotteryQuery, useUserTicketsQuery } from '../../generated/graphql'
import { useCountdown } from '../../hooks/useCountdown'
import { useStyles } from '../../hooks/useStyles'
import Badge from '../Badge'
import Stack from '../Stack'
import Surface from '../Surface'
import Typography from '../Typography'

export interface ActiveLotteryCardProps {}

export const ActiveLotteryCard: React.FC<ActiveLotteryCardProps> = ({}) => {
  const { flags } = useFeatureFlags()
  const activeLotteryQuery = useActiveLotteryQuery()
  const ticketBalanceQuery = useUserTicketsQuery()

  const navigation = useNavigation()
  const countdown = useCountdown(activeLotteryQuery.data?.activeLottery?.endDate)

  const styles = useStyles(({ palette, spacing }) => ({
    root: {
      padding: spacing(3),
      flexDirection: 'row',
    },
    imageContainer: {
      width: spacing(28),
      height: spacing(20),
      overflow: 'hidden',
      marginRight: spacing(3),
    },
    image: {
      width: '100%',
      height: '100%',
    },
    absoluteBadge: { position: 'absolute', top: spacing(2), left: spacing(2) },
  }))

  if (!flags.Lottery) {
    return null
  }

  if (activeLotteryQuery.isLoading) {
    return null
  }

  if (!activeLotteryQuery.isSuccess) {
    return null
  }

  if (!activeLotteryQuery.data) {
    return null
  }

  if (!activeLotteryQuery.data.activeLottery) {
    return null
  }

  const lottery = activeLotteryQuery.data.activeLottery

  return (
    <Pressable onPress={() => navigation.navigate('Lottery', { lotteryId: lottery.id })}>
      <Surface category="extraLarge" style={styles.root}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <Surface style={styles.imageContainer} category="medium">
            <Image source={{ uri: lottery.imageUrl ?? undefined }} style={styles.image} />
            <View style={styles.absoluteBadge}>
              <Badge
                color="surface"
                label={
                  activeLotteryQuery.isSuccess && activeLotteryQuery.data.activeLottery.endDate
                    ? countdown.timeString
                    : '--:--:--'
                }
                size="large"
              />
            </View>
          </Surface>
          <View style={{ flex: 1 }}>
            <Stack style={{ flex: 1 }} justify="space-between" align="flex-start">
              <Stack spacing={0}>
                <Typography variant="subtitle">{lottery.title}</Typography>
                <Typography variant="p2">More info</Typography>
              </Stack>
              {ticketBalanceQuery.isSuccess && (
                <Badge
                  label={`Your tickets ${ticketBalanceQuery.data.userTickets.balance}`}
                  size="large"
                />
              )}
            </Stack>
          </View>
        </View>
      </Surface>
    </Pressable>
  )
}
