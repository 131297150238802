import { People } from 'iconsax-react-native'
import { useMemo } from 'react'
import { Image, View } from 'react-native'
import { useSpacingFn } from '../../contexts/SpacingContext'
import { useTheme } from '../../contexts/ThemeContext'
import { Company } from '../../types/api'
import IconButton from '../IconButton'
import Skeleton from '../Skeleton'
import Surface, { SurfaceProps } from '../Surface'
import Typography from '../Typography'
import CompanyAvatarGroup from './CompanyAvatarGroup'

export interface CompanyInformationProps extends SurfaceProps {
  company: Company
}

const CompanyInformation = ({ company, style, ...props }: CompanyInformationProps) => {
  const isLoading = useMemo(() => {
    return company === undefined
  }, [company])

  const { shapes } = useTheme()
  const spacing = useSpacingFn()
  return (
    <Surface style={[{ borderRadius: 15, padding: 15 }, style]} {...props}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              style={[shapes.small, { height: spacing(10), width: spacing(10) }]}
            ></Skeleton>
          ) : company?.avatarUrl ? (
            <Image
              source={{ uri: company.avatarUrl }}
              style={[
                shapes.small,
                { height: spacing(10), width: spacing(10), borderRadius: 9999 },
              ]}
            />
          ) : (
            <IconButton
              color="accent"
              size={50}
              icon={({ size, color }) => <People color={color} size={size}></People>}
            />
          )}
        </View>
        <View style={{ marginLeft: spacing(3), flexGrow: 1 }}>
          {isLoading ? (
            <Skeleton variant="text" textVariant="h3" style={{ width: '75%' }}></Skeleton>
          ) : (
            <Typography variant="h3">{company.name}</Typography>
          )}
        </View>
      </View>
      <View style={{ marginTop: spacing(4) }}>
        <CompanyAvatarGroup company={company} max={7} />
      </View>
    </Surface>
  )
}

export default CompanyInformation
