import React, { ReactNode, useMemo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler'
import Animated, {
  interpolate,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { snapPoint } from '../animation/worklets'
import { useStyles } from '../hooks/useStyles'
import LinearGradient from './LinearGradient'
import Stack from './Stack'

export interface SheetProps {
  snapPoints: number[]
  clamp?: boolean
  children?: ReactNode | ((height: Animated.SharedValue<number>) => ReactNode)
}

export const Sheet: React.FC<SheetProps> = ({ snapPoints, children, clamp = true }) => {
  const minHeight = useMemo(() => Math.min(...snapPoints), [snapPoints])
  const maxHeight = useMemo(() => Math.max(...snapPoints), [snapPoints])

  const height = useSharedValue(minHeight)

  const progress = useDerivedValue(() => (height.value - minHeight) / (maxHeight - minHeight))

  const styles = useStyles(({ palette, spacing, shapes }) => ({
    root: {
      height: 100,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      overflow: 'hidden',
    },
    content: {
      flex: 1,
    },
    handle: {
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
    pressable: {
      ...StyleSheet.absoluteFillObject,
    },
    dash: {
      width: spacing(6),
      height: 3,
      backgroundColor: palette.secondary.main,
      ...shapes.full,
    },
  }))

  const onGesture = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, { offset: number }>({
    onStart: (_event, context) => {
      context.offset = height.value
    },
    onActive: ({ translationY }, context) => {
      height.value = translationY + context.offset
    },
    onEnd: ({ translationY, velocityY }, context) => {
      height.value = withTiming(snapPoint(translationY + context.offset, velocityY, snapPoints), {
        duration: 100,
      })
    },
  })

  const animatedStyle = useAnimatedStyle(() => ({
    height: interpolate(
      height.value,
      [minHeight, maxHeight],
      [minHeight, maxHeight],
      clamp ? 'clamp' : 'identity'
    ),
  }))

  const onHandlePress = () => {
    const diff = maxHeight - minHeight

    height.value =
      height.value - minHeight > diff / 2 ? withTiming(minHeight) : withTiming(maxHeight)
  }

  return (
    <Animated.View style={[styles.root, animatedStyle]}>
      <LinearGradient from="secondary" to="accent" style={{ flex: 1 }}>
        <View style={styles.content}>
          {typeof children === 'function' ? children(progress) : children}
        </View>
        <PanGestureHandler onGestureEvent={onGesture}>
          <Animated.View style={styles.handle}>
            <Pressable style={styles.pressable} onPress={onHandlePress} />
            <Stack spacing={1}>
              <View pointerEvents="none" style={styles.dash}></View>
              <View pointerEvents="none" style={styles.dash}></View>
            </Stack>
          </Animated.View>
        </PanGestureHandler>
      </LinearGradient>
    </Animated.View>
  )
}
