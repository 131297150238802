import React, { useCallback } from 'react'
import { View } from 'react-native'
import { useSpacingFn } from '../../../contexts/SpacingContext'
import useAnswerSelect from '../../../hooks/useAnswerSelect'
import { useStyles } from '../../../hooks/useStyles'
import Typography from '../../Typography'
import QuestionButton from '../QuestionButton'
import QuestionTemplate, { QuestionProps, QuestionTemplatePropsModified } from './QuestionTemplate'

interface TextQuestionProps extends Omit<QuestionProps, 'answers'>, QuestionTemplatePropsModified {}

export const TextQuestion = ({ question, onAnswer, ...props }: TextQuestionProps) => {
  const [selectedAnswer, selectAnswer] = useAnswerSelect()

  const onSubmit = () => {
    if (!question.textAnswers) {
      throw new Error('Question does not have text answers')
    }

    const { id } = question.textAnswers[selectedAnswer]
    onAnswer(id)
  }

  const setSelectedState = useCallback(
    (answerIndex: number) => {
      if (answerIndex === selectedAnswer) {
        return 'selected'
      }
      return 'unselected'
    },
    [selectedAnswer]
  )

  const spacing = useSpacingFn()

  return (
    <QuestionTemplate
      {...props}
      onButtonPress={() => onSubmit()}
      showButton={selectedAnswer !== undefined}
      headerImageUrl={question?.imageUrl ?? undefined}
    >
      <Typography variant="subtitle" color="on-surface" style={{ marginBottom: spacing(4) }}>
        {question.question}
      </Typography>
      <View>
        {question.textAnswers?.map(({ answer }, i) => (
          <QuestionButton
            key={answer + i}
            questionText={answer}
            state={setSelectedState(i)}
            onPress={() => selectAnswer(i)}
            style={{ marginTop: i === 0 ? 0 : spacing(2) }}
          />
        ))}
      </View>
    </QuestionTemplate>
  )
}

export default TextQuestion
