import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from 'iconsax-react-native'
import IconButton, { IconButtonProps } from '../IconButton'

export interface ArrowButtonProps extends IconButtonProps {
  variant: 'left' | 'right' | 'up' | 'down'
}

const ArrowButton = ({
  variant = 'left',
  size = 32,
  color = 'surface',
  showElevation = true,
  ...props
}: ArrowButtonProps) => (
  <IconButton
    hitSlop={48}
    color={color}
    size={size}
    showElevation={showElevation}
    icon={(props) => {
      if (variant === 'left') return <ArrowLeft {...props} />
      else if (variant === 'right') return <ArrowRight {...props} />
      else if (variant === 'up') return <ArrowUp {...props} />
      else return <ArrowDown {...props} />
    }}
    {...props}
  />
)

export default ArrowButton
