import React from 'react'
import { useEffect, useRef, useState, useContext } from 'react'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Subscription } from 'expo-modules-core'
import { Platform } from 'react-native'
//import useMe from '../../hooks/useMe'
import { ExpoPushToken } from 'expo-notifications'
import { useMeQuery, useUpdatePushTokenMutation } from '../generated/graphql'

const PushNotificationContext = React.createContext(null)

export const PushNotificationConsumer = (props: any) => (
  <PushNotificationContext.Consumer>{props.children}</PushNotificationContext.Consumer>
)
// let testNotification = () => {
//   if (!expoPushToken) {
//     console.log('Cannot send to token because it is null')
//     return
//   }
//   console.log('Sending a push something')
//   const r = await getSdk().testPushNotification()
//   console.log(JSON.stringify(r))
// }

const registerForPushNotificationsAsync = async () => {
  let token

  // Must use physical device for push notifications
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus

    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }

    if (finalStatus !== 'granted') {
      return
    }

    token = (await Notifications.getExpoPushTokenAsync()).data
  }

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  return token
}

export const PushNotificationProvider = ({ children }: any) => {
  const [expoPushToken, setExpoPushToken] = useState<string | null>(null)
  const [notification, setNotification] = useState<Notifications.Notification>()

  const notificationListener = useRef<Subscription>()
  const responseListener = useRef<Subscription>()

  const { data } = useMeQuery()
  const { mutate: updatePushToken } = useUpdatePushTokenMutation()

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Notifications on web is not supported
      return
    }

    // Set notification handler
    Notifications.setNotificationHandler({
      handleSuccess: () => {},
      handleError: () => {},
      handleNotification: async (notification) => {
        return {
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
          priority: Notifications.AndroidNotificationPriority.MAX,
        }
      },
    })

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      setNotification(notification)
    })

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {}
    )
    return () => {
      // Clean up
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current)
      }

      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current)
      }
    }
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Notifications on web is not supported
      return
    }

    if (!data?.me) {
      // Did not retrieve any user data on success
      return
    }

    // Process push token
    if (expoPushToken) {
      // Push token already processed.
      return
    }

    // Attempt to fetch push token
    registerForPushNotificationsAsync().then((token) => {
      if (!token) {
        // Unable to find push token
        return
      }

      setExpoPushToken(token)

      if (data?.me?.pushToken === token) {
        // Existing push token is equal to this one
        return
      }

      // Update push token
      console.log('updating push token')
      void updatePushToken({ token })
    })
  }, [data])

  return (
    <PushNotificationContext.Provider value={null}>{children}</PushNotificationContext.Provider>
  )
}
