import React, { ReactNode } from 'react'
import { View, ViewStyle } from 'react-native'
import { useSpacingFn } from '../contexts/SpacingContext'
import { useStyles } from '../hooks/useStyles'

type StackDirection = 'row' | 'column'
type AlignMode = 'flex-start' | 'center' | 'flex-end' | 'stretch'
type JustifyMode =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
export type StackProps = {
  direction?: StackDirection
  spacing?: number
  flex?: number
  align?: AlignMode
  justify?: JustifyMode
  divider?: JSX.Element
  debugMode?: boolean
  style?: ViewStyle
  children: ReactNode | ReactNode[]
}

const spaceChildren = (
  children: ReactNode | ReactNode[],
  spacing: number,
  direction: StackDirection,
  divider?: JSX.Element,
  debugMode?: boolean
) => {
  const childrenArray = React.Children.toArray(children).filter(Boolean)

  const childrenElements = childrenArray.map((child, index) => {
    return (
      <View
        key={index}
        style={{
          marginBottom:
            direction === 'column' ? (index === childrenArray.length - 1 ? 0 : spacing) : 0,
          marginRight: direction === 'row' ? (index === childrenArray.length - 1 ? 0 : spacing) : 0,
          borderWidth: debugMode ? 1 : 0,
          borderColor: 'red',
        }}
      >
        {child}
      </View>
    )
  })

  if (divider) {
    return childrenElements.reduce<JSX.Element[]>((acc, x, index) => {
      acc.push(x)

      if (index < childrenElements.length - 1) {
        const dividerElement = React.cloneElement(divider, {
          key: `divider-${index}`,
          style: { marginBottom: spacing, borderWidth: debugMode ? 1 : 0, borderColor: 'red' },
        })
        acc.push(dividerElement)
      }

      return acc
    }, [])
  }

  return childrenElements
}

const Stack: React.FC<StackProps> = ({
  children,
  divider,
  style,
  spacing = 2,
  direction = 'column',
  align = 'stretch',
  justify = 'flex-start',
  debugMode = false,
}) => {
  const spacingFn = useSpacingFn()

  const defaultStyles = ({ direction }: { direction: StackDirection }): ViewStyle => ({
    flexDirection: direction,
    justifyContent: justify,
    alignItems: align,
  })

  const styles = useStyles(({}) => ({
    root: {
      ...defaultStyles({ direction }),
    },
  }))

  return (
    <View style={[styles.root, style]}>
      {spaceChildren(children, spacingFn(spacing), direction, divider, debugMode)}
    </View>
  )
}

export default Stack
