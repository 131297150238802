import { useNavigation } from '@react-navigation/native'
import { Image, StyleSheet, View } from 'react-native'
import { useStyles } from '../../hooks/useStyles'
import { Product } from '../../types/api'
import Badge, { BadgeProps } from '../Badge'
import ArrowButton from '../buttons/ArrowButton'
import Container from '../Container'
import SafeAreaView from '../SafeAreaView'
import Skeleton from '../Skeleton'
import Typography from '../Typography'
import { LinearGradient } from 'expo-linear-gradient'
import { useTranslations } from '../../contexts/localeContext'

export type ProductImageHeaderProps = {
  product: Product
  badgeProps?: BadgeProps
}

const ProductImageHeader: React.FC<ProductImageHeaderProps> = ({ product, badgeProps }) => {
  const t = useTranslations()
  const navigation = useNavigation()
  const styles = useStyles(({ shapes, spacing }) => ({
    headerContainer: {
      borderBottomRightRadius: shapes.xxl.borderRadius,
      borderBottomLeftRadius: shapes.xxl.borderRadius,
      overflow: 'hidden',
      position: 'relative',
    },
    image: {
      width: '100%',
      height: spacing(68),
      borderBottomRightRadius: shapes.xxl.borderRadius,
      borderBottomLeftRadius: shapes.xxl.borderRadius,
    },
    badge: {
      alignSelf: 'flex-start',
      marginBottom: spacing(4),
    },
    safeAreaView: {
      flex: 1,
      paddingTop: spacing(2),
    },
    container: {
      flex: 1,
      justifyContent: 'space-between',
    },
    navTitle: { textAlign: 'center', marginTop: spacing(1), alignSelf: 'center' },
    navArrowWrapper: {
      position: 'absolute',
      top: 0,
      left: spacing(2),
    },
  }))

  return (
    <View style={styles.headerContainer}>
      <Image
        source={{ uri: product?.imageUrl ?? undefined }}
        style={styles.image}
        resizeMode="cover"
      />
      <LinearGradient
        colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: '50%',
        }}
      />
      <View style={StyleSheet.absoluteFill}>
        <SafeAreaView style={styles.safeAreaView} disableBottomSafeArea>
          <Container style={styles.container}>
            <View style={styles.navArrowWrapper}>
              <ArrowButton
                variant="left"
                size={40}
                onPress={() => navigation.navigate('Home', { screen: 'Shop' })}
              />
            </View>
            <Typography style={styles.navTitle} variant="h2" color={'on-secondary'}>
              {t('quala_shop')}
            </Typography>

            {badgeProps && (
              <View style={styles.badge}>
                <Badge {...badgeProps} />
              </View>
            )}
          </Container>
        </SafeAreaView>
      </View>
    </View>
  )
}

export default ProductImageHeader
