import { useEffect, useMemo } from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Color, usePaletteColor } from '../hooks/usePaletteColor'
import { useStyles } from '../hooks/useStyles'

export interface ProgressBarProps {
  size?: 'small' | 'medium'
  color?: Color
  backgroundColor?: Color
  currentStep?: number
  totalSteps?: number
  style?: ViewStyle
}

const ProgressBar = ({
  currentStep = 0,
  totalSteps = 37,
  size = 'medium',
  color = 'primary',
  backgroundColor = 'background',
  style,
}: ProgressBarProps) => {
  const palette = usePaletteColor(color, backgroundColor)

  const animation = useSharedValue(0)

  const percentage = useMemo(() => {
    return Math.min(Math.round((currentStep / totalSteps) * 100), 100)
  }, [totalSteps, currentStep])

  useEffect(() => {
    animation.value = withTiming(percentage, { duration: 1000 })
  }, [percentage])

  const styles = useStyles(
    ({ spacing, shapes }) => ({
      outer: {
        ...shapes.full,
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        height: size === 'medium' ? spacing(2) : spacing(1),
        backgroundColor: palette.on,
      },
      inner: {
        ...shapes.full,
        position: 'absolute',
        height: '100%',
        backgroundColor: palette.main,
      },
    }),
    [backgroundColor, color, size]
  )

  const animationStyle = useAnimatedStyle(() => ({
    width: `${animation.value}%`,
  }))

  return (
    <View style={[styles.outer, style]}>
      <Animated.View style={[styles.inner, animationStyle]}></Animated.View>
    </View>
  )
}

export default ProgressBar
