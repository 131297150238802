import ScrollView from '../components/ScrollView'
import { RootStackScreenProps } from '../navigation/types'
import { View, useWindowDimensions } from 'react-native'
import Container from '../components/Container'
import { useSpacingFn } from '../contexts/SpacingContext'
import Typography from '../components/Typography'
import { useStyles } from '../hooks/useStyles'
import { useTheme } from '../contexts/ThemeContext'
import { useProductQuery, usePurchaseUrlProductMutation } from '../generated/graphql'
import Button from '../components/Button'
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import BottomSheet from '../components/BottomSheet'
import { useState } from 'react'
import ProductImageHeader from '../components/product/ProductImageHeader'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslations } from '../contexts/localeContext'

const ErrorGraphics = () => {
  const styles = useStyles(({ palette, spacing }) => ({
    container: {
      borderRadius: 9999,
      backgroundColor: palette.error.main,
      width: spacing(10),
      height: spacing(10),
      marginTop: spacing(6),
      justifyContent: 'center',
      alignItems: 'center',
    },
    inner: {
      width: spacing(5),
      height: spacing(1),
      borderRadius: 2,
      backgroundColor: palette.error.on,
    },
  }))

  return (
    <View style={styles.container}>
      <View style={styles.inner}></View>
    </View>
  )
}

const Product = (props: RootStackScreenProps<'Product'>) => {
  const t = useTranslations()

  const productId = props.route.params.productId
  const [isMutationError, setIsMutationError] = useState(false)
  const [isNotEnoughCoinsError, setIsNotEnoughCoinsError] = useState(false)
  const queryClient = useQueryClient()

  const productQuery = useProductQuery({ id: productId })

  const isProductLoading = productQuery.data === undefined

  const purchaseMutation = usePurchaseUrlProductMutation({
    onSettled(data) {
      if (data === undefined) {
        return // ?
      }

      if (data.purchaseUrlProduct.__typename === 'NotEnoughCoinsError') {
        setIsNotEnoughCoinsError(true)
      } else if (data.purchaseUrlProduct.__typename === 'MutationPurchaseUrlProductSuccess') {
        queryClient.invalidateQueries(['PurchasedUrlProducts'])
        queryClient.invalidateQueries(['CoinAccountSummary'])
        //Redirect to success screen
        height.value = withTiming(0, { duration: 200 })
        props.navigation.navigate('PurchasedUrlProduct', {
          productId: data.purchaseUrlProduct.data.urlProductId,
        })
      }
    },
    onError() {
      setIsMutationError(true)
    },
  })

  const spacing = useSpacingFn()
  const theme = useTheme()

  const styles = useStyles(({ spacing }) => ({
    badge: {
      paddingVertical: spacing(2),
      paddingHorizontal: spacing(3),
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.shapes.full.borderRadius,
      alignSelf: 'flex-start',
      marginBottom: spacing(4),
    },
  }))

  const dimensions = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const height = useSharedValue(0 - (insets.bottom + insets.top))

  const backgroundStyle = useAnimatedStyle(() => ({
    flex: 1,
    backgroundColor: 'black',
    transform: [
      {
        scale: interpolate(height.value, [0, dimensions.height - 100], [1, 0.9], {
          extrapolateLeft: 'clamp',
          extrapolateRight: 'clamp',
        }),
      },
    ],
  }))

  const foregroundStyle = useAnimatedStyle(() => ({
    borderRadius: interpolate(height.value, [0, dimensions.height - 100], [0, 30]),
  }))

  if (productQuery.data?.product?.__typename === 'ProductNotFoundError') {
    return <Typography>{t('product_not_found')}</Typography>
  }

  const isOutOfStock = (productQuery.data?.product.data.stock ?? 0) <= 0
  const product = productQuery.data?.product.data
  const isError = isMutationError || isNotEnoughCoinsError

  return (
    <View style={{ backgroundColor: 'black', flex: 1, overflow: 'hidden' }}>
      <Animated.View
        style={[
          {
            flex: 1,
            backgroundColor: 'black',
            overflow: 'hidden',
          },
          backgroundStyle,
        ]}
      >
        <Animated.View style={[{ flex: 1, overflow: 'hidden' }, foregroundStyle]}>
          <ScrollView style={{ backgroundColor: 'white' }}>
            {!isProductLoading && product && (
              <ProductImageHeader
                product={product}
                badgeProps={{
                  color: isOutOfStock ? 'background' : 'secondary',
                  size: 'extra-large',
                  label: isOutOfStock ? t('out_of_stock') : `${t('price')}: ${product.price} Coins`,
                }}
              />
            )}

            <View>
              <Container style={{ marginVertical: spacing(4) }}>
                {product && (
                  <Typography variant="h2" weight="semibold">
                    {product.name}
                  </Typography>
                )}
                {product && (
                  <Typography style={{ marginVertical: spacing(4) }} variant="p1">
                    {product.description}
                  </Typography>
                )}
                <View style={{ alignItems: 'center' }}>
                  <Button
                    title={t('buy_product')}
                    tintColor="on-success"
                    color="success"
                    disableElevation
                    size="large"
                    style={{ marginVertical: spacing(4) }}
                    onPress={() => (height.value = withTiming(500, { duration: 200 }))}
                  />
                  <Button
                    title={t('cancel')}
                    variant="text"
                    color="on-background"
                    onPress={() => props.navigation.navigate('Home', { screen: 'Shop' })}
                  />
                </View>
              </Container>
            </View>
          </ScrollView>
          <BottomSheet height={height}>
            {isError ? (
              <>
                <ErrorGraphics />
                <Typography
                  variant="h2"
                  style={{ marginTop: spacing(6), marginBottom: spacing(5), textAlign: 'center' }}
                >
                  {isNotEnoughCoinsError ? t('not_enough_coins') : t('errors.something_went_wrong')}
                </Typography>
                <Typography style={{ textAlign: 'center', marginBottom: spacing(4) }}>
                  {isNotEnoughCoinsError
                    ? t('not_enough_coins_message')
                    : t('errors.unexpected_error_product')}
                </Typography>
                <Button
                  disableElevation={true}
                  title={t('back_to_shop')}
                  tintColor="on-success"
                  color="success"
                  size="large"
                  style={{ marginVertical: spacing(4) }}
                  onPress={() => {
                    height.value = withTiming(0, {
                      duration: 100,
                    })
                    props.navigation.navigate('Home', { screen: 'Shop' })
                  }}
                />
                <Button
                  title={t('cancel')}
                  variant="text"
                  color="on-background"
                  onPress={() => (height.value = withTiming(0, { duration: 200 }))}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle"
                  style={{ marginBottom: spacing(3), marginTop: spacing(10) }}
                >
                  {t('about_to_buy_title')}
                </Typography>
                {product && (
                  <Typography variant="h2" style={{ marginBottom: spacing(3) }}>
                    {product.name}
                  </Typography>
                )}
                <Typography style={{ textAlign: 'center', marginBottom: spacing(4) }}>
                  {t('about_to_buy_description')}
                </Typography>
                <View style={[styles.badge, { alignSelf: 'center', marginBottom: spacing(2) }]}>
                  {product && (
                    <Typography color="on-secondary" variant="subtitle" weight="semibold">
                      {`${t('price')}: ${product.price} Coins`}
                    </Typography>
                  )}
                </View>
                <Button
                  disableElevation={true}
                  disabled={purchaseMutation.isLoading || isOutOfStock}
                  title={t('confirm_purchase')}
                  tintColor="on-success"
                  color="success"
                  size="large"
                  style={{ marginVertical: spacing(4) }}
                  onPress={() => purchaseMutation.mutate({ productId })}
                  loading={purchaseMutation.isLoading}
                  loadingIndicatorPosition="trailing"
                />
                <Button
                  title={t('cancel')}
                  variant="text"
                  color="on-background"
                  onPress={() => (height.value = withTiming(0, { duration: 200 }))}
                />
              </>
            )}
          </BottomSheet>
        </Animated.View>
      </Animated.View>
    </View>
  )
}

export default Product
