import chroma from 'chroma-js'
import { useMemo } from 'react'
import { PaletteColor, useTheme } from '../contexts/ThemeContext'

export type Color =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'success'
  | 'warning'
  | 'error'
  | 'background'
  | 'surface'
  | 'on-primary'
  | 'on-secondary'
  | 'on-tertiary'
  | 'on-accent'
  | 'on-success'
  | 'on-warning'
  | 'on-error'
  | 'on-background'
  | 'on-surface'
  | string

/**
 * Returns a color from the palette, and a "on"-color for that color.
 * If no on-color is specified it will return black or white depending on
 * what gives the best contrast relative to the main color.
 *
 * @param main
 * @param on
 * @returns
 */
export const usePaletteColor = (main: Color, on?: Color): PaletteColor => {
  const { palette } = useTheme()

  return useMemo(() => {
    let _main = main
    if (palette[main]) {
      _main = palette[main].main
    } else if (main.startsWith('on-') && palette[main.replace('on-', '')]) {
      _main = palette[main.replace('on-', '')].on
    }

    let _on: string
    if (on) {
      if (palette[on]) {
        _on = palette[on].main
      } else if (on.startsWith('on-') && palette[on.replace('on-', '')]) {
        _on = palette[on.replace('on-', '')].on
      } else {
        _on = on
      }
    } else {
      _on = chroma.contrast(_main, 'white') > 4.5 ? '#FFFFFF' : '#000000'
    }

    return { main: _main, on: _on }
  }, [palette, main, on])
}
