import { forwardRef, useMemo, useState } from 'react'
import { Pressable, TextInput, View } from 'react-native'
import TextField, { TextInputProps } from './TextField'
import { EyeSlash, Eye } from 'iconsax-react-native'
import { useTranslations } from '../../contexts/localeContext'

export interface PasswordFieldProps extends Omit<TextInputProps, 'label'> {
  label?: string

  disableToggle?: boolean
}

const PasswordIcon = ({
  size,
  onPress,
  visible,
  color,
}: {
  size: number
  onPress: () => void
  visible: boolean
  color: string
}) => {
  const icon = useMemo(() => {
    if (visible) {
      return <Eye size={size} color={color} />
    }

    return <EyeSlash size={size} color={color} />
  }, [visible])
  return <Pressable onPress={onPress}>{icon}</Pressable>
}

const PasswordField = forwardRef<TextInput, PasswordFieldProps>(
  ({ label = 'password', disableToggle = false, ...rest }, ref) => {
    const t = useTranslations()

    if (label === 'password') {
      label = t(label)
    }
    const [isVisible, setIsVisible] = useState(false)

    const toggle = () => {
      setIsVisible(!isVisible)
    }

    return (
      <TextField
        label={label}
        ref={ref}
        trailing={
          !disableToggle &&
          ((props) => <PasswordIcon {...props} onPress={toggle} visible={isVisible} />)
        }
        textContentType="password"
        secureTextEntry={!isVisible}
        {...rest}
      ></TextField>
    )
  }
)

export default PasswordField
