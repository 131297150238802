import React, { createContext, ReactNode, useMemo } from 'react'
import { useGlobalSettingsQuery } from '../generated/graphql'

type FeatureFlagsContext = {
  isReady: boolean
  flags: FeatureFlags
}

export const FeatureContext = createContext<FeatureFlagsContext | null>(null)

export interface FeatureFlagProviderProps {
  children: ReactNode | undefined
}

type Feature = 'Lottery' | 'Shop'

type FeatureFlags = {
  [key in Feature]: boolean
}

const defaultContext: FeatureFlags = {
  Shop: false,
  Lottery: false,
}

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
  const settingsQuery = useGlobalSettingsQuery()

  const isReady = useMemo(() => settingsQuery.isError || settingsQuery.isSuccess, [settingsQuery])

  const flags = useMemo(() => {
    if (settingsQuery.isLoading || !settingsQuery.isSuccess || !settingsQuery.data.globalSettings) {
      return defaultContext
    }

    return {
      Shop: settingsQuery.data.globalSettings.shopIsEnabled ?? false,
      Lottery: settingsQuery.data.globalSettings.lotteryIsEnabled ?? false,
    }
  }, [settingsQuery])

  return <FeatureContext.Provider value={{ isReady, flags }}>{children}</FeatureContext.Provider>
}

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureContext)
  if (context === undefined || context === null) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }

  return context
}
