import { useState } from 'react'

const useAnswerSelect = (initial?: number): [number, (index: number) => void] => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>(initial)

  const selectAnswer = (index: number) => {
    if (selectedAnswer === index) {
      return setSelectedAnswer(undefined)
    }

    setSelectedAnswer(index)
  }

  return [selectedAnswer, selectAnswer]
}

export default useAnswerSelect
