import chroma from 'chroma-js'
import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Animated,
  TextInput as RNTextInput,
  View,
  TextInputProps as RNTextInputProps,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  Easing,
} from 'react-native'
import { useTheme } from '../../contexts/ThemeContext'
import { Color } from '../../hooks/usePaletteColor'
import { useStyles } from '../../hooks/useStyles'

export type InputType = 'text' | 'email' | 'password'

export interface TextInputProps extends RNTextInputProps {
  /**
   * A label that tells the user what this input is for.
   */
  label: string

  /**
   * A react component to be put at the end of the input.
   * Either a component or a function that receives size as a prop and returns a component
   */
  trailing?: ReactNode | ((props: { size: number; color: string }) => React.ReactNode)

  /**
   * A react component to be put at the end of the input.
   * Either a component or a function that receives size as a prop and returns a component
   */
  leading?: ReactNode | ((props: { size: number; color: string }) => React.ReactNode)

  /**
   * Color of the text field
   */
  color?: Color

  /**
   * Show this input in an error state
   */
  error?: boolean

  /**
   * Disable user interaction with this input
   */
  disabled?: boolean
}

const TextField = forwardRef<RNTextInput, TextInputProps>(
  (
    {
      color = 'surface',
      label,
      onFocus,
      onBlur,
      leading,
      trailing,
      disabled = false,
      error = false,
      style,
      ...rest
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const theme = useTheme()
    const leadingNode =
      typeof leading === 'function'
        ? leading({ size: 30, color: theme.palette[color].on })
        : leading

    const trailingNode =
      typeof trailing === 'function'
        ? trailing({ size: 30, color: theme.palette[color].on })
        : trailing

    const handleFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocus?.(event)
        setIsFocused(true)
      },
      [onFocus]
    )

    const handleBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onBlur?.(event)
        setIsFocused(false)
      },
      [onBlur]
    )

    const focusAnimation = useMemo(() => new Animated.Value(0), [])

    // Animate whenever focus changes.
    useEffect(() => {
      Animated.timing(focusAnimation, {
        toValue: isFocused ? 1 : 0,
        duration: 200,
        easing: Easing.out(Easing.ease),
        useNativeDriver: false,
      }).start()
    }, [isFocused])

    // Is the input focused or not empty?
    const isActive = useMemo(
      () => isFocused || (rest.value?.length ?? 0) > 0,
      [isFocused, rest.value]
    )

    const activeAnimation = useMemo(() => new Animated.Value(isActive ? 1 : 0), [])

    useEffect(() => {
      Animated.timing(activeAnimation, {
        toValue: isActive ? 1 : 0,
        duration: 200,
        easing: Easing.out(Easing.ease),
        useNativeDriver: false,
      }).start()
    }, [isActive])

    const styles = useStyles(({ spacing, palette, typography, shapes }) => ({
      inputContainer: {
        flexDirection: 'row',
        backgroundColor: palette[color].main,
        borderWidth: 2,
        borderColor: error
          ? palette['error'].main
          : isFocused
          ? palette['secondary'].main
          : 'transparent',
      },
      leading: {
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing(8),
        height: spacing(8),
        marginStart: spacing(2),
        marginVertical: spacing(2),
      },
      trailing: {
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing(8),
        height: spacing(8),
        marginEnd: spacing(2),
        marginVertical: spacing(2),
      },
      labelContainer: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        height: spacing(12),
        start: leadingNode ? spacing(16) : spacing(4),
      },
      input: {
        ...typography.h4,
        borderRadius: shapes.medium.borderRadius - 2,
        borderColor: 'transparent',
        borderWidth: 1,
        flex: 1,
        padding: spacing(4),
        paddingBottom: spacing(3),
        paddingStart: leadingNode ? spacing(6) : spacing(4),
        paddingEnd: trailingNode ? spacing(6) : spacing(4),
        backgroundColor: palette[color].main,
        color: !disabled ? palette[color].on : chroma(palette[color].on).alpha(0.5).hex(),
      },
    }))

    return (
      <View style={[style]}>
        <View style={[styles.inputContainer, theme.shapes.medium]}>
          {leadingNode && <View style={styles.leading}>{leadingNode}</View>}

          <RNTextInput
            ref={ref}
            value={rest.value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            editable={!disabled}
            style={styles.input}
            {...rest}
          />

          {trailingNode && <View style={styles.trailing}>{trailingNode}</View>}

          {label && (
            <View pointerEvents="none" style={styles.labelContainer}>
              <Animated.Text
                style={[
                  theme.typography.subtitle,
                  {
                    color: activeAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [
                        chroma(theme.palette[color].on).alpha(0.8).hex(),
                        chroma(theme.palette[color].on).alpha(0.5).hex(),
                      ],
                    }),
                    fontSize: activeAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [theme.typography.subtitle.fontSize ?? 15, 10],
                    }),
                    transform: [
                      {
                        translateY: activeAnimation.interpolate({
                          inputRange: [0, 1],
                          outputRange: [0, -18],
                        }),
                      },
                    ],
                  },
                ]}
              >
                {label}
              </Animated.Text>
            </View>
          )}
        </View>
      </View>
    )
  }
)

export default TextField
