/**
 * Taken from: https://stackoverflow.com/a/9102270
 *
 * @param url
 * @returns Id of youtube vidoe
 */
export const getYoutubeVideoId = (url: string): string | undefined => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  } else {
    //error
    return undefined
  }
}

export const youtubeEventStates: Record<number, string> = {
  '-1': 'unstarted',
  0: 'ended',
  1: 'playing',
  2: 'paused',
  3: 'buffering',
  5: 'video_cued',
}

export const youtubeEventToEnum = (state: number): string => {
  return youtubeEventStates[state]
}
