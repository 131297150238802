import chroma from 'chroma-js'
import { Key, PropsWithChildren } from 'react'
import { View, Modal as RNModal, StyleSheet, Pressable, ViewStyle } from 'react-native'
import { Portal } from '../../contexts/PortalContext'
import { useStyles } from '../../hooks/useStyles'

export interface ModalProps {
  open: boolean
  style?: ViewStyle
  key?: Key
  onClose: () => void
}

const Modal = ({ open, onClose, style, key, children }: PropsWithChildren<ModalProps>) => {
  if (!open) return null

  const styles = useStyles(() => ({
    container: {
      backgroundColor: chroma('#000').alpha(0.5).hex(),
    },
  }))
  return (
    <Portal key={key}>
      <View style={[StyleSheet.absoluteFill, styles.container]}>
        <RNModal visible={open} transparent={true} animationType="slide">
          <View style={[{ flex: 1 }, style]}>{children}</View>
        </RNModal>
      </View>
    </Portal>
  )
}

export default Modal
